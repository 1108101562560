import './active-student-bar.css';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ActiveStudentBar = () => {

    const { studentStatusList, colorList } = useSelector((state: any) => state.student);

    const [slicedStudentList, setSlicedStudentList] = useState([]);
    const [isHover, setIsHover] = useState<string | null>(null);

    useEffect(() => {
        if (studentStatusList.length > 5) {
            setSlicedStudentList(studentStatusList.slice(0, 5));
        } else {
            setSlicedStudentList(studentStatusList);
        }
    }, [studentStatusList]);


    return (
        <>
            <div className="student-tool-bar bg-b100" style={{ top: 60, justifyContent: 'flex-end' }}>

                <div className="active-right-btn-group">
                    {slicedStudentList.map((student: any, index: any) => {
                        const colorIndex = colorList.findIndex((item: any) => item.tabID === student.tabID);
                        console.log('colorIndex:', colorIndex);
                        console.log(slicedStudentList.length, colorList.length);
                        let color = '#28AAE1';
                        let borderColor = `2px solid ${color}`;
                        if (colorIndex != -1) {
                            color = colorList[colorIndex].color;
                            borderColor = `2px solid ${color}`;
                        }

                        return (
                            <div className='my-wrapper' key={student.tabID} onMouseEnter={() => setIsHover(student.tabID)} onMouseLeave={() => setIsHover(null)}>
                                <div
                                    className='active-student-profile cursor' >
                                    {student.url.length > 0 && <img src={student.url} style={{
                                        border: borderColor,
                                        borderRadius: '50%'
                                    }} />}
                                    {student.url.length == 0 && <div style={{
                                        border: borderColor,
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '40px',
                                        background: 'silver',
                                        boxSizing: 'border-box'
                                    }} />}
                                    <span> {student.initials}</span>
                                </div>
                                {isHover && student.tabID == isHover && <div className='student-profile-outer-wrapper'>
                                    <div className="caret"></div>
                                    <div data-index={index} className='student-profile-wrapper text-s0 body-m' style={{
                                        right: '0'
                                    }}>
                                        {student.username}
                                    </div>
                                </div>}

                            </div>);
                    })}
                    {
                        studentStatusList.length > 5 && (<div className='active-student-profile-count text-s0'>
                            +{studentStatusList.length - 5}
                        </div>)
                    }

                </div>
            </div>
        </>
    );
};

export default ActiveStudentBar;