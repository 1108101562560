import './audio.css';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ChatLogDataModel } from '../../../../models/chat-log-data.model';
import EditIcon from '../../../../assets/images/pencil_l.svg';
import DeleteIcon from '../../../../assets/images/student-input/delete_m.svg';
import { useParams } from 'react-router-dom';
import { StudentStatus } from '../../../../models/student-status.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import Tick from '../../../../assets/images/owner_tick_s.svg';
import { PARENT_TYPE_CONSTANTS } from '../../../../constants/parentTypeConstants';
export interface IProps {
    fileUploaded: boolean | undefined;
    parentType:string;
    mode:string;
    editable: boolean;
    component: ChatLogDataModel;
    handleEdit: () => void;
    handleDelete: () => void;
    handleClick: (item: ChatLogDataModel) => void;
    currentComponent: boolean;
    triggerStudentInput?: boolean;
    focusList: string[];
    studentStatusList: StudentStatus[];
    handleReleaseFocus?: () => void;
    currentItem: DraggableElement | null;
    currentChatLog: ChatLogDataModel | null;
    colorList: any;
    isWorksheetLocked: boolean;
}
const Audio: FC<IProps> = ({ mode,editable, component, handleClick, handleDelete, handleEdit, currentComponent, triggerStudentInput,
    focusList, studentStatusList, handleReleaseFocus,
    fileUploaded,
    currentItem,
    parentType,
    currentChatLog,
    colorList,
    isWorksheetLocked
}) => {
    const params = useParams<{ id?: string; sheetId?: string; token?: string }>();
    const { token } = params;
    const [editText, setEditText] = useState<boolean>(false);
    const isLocked = useRef<boolean>(false);
    const isMe = useRef<boolean>(false);

    const [activeStudentData, setActiveStudentData] = useState<any>({ name: '', color: '' });
    const [componentColor, setComponentColor] = useState<string>('black');
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const audioElem = useRef<HTMLAudioElement>(null);

    const conditionAdded = () => {
        console.log(isLocked.current, isMe.current);
        console.log('conditionAdded:', isLocked.current || isMe.current);
        console.log('currentChatlog', component.data.text);
        return ((isLocked.current || isMe.current) && selectedId);
    };
    const getBorder = () => {
        if ((isLocked.current || isMe.current) && selectedId) {
            return activeStudentData.color;
        } else if (currentChatLog && currentChatLog.id === component.id) {
            return '2px solid #4A9BFF';
        } else {
            return '';
        }
    };
    const setValueForIsLocked = (value: boolean) => {
        isLocked.current = value;
    };

    const setValueForActiveStudentData = (obj: any) => {
        setActiveStudentData(obj);
    };

    const setValueForIsMe = (value: boolean) => {
        isMe.current = value;
    };

    const [selectedId, setSelectedId] = useState<boolean>(false);

    useEffect(() => {
        const selectedId = studentStatusList.map((studentStatus: StudentStatus) => studentStatus.componentId);

        setValueForIsLocked(false);
        setValueForIsMe(false);


        const index = selectedId.indexOf(component.id);
        let selected = false;
        if (index > -1) {
            selected = true;
        } else {
            selected = false;
        }

        const focusByMeIndex = studentStatusList.findIndex((studentStatus: StudentStatus) =>
            studentStatus.userAction === 'focus' && studentStatus.componentId === component.id && studentStatus.tabID === sessionStorage.tabID);
        let focusComponenetByMe = '0';
        if (focusByMeIndex > -1)
            focusComponenetByMe = studentStatusList[focusByMeIndex].componentId;
        if (selected) {
            // handleClickParent(currentItem);
            console.log('selected:', component.id);
            studentStatusList.map((studentStatus: StudentStatus) => {
                if (studentStatus.componentId == component.id) {
                    if (studentStatus.userAction === 'focus') {
                        // handleClickParent(currentItem);
                        if (focusComponenetByMe !== studentStatus.componentId) {
                            console.log('focus by other');
                            setSelectedId(true);
                            setValueForIsLocked(true);
                            setValueForIsMe(false);
                            //search color
                            const index = colorList.findIndex((item: any) =>
                                item.tabID === studentStatus.tabID
                            );
                            if (index > -1) {
                                const color = colorList[index].color;
                                setComponentColor(color);
                                setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                            } else {
                                const color = '#28AAE1';
                                setComponentColor(color);
                                setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                            }
                            console.log('name', studentStatus.username);
                            console.log('active:', activeStudentData);
                            console.log('component color', componentColor);
                            console.log('isLocked:', isLocked.current);
                            console.log('isMe:', isMe.current);
                        } else if (focusComponenetByMe === studentStatus.componentId && studentStatus.tabID !== sessionStorage.tabID) {
                            console.log('focus by me and other');
                            // place logic here
                            if (studentStatus.tabID > sessionStorage.tabID) {
                                console.log('else release');
                                if (handleReleaseFocus)
                                    handleReleaseFocus();
                                setSelectedId(true);
                                setValueForIsLocked(true);
                                setValueForIsMe(false);
                                //search color
                                const index = colorList.findIndex((item: any) =>
                                    item.tabID === studentStatus.tabID
                                );
                                if (index > -1) {
                                    const color = colorList[index].color;
                                    setComponentColor(color);
                                    setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                                } else {
                                    const color = '#28AAE1';
                                    setComponentColor(color);
                                    setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                                }

                            } else {
                                console.log('else else release');
                                setValueForIsLocked(false);
                                setValueForIsMe(false);
                                setValueForActiveStudentData({ name: '', color: 'release' });
                            }
                        } else if (focusComponenetByMe === studentStatus.componentId && studentStatus.tabID == sessionStorage.tabID) {
                            console.log('focus by me and only');
                            //search color
                            const index = colorList.findIndex((item: any) =>
                                item.tabID === studentStatus.tabID
                            );
                            console.log('index:', index);
                            if (index > -1) {
                                const color = colorList[index].color;
                                setComponentColor(color);
                                console.log('index and color :', color);
                                setValueForActiveStudentData({ name: 'You', color: `2px solid ${color}` });
                                setValueForIsLocked(false);
                                setValueForIsMe(true);
                                setSelectedId(true);
                            } else {
                                const color = '#28AAE1';
                                setComponentColor(color);
                                setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                                setValueForIsLocked(false);
                                setValueForIsMe(true);
                                setSelectedId(true);
                            }
                            console.log('student name :', studentStatus.username);
                            console.log('active:', activeStudentData);
                            console.log('isLocked:', isLocked.current);
                            console.log('isMe:', isMe.current);
                        }
                    } else if (studentStatus.userAction === 'submit') {
                        if (studentStatus.tabID !== sessionStorage.tabID) {
                            console.log('submit by other then reload');
                            setValueForIsLocked(false);
                            setValueForIsMe(false);
                            setValueForActiveStudentData({ name: '', color: 'submit' });
                            // if (handleStudentInputUpdate)
                            //     handleStudentInputUpdate(component.id);
                        }
                    }
                }
            });
        } else {
            console.log('not current component');
            setValueForIsLocked(false);
            setValueForIsMe(false);
            setSelectedId(false);
        }

        if(audioElem.current){
            try{
                audioElem.current.load();
            }catch(e){
                console.log('error', e);
            }
        }
    }, [studentStatusList, triggerStudentInput, focusList]);

    // audio play and pause when file is uploaded
    useEffect(() => {
        if(audioElem.current && !currentChatLog){
            try{
                if (isPlaying ) {
                    audioElem.current.load();
                    audioElem.current.play();
                }
                else {
                    audioElem.current.pause();
                }
            }catch(e){
                console.log('error', e);
            }
        }
        setIsPlaying(false);
    }, [isPlaying]);

    useEffect(() => {
        setIsPlaying(true);
    }, [fileUploaded]);
    



    

    const getPaddingBottom=()=>{
        if(mode === 'collab'){
            if(parentType === PARENT_TYPE_CONSTANTS.viewSubmission)
                return '6px 16px 6px 16px';
            else if(currentChatLog && currentChatLog.id == component.id)
                return '6px 16px 6px 16px';
            else '12px';
        }else{
            return '12px';
        }
    };

    // get random string
    const getRandomString = (length: number) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const randString = getRandomString(10);


    

    return (
        <div className='chat-element-wrapper-audio' key={component.id}
            data-component-id={component.id}
            style={{
                border: getBorder()
            }}
        >
            {editable && (
                <div className="ghost-wrapper" onClick={() => !token && !isWorksheetLocked && !isLocked.current && handleClick(component)} style={{
                    display: currentChatLog && currentChatLog.id == component.id ? 'none' : 'block'
                }}> </div>
            )}


            <audio
                key={component.id}
                id={randString}
                style={{
                    // padding: mode === 'collab' ? '16px':'8px',
                    marginBottom: getPaddingBottom(),
                }}
                controls
                preload='auto'
                ref={audioElem}
                className="chat-audio"  >
                <source id={randString} src={component.data.url ? component.data.url : ''} />
                Your browser does not support the audio element.
            </audio>
            {((parentType === PARENT_TYPE_CONSTANTS.viewSubmission && mode === 'collab') || (mode === 'collab' && (currentChatLog && currentChatLog.id === component.id))) && 
                <div className="owner">
                    <img src={Tick} alt="tick" />
                    <span className='text-c50'>Added by&nbsp;</span>
                    <span className='text-c75'>{component.studentName}</span>
                </div>
            }
            {currentChatLog && currentChatLog.id == component.id && <div className="chat-tool-wrapper">
                <img src={EditIcon} alt="" className='cursor' onClick={handleEdit} style={{
                    margin: '4px 6px 4px 0px'
                }} />
                <img src={DeleteIcon} alt="" className='cursor' style={{
                    margin: '4px 0px 4px 6px'
                }} onClick={handleDelete} />
            </div>}
            {conditionAdded() && <div className="lock-user body-m text-s0"
                style={{
                    background: componentColor
                }}>
                {activeStudentData.name}
            </div>}
        </div>
    );
};

export default Audio;