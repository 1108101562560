import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
    getStudentInputDetail,
    getWorksheetDetail,
    getWorksheetDetailWithToken,
} from '../../../api/worksheet/studentApi';
import { worksheetListApi } from '../../../api/worksheet/worksheetListApi';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../constants/toolbarBtnActionConstants';
import { ChatLogDataModel } from '../../../models/chat-log-data.model';
import { DraggableElement } from '../../../models/draggable-element.model';
import { StudentStatus } from '../../../models/student-status.model';
import { WorksheetDetailModel } from '../../../models/worksheet-detail.model';

interface StudentState {
    worksheetDetail: WorksheetDetailModel | null;
    currentItem: DraggableElement | null;
    currentChatLog: ChatLogDataModel | null;
    loading: string;
    error: string | null;
    studentStatusList: StudentStatus[];
    activeStudentList: any[];
    colorList: any[];
    focusList: any[];
    canvasWidth: number;
    canvasHeight: number;
    isMobile: boolean;
    worksheetSubmitting: boolean;
    currentModalType: string | null;
    triggerStudentInput: boolean;
    maxBase: number;
    maxScore: number;
}

const initialState = {
    worksheetDetail: null,
    currentItem: null,
    currentChatLog: null,
    loading: 'idle',
    error: null,
    studentStatusList: [],
    activeStudentList: [],
    colorList: [],
    focusList: [],
    canvasWidth: 720,
    canvasHeight: 720,
    isMobile: false,
    worksheetSubmitting: false,
    currentModalType: null,
    triggerStudentInput: false,
    maxBase: 0,
    maxScore: 0,
} as StudentState;

export const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        test: (state: any) => {
            console.log('');
        },
        setCurrentItem: (
            state: any,
            action: PayloadAction<DraggableElement | null>
        ) => {
            state.currentItem = action.payload;
            console.log('current Item changed for ');
        },
        setCurrentChatLog: (
            state: any,
            action: PayloadAction<ChatLogDataModel | null>
        ) => {
            console.log('set chat log');
            state.currentChatLog = action.payload;
        },
        setChatLogItem: (
            state: any,
            action: PayloadAction<ChatLogDataModel>
        ) => {
            console.log('setChatLogItem');
            console.log('currentChatLog:', state.currentItem);
            const index = state.worksheetDetail.components.findIndex(
                (component: DraggableElement) => {
                    console.log(component.id, state.currentItem.id);
                    return component.id == state.currentItem.id;
                }
            );
            console.log('found index:', index);
            if (state.worksheetDetail.components[index].data.chatLog == null) {
                state.worksheetDetail.components[index].data.chatLog = [
                    action.payload,
                ];
            } else {
                state.worksheetDetail.components[index].data.chatLog.push(
                    action.payload
                );
            }
        },
        resetWorksheetDetail: (state: any) => {
            state.worksheetDetail = null;
        },
        deleteStudentInputStore: (state: any) => {
            const currentItemIndex = state.worksheetDetail.components.findIndex(
                (item: DraggableElement) => item.id == state.currentItem.id
            );

            const logIndex = state.worksheetDetail.components[
                currentItemIndex
            ].data.chatLog.findIndex(
                (log: ChatLogDataModel) => log.id == state.currentChatLog.id
            );
            state.worksheetDetail.components[
                currentItemIndex
            ].data.chatLog.splice(logIndex, 1);
            console.log(currentItemIndex, logIndex);
        },
        updateChatLog: (state: any, action: PayloadAction<any>) => {
            const { name, url,studentName } = action.payload;
            const currentItemIndex = state.worksheetDetail.components.findIndex(
                (item: DraggableElement) => item.id == state.currentItem.id
            );

            const logIndex = state.worksheetDetail.components[
                currentItemIndex
            ].data.chatLog.findIndex(
                (log: ChatLogDataModel) => log.id == state.currentChatLog.id
            );

            state.worksheetDetail.components[currentItemIndex].data.chatLog[
                logIndex
            ].data = {
                ...state.worksheetDetail.components[currentItemIndex].data
                    .chatLog[logIndex].data,
                url,
                name,
            };
            state.worksheetDetail.components[currentItemIndex].data.chatLog[
                logIndex
            ].studentName = studentName;
        },
        setStudentStatus: (state, action: PayloadAction<StudentStatus[]>) => {
            state.studentStatusList = action.payload;
            console.log('i change studentStatusList');
        },
        setActiveStudent: (state, action: PayloadAction<any[]>) => {
            state.activeStudentList = action.payload;
        },
        setColorList: (state, action: PayloadAction<any[]>) => {
            state.colorList = action.payload;
        },
        setIsMobile: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload;
        },
        setFocusList: (state, action: PayloadAction<any[]>) => {
            state.focusList = action.payload;
        },
        setWorksheetSubmittingStatus: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.worksheetSubmitting = action.payload;
        },

        setModalType: (state, action: PayloadAction<string | null>) => {
            console.log('here setModalType ', action.payload);
            state.currentModalType = action.payload;
        },
        setTriggerStudentInput: (state, action: PayloadAction<boolean>) => {
            state.triggerStudentInput = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWorksheetDetail.pending, (state, action) => {
            if (state.loading === 'idle') {
                console.log('state.worksheets.loading === idle');
                state.loading = 'pending';
            }
        });
        builder.addCase(getWorksheetDetail.fulfilled, (state, action) => {
            state.worksheetDetail = action.payload;
            let maxWidth = state.canvasWidth;
            let maxHeight = state.canvasHeight;
            let maxBase = 0;
            let maxScore = 0;
            state.worksheetDetail.components.map(
                (element: DraggableElement) => {
                    if (
                        element.location.x + element.size.width >
                        state.canvasWidth
                    )
                        maxWidth =
                            element.location.x + element.size.width + 500;
                    if (
                        element.location.y + element.size.height >
                        state.canvasHeight
                    )
                        maxHeight =
                            element.location.y + element.size.height + 500;

                    //calculate score
                    if (element.type == TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
                        maxBase += element.data.score.base
                            ? element.data.score.base
                            : 0;
                        maxScore += element.data.score.mark
                            ? element.data.score.mark
                            : 0;
                    }
                }
            );
            state.canvasWidth = maxWidth;
            state.canvasHeight = maxHeight;
            state.maxBase = maxBase;
            state.maxScore = maxScore;
            console.log('ful fill ', state.canvasHeight);
        });
        builder.addCase(getWorksheetDetail.rejected, (state, action) => {
            if (state.loading === 'pending') {
                console.log('state.worksheets.loading === pending');
                state.loading = 'idle';
                state.error = 'Error occured';
            }
        });
        // new
        builder.addCase(
            getWorksheetDetailWithToken.pending,
            (state, action) => {
                if (state.loading === 'idle') {
                    console.log('state.worksheets.loading === idle');
                    state.loading = 'pending';
                }
            }
        );
        builder.addCase(
            getWorksheetDetailWithToken.fulfilled,
            (state, action) => {
                state.worksheetDetail = action.payload;
                if (state.worksheetDetail) {
                    let maxWidth = state.canvasWidth;
                    let maxHeight = state.canvasHeight;
                    let maxBase = 0;
                    let maxScore = 0;
                    state.worksheetDetail.components.map(
                        (element: DraggableElement) => {
                            if (
                                element.location.x + element.size.width >
                                state.canvasWidth
                            )
                                maxWidth =
                                    element.location.x +
                                    element.size.width +
                                    500;
                            if (
                                element.location.y + element.size.height >
                                state.canvasHeight
                            )
                                maxHeight =
                                    element.location.y +
                                    element.size.height +
                                    500;

                            //calculate score
                            if (
                                element.type ==
                                TOOLBAR_BTN_ACTION_CONSTANTS.GRADING
                            ) {
                                maxBase += element.data.score.base
                                    ? element.data.score.base
                                    : 0;
                                maxScore += element.data.score.mark
                                    ? element.data.score.mark
                                    : 0;
                            }
                        }
                    );
                    state.canvasWidth = maxWidth;
                    state.canvasHeight = maxHeight;
                    state.maxBase = maxBase;
                    state.maxScore = maxScore;
                    console.log('ful fill ', state.canvasHeight);
                }
            }
        );
        builder.addCase(
            getWorksheetDetailWithToken.rejected,
            (state, action) => {
                if (state.loading === 'pending') {
                    console.log('state.worksheets.loading === pending');
                    state.loading = 'idle';
                    state.error = 'Error occured';
                }
            }
        );
        // new
        builder.addCase(getStudentInputDetail.fulfilled, (state, action) => {
            console.log('in store ', action.payload);
            const element: DraggableElement = action.payload;
            if (state.worksheetDetail) {
                const index = state.worksheetDetail.components.findIndex(
                    (component: DraggableElement) => component.id == element.id
                );
                state.worksheetDetail.components[index] = element;
                console.log('index:', index);
            }
        });
    },
});

export const {
    test,
    setCurrentItem,
    setCurrentChatLog,
    setChatLogItem,
    deleteStudentInputStore,
    updateChatLog,
    setStudentStatus,
    setActiveStudent,
    setColorList,
    setIsMobile,
    setFocusList,
    setWorksheetSubmittingStatus,
    setModalType,
    setTriggerStudentInput,
    resetWorksheetDetail
} = studentSlice.actions;

export default studentSlice.reducer;
