import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ActivityCard from '../activity-card/activity-card';
import { Activity } from '../../../../models/activity-log.model';
import './activity-list.css';
import ReactPaginate from 'react-paginate';
import { AppDispatch } from '../../../../store/store';
import { useSelector } from 'react-redux';

export interface IProps {
    activityList: Activity[];
    onPageChange: (page: number) => void;
}

const ActivityList: FC<IProps> = ({
    activityList,
    onPageChange
}) => {

    const totalLogs = useSelector((state: any) => state.activityLog.totalLogs);
    const [page, setPage] = useState<number>(1);

    const handlePageClick = (event: any) => {

        console.log('onPageClick', event);

        const pageNumber = event.selected + 1;
        console.log('pageNumber', pageNumber);
        setPage(pageNumber);
        onPageChange(pageNumber);

    };

    //console.log('activityList ', activityList);
    console.log('TotalLogs', Math.floor(totalLogs / 10));
    return (
        <>
            <div className="activity-list">
                {/* <div className="activity-header h2-strong text-c300">
                    Activity Log
                </div> */}
                <div className="activities">
                    <div className="list-header">
                        <div className="students-col text-c100 caption-u">
                            Users
                        </div>
                        <div className="actions-col text-c100 caption-u">
                            Actions
                        </div>
                        <div className="date-time-col text-c100 caption-u">
                            Date & time
                        </div>
                    </div>
                    <div className="">
                        {activityList.map((item: Activity, index: any) => (
                            <ActivityCard key={index} item={item} />
                        ))}
                    </div>
                    {
                        activityList && activityList.length != 0 &&
                        <div
                            className='pagination-wrapper'>
                            <div
                                className='range'>
                                <span
                                    className='range-label'>
                                    Displaying
                                </span>
                                <span
                                    className='range-value'>
                                    {`${(page * 10) - 9} - ${(page * 10) > totalLogs ? totalLogs : (page * 10)} of ${totalLogs}`}
                                </span>
                            </div>
                            <ReactPaginate
                                nextLabel="Next  >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                pageCount={Math.floor(totalLogs / 10) + ((totalLogs % 10 != 0) ? 1 : 0)}
                                previousLabel="<  Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName={page == 1 ? 'page-change-btn-disabled' : 'page-change-btn'}
                                previousLinkClassName="page-link"
                                nextClassName={page * 10 >= totalLogs ? 'page-change-btn-disabled' : 'page-change-btn'}
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={undefined}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    );
};
export default ActivityList;
