import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import worksheetReducer from '../features/teacher/slices/worksheetSlice';
import studentReducer from '../features/student/slices/studentSlice';
import worksheetListReducer from '../features/worksheets-home/worksheetshome_slice';
import { worksheetListApi } from '../api';
import activityLogReducer from '../features/activity-log/activityLogSlice';
import templateListReducer from '../features/template-library/templateLibrary_slice';
import publishWorksheetReducer from '../features/publish-worksheet/publishworksheet-slice';
import viewSubmissionsReducer from '../features/view-submission/view-submission-slice';
import createWorksheetReducer from '../features/create-worksheet/createworksheet_slice';
export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        [worksheetListApi.reducerPath]: worksheetListApi.reducer,
        worksheet: worksheetReducer,
        worksheetsHome: worksheetListReducer,
        student: studentReducer,
        activityLog: activityLogReducer,
        templateLibrary: templateListReducer,
        publishWorksheet: publishWorksheetReducer,
        viewSubmissions: viewSubmissionsReducer,
        createWorksheet: createWorksheetReducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(worksheetListApi.middleware),
});

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
