import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import CustomLoading from '../../../../global-components/loading/loading';
import './submission-loading.css';

// export interface IProps {
// }
const SubmissionLoading = () => {
    const studentlistArr = [1, 2, 3, 4, 5, 6];
    const sublistArr = [1, 2, 3];

    return (
        <>
            <div className="sub-loading-view">
                <div className="student-list-loading">
                    <div className='subTitle-loading'>
                        <CustomLoading loadingData='listTileLoading'></CustomLoading>
                    </div>
                    {studentlistArr.map((index: any) => (
                        <div key={index} className='student-loading'>
                            <div>
                                <CustomLoading loadingData='profileLoading'></CustomLoading>
                            </div>
                            <div>
                                <CustomLoading loadingData='nameLoading'></CustomLoading>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <div className='d-flex justify-right'>
                        <CustomLoading loadingData='subTitleLoading1'></CustomLoading>
                        <CustomLoading loadingData='subTitleLoading3'></CustomLoading>
                    </div>
                    <div>
                        <CustomLoading loadingData='subTitleLoading2'></CustomLoading>
                    </div>
                    {sublistArr.map((ind: any) => (
                        <div key={ind} className='submission-list-loading'>
                            {sublistArr.map((index: any) => (
                                <div key={index} className='submission-loading'>
                                    <CustomLoading loadingData='subLoading1'></CustomLoading>
                                    <CustomLoading loadingData='subLoading2'></CustomLoading>
                                    <CustomLoading loadingData='subLoading3'></CustomLoading>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default SubmissionLoading;