import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../global-components/button/Button';
import { BtnModel } from '../../models/btn.model';
import { Group } from '../../models/group.model';
import { StudentListModel } from '../../models/student-list.model';
import { Worksheet } from '../../models/worksheet-item.model';
import './publish-worksheet.css';
import StudentProfileDecor from '../../assets/images/student_profile_decor.svg';
import EmergingIcon from '../../assets/images/btnEmerging.svg';
import ExpectedIcon from '../../assets/images/btnExpected.svg';
import ExceedingIcon from '../../assets/images/btnExceeding.svg';
import Checkbox from '../../global-components/checkbox/Checkbox';
import SearchInput from '../../global-components/search-input/search-input';
import { AppDispatch } from '../../store/store';
import { getGroupsByClass } from '../../api/global/globalApi';
import { getStudentsApi } from '../../api/global/globalApi';
import UploadThumbnail from '../../global-components/upoad-thumbnail/upload-thumbnail';

export interface IProps {
    worksheet: Worksheet;
    clickCancel: () => void;
    clickAssign: (data: any, file: File | undefined, isDeleteThumbnail: boolean) => void;
}

const PublishWorksheet: FC<IProps> = ({
    worksheet,
    clickCancel,
    clickAssign,
}) => {
    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel-l',
        iconClass: undefined,
    };

    const saveBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Assign',
        uiClass: 'assign',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Assign',
        uiClass: 'disabled-m',
        iconClass: undefined,
        loadingText: 'Assigning'
    };

    const dispatch = useDispatch<AppDispatch>(); //use AppDispatch for redux thunk

    //for upload thumbnail
    const [file, setFile] = useState<File | undefined>(undefined);
    const [isDeleteThumbnail, setIsDeleteThumbnail] = useState<boolean>(false);

    const [isLoadingBtn, setisLoadingBtn] = useState(false);

    const handleUploadThumbnail = (file: File | undefined) => {
        setFile(file);
        setIsDeleteThumbnail(false);
    };

    const handleDeleteThumbnail = () => {
        setFile(undefined);
        setIsDeleteThumbnail(true);
    };

    const onClickCancel = () => {
        clickCancel();
    };

    const onClickAssign = () => {
        console.log(
            'onClickAssign------',
            stateGroups,
            stateStudents,
            enabledGroupWorksheet
        );
        const publishObj: any = {
            name: worksheet.name,
            mode: enabledGroupWorksheet == true ? 'collab' : 'single',
            students: [],
            groups: [],
        };
        const selectedStudents = stateStudents.filter(
            (student: any) => student.checked == true
        );
        console.log('selectedStudents', selectedStudents);
        publishObj.students = selectedStudents.map((data: any) => {
            const studentObj = {
                _id: data.pwUserId,
            };
            return studentObj;
        });
        const selectedGroups = stateGroups.filter(
            (group: any) => group.checked == true
        );
        console.log('selectedGroups', selectedGroups);
        publishObj.groups = selectedGroups.map((data: any) => {
            const groupStudents = data.students.map((student: any) => {
                const groupStudent = { _id: student.metadata != undefined && student.metadata.pwUserId != undefined && student.metadata.pwUserId != '' ? student.metadata.pwUserId : student._id };
                return groupStudent;
            });
            const groupStudentObj = {
                students: groupStudents,
            };
            return groupStudentObj;
        });
        console.log('publishObj', publishObj);
        const publishData = {
            worksheetId: worksheet.id,
            body: publishObj,
        };
        setisLoadingBtn(true);
        clickAssign(publishData, file, isDeleteThumbnail);
    };

    // const groups: Group[] = [
    //     new Group('1', 'for Emerging group', EmergingIcon),
    //     new Group('2', 'for Expected group', ExpectedIcon),
    //     new Group('3', 'for Excedding group', ExceedingIcon),
    // ];

    // const students: StudentListModel[] = [
    //     {
    //         checked: false,
    //         id: '1',
    //         name: 'Bessie Tanya',
    //     },
    //     {
    //         checked: false,
    //         id: '2',
    //         name: 'Dianne Russell',
    //     },
    //     {
    //         checked: false,
    //         id: '3',
    //         name: 'Esther Howard',
    //     },
    //     {
    //         checked: false,
    //         id: '4',
    //         name: 'Floyd Miles',
    //     },
    //     {
    //         checked: false,
    //         id: '5',
    //         name: 'Jane Cooper',
    //     },
    //     {
    //         checked: false,
    //         id: '6',
    //         name: 'John Warren',
    //     },
    //     {
    //         checked: false,
    //         id: '7',
    //         name: 'Kathryn Miles',
    //     },
    //     {
    //         checked: false,
    //         id: '8',
    //         name: 'Marvin McKinney',
    //     },
    //     {
    //         checked: false,
    //         id: '9',
    //         name: 'Priscilla Edwards',
    //     },
    // ];

    const [checkedAllStudents, setCheckAllStudents] = useState(false);
    const [enabledGroupWorksheet, setEnabledGroupWorksheet] = useState(worksheet.mode === 'collab' ? true : false);
    const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
    const handleWorksheetNameChange = (
        e: React.FormEvent<HTMLInputElement>
    ) => {
        console.log('handleWorksheetNameChange', e.currentTarget.value);
    };

    const handleOnGroupCheck = (id: string, value: boolean) => {
        const tmpGroups = JSON.parse(JSON.stringify(stateGroups));
        tmpGroups[tmpGroups.findIndex((group: any) => group.id === id)].checked = value;
        setStateGroups(tmpGroups);
        console.log('handleOnGroupCheck', tmpGroups[tmpGroups.findIndex((group: any) => group.id === id)]);
    };

    const handleOnStudentCheck = (id: string, value: boolean) => {
        const tmpStudents = JSON.parse(JSON.stringify(stateStudents));
        tmpStudents[tmpStudents.findIndex((student: any) => student.id === id)].checked = value;
        setStateStudents(tmpStudents);
        console.log('handleOnStudentCheck', tmpStudents[tmpStudents.findIndex((student: any) => student.id === id)]);
    };

    const handleCheckAllStudents = (id: string, value: boolean) => {
        setCheckAllStudents(value);
        const tmpStudents = JSON.parse(JSON.stringify(stateStudents));

        for (let i = 0; i < tmpStudents.length; i++) {
            tmpStudents[i].checked = value;
        }

        setStateStudents(tmpStudents);
    };

    const handleEnableGroupWorksheet = (id: string, value: boolean) => {
        setEnabledGroupWorksheet(value);
    };

    const handleSearch = (keyword: string | null) => {
        console.log('Searching Students:', keyword);
        setSearchKeyword(keyword);
    };

    const getNameInitials = (name: string) => {
        if (name == '') return '';

        const nameInWords = name.split(' ');

        if (nameInWords.length == 1) return nameInWords[0][0];

        return nameInWords[0][0] + nameInWords[nameInWords.length - 1][0];
    };

    // for redux thunk
    const labels = useSelector((state: any) => state.publishWorksheet.labels);
    const groups: Group[] = labels.labelList;
    const studentsState = useSelector(
        (state: any) => state.publishWorksheet.students
    );
    const studentList: StudentListModel[] = studentsState.studentList;

    const [labelChange, setlabelChange] = useState(false);

    const getLabelButton = (labelId: string) => {
        let button;
        switch (labelId) {
            case '62e373088599581bb880e26e':
                // Expected group
                // button = 'assets/images/btnExpected.svg';
                button = ExpectedIcon;
                break;
            case '62e79f6cd53e870014fff9ff':
                // Emerging group
                button = EmergingIcon;
                break;
            case '62e79f74d53e870014fffa01':
                // Exceeding group
                button = ExceedingIcon;
                break;
        }
        return button;
    };

    const [studentListChange, setstudentListChange] = useState(false);

    const getLabels = async () => {
        try {
            const getLabelGroups = await dispatch(getGroupsByClass()).unwrap();
            console.log('success:', getLabelGroups);
            setlabelChange(true);
            setStateGroups(getLabelGroups);
        } catch (err: any) {
            console.log('error', `Fetch failed: ${err.message}`);
        }
    };

    // const [stateGroups, setStateGroups] = useState(labels.labelList);
    const [stateGroups, setStateGroups] = useState(groups);
    const [stateStudents, setStateStudents] = useState(studentList);

    const getStudents = async () => {
        try {
            const getStudentList = await dispatch(getStudentsApi()).unwrap();
            console.log('getStudents > success:', getStudentList);
            const checkedStudents = worksheet.students;
            let count = 0;
            const studentList = JSON.parse(JSON.stringify(getStudentList));
            studentList.map((student: any) => {
                const idx = checkedStudents.findIndex((studentId: string) => studentId == student.pwUserId);
                if (idx != -1) {
                    student.checked = true;
                    count += 1;
                }
            });
            console.log('count', count);
            if (count == studentList.length) {
                setCheckAllStudents(true);
            }
            setstudentListChange(true);
            setStateStudents(studentList);
        } catch (err: any) {
            console.log('getStudents > error', `Fetch failed: ${err.message}`);
        }
    };

    // for redux thunk
    useEffect(() => {
        if (!labelChange) getLabels();
        if (!studentListChange) getStudents();
    }, [dispatch]);

    return (
        <>
            <div className="container">
                <div className="h4-strong text-c300 mt-16">Worksheet Name*</div>
                <input
                    type="text"
                    id="worksheet_name"
                    className="light-body-m text-c300 name-input mt-8"
                    value={worksheet.name}
                    placeholder="Type Worksheet Name..."
                    onChange={handleWorksheetNameChange}
                    readOnly
                />
                {/* <div className="mt-32 checkables-header">
                    <span className="h4-strong text-c300">
                        Assign to groups
                    </span>
                    <span className="body-m text-s70 ml-4">(optional)</span>
                </div>
                <div className="groups-wrapper mt-16">
                    {stateGroups.map((item: Group, index: any) => (
                        <Checkbox
                            key={item.id}
                            uiClass={'ml-60'}
                            checked={item.checked}
                            handleOnCheck={handleOnGroupCheck}
                            item={item}
                        >
                            <div className="group ml-8" key={index}>
                                <img src={getLabelButton(item.label.id)} />
                                <span className="body-m text-c300 ml-8">
                                    {item.name}
                                </span>
                            </div>
                        </Checkbox>
                    ))}
                </div> */}
                <div>
                    <Checkbox
                        item={{ id: 'select-all' }}
                        uiClass="mt-20"
                        checked={enabledGroupWorksheet}
                        handleOnCheck={handleEnableGroupWorksheet}
                    >
                        <span className="body-l text-c300 ml-16">
                            enable the group worksheet
                        </span>
                    </Checkbox>
                </div>
                <div className="mt-32 checkables-header">
                    <span className="h4-strong text-c300">Assign Students</span>
                    <span className="body-m text-s70 ml-4">(optional)</span>
                </div>
                <div className="students-container mt-16">
                    <div className="search-wrapper">
                        <SearchInput
                            hint={'Search student'}
                            handleSearch={handleSearch}
                            width={'430px'}
                        />
                    </div>
                    <div className="students-wrapper">
                        {!searchKeyword && stateStudents.map(
                            (item: StudentListModel, index: any) => (
                                <Checkbox
                                    key={item.id}
                                    uiClass={'ml-60'}
                                    checked={item.checked}
                                    handleOnCheck={handleOnStudentCheck}
                                    item={item}
                                >
                                    <div className="student ml-16" key={index}>
                                        <div className="student-profile">
                                            <span className="body-m text-c300">
                                                {getNameInitials(item.name)}
                                            </span>
                                            {/* <img
                                                className='student-profile-decor' 
                                                src={StudentProfileDecor} /> */}
                                        </div>
                                        <span className="body-m text-c300 ml-16">
                                            {item.name}
                                        </span>
                                    </div>
                                </Checkbox>
                            )
                        )}
                        {searchKeyword && stateStudents.map((item: StudentListModel, index: any) => {
                            if (item.name.toLowerCase().includes(searchKeyword.toLowerCase()))
                                return (<Checkbox
                                    key={item.id}
                                    uiClass={'ml-60'}
                                    checked={item.checked}
                                    handleOnCheck={handleOnStudentCheck}
                                    item={item}
                                >
                                    <div className="student ml-16" key={index}>
                                        <div className="student-profile">
                                            <span className="body-m text-c300">
                                                {getNameInitials(item.name)}
                                            </span>
                                            {/* <img
                                            className='student-profile-decor' 
                                            src={StudentProfileDecor} /> */}
                                        </div>
                                        <span className="body-m text-c300 ml-16">
                                            {item.name}
                                        </span>
                                    </div>
                                </Checkbox>);
                        })}
                    </div>
                    <div className="students-footer">
                        <Checkbox
                            item={{ id: 'select-all' }}
                            uiClass="mt-60"
                            checked={checkedAllStudents}
                            handleOnCheck={handleCheckAllStudents}
                        >
                            <span className="body-l text-c300 ml-16">
                                Select all
                            </span>
                        </Checkbox>
                    </div>
                </div>
                <div className='mb-48'>
                    <UploadThumbnail worksheet={worksheet} handleUpload={handleUploadThumbnail} handleDelete={handleDeleteThumbnail} />
                </div>
            </div>
            <div className="modal-footer">
                <span className="mr-16">
                    <Button
                        btnProps={cancelBtnProps}
                        handleBtnClicked={onClickCancel}
                    />
                </span>
                {!isLoadingBtn ? (
                    <Button
                        btnProps={worksheet.name === '' ? disabledBtnProps : saveBtnProps}
                        handleBtnClicked={onClickAssign}
                    />
                ) : (
                    <Button
                        btnProps={disabledBtnProps}
                        isLoading={isLoadingBtn}
                        handleBtnClicked={onClickAssign}
                    />
                )}
            </div>
        </>
    );
};

export default PublishWorksheet;
