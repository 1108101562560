import React, { FC } from 'react';
import './list-item.css';
import { Worksheet } from '../../../../models/worksheet-item.model';
import { OverlayMenu } from '../../../../models/overlay-menu';
import PencilIcon from '../../../../assets/images/pencil_m.svg';
import OverlayNavigation from '../../../../global-components/overlay-navigation/overlay-navigation';
import { OVERLAY_MENU_CONSTANTS } from '../../../../constants/overlayMenuConstants';
import LockedIcon from '../../../../assets/images/locked_s.svg';
import UnlockedIcon from '../../../../assets/images/unlocked_s.svg';

export interface IProps {
    item: Worksheet;
    handleClickMenu: (type: string, item: Worksheet) => void;
}

const ListItem: FC<IProps> = ({ item, handleClickMenu }) => {
    const unpublishedMenu: OverlayMenu[] = [
        {
            type: OVERLAY_MENU_CONSTANTS.USE_AS_TEMPLATE,
            name: 'Use as template',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.PUBLISH,
            name: 'Publish',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.CHANGE_TITLE,
            name: 'Change title',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.DELETE,
            name: 'Delete',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.EDIT,
            name: 'Edit',
        },
    ];

    const publishedUnlockMenu: OverlayMenu[] = [
        {
            type: OVERLAY_MENU_CONSTANTS.USE_AS_TEMPLATE,
            name: 'Use as template'
        },
        {
            type: OVERLAY_MENU_CONSTANTS.EDIT,
            name: 'Edit'
        },
        {
            type: OVERLAY_MENU_CONSTANTS.LOCK,
            name: 'Lock',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.UNPUBLISH,
            name: 'Unpublish',
        },
    ];

    const publishedLockMenu: OverlayMenu[] = [
        {
            type: OVERLAY_MENU_CONSTANTS.EDIT,
            name: 'Edit'
        },
        {
            type: OVERLAY_MENU_CONSTANTS.UNLOCK,
            name: 'Unlock',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.UNPUBLISH,
            name: 'Unpublish',
        },
    ];

    const publishedMenu =
        item.isLocked == true ? publishedLockMenu : publishedUnlockMenu;

    const overlayMenu =
        item.isPublished == true ? publishedMenu : unpublishedMenu;

    // const overlayMenu = (item: Worksheet) => {
    //     let menu;
    //     if(item.isPublished == true) {

    //     }
    // };

    const clickMenu = (type: string) => {
        handleClickMenu(type, item);
    };

    return (
        <>
            <div className="list-item">
                <div className="cover-wrapper">
                    <img src={item.thumbnailUrl} />
                    <span className="pencil_icon">
                        <img src={PencilIcon} />
                    </span>
                    {item.isPublished && (
                        <span className="text-s0 bg-g100 caption-u worksheet-tag">
                            published
                        </span>
                    )}
                    {item.isPublished && item.isLocked && (
                        <span className="locked_icon bg-c75">
                            <img src={LockedIcon} />
                        </span>
                    )}
                    {item.isPublished && !item.isLocked && (
                        <span className="locked_icon bg-c75">
                            <img src={UnlockedIcon} />
                        </span>
                    )}
                    <div className="overlay">
                        <div className="overlay-menu">
                            <OverlayNavigation
                                menuItem={overlayMenu}
                                clickMenu={clickMenu}
                            />
                        </div>
                    </div>
                </div>
                <div className="body-m text-c300 mt-16 item-text">
                    {item.name}
                </div>
            </div>
        </>
    );
};

export default ListItem;
