import React, { FC, useState } from 'react';
import { OVERLAY_MENU_CONSTANTS } from '../../constants/overlayMenuConstants';
import Button from '../../global-components/button/Button';
import { BtnModel } from '../../models/btn.model';
import { Worksheet } from '../../models/worksheet-item.model';
import { DeleteFormModel } from '../../models/delete-form.model';
import './delete-worksheet.css';

export interface IProps {
    type: string;
    worksheet: DeleteFormModel;
    clickCancel: () => void;
    clickDelete: (worksheet: DeleteFormModel) => void;
}

const DeleteWorksheet: FC<IProps> = ({
    type,
    worksheet,
    clickCancel,
    clickDelete,
}) => {
    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Cancel',
        uiClass: 'tertiary-btn',
        iconClass: undefined,
    };

    const deleteBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Delete',
        uiClass: 'delete-btn',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Delete',
        uiClass: 'disabled-m',
        iconClass: undefined,
        loadingText: 'Deleting'
    };

    const [isLoadingBtn, setisLoadingBtn] = useState(false);

    const onClickCancel = () => {
        clickCancel();
    };

    const onClickDelete = () => {
        setisLoadingBtn(true);
        clickDelete(worksheet);
    };

    return (
        <>
            <div className="text-c300 light-body-l mt-8 mb-24">
                Are you sure you want to delete this {type}?
            </div>
            <div className="custom-modal-footer">
                <Button
                    btnProps={cancelBtnProps}
                    handleBtnClicked={onClickCancel}
                />
                {!isLoadingBtn ?
                    <Button btnProps={deleteBtnProps} handleBtnClicked={onClickDelete} /> :
                    <Button btnProps={disabledBtnProps} isLoading={isLoadingBtn} handleBtnClicked={onClickDelete} />
                }
            </div>
        </>
    );
};

export default DeleteWorksheet;
