import './text-style-tool.css';
import React, { FC, useEffect, useState } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';

export interface IProps {
    handleBold: (value: string) => void;
    handleItalic: (value: boolean) => void;
    handleUnderline: (value: boolean) => void;
    currentItem: DraggableElement | null;
    isText: boolean;
}

const TextstyleTool: FC<IProps> = ({
    handleBold,
    handleItalic,
    handleUnderline,
    currentItem,
    isText
}) => {
    const [isBold, setIsBold] = useState<boolean>(false);
    const [isItalic, setIsItalic] = useState<boolean>(false);
    const [isUnderline, setIsUnderline] = useState<boolean>(false);

    const clickedBold = () => {
        handleBold(!isBold ? 'bold' : '');
        setIsBold(!isBold);
    };

    const clickedItalic = () => {
        handleItalic(!isItalic);
        setIsItalic(!isItalic);
    };

    const clickedUnderline = () => {
        handleUnderline(!isUnderline);
        setIsUnderline(!isUnderline);
    };

    useEffect(() => {
        if (currentItem && currentItem.data && (currentItem.data.style && currentItem.data.descStyle)) {
            if (isText && currentItem.data.style.fontWeight == 'bold') {
                setIsBold(true);
            } else if (!isText && currentItem.data.descStyle.fontWeight == 'bold') {
                setIsBold(true);
            } else {
                setIsBold(false);
            }

            if (isText && currentItem.data.style.fontStyle == 'italic') {
                setIsItalic(true);
            } else if (
                !isText &&
                currentItem.data.descStyle.fontStyle == 'italic'
            ) {
                setIsItalic(true);
            } else {
                setIsItalic(false);
            }

            if (isText && currentItem.data.style.textDecoration == 'underline') {
                setIsUnderline(true);
            } else if (
                !isText &&
                currentItem.data.descStyle.textDecoration == 'underline'
            ) {
                setIsUnderline(true);
            } else {
                setIsUnderline(false);
            }
        }

    }, [currentItem, isText]);

    return (
        <div className="text-style-tool-wrapper">
            <div
                className="text-style bold"
                onClick={clickedBold}
                style={{
                    background: isBold ? '#D9ECFF' : '',
                    borderRadius: '1px',
                    color: isBold ? '#0054C9' : '',
                }}
            >
                B
            </div>
            <div
                className="text-style italic"
                onClick={clickedItalic}
                style={{
                    background: isItalic ? '#D9ECFF' : '',
                    borderRadius: '1px',
                    color: isItalic ? '#0054C9' : '',
                }}
            >
                I
            </div>
            <div
                className="text-style underline"
                onClick={clickedUnderline}
                style={{
                    background: isUnderline ? '#D9ECFF' : '',
                    borderRadius: '1px',
                    color: isUnderline ? '#0054C9' : '',
                }}
            >
                U
            </div>
        </div>
    );
};

export default TextstyleTool;
