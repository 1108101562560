import React, { FC } from 'react';
import { Profile } from '../../models/profile.model';
import './user-profile.css';
import qrIcon from '../../assets/images/qr_code.svg';
import { useState } from 'react';

export interface IProps {
    profile: Profile | null;
    className: string | null | undefined;
    showQR: () => void;
}
const UserProfile: FC<IProps> = ({ profile, className, showQR }) => {
    // const [showQR, setshowQR] = useState<boolean>(false);

    return (
        <div className='profile-outer-wrapper'>
            <div className="caret"></div>
            <div className='profile-wrapper'>
                <div className="profile-wrapper_top">
                    <div className='profile-image'>

                        {profile?.url && <img src={profile?.url} alt="" />}
                        {!profile?.url && (<div style={{
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                            background: 'silver',
                            boxSizing: 'border-box'
                        }} />)}
                        <span className='student-initials'>{profile?.initials ? profile?.initials : ''}</span>
                    </div>

                    <span className='h5-strong text-c100'>{profile?.name}</span>
                </div>
                <div className="profile-wrapper_bottom">
                    <div className='small text-c75'>Class name :&nbsp;<span>{className}</span></div>
                </div>
                <div className="profile-wrapper_bottom ">
                    <div onClick={showQR} className='small text-c75 qr-login-btn'>
                        <img src={qrIcon} className="qr-icon" alt="" />&nbsp;Login with QR Code
                    </div>
                </div>

            </div>
        </div>

    );
};

export default UserProfile;