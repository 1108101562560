import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
import ModalWithFooter from '../../global-components/modal-with-footer/ModalWithFooter';
import Modal from '../../global-components/modal/modal';
import { BtnModel } from '../../models/btn.model';
import { CustomModal } from '../../models/custom-modal.model';
import { DraggableElement } from '../../models/draggable-element.model';
import { ElementDataModel } from '../../models/element-data.model';
import { PointModel } from '../../models/point.model';
import { StudentListModel } from '../../models/student-list.model';
import { WorksheetComponent } from '../../models/worksheet-component.model';
import AudioUpload from './components/audio-upload/AudioUpload';
import GalleryUpload from './components/gallery-upload/GalleryUpload';
import GradingBase from './components/grading-base/GradingBase';
import Group from './components/group/Group';
import VideoUpload from './components/video-upload/VideoUpload';
export interface IProps {
    showModal: boolean;
    modalLoading: boolean;
    modalData: CustomModal;
    editItem: boolean;
    position: PointModel;
    components: DraggableElement[];
    currentItem: DraggableElement;
    handleCloseModal: () => void;
    handleDataUpdate: (value: ElementDataModel) => void;
    handleSizeUpdate: (value: any) => void;
    existBackground: DraggableElement | null;
    studentList: StudentListModel[];
    updateStudentListById: (list: StudentListModel[]) => void;
    addGroupTool: () => void;
    handleAddComponent: (
        element: WorksheetComponent,
        blob?: File | undefined
    ) => void;
    handleAddComponentForGrading: (element: WorksheetComponent) => void;
}
const TeacherModal: FC<IProps> = ({
    showModal,
    modalLoading,
    modalData,
    editItem,
    position,
    components,
    currentItem,
    existBackground,
    handleCloseModal,
    handleDataUpdate,
    handleSizeUpdate,
    studentList,
    updateStudentListById,
    addGroupTool,
    handleAddComponent,
    handleAddComponentForGrading,
}) => {
    const renderChildren = (type: string) => {
        console.log('currentItem', currentItem);
        switch (type) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                return (
                    <GalleryUpload
                        modalLoading={modalLoading}
                        closeModal={handleCloseModal}
                        editItem={editItem}
                        currentId={currentId}
                        currentDragItemType={currentDragItemType}
                        position={position}
                        existBackground={existBackground}
                        handleDataUpdate={handleDataUpdate}
                        handleSizeUpdate={handleSizeUpdate}
                        addComponent={handleAddComponent}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND:
                return (
                    <GalleryUpload
                        modalLoading={modalLoading}
                        closeModal={handleCloseModal}
                        editItem={editItem}
                        currentId={currentId}
                        currentDragItemType={currentDragItemType}
                        position={position}
                        existBackground={existBackground}
                        handleDataUpdate={handleDataUpdate}
                        handleSizeUpdate={handleSizeUpdate}
                        addComponent={handleAddComponent}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                return (
                    <VideoUpload
                        modalLoading={modalLoading}
                        closeModal={handleCloseModal}
                        editItem={editItem}
                        currentId={currentId}
                        currentDragItemType={currentDragItemType}
                        position={position}
                        handleDataUpdate={handleDataUpdate}
                        handleSizeUpdate={handleSizeUpdate}
                        addComponent={handleAddComponent}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                return (
                    <AudioUpload
                        modalLoading={modalLoading}
                        closeModal={handleCloseModal}
                        editItem={editItem}
                        currentId={currentId}
                        currentDragItemType={currentDragItemType}
                        position={position}
                        handleDataUpdate={handleDataUpdate}
                        addComponent={handleAddComponent}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.GRADING:
                return (
                    <GradingBase
                        closeModal={handleCloseModal}
                        editItem={editItem}
                        currentId={currentId}
                        currentDragItemType={currentDragItemType}
                        position={position}
                        handleDataUpdate={handleDataUpdate}
                        currentItem={currentItem}
                        addComponent={handleAddComponentForGrading}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.GROUP:
                return (
                    <Group
                        studentList={uiStudentList}
                        closeModal={handleCloseModal}
                        handleCheckboxClicked={handleCheckedStudent}
                        handleAllChecked={handleCheckedAllStudents}
                    ></Group>
                );
            default:
                return <></>;
        }
    };

    const [currentId, setCurrentId] = useState(0);
    const [isGroupTool, setIsGroupTool] = useState<boolean>(false);
    const [uiStudentList, setUiStudentList] = useState<StudentListModel[]>([]);

    const btnList: BtnModel[] = [];

    const currentDragItemType = useSelector(
        (state: any) => state.worksheet.currentDragItemType
    );

    const getBtnList = () => {
        if (isGroupTool) {
            const cancelBtnProps: BtnModel = {
                icon: undefined,
                alt: 'Cancel',
                disable: false,
                btnText: 'Cancel',
                uiClass: 'cancel',
                iconClass: undefined,
            };

            const addBtnProps: BtnModel = {
                icon: undefined,
                alt: 'Add',
                disable: false,
                btnText: 'Add',
                uiClass: 'insert',
                iconClass: undefined,
            };

            btnList.push(cancelBtnProps);
            btnList.push(addBtnProps);
        }
        return btnList;
    };

    const renderGroupTool = () => {
        updateStudentListById(uiStudentList);
        addGroupTool();
    };

    const handleModalBtnClicked = (type: string) => {
        console.log('footer btn clicked ', type);
        switch (type) {
            case 'Cancel':
                console.log('Cancel clicked');
                break;
            case 'Add':
                handleCloseModal();
                if (editItem) {
                    updateStudentListById(uiStudentList);
                } else {
                    renderGroupTool();
                }
                break;
            default:
                console.log('type ', type);
        }
    };

    const handleCheckedStudent = (id: string, value: boolean) => {
        const temp = uiStudentList.map((student: StudentListModel) => {
            if (id === student.id) {
                return Object.assign({}, student, { checked: value });
            }
            return student;
        });
        setUiStudentList(temp);
        // updateStudentListById(id, value);
    };

    const handleCheckedAllStudents = (value: boolean) => {
        const temp = uiStudentList.map((student: StudentListModel) => {
            return Object.assign({}, student, { checked: value });
        });
        setUiStudentList(temp);
        // updateStudentList(value);
    };

    const handleTeacherModalClose = () => {
        if (modalLoading) {
            console.log('modalLoading');
        } else {
            handleCloseModal();
        }

    };

    useEffect(() => {
        if (components) {
            setCurrentId(components.length);
        }

        if (currentDragItemType) {
            setIsGroupTool(
                currentDragItemType === TOOLBAR_BTN_ACTION_CONSTANTS.GROUP
            );
        }
        setUiStudentList(studentList);
        console.log('teacher modal: editItem: ', editItem);
        console.log('currentDrag', currentDragItemType);
    }, [
        showModal,
        modalData,
        position,
        components,
        currentItem,
        currentDragItemType,
    ]);

    return (
        <>
            {showModal && !isGroupTool ? (
                <Modal
                    modalProps={modalData}
                    isOpen={showModal}
                    toggle={
                        handleTeacherModalClose
                    }
                    cancelModal={
                        handleTeacherModalClose
                    }
                >
                    {currentItem ? (
                        renderChildren(currentItem.type)
                    ) : currentDragItemType ? (
                        renderChildren(currentDragItemType)
                    ) : !currentItem && !editItem ? (
                        renderChildren(TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND)
                    ) : (
                        <></>
                    )}
                </Modal>
            ) : showModal && isGroupTool ? (
                <ModalWithFooter
                    modalProps={modalData}
                    isOpen={showModal}
                    toggle={handleTeacherModalClose}
                    cancelModal={handleTeacherModalClose}
                    btnList={getBtnList()}
                    btnClicked={handleModalBtnClicked}
                >
                    {currentItem ? (
                        renderChildren(currentItem.type)
                    ) : currentDragItemType ? (
                        renderChildren(currentDragItemType)
                    ) : (
                        <></>
                    )}
                </ModalWithFooter>
            ) : (
                <></>
            )}
        </>
    );
};

export default TeacherModal;
