import React, { FC, useRef, useState } from 'react';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import './resizable.css';
export interface IProps {
    resizeBtnProps: IconBtnModel;
    component: DraggableElement;
    draggable: React.MutableRefObject<HTMLDivElement | null>;
    editItem: boolean;
    publishedStatus: string;
    handleResize: (size: any) => void;
    handleTempSize: (size: any) => void;
}
const Resizable: FC<IProps> = ({
    resizeBtnProps,
    component,
    draggable,
    editItem,
    publishedStatus,
    handleResize,
    handleTempSize
}) => {
    console.log('editItem------', editItem);
    const resizeRef = useRef<HTMLImageElement | null>(null);
    const [isMove, setIsMove] = useState<boolean>(false);
    let initialX = 0;
    let initialY = 0;
    const handleMove = (event: any) => {
        // if (isMove && component.draggable) {

        // event.preventDefault();
        //1. get current mouse point
        //2. get how many point move
        //3. subtract from initial point

        console.log('move', event.clientX, event.clientY);
        console.log('initial', initialX, initialY);
        const newX = initialX - event.clientX;
        const newY = initialY - event.clientY;
        console.log('new position', newX, newY);
        // initialX = event.clientX;
        // initialY = event.clientY;
        // setInitialX(event.clientX);
        // setInitialY(event.clientY);

        const element = document.getElementById(component.id.toString());
        const newWidth = component.size.width - newX;
        const newHeight = component.size.height - newY;
        // console.log('old', component.size);
        // console.log('new', newWidth, newHeight);

        if (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX)
            handleTempSize({ width: newWidth < 272 ? component.size.width : newWidth, height: newHeight < 100 ? component.size.height : newHeight });
        else
            handleTempSize({ width: newWidth, height: newHeight });
        // dispatch(updateComponentSize({ size: { width: newWidth, height: newHeight }, id: component.id }));
        // do not need ui update as we update store
        // if (element) {
        //     element.style.width = newWidth + 'px';
        //     element.style.height = newHeight + 'px';
        // }
    };

    const handleUp = (event: any) => {
        console.log('up');
        setIsMove(false);
        const element = document.getElementById(component.id.toString());
        if (element) {
            handleResize({ width: Number.parseInt((element.clientWidth).toString()), height: Number.parseInt((element.clientHeight).toString()) });

            // handleResize({ width: component.size.width, height: component.size.height });
            console.log('handle resize called:', element.clientWidth, element.clientHeight);
        }

        document.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleUp);
    };

    const handleTouchStart = (event: any) => {
        // setIsMove(true);
        // setInitialX(event.changedTouches[0].clientX);
        // setInitialY(event.changedTouches[0].clientY);
        console.log('down');
    };

    const handleTouchMove = (event: any) => {
        const targetIsMove = event.target.alt === 'move' ? true : false;
        console.log(targetIsMove);
        if (!editItem && isMove && targetIsMove) {
            event.cancelable && event.preventDefault();
            event.cancelable && event.stopPropagation();
            console.log(
                'move',
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );

            const newX = initialX - event.changedTouches[0].clientX;
            const newY = initialY - event.changedTouches[0].clientY;

            // setInitialX(event.changedTouches[0].clientX);
            // setInitialY(event.changedTouches[0].clientY);

            const element = document.getElementById(component.id.toString());
        }
    };

    const handleTouchEnd = (event: any) => {
        console.log('up');
        setIsMove(false);
    };

    const handleDown = (event: any) => {
        event.preventDefault();
        initialX = event.clientX;
        initialY = event.clientY;
        // setInitialX(event.clientX);
        // setInitialY(event.clientY);
        console.log('down');
        document.addEventListener('mousemove', handleMove);
        document.addEventListener('mouseup', handleUp);
    };

    return (
        <>
            <span className={resizeBtnProps.uiClass} style={{ cursor: publishedStatus === 'published' ? 'not-allowed' : 'pointer', opacity: publishedStatus === 'published' ? '0.5' : '' }}>
                <img
                    src={resizeBtnProps.icon}
                    onMouseDown={handleDown}
                    style={{ pointerEvents: publishedStatus === 'published' ? 'none' : 'auto' }}
                // onMouseMove={handleMove}
                // onMouseUp={handleUp}
                // onMouseLeave={handleUp}
                // onTouchStart={handleTouchStart}
                // onTouchMove={handleTouchMove}
                // onTouchEnd={handleTouchEnd}
                // onTouchCancel={handleTouchEnd}
                />
            </span>
        </>
    );
};

export default Resizable;
