export const TOOLBAR_BTN_ACTION_CONSTANTS = {
    TEXT: 'text',
    TEXTBOX: 'textbox',
    GALLERY: 'gallery',
    BACKGROUND: 'backgorund',
    VIDEO: 'video',
    AUDIO: 'audio',
    POPUP: 'popup',
    SCORE: 'score',
    GRADING: 'grading',
    GROUP: 'group',
    EDITLAYOUT: 'editlayout',
    JOURNAL: 'journal',
    USER: 'user',
    PDF: 'pdf',
    BIN: 'bin',
    SUBMIT: 'submit',
    FEEDBACK: 'feedback',
    PREVIEW: 'preview',
    QRCODE: 'qrcode',
};
