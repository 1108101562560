import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Worksheet } from '../../models/worksheet-item.model';
import { LabelModel } from '../../models/label.model';
import { AuthService } from '../../service/auth.service';
import { Group } from '../../models/group.model';
import { StudentListModel } from '../../models/student-list.model';

const _authService = new AuthService();

const aplsApiUrl = process.env.REACT_APP_APLS_API_URL + 'api/v1/';
const worksheetApiUrl = process.env.REACT_APP_WORKSHEET_API_URL + 'api/v1/';
export const getLabelsApi = createAsyncThunk('label/getLabels', async () => {
    const apiurl = aplsApiUrl + 'labels';
    const headers = _authService.getHeadersInfo();
    const response = await axios.get(apiurl, { headers });
    const res = response.data;
    const labelArray: LabelModel[] = [];
    console.log('response', response);
    res.map((label: any) => {
        const labelObj: LabelModel = {
            id: label._id,
            name: label.name,
            color: label.color,
            labelIcon: null,
        };
        labelArray.push(labelObj);
    });
    console.log('labelArray', labelArray);
    return labelArray;
});

export const getGroupsByClass = createAsyncThunk(
    'group/getGroups',
    async () => {
        const userInfo = _authService.getUserInfo();
        const apiurl = aplsApiUrl + 'groups/class/' + userInfo.classId;
        const headers = _authService.getHeadersInfo();
        const response = await axios.get(apiurl, { headers });
        const res = response.data;
        const groupArray: Group[] = [];
        console.log('response', response);
        res.map((group: any) => {
            const groupLabel = {
                id: group.label._id,
                name: group.label.name,
            };
            const groupObj: Group = new Group(
                group._id,
                group.name,
                null,
                groupLabel,
                group.students
            );
            groupArray.push(groupObj);
        });
        console.log('groupArray', groupArray);
        return groupArray;
    }
);

export const getStudentsApi = createAsyncThunk(
    'student/getStudents',
    async () => {
        const userInfo = _authService.getUserInfo();
        const apiurl = aplsApiUrl + 'classes/' + userInfo.classId + '/students';
        const headers = _authService.getHeadersInfo();
        const response = await axios.get(apiurl, { headers });
        const res = response.data;
        const studentArray: StudentListModel[] = [];
        console.log('response', response);
        res.map((student: any) => {
            const studentObj: StudentListModel = {
                id: student._id,
                pwUserId:
                    student.metadata != undefined &&
                    student.metadata.pwUserId != undefined &&
                    student.metadata.pwUserId != null
                        ? student.metadata.pwUserId
                        : student._id,
                name: student.username,
                checked: false,
            };
            studentArray.push(studentObj);
        });
        console.log('studentArray', studentArray);
        return studentArray;
    }
);

export const editWorksheetApi = createAsyncThunk(
    'student/editWorksheet',
    async (data: any) => {
        const { id, payload } = data;
        const apiurl = worksheetApiUrl + 'worksheets/' + id;
        const headers = _authService.getHeadersInfo();
        const response = await axios.put(apiurl, payload, { headers });
        return response.data;
    }
);

// 'https://dev-smart-school-apls-api.pagewerkz.com/api/v1/classes/62f37b7945cc0d2c7fb26ea6'

export const getClassName = createAsyncThunk(
    'student/getClassName',
    async () => {
        const userInfo = _authService.getUserInfo();
        const apiurl = aplsApiUrl + 'classes/' + userInfo.classId;
        const headers = _authService.getHeadersInfo();
        const response = await axios.get(apiurl, { headers });
        const res = response.data;
        const studentArray: StudentListModel[] = [];
        console.log('response', response);
        // console.log('studentArray', studentArray);
        return res;
    }
);
