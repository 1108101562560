import React, { FC } from 'react';
import TemplateItem from '../template-list-item/template-item';
import './template-list.css';
import { Template } from '../../../models/template-item.model';

export interface IProps {
    templateList: Template[];
    clickMenu: (type: string, item: Template) => void;
    keyword?: string | null;
}

const TemplateList: FC<IProps> = ({ templateList, clickMenu, keyword }) => {
    const handleClickMenu = (type: string, item: Template) => {
        clickMenu(type, item);
    };

    return (
        <>
            <div className="list-wrapper">
                <div className="list">
                    {!keyword && templateList.map((item: Template, index: any) => (
                        <TemplateItem
                            key={index}
                            item={item}
                            handleClickMenu={handleClickMenu}
                        />
                    ))}
                    {keyword && templateList.map((item: Template, index: any) => {
                        if (item.name.toLowerCase().includes(keyword.toLowerCase()))
                            return (<TemplateItem
                                key={index}
                                item={item}
                                handleClickMenu={handleClickMenu}
                            />);
                    })}
                </div>
            </div>
        </>
    );
};

export default TemplateList;
