import React, { FC, useState } from 'react';
import './submission-sidebar.css';
import { WorksheetSubmissionDetail } from '../../../../models/worksheet-submission-detail.model';

export interface IProps {
    activeStudentId: string;
    submissionDetails: WorksheetSubmissionDetail[];
    onClickStudent: (submission: any) => void;
    isStudentsSelectable: boolean
}

const SubmissionSidebar: FC<IProps> = ({
    activeStudentId
    , onClickStudent
    , submissionDetails
    , isStudentsSelectable
}) => {
    console.log('submissionDetails', submissionDetails);

    const defaultProfileImage = 'https://pagewerkz-main.s3.amazonaws.com/user-profile-svg-icons/grey_deco_11.svg';

    const handleClickStudent = (submission: any) => {
        onClickStudent(submission);
    };

    const getUsernameInitials = (username: string) => {
        const initials = username.charAt(0).toUpperCase();
        return initials;
    };

    return (
        <>
            <div className='text-c100 h4-strong sub-header'>
                Students
            </div>
            <div>
                {
                    submissionDetails.map((submission: any, index: any) => (
                        <div
                            key={index}
                            className={(isStudentsSelectable && activeStudentId == submission.studentId) ? 'sidebar-item active-item' : 'sidebar-item'}
                            onClick={() => { if (isStudentsSelectable) handleClickStudent(submission); }}>
                            <div className='pr-16'>
                                <div className='profile-img'>
                                    <img src={submission.studentData.generatedProfileImage.profileImage} />
                                    <span className='text-c300 body-m'>
                                        {submission.studentData.generatedProfileImage.initials}
                                    </span>
                                </div>
                            </div>
                            <span className='text-c100 h5-strong'>
                                {submission.studentData.username ? submission.studentData.username : ''}
                            </span>
                        </div>
                    ))
                }
                {/* {
                    submissionDetails.map((submission: any, index: any) => (
                        <div
                            key={index}
                            className={(isStudentsSelectable && activeStudentId == submission.studentId) ? 'sidebar-item active-item' : 'sidebar-item'}
                            onClick={() => { if (isStudentsSelectable) handleClickStudent(submission); }}>
                            <div className='pr-16'>
                                <div className='profile-img'>
                                    <img src={submission.studentData.generatedProfileImage ? submission.studentData.generatedProfileImage.profileImage : defaultProfileImage} />
                                    <span className='text-c300 body-m'>
                                        {submission.studentData.generatedProfileImage ? submission.studentData.generatedProfileImage.initials : getUsernameInitials(submission.studentData.username)}
                                    </span>
                                </div>
                            </div>
                            <span className='text-c100 h5-strong'>
                                {submission.studentData.username ? submission.studentData.username : ''}
                            </span>
                        </div>
                    ))
                } */}
            </div>
        </>
    );
};

export default SubmissionSidebar;