import { useState } from 'react';
import { CustomModal } from '../models/custom-modal.model';

export default function UseWorksheetsHomeModal() {
    const [modalData, setData] = useState<CustomModal>({
        title: '',
        showCrossBtn: true,
        width: '100px',
        height: null,
        padding: '15px',
    });

    const setModalData = (data: CustomModal) => {
        setData(data);
    };

    return {
        modalData,
        setModalData,
    };
}
