import React, { FC, useState } from 'react';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import './toolbar.css';
import GroupIcon from '../../../../assets/images/group_b.svg';
import DefaultUserIcon from '../../../../assets/images/user.svg';
import DefaultUserActiveIcon from '../../../../assets/images/user_stroke_hover.svg';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import IconButton from '../../../../global-components/icon-button/IconButton';
import { BtnModel } from '../../../../models/btn.model';
import Button from '../../../../global-components/button/Button';
import { useSelector } from 'react-redux';
import { WorksheetDetailModel } from '../../../../models/worksheet-detail.model';
import StudentGroup from '../../../../global-components/student-group/StudentGroup';
import UserProfile from '../../../../global-components/user-profile/UserProfile';
import { AuthService } from '../../../../service/auth.service';
import { useParams } from 'react-router-dom';

export interface IProps {
    studentName: any,
    className: null | string,
    worksheetDetail: WorksheetDetailModel,
    handleToolbarBtnAction: (type: string | undefined) => void;
}
const Toolbar: FC<IProps> = ({
    handleToolbarBtnAction,
    worksheetDetail,
    studentName,
    className
}) => {
    const params = useParams<{ id?: string; sheetId?: string; token?: string }>();
    const { token } = params;
    const grouptBtnProps: IconBtnModel = {
        icon: GroupIcon,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.GROUP,
        uiClass: 'toolbar-icon',
        disable: false,
        draggable: true,
    };
    const userBtnProps: IconBtnModel = {
        icon: DefaultUserIcon,
        activeIcon: DefaultUserActiveIcon,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.USER,
        uiClass: 'student-user-icon',
        disable: false,
        draggable: false,
    };
    const publicBtnProps: BtnModel = {
        icon: undefined,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.SUBMIT,
        disable: false,
        btnText: 'Submit',
        uiClass: 'publish-btn',
        iconClass: undefined,
    };

    const publishBtnLockedProps: BtnModel = {
        icon: undefined,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.SUBMIT,
        disable: false,
        btnText: 'Submit',
        uiClass: 'disabled',
        iconClass: undefined,
    };

    const publishBtnDisabledProps: BtnModel = {
        icon: undefined,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.SUBMIT,
        disable: false,
        btnText: 'Submitting...',
        uiClass: 'disabled',
        iconClass: undefined,
    };

    const { studentStatusList, worksheetSubmitting } = useSelector((state: any) => state.student);

    const [showUserProfile, setShowUserProfile] = useState<boolean>(false);

    const handleIconAction = (type: string) => {
        handleToolbarBtnAction(type);
        if (type === TOOLBAR_BTN_ACTION_CONSTANTS.USER)
            setShowUserProfile(!showUserProfile);
        else
            setShowUserProfile(false);
    };
    return (
        <>
            <div className="student-tool-bar toolbar-align bg-s0" style={{ top: 0 }}>
                <div className='toolbar-title'>
                    <span className="text-c200 h4-strong truncate-overflow" style={{ width: '100%' }}>
                        {worksheetDetail
                            ? worksheetDetail.name
                            : 'Untitled Activity worksheet name'}
                        {worksheetDetail && worksheetDetail.isLocked && <span className="body-m text-s0 bg-g100 published-status">Locked</span>}
                    </span>
                    
                    
                </div>
                {!token && (<div className="student-toolbar-btn-group">
                    <Button
                        btnProps={worksheetSubmitting ? publishBtnDisabledProps : worksheetDetail && worksheetDetail.isLocked ? publishBtnLockedProps : publicBtnProps}
                        handleBtnClicked={() =>
                            !worksheetDetail.isLocked && handleIconAction('submit')
                        }
                    />
                    <div className="tool-bar-user"
                        style={{

                        }}>
                        <IconButton
                            btnProps={userBtnProps}
                            currentSelected={showUserProfile}
                            handleBtnClicked={() =>
                                handleIconAction(userBtnProps.alt)
                            }
                        />
                        {showUserProfile && <UserProfile profile={studentName} className={className} showQR={() =>
                            handleIconAction('qrcode')
                        }></UserProfile>}

                    </div>
                </div>)}
            </div>
        </>
    );
};

export default Toolbar;
