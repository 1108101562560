import React, { FC, useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as CryptoJS from 'crypto-js';
import { AuthService } from '../../service/auth.service';
const myServiceInstance = new AuthService();
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const RedirectComponent = () => {
    const navigate = useNavigate();

    const [authenticated, setauthenticated] = useState();

    const queryParams = new URLSearchParams(window.location.search);

    const encrypt = (message: string, secretkey: string) => {
        const encrypted = myServiceInstance.encrypt(message, secretkey);
        return encrypted;
    };

    const decrypt = (encrypted: string, secretkey: string) => {
        const decrypted = myServiceInstance.decrypt(encrypted, secretkey);
        return decrypted;
    };

    const saveData = (key: string, value: string) => {
        console.log('key ..... ', key, 'value .....', value);
        myServiceInstance.saveData(key, value);
    };

    const navigateTeacherView = (userid:string,worksheetid: string) => {
        console.log(decryptedAction);
        if (
            decryptedAction == 'open-create-worksheet'
        )
            navigate(`/?action=${encodeURIComponent(decryptedAction)}`);
        else if (
            decryptedRefer !== undefined && 
            decryptedRefer !== null &&
            decryptedRefer !== '' &&
            decryptedWorksheetId != undefined &&
            decryptedWorksheetId != null &&
            decryptedWorksheetId != '')
            navigate(`/student/${userid}/sheet/${worksheetid}`);
            
        else if (
            (
                decryptedRefer == undefined || 
                decryptedRefer == null ||
                decryptedRefer == '') &&
            decryptedWorksheetId != undefined &&
            decryptedWorksheetId != null &&
            decryptedWorksheetId != ''
        )
            navigate(`/editor/${worksheetid}`);
        else navigate('/');
    };

    const navigateStudentView = (userid: string, worksheetid: string) => {
        if (
            decryptedWorksheetId != undefined &&
            decryptedWorksheetId != null &&
            decryptedWorksheetId != ''
        )
            navigate(`/student/${userid}/sheet/${worksheetid}`);
        else navigate('/public/secredId');
    };

    const secretkey = 'bc776';
    const decryptedUserId = decrypt(
        decodeURIComponent(queryParams.get('idUser') || ''),
        secretkey
    );
    const decryptedUsername = decrypt(
        decodeURIComponent(queryParams.get('username') || ''),
        secretkey
    );
    const decryptedToken = decrypt(
        decodeURIComponent(queryParams.get('token') || ''),
        secretkey
    );
    const decryptedapikey = decrypt(
        decodeURIComponent(queryParams.get('apikey') || ''),
        secretkey
    );
    const decryptedUsertype = JSON.parse(
        decrypt(
            decodeURIComponent(queryParams.get('usertype') || ''),
            secretkey
        )
    );
    const decryptedLessonId = decrypt(
        decodeURIComponent(queryParams.get('lessonid') || ''),
        secretkey
    );
    const decryptedClassId = decrypt(
        decodeURIComponent(queryParams.get('classid') || ''),
        secretkey
    );
    const decryptedWorksheetId = decrypt(
        decodeURIComponent(queryParams.get('worksheetid') || ''),
        secretkey
    );
    const decryptedAction = decrypt(
        decodeURIComponent(queryParams.get('action') || ''),
        secretkey
    );
    const decryptedRefer = decrypt(
        decodeURIComponent(queryParams.get('refer') || ''),
        secretkey
    );

    if (
        decryptedUserId &&
        decryptedUsername &&
        decryptedToken &&
        decryptedapikey &&
        decryptedUsertype &&
        decryptedLessonId &&
        decryptedClassId
    ) {
        const userObj: any = {
            userid: decryptedUserId,
            username: decryptedUsername,
            password: decryptedToken,
            apikey: decryptedapikey,
            usertype: decryptedUsertype,
            lessonId: decryptedLessonId,
            worksheetId: decryptedWorksheetId,
            classId: decryptedClassId,
        };
        console.log('userObj', userObj);

        const encryptedData = encrypt(JSON.stringify(userObj), secretkey);
        saveData('userInfo', encryptedData);
    }

    useEffect(() => {
        console.log('process.env.REACT_APP_FIREBASE_EMAIL:', process.env.REACT_APP_FIREBASE_EMAIL);
        const email = process.env.REACT_APP_FIREBASE_EMAIL ? process.env.REACT_APP_FIREBASE_EMAIL : '';
        const password = process.env.REACT_APP_FRIEBASE_PASSWORD ? process.env.REACT_APP_FRIEBASE_PASSWORD : '';
        switch (decryptedUsertype) {
            case 3:
                // redirect to editor when user is student
                {
                    navigateStudentView(decryptedUserId, decryptedWorksheetId);
                    // const auth = getAuth();
                    // signInWithEmailAndPassword(auth, email, password).then((res) => {
                    //     navigateStudentView(decryptedUserId, decryptedWorksheetId);
                    // }).catch((error) => {
                    //     const errorCode = error.code;
                    //     const errorMessage = error.message;
                    //     alert(errorMessage);
                    // });
                }
                break;
            case 4:
                // redirect to worksheet list when user is teacher
                navigateTeacherView(decryptedUserId,decryptedWorksheetId);
                break;
            case 1:
                // redirect to worksheet list when user is super admin
                navigateTeacherView(decryptedUserId,decryptedWorksheetId);
                break;
            case 2:
                // redirect to worksheet list when user is org admin
                navigateTeacherView(decryptedUserId,decryptedWorksheetId);
                break;
            default:
                navigateStudentView(decryptedUserId, decryptedWorksheetId);
        }
    }, [decryptedUsertype]);

    return (
        <>
            <div>Redirecting...</div>
        </>
    );
};

export default RedirectComponent;
