import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetWorksheetByIdQuery } from '../../api/worksheet/worksheetListApi';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../constants/navBarBtnActionConstants';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
import EditorNavbar from '../../global-components/editor-navbar/EditorNavbar';
import Modal from '../../global-components/modal/modal';
import { CustomModal } from '../../models/custom-modal.model';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';
import StudentModal from '../student-modal/StudentModal';
import Toolbar from './components/toolbar/Toolbar';
import FakeWorkSheet from '../../fake-data/worksheet.json';
import { DraggableElement } from '../../models/draggable-element.model';
import { deleteStudentInput, editStudentInput, getStudentDetail, getStudentInputDetail, getWorksheetDetail, getWorksheetDetailWithToken, studentAction, submitWorksheet, uploadMedia } from '../../api/worksheet/studentApi';
import { AppDispatch } from '../../store/store';
import { AlertModel } from '../../models/alert.model';
import CustomAlert from '../../global-components/custom-alert/CustomAlert';
import { resetWorksheetDetail, deleteStudentInputStore, setTriggerStudentInput, setChatLogItem, setColorList, setCurrentChatLog, setCurrentItem, setFocusList, setIsMobile, setStudentStatus, updateChatLog, setWorksheetSubmittingStatus, setModalType } from './slices/studentSlice';
import { ChatLogDataModel } from '../../models/chat-log-data.model';
import FirebaseStudent from './components/firebase-student/FirebaseStudent';
import { setText } from '../teacher/slices/worksheetSlice';
import ActiveStudentBar from './components/active-student-bar/ActiveStudentBar';
import uuid from 'react-uuid';
import { getClassName } from '../../api/global/globalApi';
import GenerateQRcode from '../generate-qrcode/generate-qrcode';
import { Worksheet } from '../../models/worksheet-item.model';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { StudentStatus } from '../../models/student-status.model';
import CommonCanvas from '../common-canvas/CommonCanvas';
import { PointModel } from '../../models/point.model';
import { WorksheetComponent } from '../../models/worksheet-component.model';
import { PARENT_TYPE_CONSTANTS } from '../../constants/parentTypeConstants';
import { isConstructorDeclaration } from 'typescript';
import CanvasLoading from '../../global-components/canvas-loading/CanvasLoading';
const Student = () => {

    const params = useParams<{ id?: string; sheetId?: string; token: string }>();
    const { id, sheetId, token } = params;
    console.log('worksheet id ~~~~~~~~~~ ', id, '~~~~', sheetId);
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [currentComponentId, setCurrentComponentId] = useState<string | null>(null);
    const [checkIsEdit, setCheckIsEdit] = useState<boolean>(false);
    const [payloadForStudentInput, setPayloadForStudentInput] = useState<any>(null);
    const [studentInputUrl, setStudentInputUrl] = useState<string>('');
    const [studentName, setStudentName] = useState<any>({ name: '', url: '', initials: '' });
    const [callFirebaseFirst, setCallFirebaseFirst] = useState<boolean>(false);
    const [showWorksheetModal, setShowWorksheetModal] = useState<boolean>(false);

    const { worksheetDetail, currentChatLog, currentItem, editItem, studentStatusList, canvasWidth, canvasHeight, triggerStudentInput, maxScore, maxBase, colorList, focusList } = useSelector((state: any) => state.student);
    const defaultAlertData: AlertModel = {
        title: 'default',
        body: null,
        type: 'success',
        uiClass: 'default',
    };
    const [alertData, setalertData] = useState(defaultAlertData);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const [loading, setloading] = useState<boolean>(true);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [className, setClassName] = useState<null | string>(null);
    // console.log('Worksheet data from api', data);

    const getClassNameCall = async () => {
        try {
            const getClassNameApi = await dispatch(getClassName()).unwrap().then((res) => {
                console.log('student Name', res);
                setClassName(res.name);
            })
                .catch((error) => {
                    console.log('student Name error', error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    const callStudentDetail = async () => {
        try {
            const studentDetailApi = await dispatch(getStudentDetail(id ? [id] : []));
            console.log('studentDetailApi:', studentDetailApi);
            console.log(studentDetailApi.payload);
            console.log(studentDetailApi.payload.userData[0].username);
            const user = studentDetailApi.payload.userData[0];
            setStudentName({
                name: user.username,
                url: user.generatedProfileImage ? user.generatedProfileImage.profileImage : '',
                initials: user.generatedProfileImage ? user.generatedProfileImage.initials : user.username.slice(0, 2).toUpperCase()
            });
            // setStudentName({
            //     name: user.username,
            //     url: user.generatedProfileImage.profileImage,
            //     initials: user.generatedProfileImage.initials
            // });
        } catch (error) {
            if (error) {
                console.error(error);
            }
        }
    };

    const getWorksheetDetailApi = async () => {
        try {
            const getWorksheetDetailApiCall = await dispatch(getWorksheetDetail({ studentId: id, worksheetId: sheetId })).unwrap();
            setloading(false);
        } catch (error) {
            if (error) {
                console.error(error);
            }
        }
    };

    const mapWorksheetComponents = (components: any) => {
        const componentArray: DraggableElement[] = [];
        components.map((component: any) => {
            const componentObj: DraggableElement = {
                id: component._id,
                type: component.type,
                location: component.location,
                size: component.size,
                data: component.data,
            };
            componentArray.push(componentObj);
        });
        return componentArray;
    };

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<CustomModal>({
        title: '',
        showCrossBtn: true,
        width: '100px',
        height: null,
        padding: '15px',
    });

    const handleModalData = (value: CustomModal) => {
        setModalData(value);
    };

    const [currentInputType, setCurrentInputType] = useState<string>('');
    const [textUploaded, setTextUploaded] = useState<boolean>(false);

    const handleNavBtnAction = (type: string) => {
        switch (type) {
            case NAVBAR_BTN_ACTION_CONSTANTS.BACK:
                navigate('/');
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.EDIT:
                console.log('handle edit');
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.LOCK:
                console.log('handle lock');
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.PUBLISH:
                console.log('handle publish');
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.CREATEWORKSHEET:
                console.log('handle create worksheet');
                break;
            default:
                console.log('default');
        }
    };

    const handleToolbarBtnAction = (type: string | undefined) => {
        console.log('Btn Submit Clicked', type);

        if (type === TOOLBAR_BTN_ACTION_CONSTANTS.SUBMIT) {

            const payload = {
                'worksheetId': sheetId,
                'studentId': id
            };

            dispatch(setWorksheetSubmittingStatus(true));

            dispatch(submitWorksheet(payload))
                .unwrap()
                .then((res) => {
                    console.log('Worksheet submitted', res);

                    const alertData: AlertModel = {
                        title: 'Your worksheet submitted successfully.',
                        body: null,
                        type: 'success',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);

                })
                .catch((error) => {
                    console.log('Worksheet submit error', error);

                    const alertData: AlertModel = {
                        title: 'Your worksheet submission failed.',
                        body: null,
                        type: 'error',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);

                })
                .finally(() => {
                    dispatch(setWorksheetSubmittingStatus(false));
                });

        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.QRCODE) {
            const qrModalProps: CustomModal = {
                title: 'Login with QR Code',
                showCrossBtn: true,
                width: '480px',
                height: null,
                padding: '32px',
            };
            dispatch(setModalType(TOOLBAR_BTN_ACTION_CONSTANTS.QRCODE));
            handleModalData(qrModalProps);
            setShowWorksheetModal(true);
        } else {
            console.log('else');
        }

    };

    const handleOpenModal = (id: string) => {
        setShowModal(true);
        setCurrentComponentId(id);
    };

    const handleCloseModal = () => {
        setShowModal(!showModal);
        setCurrentComponentId(null);
        // release
        setIsEdit(false);
        handleReleaseFocus();

    };

    const changeCurrentInputType = (type: string) => {
        console.log('changeCurrentInputType');
        setCurrentInputType(type);
        setChangeActiveStatus(true);
        setCallFirebaseFirst(false);
        setReleaseStatus({ studentId: null, sheetId: null });
        let title = 'Upload Image';
        switch (type) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                title = 'Upload Image';
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                title = 'Upload Video';
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                title = 'Upload Audio';
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.PDF:
                title = 'Upload PDF';
                break;
            default:
                title = 'Upload Image';
        }
        const modalData: CustomModal = {
            title: title,
            showCrossBtn: true,
            width: '944px',
            height: null,
            padding: '32px',
        };
        setModalData(modalData);
    };

    const [fileUploaded, setFileUploaded] = useState<boolean>(false);

    const handleStudentInputEdit = async (actionPayload: any, currentChatLog: ChatLogDataModel) => {
        try {
            console.log('currentchatLog', currentChatLog, id);
            actionPayload.userInput._id = currentChatLog.id;
            actionPayload.userInput.studentId = id;
            const preparePayload = {
                'userInput': actionPayload.userInput
            };
            const studentInputEditApi = await dispatch(editStudentInput({ body: preparePayload, studentSubmissionId: currentChatLog.studentSubmissionId })).unwrap().then((res) => {
                console.log('studentInputEditApi', res);
                setModalLoading(false);
                setShowModal(false);
                const alertData: AlertModel = {
                    title: 'Your worksheet saved successfully.',
                    body: null,
                    type: 'success',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setSubmitStatus({
                    componentId: actionPayload.componentId, sheetId: sheetId, studentId: id
                });
                setTimeout(() => {
                    setSubmitStatus({
                        componentId: '', sheetId: sheetId, studentId: id
                    });
                }, 500);
                dispatch(updateChatLog({ name: actionPayload.userInput.data.name, url: actionPayload.userInput.data.url,studentName:res.userInput.studentName }));
                console.log('updateChatLog:', actionPayload.userInput.data.url, actionPayload.userInput.data.name,res.userInput.studentName);
                setIsEdit(false);
                setCheckIsEdit(false);
                dispatch(setCurrentChatLog(null));
                setFileUploaded(true);
                setTimeout(() => {
                    setFileUploaded(false);
                }, 500);
            }, err => {
                console.error(err);
                setModalLoading(false);
                setShowModal(false);
                const alertData: AlertModel = {
                    title: err.message,
                    body: null,
                    type: 'error',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setIsEdit(false);
                setCheckIsEdit(false);
            });

        } catch (error) {
            if (error) {
                console.error(error);
                setModalLoading(false);
                setShowModal(false);
                const alertData: AlertModel = {
                    title: 'Your worksheet cannot saved.',
                    body: null,
                    type: 'error',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setIsEdit(false);
                setCheckIsEdit(false);
            }
        }
    };

    const handleStudentInputPost = async (actionPayload: any, url: string) => {
        try {
            const studentActionApi = await dispatch(studentAction(actionPayload)).unwrap();
            setModalLoading(false);
            setShowModal(false);
            const alertData: AlertModel = {
                title: 'Your worksheet saved successfully.',
                body: null,
                type: 'success',
                uiClass: 'default',
            };
            setalertData(alertData);
            setShowAlert(true);
            const componentIdForNewItem = studentActionApi.userInput._id;
            const log: ChatLogDataModel = {
                id: componentIdForNewItem,
                type: currentInputType,
                data: {
                    url: url,
                    text: null,
                    name: actionPayload.userInput.data.name,
                },
                studentName: studentActionApi.userInput.studentName,
                studentSubmissionId: studentActionApi.studentSubmission._id
            };


            setSubmitStatus({
                componentId: payloadForStudentInput.componentId, sheetId: sheetId, studentId: id
            });
            setTimeout(() => {
                setSubmitStatus({
                    componentId: '', sheetId: sheetId, studentId: id
                });
            }, 500);
            dispatch(setChatLogItem(log));
            setIsEdit(false);
            setCheckIsEdit(false);
        } catch (error) {
            if (error) {
                console.error(error);
                setModalLoading(false);
                setShowModal(false);
                const alertData: AlertModel = {
                    title: 'Your worksheet cannot saved.',
                    body: null,
                    type: 'error',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setIsEdit(false);
                setCheckIsEdit(false);
            }
        }
    };

    const handleUploadComponent = async (file: File | undefined) => {
        //

        const timestamp = Date.now();
        const fileData = new FormData();
        if (file) {
            fileData.append(
                'file',
                file,
                timestamp.toString().concat('_', file.name)
            );
            fileData.append('studentId', id ? id : '');
        }
        console.log('fileData:', fileData);

        try {
            setModalLoading(true);
            const uploadMediaApi = await dispatch(uploadMedia({ id: sheetId, body: fileData })).unwrap();
            console.log('uploadMediaApi', uploadMediaApi);
            if (uploadMediaApi.url) {
                const name: string = uploadMediaApi.name ? uploadMediaApi.name : null;
                const array = name ? name.split('_') : [];
                console.log('name 222222 : ', name);
                console.log('array 222222 : ', array);
                const actionPayload = {
                    'worksheetId': sheetId,
                    'studentId': id,
                    'componentId': currentComponentId,
                    'userInput': {
                        'type': currentInputType,
                        'data': {
                            'url': uploadMediaApi.url,
                            'name': name ? array[1] : null
                        }
                    }
                };
                setCheckIsEdit(true);
                setPayloadForStudentInput(actionPayload);
                setStudentInputUrl(uploadMediaApi.url);
            }

        } catch (error) {
            if (error) {
                console.error(error);
                setModalLoading(false);
                setShowModal(false);
                const alertData: AlertModel = {
                    title: 'You have to wait worksheet update finish.',
                    body: null,
                    type: 'error',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
            }
        }

    };

    const handleUpdateText = async (text: string, componentId: string) => {
        try {
            const actionPayload = {
                'worksheetId': sheetId,
                'studentId': id,
                'componentId': componentId,
                'userInput': {
                    'type': 'text',
                    'data': {
                        'text': text
                    }
                }
            };
            setCheckIsEdit(true);
            setIsEdit(true);
            setPayloadForStudentInput(actionPayload);
        } catch (error) {
            if (error) console.error(error);
        }
    };

    const handleAddText = async (text: string, componentId: string) => {
        console.log('handleAddText', currentInputType);
        try {
            const actionPayload = {
                'worksheetId': sheetId,
                'studentId': id,
                'componentId': componentId,
                'userInput': {
                    'type': TOOLBAR_BTN_ACTION_CONSTANTS.TEXT,
                    'data': {
                        'text': text
                    }
                }
            };
            const studentActionApi = await dispatch(studentAction(actionPayload)).unwrap();
            console.log(studentActionApi);
            setModalLoading(false);
            setShowModal(false);
            const alertData: AlertModel = {
                title: 'Your worksheet saved successfully.',
                body: null,
                type: 'success',
                uiClass: 'default',
            };
            setalertData(alertData);
            setShowAlert(true);
            setTextUploaded(true);
            setTimeout(() => {
                setTextUploaded(false);
            }, 500);
            setChangeActiveStatus(true);
            setCallFirebaseFirst(false);
            setReleaseStatus({ studentId: null, sheetId: null });

            const componentIdForNewItem = studentActionApi.userInput._id;
            const log: ChatLogDataModel = {
                id: componentIdForNewItem,
                type: 'text',
                data: {
                    url: null,
                    text: text,
                    name: null
                },
                studentName: studentActionApi.userInput.studentName,
                studentSubmissionId: studentActionApi.studentSubmission._id
            };
            console.log('====================================');
            console.log('apo bug catch');
            console.log('====================================');
            setSubmitStatus({
                componentId: componentId, sheetId: sheetId, studentId: id
            });
            setTimeout(() => {
                setSubmitStatus({
                    componentId: '', sheetId: sheetId, studentId: id
                });
            }, 500);
            dispatch(setChatLogItem(log));
            // have to update store as well.
        } catch (error) {
            if (error) {
                console.error(error);
                setModalLoading(false);
                setShowModal(false);
                const alertData: AlertModel = {
                    title: 'You have to wait worksheet update finish.',
                    body: null,
                    type: 'error',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setTextUploaded(false);
                setTextUploaded(true);
                // setTimeout(() => {
                //     setTextUploaded(false);
                // }, 500);
            }
        }
    };
    const [changeActiveStatus, setChangeActiveStatus] = useState<boolean>(false);
    const [submitStatus, setSubmitStatus] = useState<any>({ componentId: '', sheetId: sheetId, studentId: id });
    const [releaseStatus, setReleaseStatus] = useState<any>({ sheetId: null, studentId: null });
    const handleClick = (item: ChatLogDataModel) => {
        dispatch(setCurrentChatLog(item));
        // setChangeActiveStatus(true);
        // setCallFirebaseFirst(false);
        // setReleaseStatus({ studentId: null, sheetId: null });
    };

    const handleEdit = () => {
        setIsEdit(true);
    };
    const [callDelete, setCallDelete] = useState<boolean>(false);
    const handleDelete = () => {
        setCallDelete(true);
    };

    const deleteStudentInputApiCall = (currentItem: DraggableElement, userInputId: string, studentSubmissionId: string) => {
        try {
            const payload = {
                userInputId, studentSubmissionId
            };
            const callStudentInputDelete = dispatch(deleteStudentInput(payload)).unwrap().then((res) => {
                dispatch(deleteStudentInputStore());
                setIsEdit(false);
                setCheckIsEdit(false);
                setCallDelete(false);
                const alertData: AlertModel = {
                    title: `You deleted a ${currentChatLog.type} message.`,
                    body: null,
                    type: 'success',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setSubmitStatus({
                    componentId: currentItem.id, sheetId: sheetId, studentId: id
                });
                setTimeout(() => {
                    setSubmitStatus({
                        componentId: '', sheetId: sheetId, studentId: id
                    });
                }, 500);

            }, err => {
                console.log('error', err.message);
                const alertData: AlertModel = {
                    title: err.message,
                    body: null,
                    type: 'fail',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setIsEdit(false);
                setCheckIsEdit(false);
                setCallDelete(false);
            });

        } catch (error) {
            if (error) {
                console.error(error);
                setIsEdit(false);
                setCheckIsEdit(false);
                setCallDelete(false);
                const alertData: AlertModel = {
                    title: 'Your chat log deleting failed.',
                    body: null,
                    type: 'fail',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
            }
        }
    };

    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

    const handleStudentInputClick = (item: DraggableElement) => {
        console.log('item click:', item);
        dispatch(setCurrentItem(item));
        setCallFirebaseFirst(false);
        setChangeActiveStatus(false);
        setChangeActiveStatus(true);
        setReleaseStatus({ studentId: null, sheetId: null });
    };

    // getWorksheetDetailApi();
    const changeActiveStudents = async (studentArray: any) => {
        if (studentArray) {
            const covert = Object.keys(studentArray).map(function (key) {
                return studentArray[key];
            });
            console.log('studentArray>>>>>>>>>>', covert, covert.length);
            const focusList = covert.map((item: StudentStatus) => item.componentId);
            dispatch(setFocusList(focusList));
            dispatch(setStudentStatus(covert));
            dispatch(setTriggerStudentInput(false));
            dispatch(setTriggerStudentInput(true));
        } else { dispatch(setStudentStatus([])); }
    };

    const handleReleaseFocus = () => {
        console.log('handleReleaseFocus');
        setChangeActiveStatus(false);
        setChangeActiveStatus(true);
        setCallFirebaseFirst(false);
        if (currentItem)
            setReleaseStatus({
                sheetId: sheetId, studentId: id, componentId: currentItem.id
            });
        dispatch(setCurrentItem(null));
        dispatch(setCurrentChatLog(null));
    };

    const handleStudentInputUpdate = async (cid: string) => {
        console.log('handleStudentInputUpdate:', id);
        try {
            const payload = {
                studentId: id,
                worksheetId: sheetId,
                componentId: cid
            };
            const apiCall = await dispatch(getStudentInputDetail(payload));
            console.log('====================================');
            console.log(apiCall);
            console.log('====================================');
        } catch (error) {
            if (error) console.error(error);
        }
    };

    const detectMob = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        const userAgent = window.navigator.userAgent;
        console.log('userAgent:', userAgent);

        const isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
        if (isIpad == true || userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            return true;
        }
        else if (userAgent.match(/Android/i)) {
            return true;
        }
        else {
            return false;
        }

        // return toMatch.some((toMatchItem) => {
        //     return navigator.userAgent.match(toMatchItem);
        // });
    };

    const getWorksheetWithToken = async () => {
        try {
            const getWorksheetWithTokenApiCall = await dispatch(getWorksheetDetailWithToken(token ? token : '')).unwrap().then((res) => {
                console.log('res getWorksheetWithTokenApiCall :', res);
            }).catch((error) => {
                console.error('error getWorksheetWithTokenApiCall :', error);
            });
            setloading(false);
        } catch (error) {
            if (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetWorksheetDetail());
        };
    }, []);

    useEffect(() => {
        //check if mobile or not
        const mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

        if (mac) {
            document.body.classList.add('edited_scrollbar_mac');
        } else {

            document.body.classList.add('edited_scrollbar_windows');
        }


        getClassNameCall();
        if (detectMob()) {
            console.log('====================================');
            console.log('mobile');
            console.log('====================================');
            dispatch(setIsMobile(true));
        } else {
            console.log('====================================');
            console.log('desktop');
            console.log('====================================');
            dispatch(setIsMobile(false));
        }
        const auth = getAuth();
        const email = process.env.REACT_APP_FIREBASE_EMAIL ? process.env.REACT_APP_FIREBASE_EMAIL : '';
        const password = process.env.REACT_APP_FRIEBASE_PASSWORD ? process.env.REACT_APP_FRIEBASE_PASSWORD : '';

        if (!token) {
            if (id && !worksheetDetail) {
                signInWithEmailAndPassword(auth, email, password).then((res) => {
                    console.log('calling api .......');
                    getWorksheetDetailApi();
                    callStudentDetail();
                    setCallFirebaseFirst(true);
                    const tabID = sessionStorage.tabID &&
                        sessionStorage.closedLastTab !== '2' ?
                        sessionStorage.tabID :
                        sessionStorage.tabID = uuid();
                    sessionStorage.closedLastTab = '2';
                }).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorMessage);
                });

            }
            if (callDelete) {
                console.log('currentChatLog', currentChatLog);
                console.log('currentItem:', currentItem);
                deleteStudentInputApiCall(currentItem, currentChatLog.id, currentChatLog.studentSubmissionId);
            }
            if (isEdit && !checkIsEdit && !callDelete && currentChatLog) {
                console.log('currentChatLog:', currentChatLog);
                setShowModal(true);
                changeCurrentInputType(currentChatLog.type);
                setCurrentComponentId(currentItem.id);
                console.log('isEdit:', editItem);
                setCheckIsEdit(false);
            }
            if (checkIsEdit) {
                if (isEdit) handleStudentInputEdit(payloadForStudentInput, currentChatLog);
                else handleStudentInputPost(payloadForStudentInput, studentInputUrl);
            }

            window.addEventListener('unload beforeunload', function () {
                sessionStorage.closedLastTab = '1';
                console.log('unload beforeunload');
            });
            console.log('``````````` worksheetDetail:', worksheetDetail);

            return () => {
                window.removeEventListener('unload beforeunload', function () {
                    sessionStorage.closedLastTab = '1';
                });
            };
        } else {
            // alert('token exist');
            getWorksheetWithToken();
        }




    }, [callDelete, isEdit, checkIsEdit]);

    const toggleWorksheetModal = () => {
        setShowWorksheetModal(!showWorksheetModal);
    };

    const handleCancel = () => {
        setShowWorksheetModal(false);
    };

    const renderModal = (type: string) => {
        console.log('render modal', type);
        switch (type) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.QRCODE:
                console.log('show QR----');
                return (
                    <GenerateQRcode
                        type={TOOLBAR_BTN_ACTION_CONSTANTS.QRCODE}
                        worksheetId={sheetId ? sheetId : ''}
                    ></GenerateQRcode>
                );
        }
    };

    const currentModalType = useSelector(
        (state: any) => state.student.currentModalType
    );

    return (

        <>
            {worksheetDetail && worksheetDetail.mode === 'collab' && id && sheetId && studentName.name.length > 0 && <FirebaseStudent
                studentName={studentName}
                studentId={id}
                sheetId={sheetId}
                componentId={currentChatLog ? currentChatLog.id : ''}
                changeActiveStatus={changeActiveStatus}
                resetActiveStatus={() => setChangeActiveStatus(false)}
                changeActiveStudents={changeActiveStudents}
                submitStatus={submitStatus}
                releaseStatus={releaseStatus}
                callFirebaseFirst={callFirebaseFirst}
                setColorList={(colorList: any[]) => dispatch(setColorList(colorList))}
                setFocusList={(focusList: any[]) => dispatch(setFocusList(focusList))}
            />}
            {showAlert && (
                <CustomAlert
                    alertData={alertData}
                    alertClose={() => setShowAlert(false)}
                ></CustomAlert>
            )}
            <Modal
                modalProps={modalData}
                isOpen={showWorksheetModal}
                toggle={toggleWorksheetModal}
                cancelModal={handleCancel}
            >
                {showWorksheetModal && (
                    renderModal(currentModalType)
                )}
            </Modal>

            <StudentModal
                modalData={modalData}
                showModal={showModal}
                modalLoading={modalLoading}
                handleCloseModal={handleCloseModal}
                currentInputType={currentInputType}
                uploadComponent={handleUploadComponent}
            />

            {/* <EditorNavbar
                    handleNavBtnAction={handleNavBtnAction}
                    isTeacher={false}
                    worksheetDetail={worksheetDetail}
                    saveClick={false}
                    isViewSubmissions={false}
                    isEditLayout={false}
                /> */}

            {loading && <CanvasLoading type='student'></CanvasLoading>}

            {/* {!loading && <Canvas
                worksheetDetail={worksheetDetail}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                studentId={id ? id : ''}
                openModal={handleOpenModal}
                changeCurrentInputType={changeCurrentInputType}
                addText={handleAddText}
                textUploaded={textUploaded}
                changeCurrentItem={(item) => dispatch(setCurrentItem(item))}
                handleClick={handleClick}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleStudentInputClick={handleStudentInputClick}
                handleReleaseFocus={handleReleaseFocus}
                handleStudentInputUpdate={handleStudentInputUpdate}
                triggerStudentInput={triggerStudentInput}
                updateText={handleUpdateText}
            />} */}
            {!loading && <CommonCanvas
                fileUploaded={fileUploaded}
                mode={worksheetDetail.mode}
                parentType={PARENT_TYPE_CONSTANTS.student}
                components={worksheetDetail.components}
                currentDragItemType={''}
                editItem={false}
                currentItem={currentItem}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                totalScore={maxScore}
                totalBase={maxBase}
                isText={false}
                publishedStatus={worksheetDetail.publishedStatus}
                isEditLayout={false}
                studentId={id ? id : ''}

                toggleModal={function (): void {
                    throw new Error('Function not implemented.');
                }}
                handleModalData={function (value: CustomModal): void {
                    throw new Error('Function not implemented.');
                }}
                handleTempPosition={function (position: PointModel): void {
                    throw new Error('Function not implemented.');
                }}
                handleEdit={handleEdit}
                handleClickOutside={function (): void {
                    throw new Error('Function not implemented.');
                }}
                addComponent={function (element: WorksheetComponent): void {
                    throw new Error('Function not implemented.');
                }}
                deleteComponent={function (): void {
                    throw new Error('Function not implemented.');
                }}
                handleBlur={() => console.log()}
                updateCurrentItem={(component: DraggableElement) => dispatch(setCurrentItem(component))}
                handleUpdatePosition={function (position: PointModel): void {
                    throw new Error('Function not implemented.');
                }}
                handleBgDelete={function (): void {
                    throw new Error('Function not implemented.');
                }}
                handleBgEdit={function (): void {
                    throw new Error('Function not implemented.');
                }}
                handleTextChange={function (text: string, descText: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleResize={function (size: any): void {
                    throw new Error('Function not implemented.');
                }}
                handleClone={function (): void {
                    throw new Error('Function not implemented.');
                }}
                handleTempSize={function (size: any): void {
                    throw new Error('Function not implemented.');
                }}
                handleFontChange={function (data: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleFontSizeChange={function (data: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleFontWeightChange={function (data: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleItalic={function (data: boolean): void {
                    throw new Error('Function not implemented.');
                }}
                handleUnderline={function (data: boolean): void {
                    throw new Error('Function not implemented.');
                }}
                handleColorChange={function (data: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleBorderChange={function (data: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleBackgroundChange={function (data: string): void {
                    throw new Error('Function not implemented.');
                }}
                handlePopupIconClicked={function (data: string): void {
                    throw new Error('Function not implemented.');
                }}
                updateActiveItem={function (isText: boolean): void {
                    throw new Error('Function not implemented.');
                }}
                handleInputChange={function (text: string | undefined): void {
                    throw new Error('Function not implemented.');
                }}
                handleIsBack={function (value: boolean): void {
                    throw new Error('Function not implemented.');
                }}
                handleCanvas={function (value: any): void {
                    throw new Error('Function not implemented.');
                }}
                changeCurrentInputType={changeCurrentInputType}
                handleStudentInputClick={handleStudentInputClick}
                addText={handleAddText}
                textUploaded={textUploaded}
                updateText={handleUpdateText}
                handleChatLogClick={handleClick}
                openModal={handleOpenModal}
                handleDelete={handleDelete}
                handleReleaseFocus={handleReleaseFocus}
                handleStudentInputUpdate={handleStudentInputUpdate}
                triggerStudentInput={triggerStudentInput}

                studentStatusList={studentStatusList}
                colorList={colorList}
                focusList={focusList}
                worksheetDetail={worksheetDetail}
                currentChatLog={currentChatLog}
                onGradeChange={
                    () => {
                        throw new Error('Function not implemented');

                    }
                }
                onFeedbackChange={
                    () => {
                        throw new Error('Function not implemented');

                    }
                }
                handleClosePopup={() => dispatch(setCurrentItem(null))}
            />
            }
            <div className="tool-wrap" style={{
                position: 'absolute',
                zIndex: '9998'
            }}>
                {
                    worksheetDetail && worksheetDetail.mode === 'collab' && !token && <ActiveStudentBar />
                }
                <Toolbar
                    studentName={studentName}
                    className={className}
                    worksheetDetail={worksheetDetail}
                    handleToolbarBtnAction={handleToolbarBtnAction} />
            </div>




        </>

    );
};

export default Student;

// amk@smart-school-dev8 =>
// http://localhost:3000/redirect?username=U2FsdGVkX1%2BOpWxTDI83QTld72je3LlL0OqDwkqiJPq0bC%2FQcAPXY%2FIQ9KVkQwOD&token=U2FsdGVkX1%2BixkV8To0wX2v%2F2wrKwqzJXXCbAwkJ2yV54sOVdiwQvN4V4840oB15&apikey=U2FsdGVkX1%2FLKMxOeMv1%2BoLswm%2F4zy%2F50QrRyELF2whS0QpMPmrQ7aq4QgYa4MYvUzxzygKtKbZloVbLZBbIJw%3D%3D&usertype=U2FsdGVkX19PgcqaEg%2BX%2B%2FDmIDa0kI6YZlz5qY4uCmQ%3D&idUser=U2FsdGVkX1%2FfE6K6w8rkIMI%2Bh4Ijn342YKekdB701L5GxNuOG8%2FKin2krVsHgDG8&lessonid=U2FsdGVkX1%2Ba08xSov22gTE4dvdViPczcTxQbzfOFMYH%2BxtLfuXLMJThzp%2F3R1UN&worksheetid=U2FsdGVkX1%2Bn%2BXk%2FS5VGnZTHNnma9Uv5WOoJYvvwpla97ztBtW%2BeiktyELp%2BIMc6&classid=U2FsdGVkX1%2BipBSdoBPekhzwHpxbhdMaiqst5XlNoFGC2AFXTnb7hl%2FqSUdZPDRz

// amk@smart-school-dev2 =>
// http://localhost:3000/redirect?username=U2FsdGVkX197Dy7EaQBNt5CQeb4GKE%2BHos48K8%2B2piKT06vbhFAjVjcwAFQuzc9S&token=U2FsdGVkX187aMmrGq8qJHQwR7bin1GazZqjA28Vpg%2BdsmQlbL%2FT9VPpiMOAiMmY&apikey=U2FsdGVkX1%2FCccOkqv%2BUXIDwGEUGCBhmNzpDK5yB1UmXQZwYdirS7ymZ9%2BZkcIl5dksjhq4evWKpWDpElbYwBQ%3D%3D&usertype=U2FsdGVkX1%2BDp%2BllgqFGuciHCIpR0dRpjnAHW8kgeeI%3D&idUser=U2FsdGVkX1%2BGbuaCMJDhRhiPFTd%2BNMmiUvwC2gWaidMpqxoelkmXBqJNJgf%2FZ9hd&lessonid=U2FsdGVkX18UsAcXurOm0bEIq3vedQcfNuQVtFPxS5DL3oTVwrf%2FWPYz%2FTG8VG33&worksheetid=U2FsdGVkX1%2FvLZs2nLIJfkEObaj3WcnsGhoZBfsG0BcoVsJCzZN1mMNMMo%2F1LKU5&classid=U2FsdGVkX19LJyn5yxRhg1MHysABQovCM%2Fw1R%2BhDBO4N7gxmwTF53a9%2BmhXXSPz5

// amk@smart-school-dev11 =>
// http://localhost:3000/redirect?username=U2FsdGVkX1%2BTBUdhnTcqzusSVPwLFceX%2B72vKQhyNlR4xq5GI90X%2BHhY38T23r9z&token=U2FsdGVkX18QLF%2B06shqVjWoDcb7drk37UqWBYagE3v4RYGl8LDrOlWexQpOp%2FRk&apikey=U2FsdGVkX1%2FBrvaZhiSsZ7oOocdg%2Fp0EdP3ZafC51n35IRx5qjtkDAUVXd6BvzDD1j1z6uZA%2FbMrqFd0ddoyyA%3D%3D&usertype=U2FsdGVkX1%2BDhU5wEw2zBtFkp1mLk8%2BIW8Kw%2FEcR6VI%3D&idUser=U2FsdGVkX1%2FZiaFvoJRpxffhRbR9rFg135jngeUbaeuyT0JyNzArUJESvGVoFECR&lessonid=U2FsdGVkX1%2BNlALnKv7XUJ59qop6Acyd2xYWVjsdCbaEL%2BFipLcyaHWpx8HCLySF&worksheetid=U2FsdGVkX18BdnZSw%2FkEefEs5%2F8IaPqHiJ%2FtMHZSqKICnWFfUAg1sXQpGbFZycwb&classid=U2FsdGVkX18yudd3rINaa6TL25gH%2BpMJqVCrR0Mj1TeQw30OR1nQYFIXXc498tqk

// amk@smart-school-dev9 =>
// http://localhost:3000/redirect?username=U2FsdGVkX1%2BLVGvnkwUPNLywpVulnttJvJ%2FWJPwLuUMusnNvR5IJYEAq8Hgn36UT&token=U2FsdGVkX18PhkZFoeunz%2FlfEuXXTjLr5WugZXH8hNMH3BUvcoauwkvUstYrvSa1&apikey=U2FsdGVkX19limIQpOvlgvXQ4uHjAsKrk6xsE1rAYIXPfh2lVAjEHwcDdKWjNGneidbWZH9fHTUlk5YF6bOt3Q%3D%3D&usertype=U2FsdGVkX198%2FIm0dyrNQWbstbdz4%2FlN68gAYgDBEj0%3D&idUser=U2FsdGVkX1%2FcQEwzY55vNwf2IConqoZ4QtIaFniE%2FXTkgw55NKdDn2EQBgvWCK49&lessonid=U2FsdGVkX19AsFD9TkAss4utLP9DkIV4XiMrkyYw%2F5XllwZbPFDtSgi2Dtqpl4NS&worksheetid=U2FsdGVkX18M0sVtoVXFJe3f8HtZUdmiLHV%2BrAd%2FrBk1PqAc9FpadJyaBkoC%2F%2FxS&classid=U2FsdGVkX18GuTT892JcAzVlvfT5vegVJtS%2F6DQ%2Bt%2B49LOZMyynP48lI%2F7yxcDvw

// amk@smart-school-dev1 =>
// http://localhost:3000/redirect?username=U2FsdGVkX1%2FBvfxr68jmuF8g%2Fjfxv8SLn0GTDyY1wsHYQmlp7ZFemIET%2BXUMNOSx&token=U2FsdGVkX18oXFTSNUvbqijIPQ5UWDi12OcbMiGByTcqw9xiUMi2YkkDslDykJfg&apikey=U2FsdGVkX1%2BlMkdwO3nhVrikPi5Ew%2FXiA7KgJMpLfg1sdeBPp6IuxEN2dVFjVLr4roX080aCCf5m384Yw30mlw%3D%3D&usertype=U2FsdGVkX1%2FiecLLl4hZFN2SnhcHatbczNQQbLaKGXY%3D&idUser=U2FsdGVkX1%2BJE8lGyE43VYwFjixlAHzZI6bSrx3VY6okwJMfXss%2B7liUmTUyBQZn&lessonid=U2FsdGVkX18qI6Vo2tUnl9bOfn1T%2FAF0%2F1PaMcnF3GVAwumT7n3IyFIWuQaQ2wXz&worksheetid=U2FsdGVkX18lp1Wq0gDAWoUonMqP3%2BuPuQcIxPo%2FpeaId3ZpJaNbmg8rTJNyLys%2B&classid=U2FsdGVkX194Z5Ef%2F%2FXeq0RoEkSigQilaGFXkOUMGSyXAHy3oyypRR8%2BdMAib537