import React, { FC, useEffect } from 'react';
import './loading.css';
export interface IProps {
    loadingData: string;
}
const CustomLoading: FC<IProps> = ({ loadingData }) => {
    return (
        <div>
            <div className={loadingData}>
            </div>
        </div>
    );
};

export default CustomLoading;
