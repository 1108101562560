import React, { FC, useEffect, useRef, useState } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';
import './grading-tool.css';
export interface IProps {
    component: DraggableElement;
    handleItemClicked: () => void;
    handleEnterHit?: (component: DraggableElement) => void;
    editItem: boolean;
    readOnly: boolean;
}
const GradingTool: FC<IProps> = ({
    component,
    handleItemClicked,
    editItem,
    readOnly,
    handleEnterHit
}) => {

    const [mark, setMark] = useState<string | null>(null);

    const clickedItem = () => {
        handleItemClicked();
    };

    const handleTextChange = (e: any) => {
        // if (e.target.value <= currentItem.data.score.base) {
        //     setMark(e.target.value);
        // }

        if (component.data.score.base && e.target.value <= component.data.score.base) {
            setMark(e.target.value);
        }
    };

    const gatherComponentData = () => {
        const comp: DraggableElement = {
            id: component.id,
            type: component.type,
            location: component.location,
            size: component.size,
            data: {
                text: component.data.text,
                score: {
                    base: component.data.score.base,
                    mark: mark ? Number.parseInt(mark) : 0,
                },
                url: component.data.url,
                chatLog: component.data.chatLog,
                style: component.data.style,
                iconType: component.data.iconType,
                descText: component.data.descText,
                descStyle: component.data.descStyle,
                isBack: component.data.isBack,
            }
        };

        return comp;
    };

    const handleFocusOut = (e: any) => {

        if (mark && handleEnterHit) {
            handleEnterHit(gatherComponentData());
        }

    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter' && mark && handleEnterHit) {

            // component.data.score.mark = mark ? Number.parseInt(mark) : 0;

            handleEnterHit(gatherComponentData());
        }
    };

    useEffect(() => {
        setMark(component.data.score.mark ? component.data.score.mark.toString() : null);
    }, [component]);

    return (
        <>
            {component.data.score && (
                <div onClick={clickedItem} className="grading-wrapper">
                    <input
                        type="text"
                        onChange={handleTextChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleFocusOut}
                        className="mark text-c300"
                        value={mark ? mark : ''}
                        readOnly={readOnly}
                    />
                    <span>/ {component.data.score.base} {component.data.score.base && component.data.score.base > 1 ? 'points' : 'point'}</span>
                </div>
            )}
        </>
    );
};

export default GradingTool;
