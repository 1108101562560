import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { worksheetListApi } from '../../api';
import { Template } from '../../models/template-item.model';
import { getTemplates } from '../../api/worksheet/templatesApi';

// const worksheetList: Worksheet[] = [];
// const currentModalType: string | null = null;

interface TemplateListState {
    templates: {
        data: Template[];
        loading: string | null;
        error: string | null;
    };
    templateList: Template[];
    currentModalType: string | null;
    currentTemplate: Template | null;
}

const initialState = {
    templates: {
        data: [],
        loading: 'idle',
        error: null,
    },
    templateList: [],
    currentModalType: null,
    currentTemplate: null,
} as TemplateListState;

export const TemplateListSlice = createSlice({
    name: 'templateList',
    initialState,
    reducers: {
        setModalType: (state, action: PayloadAction<string | null>) => {
            state.currentModalType = action.payload;
        },
        setCurrentTemplate: (state, action: PayloadAction<Template | null>) => {
            state.currentTemplate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTemplates.pending, (state, action) => {
            if (state.templates.loading === 'idle') {
                console.log('state.templates.loading === idle');
                state.templates.loading = 'pending';
            }
        });

        builder.addCase(getTemplates.fulfilled, (state, action) => {
            if (state.templates.loading === 'pending') {
                console.log(
                    'state.templates.loading === pending',
                    action.payload
                );
                state.templates.data = action.payload;
                state.templates.loading = 'idle';
            }
        });

        builder.addCase(getTemplates.rejected, (state, action) => {
            if (state.templates.loading === 'pending') {
                console.log('state.templates.loading === pending');
                state.templates.loading = 'idle';
                state.templates.error = 'Error occured';
            }
        });

        // builder.addMatcher(
        //     worksheetListApi.endpoints.getTemplateList.matchFulfilled,
        //     (state, action) => {
        //         console.log('templateList ', action.payload);
        //         state.templateList = action.payload;
        //     }
        // );
    },
});

export const { setModalType, setCurrentTemplate } = TemplateListSlice.actions;

export default TemplateListSlice.reducer;
