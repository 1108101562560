import './student-input.css';
import React, { createElement, FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';
import './student-input.css';

import TextIcon from '../../../../assets/images/student-input/text_b.svg';
import GalleryIcon from '../../../../assets/images/student-input/gallery_s_b.svg';
import VideoIcon from '../../../../assets/images/student-input/video_camera_b.svg';
import AudioIcon from '../../../../assets/images/student-input/audio_b.svg';
import PDFIcon from '../../../../assets/images/student-input/pdf.svg';
import TextActiveIcon from '../../../../assets/images/student-input/textActive.svg';
import GalleryActiveIcon from '../../../../assets/images/student-input/galleryActive.svg';
import VideoActiveIcon from '../../../../assets/images/student-input/videoActive.svg';
import AudioActiveIcon from '../../../../assets/images/student-input/audioActive.svg';
import PDFActiveIcon from '../../../../assets/images/student-input/pdfActive.svg';

import SendIcon from '../../../../assets/images/student-input/Send.svg';
import SendActiveIcon from '../../../../assets/images/student-input/Send_Active.svg';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import IconButton from '../../../../global-components/icon-button/IconButton';
import { ChatLogDataModel } from '../../../../models/chat-log-data.model';
import Text from '../text/Text';
import Gallery from '../image/Image';
import Video from '../video-input/Video';
import MP3 from '../audio-input/Audio';
import Pdf from '../pdf-input/Pdf';
import { StudentStatus } from '../../../../models/student-status.model';
import UseOutsideClick from '../../../../hooks/UseOutsideClick';
import { useParams } from 'react-router-dom';
import { WorksheetDetailModel } from '../../../../models/worksheet-detail.model';
import { PARENT_TYPE_CONSTANTS } from '../../../../constants/parentTypeConstants';
export interface IProps {
    fileUploaded: boolean | undefined;
    mode:string;
    parentType: string;
    worksheetDetail: WorksheetDetailModel | null;
    component: DraggableElement;
    studentId?: string;
    openModal: (id: string) => void;
    changeCurrentInputType: (type: string) => void;
    addText: (text: string, id: string) => void;
    textUploaded: boolean;
    changeCurrentItem: (item: DraggableElement) => void;

    handleEdit: () => void;
    handleDelete: () => void;
    handleClick: (item: ChatLogDataModel) => void;
    handleStudentInputClick: (item: DraggableElement) => void;
    handleReleaseFocus?: () => void;

    editable: boolean;
    handleStudentInputUpdate?: (id: string) => void;
    triggerStudentInput?: boolean;
    updateText?: (text: string, id: string) => void;
    currentItem: DraggableElement | null;
    studentStatusList: any;
    colorList: any;
    focusList: any;
    currentChatLog: ChatLogDataModel | null;
}

const StudentInput: FC<IProps> = ({
    fileUploaded,
    mode,
    parentType,
    worksheetDetail,
    component,
    studentId,
    openModal,
    changeCurrentInputType,
    addText,
    textUploaded,
    changeCurrentItem,
    handleClick,
    handleDelete,
    handleEdit,
    handleStudentInputClick,
    handleReleaseFocus,
    editable,
    handleStudentInputUpdate,
    triggerStudentInput,
    updateText,
    currentItem,
    studentStatusList,
    colorList,
    focusList,
    currentChatLog
}) => {
    const params = useParams<{ id?: string; sheetId?: string; token?: string }>();
    const { token } = params;
    const toolList: IconBtnModel[] = [
        {
            icon: TextIcon,
            activeIcon: TextActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.TEXT,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        {
            icon: GalleryIcon,
            activeIcon: GalleryActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        {
            icon: VideoIcon,
            activeIcon: VideoActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        {
            icon: AudioIcon,
            activeIcon: AudioActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        {
            icon: PDFIcon,
            activeIcon: PDFActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.PDF,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        // {
        //     icon: BinIcon,
        //     alt: TOOLBAR_BTN_ACTION_CONSTANTS.BIN,
        //     uiClass: 'toolbar-icon',
        //     disable: false,
        //     draggable: true,
        // },
    ];

    console.log('InStudentInput', component.data.chatLog);

    const viewRef = useRef<HTMLDivElement>(null);
    const studentInputRef = useRef<HTMLDivElement>(null);
    const [currentComponent, setCurrentComponent] = useState<boolean>(false);


    const [checkCurrentItem, setCheckCurrentItem] = useState<boolean>(false);
    const [currentActiveType, setCurrentActiveType] = useState<string | null>(null);

    const autoResize = (textbox: HTMLTextAreaElement) => {
        console.log('input', textbox.value);
        textbox.style.height = 'auto';
        textbox.style.height = textbox.scrollHeight + 'px';
    };

    const uploadText = () => {
        const textValue = (document.getElementById('sample') as HTMLTextAreaElement).value;
        if (textValue) {
            console.log('data will sent', textValue);
            addText(textValue, component.id);
        }
        setCurrentActiveType(null);
    };

    const handleStudentInputToolClicked = (item: ChatLogDataModel) => {
        console.log('====================================');
        console.log('new function ');
        console.log('====================================');
        setCurrentActiveType(null);
        handleClick(item);
    };

    const renderText = () => {
        console.log('renderText');
        const checkElement = document.getElementById('render-textbox');
        if (viewRef.current && !checkElement) {
            const div = document.createElement('div');
            div.id = 'render-textbox';
            const textbox = document.createElement('textarea');
            textbox.classList.add('auto-resize-textbox');
            textbox.id = 'sample';
            textbox.rows = 1;
            viewRef.current?.appendChild(textbox);
            textbox.addEventListener('input', () => autoResize(textbox));


            const btn = document.createElement('img');
            btn.src = SendIcon;
            btn.id = 'send';
            btn.classList.add('send-btn');

            div.appendChild(btn);
            div.appendChild(textbox);

            viewRef.current?.appendChild(div);

            btn.addEventListener('click', uploadText);
            viewRef.current.scrollTop = viewRef.current.scrollHeight;
            textbox.focus();
            textbox.style.border = '1px solid #4A9BFF';
            btn.src = SendActiveIcon;

            textbox.addEventListener('focus', () => {
                if (btn)
                    btn.src = SendActiveIcon;
                textbox.style.border = '1px solid #4A9BFF';
            });
            textbox.addEventListener('blur', () => {
                if (btn)
                    btn.src = SendIcon;
                textbox.style.border = '1px solid #E3E4E6';
            });
        } else if (viewRef.current && checkElement && currentItem) {
            //if text box opened, remove first and 
            const parent = document.getElementById(currentItem.id);
            console.log('====================================');
            console.log('parent:', parent);
            console.log('====================================');
            if (parent) {
                parent.removeChild(checkElement);
            }

            const div = document.createElement('div');
            div.id = 'render-textbox';
            const textbox = document.createElement('textarea');
            textbox.classList.add('auto-resize-textbox');
            textbox.id = 'sample';
            textbox.rows = 1;
            viewRef.current?.appendChild(textbox);
            textbox.addEventListener('input', () => autoResize(textbox));


            const btn = document.createElement('img');
            btn.src = SendIcon;
            btn.id = 'send';
            btn.classList.add('send-btn');

            div.appendChild(btn);
            div.appendChild(textbox);

            viewRef.current?.appendChild(div);

            btn.addEventListener('click', uploadText);
            viewRef.current.scrollTop = viewRef.current.scrollHeight;
            textbox.focus();
            textbox.style.border = '1px solid #4A9BFF';
            btn.src = SendActiveIcon;

            textbox.addEventListener('focus', () => {
                if (btn)
                    btn.src = SendActiveIcon;
                textbox.style.border = '1px solid #4A9BFF';
            });
            textbox.addEventListener('blur', () => {
                if (btn)
                    btn.src = SendIcon;
                textbox.style.border = '1px solid #E3E4E6';
            });
        }
    };

    const handlebtnClicked = (type: string) => {
        if (!token) {
            if (worksheetDetail && !worksheetDetail.isLocked) {
                changeCurrentInputType(type);
                console.log(
                    'setCurrentITem:', component.id, type
                );
                changeCurrentItem(component);
                setCurrentActiveType(type);
                if (type !== TOOLBAR_BTN_ACTION_CONSTANTS.TEXT) {
                    setCurrentActiveType(null);
                }
                switch (type) {
                    case TOOLBAR_BTN_ACTION_CONSTANTS.TEXT:
                        renderText();
                        break;
                    case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                        openModal(component.id);
                        break;
                    case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                        openModal(component.id);
                        break;
                    case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                        openModal(component.id);
                        break;
                    case TOOLBAR_BTN_ACTION_CONSTANTS.PDF:
                        openModal(component.id);
                        break;
                    default:
                        console.log('');
                }

                if (type !== TOOLBAR_BTN_ACTION_CONSTANTS.TEXT && currentItem) {
                    //btn remove
                    const div = document.getElementById('render-textbox');
                    try {
                        if (div && currentItem.id === component.id && viewRef.current) {
                            console.log('remove btn');
                            viewRef.current?.removeChild(div);
                        }
                    } catch (error) {
                        console.error(error);
                    }

                }
            } else {
                console.log('I do not locked now:');
            }
        }


    };

    const getHeight = () => {
        const componentHeight = component.size.height;
        return componentHeight - 40;
    };

    const checkIfLocked = (item: ChatLogDataModel) => {
        handleStudentInputToolClicked(item);
        console.log('====================================');
        console.log('checkIfLocked');
        console.log('====================================');
    };

    const renderStudentInputChildren = (components: ChatLogDataModel[] | null) => {
        console.log('components', components);
        if (components && worksheetDetail)
            return components.map((component: ChatLogDataModel, index: number) => {
                console.log('student input', component.id);
                switch (component.type) {
                    case TOOLBAR_BTN_ACTION_CONSTANTS.TEXT:
                        if (updateText)
                            return <Text parentType={parentType} mode={mode} currentItem={currentItem} currentChatLog={currentChatLog} colorList={colorList} currentComponent={currentComponent} isWorksheetLocked={worksheetDetail.isLocked} triggerStudentInput={triggerStudentInput} studentStatusList={studentStatusList} focusList={focusList} key={component.id ? component.id : index} component={component} handleClick={(item: ChatLogDataModel) => checkIfLocked(item)} handleReleaseFocus={handleReleaseFocus} updateText={updateText} handleDelete={handleDelete} handleClickParent={checkBeforeComponentClick}></Text>;
                        else return <React.Fragment key={'0'}>else</React.Fragment>;
                    case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                        return <Gallery parentType={parentType} mode={mode} currentItem={currentItem} currentChatLog={currentChatLog} colorList={colorList} currentComponent={currentComponent} isWorksheetLocked={worksheetDetail.isLocked} triggerStudentInput={triggerStudentInput} studentStatusList={studentStatusList} focusList={focusList} key={component.id ? component.id : index} component={component} handleClick={(item: ChatLogDataModel) => checkIfLocked(item)} handleReleaseFocus={handleReleaseFocus} handleEdit={handleEdit} handleDelete={handleDelete}></Gallery>;
                    case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                        return <Video parentType={parentType} mode={mode} currentItem={currentItem} currentChatLog={currentChatLog} colorList={colorList} currentComponent={currentComponent} isWorksheetLocked={worksheetDetail.isLocked} triggerStudentInput={triggerStudentInput} studentStatusList={studentStatusList} focusList={focusList} key={component.id ? component.id : index} component={component} handleClick={(item: ChatLogDataModel) => checkIfLocked(item)} handleReleaseFocus={handleReleaseFocus} handleEdit={handleEdit} handleDelete={handleDelete}></Video>;
                    case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                        return <MP3 fileUploaded={fileUploaded} parentType={parentType} mode={mode} currentItem={currentItem} currentChatLog={currentChatLog} colorList={colorList} currentComponent={currentComponent} isWorksheetLocked={worksheetDetail.isLocked} triggerStudentInput={triggerStudentInput} studentStatusList={studentStatusList} focusList={focusList} key={component.id ? component.id : index} editable={editable} component={component} handleClick={(item: ChatLogDataModel) => checkIfLocked(item)} handleReleaseFocus={handleReleaseFocus} handleEdit={handleEdit} handleDelete={handleDelete}></MP3>;
                    case TOOLBAR_BTN_ACTION_CONSTANTS.PDF:
                        return <Pdf parentType={parentType} mode={mode} currentItem={currentItem} currentChatLog={currentChatLog} colorList={colorList} currentComponent={currentComponent} isWorksheetLocked={worksheetDetail.isLocked} triggerStudentInput={triggerStudentInput} studentStatusList={studentStatusList} focusList={focusList} key={component.id ? component.id : index} editable={editable} component={component} handleClick={(item: ChatLogDataModel) => checkIfLocked(item)} handleReleaseFocus={handleReleaseFocus} handleEdit={handleEdit} handleDelete={handleDelete}></Pdf>;
                }

            });
        else
            return <></>;

    };

    const callbackFunction = () => {
        setCheckCurrentItem(true);
        setCurrentActiveType(null);
    };

    UseOutsideClick(studentInputRef, callbackFunction);

    const checkBeforeComponentClick = () => {
        handleStudentInputClick(component);
    };

    useEffect(() => {
        if (textUploaded) {
            const div = document.getElementById('render-textbox');
            try {
                if (div && currentItem && currentItem.id === component.id && viewRef.current) {
                    console.log('remove btn');
                    viewRef.current?.removeChild(div);
                }
            } catch (error) {
                console.error(error);
            }

        }

        // console.log('====================================');
        // console.log('triggered . . .');
        // console.log('====================================');

        //check is locked
        setCurrentComponent(false);
        console.log('focusList:', focusList);
        console.log('component', component);
        studentStatusList.map((studentStatus: StudentStatus) => {
            if (studentStatus.parent == component.id) {
                setCurrentComponent(true);
                console.log('current Component true');
                if (studentStatus.userAction === 'submit') {
                    if (studentStatus.tabID !== sessionStorage.tabID) {
                        console.log('submit by other then reload');
                        if (handleStudentInputUpdate)
                            handleStudentInputUpdate(component.id);
                    }
                }
            }
        });

        if (checkCurrentItem) {
            // alert('check current item'+currentItem+'*'+currentChatLog);
            if (currentItem && component.id == currentItem.id) {
                const div = document.getElementById('render-textbox');
                const div2 = document.getElementById('edit-textbox');
                const sendIcon = document.getElementById('send-icon');
                try {
                    if (div && currentItem.id === component.id && viewRef.current) {
                        uploadText();
                        console.log('remove btn');
                        viewRef.current?.removeChild(div);
                    } else if(div2 && sendIcon && currentItem.id === component.id && viewRef.current && currentChatLog) {
                        // this one is for text edit 
                        // different component
                        const element = (document.getElementById(currentChatLog.id) as HTMLDivElement);
                        if(element){
                            const textValue = element.innerText;
                            if (textValue !== undefined && textValue !== null && textValue !== '' && updateText) {
                                console.log('data will sent', textValue);
                                updateText(textValue, component.id);
                            }
                            setCurrentActiveType(null);
                        }else{
                            if (handleReleaseFocus)
                                handleReleaseFocus();
                        }
                        
                    }
                    else {
                        if (handleReleaseFocus)
                            handleReleaseFocus();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
            setCheckCurrentItem(false);
        }
    }, [checkCurrentItem, triggerStudentInput, studentStatusList,textUploaded]);

    // listen to combination of command key and enter key event to call uploadText
    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.keyCode === 13 && event.metaKey) {
                //
                callbackFunction();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <div
                ref={studentInputRef}
                style={{
                    width: component.size.width,
                    height: component.size.height,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    opacity: currentComponent ? '1' : '0.99',
                    borderColor: component.data.style?.border ? component.data.style?.border : '#000000',
                    background: component.data.style?.background ? component.data.style?.background : 'transparent',
                }}
                className="student-input-wrapper"
                data-component-id={component.id}
                onClick={() => checkBeforeComponentClick()}
                onTouchStart={() => checkBeforeComponentClick()}
            >
                <div className="view-layout new" id={component.id} ref={viewRef} style={{
                    height: getHeight()
                }}>
                    {component.data.chatLog && renderStudentInputChildren(component.data.chatLog)}
                </div>
                {editable && <div className="student-input-tool-bar" >
                    {toolList.map((tool: IconBtnModel) => {
                        return (
                            <IconButton
                                currentSelected={currentActiveType === tool.alt}
                                key={tool.alt}
                                btnProps={tool}
                                handleBtnClicked={() => handlebtnClicked(tool.alt)}
                            />
                        );
                    })}
                    
                </div>}
                {!editable && <div className="student-input-tool-bar-transparent" ></div>}
            </div>
        </>

    );
};

export default StudentInput;
