import React from 'react';
import Teacher from '../teacher/Teacher';

const Editor = () => {
    return (
        <>
            <Teacher />
        </>
    );
};

export default Editor;
