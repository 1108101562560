import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Worksheet } from '../../models/worksheet-item.model';
import WorksheetList from '../../fake-data/worksheet-list.json';
import WorksheetForStudent from '../../fake-data/worksheet.json';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';
import { Activity } from '../../models/activity-log.model';
import ActivityList from '../../fake-data/activitylist.json';
import StudentList from '../../fake-data/student-list.json';
import { StudentListModel } from '../../models/student-list.model';
import { AuthService } from '../../service/auth.service';
import { DraggableElement } from '../../models/draggable-element.model';
import { Template } from '../../models/template-item.model';

const _authService = new AuthService();

export const worksheetListApi = createApi({
    reducerPath: 'worksheetListApi',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'https://www.thecocktaildb.com/api/json/v1/1/',
        baseUrl: process.env.REACT_APP_WORKSHEET_API_URL + 'api/v1/',
        prepareHeaders: (headers, { endpoint }) => {
            const credential = _authService.getHeadersWithLessonId();
            headers.set('username', credential.username);
            headers.set('apikey', credential.apikey);
            headers.set('password', credential.password);
            headers.set('lessonId', credential.lessonId);
            // headers.set('Content-Type', 'multipart/form-data');
            // if (/media/gi.test(endpoint)) {
            //     headers.set('content-length', '0');
            // } else {
            //     headers.set('content-length', '1234');
            // }
            return headers;
        },
    }),
    tagTypes: ['Worksheet'],
    endpoints: (builder) => ({
        getWorksheetList: builder.query({
            query: () => ({
                url: 'worksheets',
                method: 'GET',
                headers: _authService.getHeadersWithLessonId(),
            }),
            providesTags: ['Worksheet'],

            transformResponse: (response: any) => {
                const worksheetArray: Worksheet[] = [];
                console.log('response', response);
                const res = response.worksheets;
                res.map((worksheet: any) => {
                    const worksheetObj: Worksheet = {
                        id: worksheet._id,
                        name: worksheet.name,
                        thumbnailUrl: worksheet.thumbnailUrl,
                        publishedStatus: worksheet.publishedStatus,
                        isPublished:
                            worksheet.publishedStatus === 'published'
                                ? true
                                : false,
                        isLocked: worksheet.isLocked,
                        labelId: worksheet.labelId,
                        label:
                            worksheet.label != undefined
                                ? worksheet.label
                                : null,
                        mode: worksheet.mode,
                        students: [],
                    };
                    worksheetArray.push(worksheetObj);
                });
                // WorksheetList.map((worksheet: any) => {
                //     const worksheetObj: Worksheet = {
                //         id: worksheet.id,
                //         name: worksheet.name,
                //         thumbnailUrl: worksheet.thumbnailUrl,
                //         publishedStatus: 'draft',
                //         isPublished: worksheet.published,
                //         isLocked: worksheet.locked,
                //     };
                //     worksheetArray.push(worksheetObj);
                // });
                console.log('worksheetArray', worksheetArray);
                return worksheetArray;
            },
        }),
        getWorksheetById: builder.query({
            query: ({ id }) => ({
                url: `worksheets/${id}`,
                method: 'GET',
                headers: _authService.getHeadersWithLessonId(),
            }),
            transformResponse: (response: any) => {
                const worksheetDetail: WorksheetDetailModel[] = [];
                const mapWorksheetComponents = (components: any) => {
                    const componentArray: DraggableElement[] = [];
                    components.map((component: any) => {
                        const componentObj: DraggableElement = {
                            id: component._id,
                            type: component.type,
                            location: component.location,
                            size: component.size,
                            data: component.data,
                        };
                        componentArray.push(componentObj);
                    });
                    return componentArray;
                };
                [response].map((worksheet: any) => {
                    const worksheetObj: WorksheetDetailModel = {
                        id: worksheet._id,
                        name: worksheet.name,
                        studentGroup: worksheet.studentGroup
                            ? worksheet.studentGroup
                            : null,
                        publishedStatus: worksheet.publishedStatus,
                        components: mapWorksheetComponents(
                            worksheet.worksheetComponents
                        ),
                        componentGroup: worksheet.componentGroup
                            ? worksheet.componentGroup
                            : [],
                        createdAt: worksheet.createdAt,
                        updatedAt: worksheet.updatedAt,
                        mode: worksheet.mode,
                        submissionCount: worksheet.submissionCount,
                        isLocked: worksheet.isLocked,
                        thumbnailUrl: worksheet.thumbnailUrl,
                        labelId: worksheet.labelId,
                        label:
                            worksheet.label != undefined
                                ? worksheet.label
                                : null,
                        students: [],
                    };
                    worksheetDetail.push(worksheetObj);
                });

                console.log('StudentWorkSheet', worksheetDetail);
                console.log('worksheet', worksheetDetail[0]);
                return worksheetDetail[0];
            },
        }),
        getActivityLogById: builder.query({
            query: () => ({
                // url: 'filter.php?c=Cocktail',
                url: 'worksheets',
                method: 'GET',
                headers: _authService.getHeadersWithLessonId(),
            }),
            transformResponse: (response: any) => {
                const activityData: Activity[] = [];
                ActivityList.map((activity: any) => {
                    const activityObj: Activity = {
                        id: activity.id,
                        studentname: activity.studentname,
                        initials: activity.initials,
                        profile: activity.profile,
                        action: activity.action,
                        date: activity.date,
                        time: activity.time,
                    };
                    activityData.push(activityObj);
                });

                console.log('activitydata', activityData);
                return activityData;
            },
        }),
        getStudentListById: builder.query({
            query: () => ({
                // url: 'filter.php?c=Cocktail',
                url: 'worksheets',
                method: 'GET',
                headers: _authService.getHeadersWithLessonId(),
            }),
            transformResponse: (response: any) => {
                const studentData: StudentListModel[] = [];
                StudentList.map((student: any) => {
                    const studentObj: StudentListModel = {
                        id: student.id,
                        pwUserId:
                            student.metadata != undefined &&
                            student.metadata.pwUserId != undefined &&
                            student.metadata.pwUserId != null
                                ? student.metadata.pwUserId
                                : student._id,
                        name: student.name,
                        checked: false,
                    };
                    studentData.push(studentObj);
                });

                console.log('studentdata', studentData);
                return studentData;
            },
        }),
        postWorksheetComponent: builder.mutation({
            query: ({ id, payload }) => ({
                url: `worksheets/${id}/components`,
                method: 'POST',
                headers: _authService.getHeadersInfo(),
                body: payload,
            }),
        }),
        deleteWorksheetComponent: builder.mutation({
            query: ({ id, componentId }) => ({
                url: `worksheets/${id}/components/${componentId}`,
                method: 'DELETE',
                headers: _authService.getHeadersInfo(),
            }),
        }),
        uploadMedia: builder.mutation({
            query: ({ id, payload }) => ({
                url: `worksheets/${id}/media`,
                method: 'POST',
                // headers: _authService.getHeadersInfo(),
                body: payload,
                endpoint: 'media',
                contentType: false,
            }),
        }),
        createWorksheet: builder.mutation({
            query: (body) => ({
                url: 'worksheets',
                method: 'POST',
                headers: _authService.getHeadersInfo(),
                body: body,
            }),
            invalidatesTags: ['Worksheet'],
        }),
        deleteWorksheet: builder.mutation({
            query: (id) => ({
                url: 'worksheets/' + id,
                method: 'DELETE',
                headers: _authService.getHeadersInfo(),
            }),
            invalidatesTags: ['Worksheet'],
        }),
    }),
});

export const {
    useGetWorksheetListQuery,
    useGetWorksheetByIdQuery,
    useGetActivityLogByIdQuery,
    useGetStudentListByIdQuery,
    usePostWorksheetComponentMutation,
    useDeleteWorksheetComponentMutation,
    useUploadMediaMutation,
    useCreateWorksheetMutation,
    useDeleteWorksheetMutation,
} = worksheetListApi;
