import React, { FC, useEffect, useRef, useState } from 'react';
import { PARENT_TYPE_CONSTANTS } from '../../../../constants/parentTypeConstants';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { Font } from '../../../../models/font.model';
import './text-tool.css';
export interface IProps {
    parentType: string;
    component: DraggableElement;
    currentItem: DraggableElement | null;
    handleItemClicked: () => void;
    editItem: boolean;
    handleInputChange: (text: string | undefined) => void;
}

const TextTool: FC<IProps> = ({
    parentType,
    component,
    currentItem,
    handleItemClicked,
    editItem,
    handleInputChange,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const handleTextChange = (e: any) => {
        console.log(ref.current?.innerText, e);
        if (parentType === PARENT_TYPE_CONSTANTS.teacher || parentType === PARENT_TYPE_CONSTANTS.template)
            handleInputChange(ref.current?.innerText);
    };

    const handleFocus = (e: React.FocusEvent<HTMLDivElement, Element>) => {
        e.stopPropagation();
        e.preventDefault();
        console.log('handle Focus');
    };

    const [text, setText] = useState(component.data.text);

    const clickedItem = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // e.stopPropagation();
        // e.preventDefault();
        // handleItemClicked();
    };

    const idForTextTool = 'component-' + component.id;
    const sampleFontStyle: Font = {
        fontFamily: 'Inter',
        fontWeight: 'Regular',
        fontSize: '24px',
        fontColor: '#000000',
        fontStyle: null,
        textDecoration: null,
        border: null,
        background: null,
    };
    const fontStyle: Font | null = component.data.style
        ? component.data.style
        : sampleFontStyle;

    useEffect(() => {
        console.log('text tools', currentItem);
        if (
            currentItem &&
            currentItem.id === component.id &&
            ref.current &&
            editItem
        ) {
            console.log('focus', ref.current);
            ref.current.contentEditable = 'true';
            document.body.style.cursor = 'auto';
            ref.current.focus();
        } else if (ref.current) {
            console.log('blur', ref.current.innerText);
            ref.current.contentEditable = 'false';
        }

        if (ref.current) {
            ref.current.addEventListener('paste', function (e: ClipboardEvent) {
                e.preventDefault();
                if (e.clipboardData) {
                    const text = e.clipboardData.getData('text/plain');
                    document.execCommand('insertText', false, text);
                    setText(text);
                }
                // alert('paste');
            });
        }
        // console.log('font', component.data.style);
    }, []);
    // [currentItem, editItem, ref]);

    return (
        <>
            <div
                ref={ref}
                id={idForTextTool}
                className="text-tool"
                contentEditable={editItem}
                onInput={handleTextChange}
                suppressContentEditableWarning={true}
                tabIndex={-1}
                onBlur={handleTextChange}
                onClick={(e) => clickedItem(e)}
                style={{
                    width: component.size.width + 'px',
                    height: component.size.height + 'px',
                    fontFamily: fontStyle!.fontFamily
                        ? fontStyle!.fontFamily
                        : 'Inter',
                    fontSize: fontStyle!.fontSize
                        ? fontStyle!.fontSize
                        : '12px',
                    fontWeight: fontStyle!.fontWeight ? fontStyle!.fontWeight : '',
                    fontStyle: fontStyle!.fontStyle
                        ? fontStyle!.fontStyle
                        : 'normal',
                    textDecoration: fontStyle!.textDecoration
                        ? fontStyle!.textDecoration
                        : 'none',
                    color: fontStyle!.fontColor ? fontStyle!.fontColor : '',
                    transition: 'font-family 1s ease 0s',
                }}
            >
                {text}
            </div>
        </>
    );
};

export default TextTool;
