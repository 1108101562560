import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { worksheetListApi } from '../../api';
import {
    getWorksheetDetail,
    getWorksheetSubmissionDetails,
} from '../../api/worksheet/teacherApi';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';
import { DraggableElement } from '../../models/draggable-element.model';
import { Submission, WorksheetSubmissionDetail } from '../../models/worksheet-submission-detail.model';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';

interface SubmissionsState {
    worksheetDetail: WorksheetDetailModel | null;
    worksheetSubmissions: WorksheetSubmissionDetail[] | null;
    studentSubmission: Submission | null;
    selectedStudentId: string | null;
    mode: string | null;
    loading: string;
    error: string | null;
    isEditLayout: boolean;
    currentDragItemType: string | null;
    currentItem: DraggableElement | null;
    editItem: boolean;
    editItemType: string | null;
    canvasWidth: number;
    canvasHeight: number;
    returningWorksheet: boolean;
    currentModalType: string | null;
    isQRcode: boolean;
    maxScore: number;
    maxBase: number;
}

const initialState = {
    worksheetDetail: null,
    worksheetSubmissions: null,
    studentSubmission: null,
    selectedStudentId: null,
    mode: null,
    loading: 'idle',
    error: null,
    isEditLayout: true,
    currentDragItemType: null,
    currentItem: null,
    editItem: false,
    editItemType: null,
    canvasWidth: 720,
    canvasHeight: 720,
    returningWorksheet: false,
    currentModalType: null,
    maxScore: 0,
    maxBase: 0
} as SubmissionsState;

export const ViewSubmissionsSlice = createSlice({
    name: 'viewSubmissions',
    initialState,
    reducers: {
        toggleEditLayout: (state: any) => {
            state.isEditLayout = !state.isEditLayout;
            console.log('isEditLayout', state.isEditLayout);
        },
        setCurrentDragItemType: (state, action: PayloadAction<string>) => {
            state.currentDragItemType = action.payload;
        },
        setCurrentItem: (
            state,
            action: PayloadAction<DraggableElement | null>
        ) => {
            console.log('set id');
            state.currentItem = action.payload;
            if (action.payload === null) {
                state.editItemType = null;
                state.editItem = false;
            }
            console.log(state.currentItem);
        },
        setCanvas: (state: any, action: PayloadAction<{width: number, height: number}>) => {

            const canvasDimens = action.payload;

            if(canvasDimens.width > 0)
                state.canvasWidth = canvasDimens.width;
            if(canvasDimens.height > 0)
                state.canvasHeight = canvasDimens.height;

        },
        setSelectedStudentId: (
            state,
            action: PayloadAction<string>
        ) => {
            console.log('SelectedStudentId', action.payload);
            state.selectedStudentId = action.payload;
        },
        setStudentSubmission: (
            state,
            action: PayloadAction<Submission>
        ) => {
            console.log('inSetStudentSubmission', action.payload);
            state.studentSubmission = action.payload;
        },
        toggleEditItem: (state, action: PayloadAction<string | null>) => {
            console.log('toggleEditItem ', action.payload);
            const origin = state.editItem;
            console.log('origin', origin);
            state.editItem = action.payload ? true : false;
            if (state.editItem) {
                state.editItemType = action.payload;
            }
            console.log('new', state.editItem);
        },
        setReturningWorksheet: (state, action: PayloadAction<boolean>) => {

            console.log('Returning Worksheet', action.payload);

            state.returningWorksheet = action.payload;

        },
        toggleQRcode: (state: any) => {
            state.isQRcode = !state.isQRcode;
        },
        setModalType: (state, action: PayloadAction<string | null>) => {
            state.currentModalType = action.payload;
        },
        updateComponentSize: (state: any, action: PayloadAction<{size: any, id: string}>) => {

            const {size, id} = action.payload;
            state.studentSubmission.components.map((component: DraggableElement) => {

                if(component.id === id) {
                    component.size = size;
                    state.currentItem = component;
                }

            });

        },
        updateComponent: (state, action: PayloadAction<{studentId: string, tool: DraggableElement}>) => {
            const tmpWorksheetSubmissions: WorksheetSubmissionDetail[] = [];

            console.log('updatingFeedbackStates', action.payload);

            state.worksheetSubmissions?.forEach((worksheetSubmission) => {
                if(worksheetSubmission.studentId === action.payload.studentId) {
                    const tmpSubmissions: Submission[] = [];
                    worksheetSubmission.submissions.forEach((submission, index) => {

                        if(index == 0) {

                            const tmpComponents: DraggableElement[] = [];
                            let updatedMark = 0;

                            worksheetSubmission.submissions[0].components.forEach((component) => {

                                if(component.type === TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
                                    if(component.id === action.payload.tool.id 
                                        && action.payload.tool.data.score
                                        && action.payload.tool.data.score.mark) {
                                        updatedMark += action.payload.tool.data.score.mark;
                                    } else if(component.data.score && component.data.score.mark) {
                                        updatedMark += component.data.score.mark;
                                    }
                                }

                            });

                            console.log('UpdatedMark', updatedMark);

                            worksheetSubmission.submissions[0].components.forEach((component) => {

                                if(component.id === action.payload.tool.id) {

                                    console.log('UpdatingLocalFeedback', action.payload.tool);

                                    tmpComponents.push(Object.assign({}, action.payload.tool));

                                } else if(component.type === TOOLBAR_BTN_ACTION_CONSTANTS.SCORE) {
                                  
                                    const tmpComponent = component;
                                    const tmpData = component.data;
                                    tmpData.score = {
                                        base: component.data.score.base,
                                        mark: updatedMark
                                    };
                                    tmpComponent.data = tmpData;

                                    console.log('UpdatedMarkTmpCOmponent', tmpComponent);
                                    tmpComponents.push(tmpComponent);

                                } else {
                                    tmpComponents.push(component);
                                }

                            });

                            tmpSubmissions.push({
                                submittedTime: submission.submittedTime,
                                submissionKey: submission.submissionKey,
                                components: tmpComponents,
                                teacherFeedback: []
                            });

                        } else {
                            tmpSubmissions.push(Object.assign({}, submission));
                        }

                    });


                    tmpWorksheetSubmissions.push({
                        studentId: worksheetSubmission.studentId,
                        studentData: worksheetSubmission.studentData,
                        submissions: tmpSubmissions
                    });

                } else {
                    tmpWorksheetSubmissions.push(Object.assign({}, worksheetSubmission));
                }
            });

            console.log('GradingUpdatedOK', tmpWorksheetSubmissions);

            let selectedStudentIndex = 0;

            

            if (state.selectedStudentId && state.worksheetSubmissions) {
                state.worksheetSubmissions.forEach((worksheetSubmission: WorksheetSubmissionDetail, index: number) => {
                    if(state.selectedStudentId == worksheetSubmission.studentId) {
                        selectedStudentIndex = index;
                    }
                });
            }

            state.worksheetSubmissions = tmpWorksheetSubmissions;
            state.studentSubmission = tmpWorksheetSubmissions ? (
                tmpWorksheetSubmissions.length != 0 ? (
                    tmpWorksheetSubmissions[selectedStudentIndex].submissions.length != 0 ? (
                        tmpWorksheetSubmissions[selectedStudentIndex].submissions[0]
                    ) : null
                ) : null
            ) : null;

            console.log('UpdatingLocalFeedback', tmpWorksheetSubmissions);
        },
        setWorksheetSubmissions: (state, action: PayloadAction<WorksheetSubmissionDetail[] | null>) => {
            state.worksheetSubmissions = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getWorksheetDetail.pending, (state, action) => {
            if (state.loading === 'idle') {
                console.log('state.loading === idle');
                state.loading = 'pending';
            }
        });

        builder.addCase(getWorksheetDetail.fulfilled, (state, action) => {
            if (state.loading === 'pending') {
                console.log('state.loading === pending', action.payload);
                state.worksheetDetail = action.payload;
                state.loading = 'idle';
            }
        });

        builder.addCase(getWorksheetDetail.rejected, (state, action) => {
            if (state.loading === 'pending') {
                console.log('state.loading === pending');
                state.loading = 'idle';
                state.error = 'Error occured';
            }
        });

        builder.addCase(
            getWorksheetSubmissionDetails.fulfilled,
            (state, action) => {
                console.log('~~~~~~~~~~~~', action.payload);
                state.worksheetSubmissions = action.payload.worksheetSubmissions;
                state.mode = action.payload.mode;
                state.loading = 'idle';

                let maxWidth = state.canvasWidth;
                let maxHeight = state.canvasHeight;

                let selectedStudentIndex = 0;

                if(state.worksheetDetail && state.worksheetDetail.mode !== 'collab'){
                    if (state.selectedStudentId && state.worksheetSubmissions) {
                        state.worksheetSubmissions.forEach((worksheetSubmission: WorksheetSubmissionDetail, index: number) => {
                            if(state.selectedStudentId == worksheetSubmission.studentId) {
                                selectedStudentIndex = index;
                            }
                        });
                    }
                }

                

                console.log('~~~~~~~~~~~~~~~ selectedStudentIndex', selectedStudentIndex);

                state.studentSubmission = state.worksheetSubmissions ? (
                    state.worksheetSubmissions.length != 0 ? (
                        state.worksheetSubmissions[selectedStudentIndex].submissions.length != 0 ? (
                            state.worksheetSubmissions[selectedStudentIndex].submissions[0]
                        ) : null
                    ) : null
                ) : null;

                state.selectedStudentId = state.worksheetSubmissions ? (
                    state.worksheetSubmissions.length != 0 ? (
                        state.worksheetSubmissions[selectedStudentIndex].studentId
                    ) : ''
                ) : '';

                if(state.worksheetSubmissions.length != 0 && state.worksheetSubmissions[0].submissions.length != 0) {
                    state.worksheetSubmissions[0].submissions[0].components.map(
                        (element: DraggableElement) => {
                            if (
                                element.location.x + element.size.width >
                                state.canvasWidth
                            )
                                maxWidth =
                                    element.location.x + element.size.width + 500;
                            if (
                                element.location.y + element.size.height >
                                state.canvasHeight
                            )
                                maxHeight =
                                    element.location.y + element.size.height + 500;
                        }
                    );
                    state.canvasWidth = maxWidth;
                    state.canvasHeight = maxHeight;
                }
                //console.log('ful fill ', state.canvasHeight); 

                // if(!state.studentSubmission) {
                //     state.studentSubmission = [];
                // }

                console.log('worksheetSubmissionsInSlice', state.worksheetSubmissions, state.studentSubmission);

            }
        );
    },
});

export const {
    toggleEditLayout, 
    setCurrentItem, 
    setCurrentDragItemType,
    toggleEditItem,
    setReturningWorksheet,
    updateComponent,
    setModalType,
    toggleQRcode,
    setSelectedStudentId,
    setStudentSubmission,
    updateComponentSize,
    setCanvas,
    setWorksheetSubmissions
    // updateFeedback,
    // updateGrade
} = ViewSubmissionsSlice.actions;

export default ViewSubmissionsSlice.reducer;
