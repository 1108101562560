import { useEffect } from 'react';
// import { useSelector } from 'react-redux';

const UseOutsideClick = (ref: any, callback: any) => {
    // const currentItem = useSelector(
    //     (state: any) => state.worksheet.currentItem
    // );
    useEffect(() => {
        const handleClick = (event: any) => {
            if (
                ref.current &&
                (event.target.id === 'canvas' ||
                    event.target.id === 'canvas-bg' || event.target.id == 'student-canvas')
            ) {
                console.log('callback');
                callback();
            }
            else {
                // console.log('click item', event.target);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);
};

export default UseOutsideClick;
