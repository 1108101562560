import React, { FC, useEffect, useState } from 'react';
import './editor-navbar.css';
import ArrowIcon from '../../assets/images/arrow.svg';
import PencilIcon from '../../assets/images/pencil_s.svg';
import LockedIcon from '../../assets/images/locked.svg';
import UnlockIcon from '../../assets/images/unlock.svg';
import StudentGroup from '../student-group/StudentGroup';
import IconButton from '../icon-button/IconButton';
import { IconBtnModel } from '../../models/icon-btn.model';
import { BtnModel } from '../../models/btn.model';
import Button from '../button/Button';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../constants/navBarBtnActionConstants';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';
import { Worksheet } from '../../models/worksheet-item.model';
import { AuthService } from '../../service/auth.service';
import { useSelector } from 'react-redux';
const _authService = new AuthService();

export interface IProps {
    handleNavBtnAction: (type: string) => void;
    isTeacher: boolean;
    worksheetDetail?: WorksheetDetailModel | Worksheet;
    saveClick: boolean;
    isViewSubmissions: boolean;
    isEditLayout: boolean;
    isLocked?: boolean;
}

const EditorNavbar: FC<IProps> = ({
    handleNavBtnAction,
    isTeacher,
    worksheetDetail,
    saveClick,
    isViewSubmissions,
    isEditLayout,
    isLocked
}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type') ? queryParams.get('type') : null;
    const arrowBtnProps: IconBtnModel = {
        icon: ArrowIcon,
        alt: 'ArrowIcon',
        uiClass: 'arrow-icon',
        disable: false,
        draggable: false,
    };

    const pencilBtnProps: IconBtnModel = {
        icon: PencilIcon,
        alt: 'PencilIcon',
        uiClass: 'pencil-icon',
        disable: false,
        draggable: false,
    };

    const lockBtnProps: IconBtnModel = {
        icon: LockedIcon,
        alt: 'LockedIcon',
        uiClass: 'locked-icon',
        disable: false,
        draggable: false,
    };

    const unlockBtnProps: IconBtnModel = {
        icon: UnlockIcon,
        alt: 'LockedIcon',
        uiClass: 'locked-icon',
        disable: false,
        draggable: false,
    };

    const publishBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Publish',
        uiClass: 'publish-btn',
        iconClass: undefined,
    };

    const unpublishBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Unpublish',
        uiClass: 'publish-btn',
        iconClass: undefined,
    };

    const createWorksheetBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Create',
        uiClass: 'create-worksheet-btn',
        iconClass: undefined,
    };

    const saveBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Save',
        uiClass: 'save-worksheet-btn',
        iconClass: undefined,
        loadingText: 'Saving',
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Save',
        uiClass: 'disabled-l-nav',
        iconClass: undefined,
    };



    const previewBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Preview',
        uiClass: 'editlayout-btn',
        iconClass: undefined,
    };

    const editLayoutBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: isEditLayout ? 'Edit Layout' : 'View Layout',
        uiClass: 'editlayout-btn',
        iconClass: undefined,
    };

    const returnBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Return',
        uiClass: 'primary-m',
        iconClass: undefined,
        loadingText: 'Loading',
    };

    const disabledReturnBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Returning...',
        uiClass: 'disabled',
        iconClass: undefined,
        loadingText: 'Loading',
    };

    const userInfo = _authService.getUserInfo();

    const currentURLOrigin = new URL(window.location.href).origin;

    const previewLink = currentURLOrigin + '/student/' + userInfo.userid + '/sheet/' + worksheetDetail?.id;

    const { returningWorksheet } = useSelector((state: any) => state.viewSubmissions);

    const handleBack = () => {
        if (isViewSubmissions)
            handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.BACKEDITOR);
        else
            handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.BACK);
    };

    const handleEdit = () => {
        if (type == 'template')
            handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.EDIT_TEMPLATE);
        else
            handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.EDIT_WORKSHEET);
    };

    const handlePublish = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.PUBLISH);
    };

    const handleUnpublish = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.UNPUBLISH);
    };

    const handleCreateWorksheet = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.CREATEWORKSHEET);
    };

    const handleLock = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.LOCK);
    };

    const handleSave = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.SAVE);
    };

    const handleEditLayout = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.EDIT_LAYOUT);
    };

    const handleReturn = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.RETURN);
    };


    return (
        <>
            <div className="editor-navbar">
                <div className="navbar-left">
                    <IconButton
                        btnProps={arrowBtnProps}
                        handleBtnClicked={handleBack}
                    />
                    {
                        isViewSubmissions ? (<span className="text-c100 body-m">Back</span>) : (<span className="text-c100 body-m">Activity worksheet</span>)
                    }
                </div>
                <div className="navbar-middle">
                    <span className="text-c200 h4-strong truncate-overflow">
                        {worksheetDetail
                            ? worksheetDetail.name
                            : 'Untitled Activity worksheet name'}
                    </span>
                    {worksheetDetail?.publishedStatus === 'published' && (<span className='body-m text-s0 bg-g100 published-status'>Published</span>)}
                    {/* <StudentGroup worksheet={worksheetDetail} /> */}
                    {(isTeacher && !isViewSubmissions) && (
                        <IconButton
                            btnProps={pencilBtnProps}
                            handleBtnClicked={handleEdit}
                        />
                    )}
                </div>
                {(isTeacher && !isViewSubmissions) && (
                    <div className="navbar-right">
                        {/* <Button
                            btnProps={previewBtnProps}
                            handleBtnClicked={handlePreview}
                        /> */}
                        {/* <a className='text-b100 body-m preview-link' href={previewLink} target="_blank" rel="noreferrer noopener">
                            Preview
                        </a> */}

                        {type !== 'template' && <IconButton
                            btnProps={isLocked ? lockBtnProps : unlockBtnProps}
                            handleBtnClicked={handleLock}
                        />}

                        {
                            type !== 'template' && worksheetDetail?.publishedStatus === 'draft' ? (<Button
                                btnProps={publishBtnProps}
                                handleBtnClicked={handlePublish}
                            />) : type == 'template' ? (<Button
                                btnProps={createWorksheetBtnProps}
                                handleBtnClicked={handleCreateWorksheet}
                            />) : (<Button
                                btnProps={unpublishBtnProps}
                                handleBtnClicked={handleUnpublish}
                            />)
                        }
                        <Button
                            btnProps={worksheetDetail?.publishedStatus === 'published' ? disabledBtnProps : saveBtnProps}
                            handleBtnClicked={handleSave}
                            isLoading={saveClick}
                        />
                    </div>
                )}
                {
                    (isTeacher && isViewSubmissions) && (
                        <div className='navbar-right'>
                            {/* <Button
                                btnProps={editLayoutBtnProps}
                                handleBtnClicked={handleEditLayout}
                            /> */}
                            <Button
                                btnProps={returningWorksheet ? disabledReturnBtnProps : returnBtnProps}
                                handleBtnClicked={handleReturn}
                                isLoading={saveClick}
                            />
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default EditorNavbar;
