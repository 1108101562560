import React, { FC, useEffect } from 'react';
import './button.css';
import { BtnModel } from '../../models/btn.model';

export interface IProps {
    btnProps: BtnModel;
    handleBtnClicked: () => void;
    isLoading?: boolean;
}
const Button: FC<IProps> = ({
    btnProps,
    handleBtnClicked,
    isLoading = false,
}) => {
    const getBtnClassName = (className: string) => {
        switch (className) {
            case 'publish-btn':
                return 'primary-m ml-18';
            case 'create-worksheet-btn':
                return 'primary-m ml-18';
            case 'edit-layout-btn':
                return 'secondary-s mr-24';
            case 'create-btn':
                return 'primary-btn mr-26';
            case 'primary-btn':
                return 'primary-btn';
            case 'primary-m':
                return 'primary-m';
            case 'secondary-btn':
                return 'secondary-btn';
            case 'cancel':
                return 'tertiary-s modal-btn';
            case 'cancel-l':
                return 'tertiary-s modal-btn-l';
            case 'add-l':
                return 'primary-s modal-btn-l';
            case 'assign':
                return 'primary-s modal-btn-l';
            case 'insert':
                return 'primary-s modal-btn ml-24';
            case 'disabled':
                return 'disabled modal-btn ml-24';
            case 'tertiary-btn':
                return 'tertiary-btn mr-20';
            case 'delete-btn':
                return 'delete-btn';
            case 'disabled-l':
                return 'disabled modal-btn-l';
            case 'disabled-l-nav':
                return 'disabled-l ml-24';
            case 'disabled-m':
                return 'disabled disabled-m';
            case 'back-btn':
                return 'nav-back-btn';
            case 'save-worksheet-btn':
                return 'modal-btn save-worksheet-btn';
            case 'save-worksheet-btn-loading':
                return 'modalbtn save-worksheet-btn-loading';
            case 'editlayout-btn':
                return 'secondary-s mr-16';
            case 'share-btn':
                return 'share-btn';
            case 'copy-btn':
                return 'copy-btn';
            default:
                return 'primary-s';
        }
    };

    const getIconClassName = (className: string) => {
        switch (className) {
            case 'publish-btn':
                return 'primary-s';
            case 'edit-layout-btn':
                return 'secondary-s';
            case 'back-btn':
                return 'nav-back-btn';
            default:
                return 'primary-s';
        }
    };

    const getTextClassName = (className: string) => {
        switch (className) {
            case 'publish-btn':
                return 'body-m text-s0';
            case 'create-worksheet-btn':
                return 'body-m text-s0';
            case 'edit-layout-btn':
                return 'body-m text-b100';
            case 'create-btn':
                return 'text-s0 h4-strong';
            case 'secondary-btn':
                return 'text-b100 h4-strong';
            case 'cancel':
                return 'body-m text-c100 ';
            case 'cancel-l':
                return 'body-m text-c100 ';
            case 'add-l':
                return 'body-m text-s0';
            case 'insert':
                return 'body-m text-s0 ';
            case 'disabled':
                return 'body-m text-s0 ';
            case 'tertiary-btn':
                return 'body-m text-c100';
            case 'delete-btn':
                return 'body-m text-s0';
            case 'disabled-l':
                return 'body-m text-s0 ';
            case 'back-btn':
                return 'body-m text-r100';
            case 'save-worksheet-btn':
                return 'body-m text-c300';
            case 'save-worksheet-btn-loading':
                return 'body-m text-c300';
            case 'editlayout-btn':
                return 'body-m text-b100';
            case 'share-btn':
                return 'body-m text-g300';
            case 'copy-btn':
                return 'body-m text-s0';
            default:
                return 'body-m text-s0';
        }
    };

    const clickedBtn = () => {
        handleBtnClicked();
    };

    return (
        <>
            {btnProps.icon ? (
                <button
                    onClick={clickedBtn}
                    disabled={btnProps.disable}
                    className={getBtnClassName(btnProps.uiClass)}
                >
                    <img
                        className={getIconClassName(btnProps.uiClass)}
                        src={btnProps.icon}
                        alt={btnProps.alt}
                    ></img>
                    <span className={getTextClassName(btnProps.uiClass)}>
                        {isLoading ? 'Loading...' : btnProps.btnText}
                    </span>
                </button>
            ) : (
                <button
                    onClick={clickedBtn}
                    disabled={btnProps.disable}
                    className={getBtnClassName(btnProps.uiClass)}
                >
                    <span className={getTextClassName(btnProps.uiClass)}>
                        {isLoading
                            ? btnProps.loadingText + '...'
                            : btnProps.btnText}
                    </span>
                </button>
            )}
        </>
    );
};

export default Button;
