import React, { FC, useRef } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';
export interface IProps {
    component: DraggableElement;
    handleItemClicked: () => void;
    editItem: boolean;
    isEditLayout: boolean;
}
const VideoTool: FC<IProps> = ({
    component,
    handleItemClicked,
    editItem,
    isEditLayout,
}) => {
    const ref = useRef<HTMLVideoElement>(null);

    const clickedItem = () => {
        handleItemClicked();
    };

    return (
        <>
            {component.data!.url && (
                <video
                    ref={ref}
                    onClick={clickedItem}
                    controls={!isEditLayout}
                    src={component.data!.url}
                    style={{
                        width: component.size.width + 'px',
                        height: component.size.height + 'px',
                        // border:
                        //     currentItem && currentItem.id == component.id
                        //         ? '2px solid #8CC4FF'
                        //         : 'none',
                        // borderRadius:
                        //     currentItem && currentItem.id == component.id
                        //         ? '1px'
                        //         : 'none',
                    }}
                />
            )}
        </>
    );
};

export default VideoTool;
