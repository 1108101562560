import React, { FC } from 'react';
import { STYLING_TOOL_BAR_CONSTANTS } from '../../../../constants/stylingToolBarConstants';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import ColorPicker from '../../../../global-components/color-picker/ColorPicker';
import DropdownWithSearch from '../../../../global-components/dropdown-with-search/DropdownWithSearch';
import { DraggableElement } from '../../../../models/draggable-element.model';
import PopupIconList from '../popup-icon-list/PopupIconList';
import TextstyleTool from '../text-style-tool/TextstyleTool';
import './styling-tool-bar.css';
export interface IProps {
    type: string;
    currentItem: DraggableElement | null;
    isText: boolean;
    handlePopupIconClicked: (type: string) => void;
    handleColorChange: (color: string) => void;
    handleBorderChange: (color: string) => void;
    handleBackgroundChange: (color: string) => void;
    handleBold: (value: string) => void;
    handleItalic: (value: boolean) => void;
    handleUnderline: (value: boolean) => void;
    handleFontChange: (name: string) => void;
    handleFontSizeChange: (name: string) => void;
}
const StylingToolBar: FC<IProps> = ({
    handlePopupIconClicked,
    type,
    currentItem,
    isText,
    handleColorChange,
    handleBorderChange,
    handleBackgroundChange,
    handleBold,
    handleItalic,
    handleUnderline,
    handleFontChange,
    handleFontSizeChange,
}) => {
    return (
        <div className="styling-tool-bar bg-b50">
            {type !== TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX && <DropdownWithSearch
                type={STYLING_TOOL_BAR_CONSTANTS.FONT}
                currentItem={currentItem}
                handleFontChange={handleFontChange}
                handleFontSizeChange={handleFontSizeChange}

            />}
            {type !== TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX && <TextstyleTool
                currentItem={currentItem}
                isText={isText}
                handleBold={handleBold}
                handleItalic={handleItalic}
                handleUnderline={handleUnderline}
            />}
            {/* <DropdownWithSearch type={STYLING_TOOL_BAR_CONSTANTS.FONT_WEIGHT} /> */}
            {type !== TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX && <DropdownWithSearch
                type={STYLING_TOOL_BAR_CONSTANTS.FONT_SIZE}
                currentItem={currentItem}
                handleFontChange={handleFontChange}
                handleFontSizeChange={handleFontSizeChange}

            />}

            {type === TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX && <div className='flex-align-center' style={{
                marginLeft: '36px'
            }}><span>Border:</span><ColorPicker type={'border'} handleColorChange={handleBorderChange}></ColorPicker></div>}

            {type !== TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX && <ColorPicker type={'color'} handleColorChange={handleColorChange}></ColorPicker>}

            {type === TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX && <div className='flex-align-center'><span>Background:</span><ColorPicker type={'background'} handleColorChange={handleBackgroundChange}></ColorPicker></div>}



            {
                type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP && (
                    <>
                        <div className="line-for-popup"></div>
                        <PopupIconList
                            handlePopupIconClicked={handlePopupIconClicked}
                        />
                        <div className="color"></div>
                    </>
                )
            }
        </div >
    );
};

export default StylingToolBar;
