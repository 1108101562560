import React, { ReactNode, FC } from 'react';
import './modal.css';
import { CustomModal } from '../../models/custom-modal.model';
import CancelIcon from '../../assets/images/cancel_m.svg';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../constants/navBarBtnActionConstants';

export interface ModalType {
    modalProps: CustomModal;
    children?: ReactNode;
    isOpen: boolean;
    toggle: () => void;
    cancelModal: () => void;
    currentModalType?: any;
}

const Modal: FC<ModalType> = ({
    modalProps,
    children,
    isOpen,
    toggle,
    cancelModal,
    currentModalType
}) => {
    const onClickCrossBtn = () => {
        cancelModal();
    };
    const setOverflow = (value: string) => {
        document.body.style.overflow = value;
    };
    console.log('====================================');
    console.log(
        'Happy New Year', currentModalType
    );
    console.log('====================================');
    return (
        <>
            {isOpen ? setOverflow('hidden') : setOverflow('auto')}
            {isOpen && (
                <div className={currentModalType && (currentModalType === NAVBAR_BTN_ACTION_CONSTANTS.PUBLISH || currentModalType === NAVBAR_BTN_ACTION_CONSTANTS.EDIT_WORKSHEET || currentModalType === NAVBAR_BTN_ACTION_CONSTANTS.CREATEWORKSHEET || currentModalType === NAVBAR_BTN_ACTION_CONSTANTS.EDIT_TEMPLATE) ? 'modal-overlay-xl' : 'modal-overlay'} onClick={toggle} data-id={currentModalType}>
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={currentModalType && (currentModalType === NAVBAR_BTN_ACTION_CONSTANTS.PUBLISH || currentModalType === NAVBAR_BTN_ACTION_CONSTANTS.EDIT_WORKSHEET || currentModalType === NAVBAR_BTN_ACTION_CONSTANTS.CREATEWORKSHEET || currentModalType === NAVBAR_BTN_ACTION_CONSTANTS.EDIT_TEMPLATE) ? 'modal-box-xl' : 'modal-box'}
                        style={{
                            width:
                                modalProps.width != undefined
                                    ? modalProps.width
                                    : '70%',
                            height: modalProps.height != undefined && modalProps.height != null ? modalProps.height : 'unset',
                            padding:
                                modalProps.padding != undefined
                                    ? modalProps.padding
                                    : '20px',
                        }}
                    >
                        <div className="modal-header">
                            <div className="h3-strong text-c300 title-text"

                            >
                                {modalProps.title}
                            </div>
                            {modalProps.showCrossBtn && (
                                <div
                                    className="cursor"
                                    onClick={onClickCrossBtn}
                                >
                                    <img src={CancelIcon} />
                                </div>
                            )}
                        </div>
                        <div>{children}</div>
                    </div>
                </div>
            )
            }
        </>
    );
};

export default Modal;
