import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { worksheetListApi } from '../../api';
import {
    deleteWorksheetApi,
    getWorksheets,
} from '../../api/worksheet/worksheetsApi';
import { Worksheet } from '../../models/worksheet-item.model';

interface WorksheetState {
    worksheets: {
        data: Worksheet[];
        loading: string | null;
        error: string | null;
    };
    createdWorksheetId: string | null;
    worksheetList: Worksheet[];
    currentModalType: string | null;
    currentWorksheet: Worksheet | null;
}

const initialState = {
    worksheets: {
        data: [],
        loading: 'idle',
        error: null,
    },
    createdWorksheetId: null,
    worksheetList: [],
    currentModalType: null,
    currentWorksheet: null,
} as WorksheetState;

export const WorksheetListSlice = createSlice({
    name: 'worksheetsHome',
    initialState,
    reducers: {
        setModalType: (state, action: PayloadAction<string | null>) => {
            state.currentModalType = action.payload;
        },
        setCurrentWorksheet: (
            state,
            action: PayloadAction<Worksheet | null>
        ) => {
            state.currentWorksheet = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWorksheets.pending, (state, action) => {
            if (state.worksheets.loading === 'idle') {
                console.log('state.worksheets.loading === idle');
                state.worksheets.loading = 'pending';
            }
        });

        builder.addCase(getWorksheets.fulfilled, (state, action) => {
            if (state.worksheets.loading === 'pending') {
                console.log(
                    'state.worksheets.loading === pending',
                    action.payload
                );
                state.worksheets.data = action.payload;
                state.worksheets.loading = 'idle';
            }
        });

        builder.addCase(getWorksheets.rejected, (state, action) => {
            if (state.worksheets.loading === 'pending') {
                console.log('state.worksheets.loading === pending');
                state.worksheets.loading = 'idle';
                state.worksheets.error = 'Error occured';
            }
        });

        builder.addCase(deleteWorksheetApi.fulfilled, (state, action) => {
            console.log('deleteWorksheetApi > ', state, action);
            const deleteId = action.meta.arg;
            const currentWorksheets = state.worksheets.data.filter(
                (worksheet) => worksheet.id !== deleteId
            );
            state.worksheets.data = currentWorksheets;
        });

        builder.addMatcher(
            worksheetListApi.endpoints.getWorksheetList.matchFulfilled,
            (state, action) => {
                state.worksheetList = action.payload;
            }
        );
    },
});

export const { setModalType, setCurrentWorksheet } = WorksheetListSlice.actions;

export default WorksheetListSlice.reducer;
