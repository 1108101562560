import React, { FC, useState } from 'react';
import './upload-thumbnail.css';

import { Worksheet } from '../../models/worksheet-item.model';

import { OVERLAY_MENU_CONSTANTS } from '../../constants/overlayMenuConstants';

import SettingsIcon from '../../assets/images/more_stroke.svg';

type Props = {
    worksheet: Worksheet;
    handleUpload: (file: File | undefined) => void;
    handleDelete: () => void;
}

const UploadThumbnail: FC<Props> = ({ worksheet, handleUpload, handleDelete }) => {
    const [uploadItem, setUploadItem] = useState<null | string>(worksheet.thumbnailUrl != undefined ? worksheet.thumbnailUrl : null);
    const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    const [uploadError, setUploadError] = useState<boolean>(false);

    const handleUploadImage = (e: any) => {
        console.log('handleUploadImage', e);
        const element = e.target.files[0];
        const url = URL.createObjectURL(e.target.files[0]);
        console.log('url', url, element, element.type.includes('image'));
        if (element.type.includes('image')) {
            setUploadItem(url);
            calculateImageDimension(url);
            setUploadError(false);
            handleUpload(element);
        } else {
            setUploadError(true);
        }
    };

    const calculateImageDimension = (url: string) => {
        const img: HTMLImageElement = document.createElement('img');

        img.onload = function () {
            console.log('onLoad', img.width);
            const imgWidth = img.width > 450 ? 450 : img.width;
            const imgHeight =
                img.height > 450
                    ? calculateImageHeight(img.width, img.height)
                    : img.height;
            setElementSize({
                width: Math.round(imgWidth),
                height: Math.round(imgHeight),
            });
        };
        img.src = url;
    };

    const calculateImageHeight = (width: number, height: number) => {
        console.log((height * 450) / width);
        return (height * 450) / width;
    };

    const deleteThumbnail = () => {
        console.log('deleteThumbnail');
        setUploadItem(null);
        handleDelete();
    };

    const clickMenu = (type: string) => {
        console.log('clickMenu', type);
        if (type == OVERLAY_MENU_CONSTANTS.DELETE)
            deleteThumbnail();
        else
            console.log('edit');
    };

    return (
        <>
            <div>
                <span className="text-c300 h4-strong">Upload Book Cover Image</span>
                <span className='text-s70 body-m'>(optional)</span>
            </div>
            <div className='pt-16'>
                {uploadItem && (
                    <div className="upload-thumbnail">
                        <img src={uploadItem} alt="" />
                        <span className='settings_icon'>
                            <img src={SettingsIcon} />
                        </span>
                        <div className='thumbnail-overlay'>
                            <div className='thumbnail-overlay'>
                                <div className="thumbnail-overlay-menu">
                                    <div
                                        className="body-m text-s0 thumbnail-menu-item"
                                        onClick={() => clickMenu(OVERLAY_MENU_CONSTANTS.DELETE)}
                                    >
                                        Delete
                                    </div>
                                    <label className="thumbnail-edit-btn body-m text-s0">
                                        <input type="file" accept="image/*" onChange={handleUploadImage} />
                                        Edit
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!uploadItem && (
                    <div>
                        <label className="thumbnail-img-upload body-m text-s0">
                            <input type="file" accept="image/*" onChange={handleUploadImage} />
                            Upload image
                        </label>
                    </div>
                )}
            </div>
        </>
    );
};

export default UploadThumbnail;