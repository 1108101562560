import React, { FC, useState } from 'react';
import SearchIcon from '../../assets/images/search_l.svg';
import './search-input.css';

export interface Props {
    width?: string;
    height?: string;
    hint: string;
    handleSearch: (keywork: string | null) => void;
}

const SearchInput: FC<Props> = ({ hint, handleSearch, width, height }) => {
    const handleSearchInput = (e: React.FormEvent<HTMLInputElement>) => {
        handleSearch(e.currentTarget.value);
    };
    return (
        <div
            className="bg-s0 search-input"
            style={{
                width: width ? width : '446px',
                height: height ? height : '48px',
            }}
        >
            <span>
                <img src={SearchIcon} className="cursor" />
            </span>
            <input
                type="text"
                id="keyword"
                className="light-body-m text-c300"
                placeholder={hint}
                onChange={handleSearchInput}
            />
        </div>
    );
};

export default SearchInput;
