import React, { FC, useState } from 'react';
import QRcode from 'react-qr-code';
import './generate-qrcode.css';
import { Worksheet } from '../../models/worksheet-item.model';
import { AuthService } from '../../service/auth.service';
import * as CryptoJS from 'crypto-js';

export interface IProps {
    type: string;
    worksheetId: string;
}

const GenerateQRcode: FC<IProps> = ({
    type,
    worksheetId
}) => {
    const _authService = new AuthService();

    const encrypt = (message: string, secretkey: string) => {
        const encrypted = _authService.encrypt(message, secretkey);
        return encrypted;
    };

    const secretkey = 'bc776';
    const userInfo = _authService.getUserInfo();
    const username = userInfo.username ? encodeURIComponent(encrypt(userInfo.username, secretkey)) : '';
    const token = userInfo.password ? encodeURIComponent(encrypt(userInfo.password, secretkey)) : '';
    const apikey = userInfo.apikey ? encodeURIComponent(encrypt(userInfo.apikey, secretkey)) : '';
    const usertype = userInfo.usertype ? encodeURIComponent(encrypt(userInfo.usertype.toString(), secretkey)) : '';
    const idUser = userInfo.userid ? encodeURIComponent(encrypt(userInfo.userid, secretkey)) : '';
    const lessonid = userInfo.lessonId ? encodeURIComponent(encrypt(userInfo.lessonId, secretkey)) : '';
    const classid = userInfo.classId ? encodeURIComponent(encrypt(userInfo.classId, secretkey)) : '';
    const worksheetid = worksheetId ? encodeURIComponent(encrypt(worksheetId, secretkey)) : '';
    console.log('worksheet ', worksheetId);
    const url = window.location.origin + '/redirect?username=' + username + '&token=' + token + '&apikey=' + apikey + '&usertype=' + usertype + '&idUser=' + idUser + '&lessonid=' + lessonid + '&worksheetid=' + worksheetid + '&classid=' + classid + '&refer=qr';
    // const url = 'https://dev-smart-school-activity-worksheet.pagewerkz.com/redirect?username=' + username + '&token=' + token + '&apikey=' + apikey + '&usertype=' + usertype + '&idUser=' + idUser + '&lessonid=' + lessonid + '&worksheetid=' + worksheetid + '&classid=' + classid;

    console.log(url);
    const [data, setData] = useState(url);

    return (
        <>
            <div className='qr-wrapper'>
                <QRcode size={200} value={data} />
            </div>
        </>
    );
};

export default GenerateQRcode;
