import React, { FC } from 'react';
import './activity-navbar.css';
import ArrowIcon from '../../assets/images/arrow.svg';
import PencilIcon from '../../assets/images/pencil_s.svg';
import LockedIcon from '../../assets/images/locked.svg';
import StudentGroup from '../student-group/StudentGroup';
import IconButton from '../icon-button/IconButton';
import { IconBtnModel } from '../../models/icon-btn.model';
import { BtnModel } from '../../models/btn.model';
import Button from '../button/Button';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../constants/navBarBtnActionConstants';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';

export interface IProps {
    handleNavBtnAction: (type: string) => void;
    worksheetDetail?: WorksheetDetailModel;
}

const ActivityNavbar: FC<IProps> = ({
    handleNavBtnAction,
    worksheetDetail,
}) => {
    const arrowBtnProps: IconBtnModel = {
        icon: ArrowIcon,
        alt: 'ArrowIcon',
        uiClass: 'arrow-icon',
        disable: false,
        draggable: false,
    };

    const backBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Back',
        uiClass: 'back-btn',
        iconClass: undefined,
    };

    const handleBack = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.BACK);
    };

    const handleBackToEditor = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.BACKEDITOR);
    };

    console.log('worksheetDetailInActyLog', worksheetDetail);

    return (
        <>
            <div className="activity-navbar">
                <div className="navbar-left">
                    <IconButton
                        btnProps={arrowBtnProps}
                        handleBtnClicked={handleBack}
                    />
                    <span className="text-c100 body-m">Activity worksheet</span>
                </div>
                <div className="navbar-middle">
                    <span className="text-c200 h4-strong">
                        {worksheetDetail
                            ? worksheetDetail.name
                            : 'Untitled Activity worksheet name'}
                    </span>
                    {/* <StudentGroup /> */}
                </div>
                <div className="navbar-right">
                    <Button
                        btnProps={backBtnProps}
                        handleBtnClicked={handleBackToEditor}
                    />
                </div>
            </div>
        </>
    );
};

export default ActivityNavbar;
