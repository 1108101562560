import React, { FC } from 'react';
import './template-item.css';
import { Template } from '../../../models/template-item.model';
import { OverlayMenu } from '../../../models/overlay-menu';
import PencilIcon from '../../../assets/images/pencil_m.svg';
import OverlayNavigation from '../../../global-components/overlay-navigation/overlay-navigation';
import { OVERLAY_MENU_CONSTANTS } from '../../../constants/overlayMenuConstants';

export interface IProps {
    item: Template;
    handleClickMenu: (type: string, item: Template) => void;
}

const TemplateItem: FC<IProps> = ({ item, handleClickMenu }) => {
    const templateMenu: OverlayMenu[] = [
        {
            type: OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET,
            name: 'Create worksheet',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.CHANGE_TITLE,
            name: 'Change title',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.DELETE,
            name: 'Delete',
        },
        {
            type: OVERLAY_MENU_CONSTANTS.EDIT,
            name: 'Edit',
        },
    ];

    const clickMenu = (type: string) => {
        handleClickMenu(type, item);
    };

    return (
        <>
            <div className="list-item">
                <div className="cover-wrapper">
                    <img src={item.thumbnailUrl} />
                    <span className="pencil_icon">
                        <img src={PencilIcon} />
                    </span>
                    <div className="overlay">
                        <div className="overlay-menu">
                            <OverlayNavigation
                                menuItem={templateMenu}
                                clickMenu={clickMenu}
                            />
                            <div>
                                <img
                                    className="overlay-pencil"
                                    src={PencilIcon}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body-m text-c300 mt-16 item-text">
                    {item.name}
                </div>
            </div>
        </>
    );
};

export default TemplateItem;
