import './score-tool.css';
import React, { FC, useEffect, useState } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';

export interface IProps {
    component: DraggableElement;
    totalScore: number;
    totalBase: number;
    handleItemClicked: () => void;
    editItem: boolean;
}
const ScoreTool: FC<IProps> = ({ component, handleItemClicked, editItem, totalBase, totalScore }) => {
    const clickedItem = () => {
        handleItemClicked();
    };

    return (
        <div onClick={clickedItem} className="score-wrapper">
            <div className="score-top caption-u text-s0">Score</div>
            <div className="score-bottom h5-strong text-c100">
                {totalScore}/{totalBase}
            </div>
        </div>
    );
};

export default ScoreTool;
