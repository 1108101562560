import React, { FC, useRef } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';
import './textbox-tool.css';
import TextIcon from '../../../../assets/images/student-input/text_b.svg';
import GalleryIcon from '../../../../assets/images/student-input/gallery_s_b.svg';
import VideoIcon from '../../../../assets/images/student-input/video_camera_b.svg';
import AudioIcon from '../../../../assets/images/student-input/audio_b.svg';
import PDFIcon from '../../../../assets/images/student-input/pdf.svg';
import TextActiveIcon from '../../../../assets/images/student-input/textActive.svg';
import GalleryActiveIcon from '../../../../assets/images/student-input/galleryActive.svg';
import VideoActiveIcon from '../../../../assets/images/student-input/videoActive.svg';
import AudioActiveIcon from '../../../../assets/images/student-input/audioActive.svg';
import PDFActiveIcon from '../../../../assets/images/student-input/pdfActive.svg';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import IconButton from '../../../../global-components/icon-button/IconButton';
import { IconBtnModel } from '../../../../models/icon-btn.model';
export interface IProps {
    component: DraggableElement;
    handleItemClicked: () => void;
    editItem: boolean;
    currentItem: DraggableElement | null;
}

const TextboxTool: FC<IProps> = ({
    component,
    handleItemClicked,
    editItem,
    currentItem
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const clickedItem = () => {
        handleItemClicked();
    };
    const idForTextboxTool = 'component-' + component.id;
    const toolList: IconBtnModel[] = [
        {
            icon: TextIcon,
            activeIcon: TextActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.TEXT,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        {
            icon: GalleryIcon,
            activeIcon: GalleryActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        {
            icon: VideoIcon,
            activeIcon: VideoActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        {
            icon: AudioIcon,
            activeIcon: AudioActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        {
            icon: PDFIcon,
            activeIcon: PDFActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.PDF,
            uiClass: 'student-toolbar-icon',
            disable: false,
            draggable: true,
        },
        // {
        //     icon: BinIcon,
        //     alt: TOOLBAR_BTN_ACTION_CONSTANTS.BIN,
        //     uiClass: 'toolbar-icon',
        //     disable: false,
        //     draggable: true,
        // },
    ];

    const getWidth = () => {
        const width = currentItem && currentItem.id === component.id ? 'calc(100% - 32px)' : 'calc(100% - 4px)';
        return width;
    };
    const getBottom = () => {
        const bottom = currentItem && currentItem.id === component.id ? '13px' : '2px';
        return bottom;
    };
    
    return (
        <div
            ref={ref}
            id={idForTextboxTool}
            onClick={clickedItem}
            className="textbox-tool"
            style={{
                width: component.size.width + 'px',
                height: component.size.height + 'px',
                borderColor: component.data.style?.border ? component.data.style?.border : '#000000',
                background: component.data.style?.background ? component.data.style?.background : '#ffffff',
            }}
        >
            <div className="fake-tool-bar" style={{
                width: getWidth(),
                bottom: getBottom()
            }} >
                {toolList.map((tool: IconBtnModel) => {
                    return (
                        <IconButton
                            currentSelected={false}
                            key={tool.alt}
                            btnProps={tool}
                            handleBtnClicked={() => console.log()}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default TextboxTool;
