import React, { ReactNode, FC, useEffect, useLayoutEffect } from 'react';
import './modal-with-footer.css';
import CancelIcon from '../../assets/images/cancel_m.svg';
import { BtnModel } from '../../models/btn.model';
import Button from '../button/Button';
import { CustomModal } from '../../models/custom-modal.model';

export interface ModalType {
    modalProps: CustomModal;
    children?: ReactNode;
    isOpen: boolean;
    toggle: () => void;
    cancelModal: () => void;
    btnList: BtnModel[];
    btnClicked: (type: string) => void;
}

const ModalWithFooter: FC<ModalType> = ({
    modalProps,
    children,
    isOpen,
    toggle,
    cancelModal,
    btnList,
    btnClicked,
}) => {
    const onClickCrossBtn = () => {
        cancelModal();
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        console.log('hello~~~~~~~~~~~~~~~~~~~~');
    }, [isOpen]);

    const setOverflow = (value: string) => {
        document.body.style.overflow = value;
    };

    return (
        <>
            {/* {isOpen ? setOverflow('hidden') : setOverflow('auto')} */}
            {isOpen && (
                <div className="modal-overlay-with-footer" onClick={toggle}>
                    <div>
                        <div
                            onClick={(e) => e.stopPropagation()}
                            className="modal-box-with-footer"
                            style={{
                                width:
                                    modalProps.width != undefined
                                        ? modalProps.width
                                        : '70%',
                                padding:
                                    modalProps.padding != undefined
                                        ? modalProps.padding
                                        : '20px',
                            }}
                        >
                            <div className="modal-header-with-footer">
                                <div className="h3-strong text-c300">
                                    {modalProps.title}
                                </div>
                                {modalProps.showCrossBtn && (
                                    <div
                                        className="cursor"
                                        onClick={onClickCrossBtn}
                                    >
                                        <img src={CancelIcon} />
                                    </div>
                                )}
                            </div>
                            <div>{children}</div>
                        </div>
                        <div
                            className="modal-footer"
                            style={{
                                padding:
                                    modalProps.padding != undefined
                                        ? modalProps.padding
                                        : '20px',
                            }}
                        >
                            {btnList.map((btn: BtnModel) => {
                                return (
                                    <Button
                                        key={btn.alt}
                                        btnProps={btn}
                                        handleBtnClicked={() =>
                                            btnClicked(btn.alt ? btn.alt : '')
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalWithFooter;
