import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { worksheetListApi } from '../../api';
import { getGroupsByClass, getStudentsApi } from '../../api/global/globalApi';
import { Group } from '../../models/group.model';
import { StudentListModel } from '../../models/student-list.model';
import { LabelModel } from '../../models/label.model';

interface PublishWorksheetState {
    labels: {
        labelList: Group[];
        loading: string | null;
        error: string | null;
    };
    students: {
        studentList: StudentListModel[];
        loading: string | null;
        error: string | null;
    };
}

const initialState = {
    labels: {
        labelList: [],
        loading: 'idle',
        error: null,
    },
    students: {
        studentList: [],
        loading: 'idle',
        error: null,
    },
} as PublishWorksheetState;

export const PublishWorksheetSlice = createSlice({
    name: 'publishWorksheet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGroupsByClass.pending, (state, action) => {
            if (state.labels.loading === 'idle') {
                console.log('state.labels.loading === idle');
                state.labels.loading = 'pending';
            }
        });

        builder.addCase(getGroupsByClass.fulfilled, (state, action) => {
            if (state.labels.loading === 'pending') {
                console.log('state.labels.loading === pending', action.payload);
                state.labels.labelList = action.payload;
                state.labels.loading = 'idle';
            }
        });

        builder.addCase(getGroupsByClass.rejected, (state, action) => {
            if (state.labels.loading === 'pending') {
                console.log('state.labels.loading === pending');
                state.labels.loading = 'idle';
                state.labels.error = 'Error occured';
            }
        });

        builder.addCase(getStudentsApi.pending, (state, action) => {
            if (state.labels.loading === 'idle') {
                console.log('state.labels.loading === idle');
                state.labels.loading = 'pending';
            }
        });

        builder.addCase(getStudentsApi.fulfilled, (state, action) => {
            if (state.labels.loading === 'pending') {
                console.log('state.labels.loading === pending', action.payload);
                state.students.studentList = action.payload;
                state.labels.loading = 'idle';
            }
        });

        builder.addCase(getStudentsApi.rejected, (state, action) => {
            if (state.labels.loading === 'pending') {
                console.log('state.labels.loading === pending');
                state.labels.loading = 'idle';
                state.labels.error = 'Error occured';
            }
        });
    },
});

export default PublishWorksheetSlice.reducer;
