
import './video.css';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ChatLogDataModel } from '../../../../models/chat-log-data.model';
import EditIcon from '../../../../assets/images/pencil_l.svg';
import DeleteIcon from '../../../../assets/images/student-input/delete_m.svg';
import { useParams } from 'react-router-dom';
import { StudentStatus } from '../../../../models/student-status.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import Tick from '../../../../assets/images/owner_tick_s.svg';
import { PARENT_TYPE_CONSTANTS } from '../../../../constants/parentTypeConstants';
export interface IProps {
    parentType:string;
    mode:string;
    component: ChatLogDataModel;
    handleEdit: () => void;
    handleDelete: () => void;
    handleClick: (item: ChatLogDataModel) => void;
    currentComponent: boolean;
    triggerStudentInput?: boolean;
    focusList: string[];
    studentStatusList: StudentStatus[];
    handleReleaseFocus?: () => void;
    currentItem: DraggableElement | null;
    currentChatLog: ChatLogDataModel | null;
    colorList: any;
    isWorksheetLocked: boolean;
}
const Video: FC<IProps> = ({ mode,component, handleClick, handleDelete, handleEdit, currentComponent, triggerStudentInput, focusList, studentStatusList, handleReleaseFocus,
    parentType,
    currentItem,
    currentChatLog,
    colorList,
    isWorksheetLocked }) => {
    const params = useParams<{ id?: string; sheetId?: string; token?: string }>();
    const { token } = params;
    const [editText, setEditText] = useState<boolean>(false);
    const isLocked = useRef<boolean>(false);
    const isMe = useRef<boolean>(false);

    const [selectedId, setSelectedId] = useState<boolean>(false);

    const [activeStudentData, setActiveStudentData] = useState<any>({ name: '', color: '' });
    const [componentColor, setComponentColor] = useState<string>('black');


    const conditionAdded = () => {
        console.log(isLocked.current, isMe.current);
        console.log('conditionAdded:', isLocked.current || isMe.current);
        console.log('currentChatlog', component.data.text);
        return ((isLocked.current || isMe.current) && selectedId);
    };

    const getBorder = () => {
        if ((isLocked.current || isMe.current) && selectedId) {
            return activeStudentData.color;
        } else if (currentChatLog && currentChatLog.id === component.id) {
            return '2px solid #4A9BFF';
        } else {
            return '';
        }
    };

    const setValueForIsLocked = (value: boolean) => {
        isLocked.current = value;
    };

    const setValueForActiveStudentData = (obj: any) => {
        setActiveStudentData(obj);
    };

    const setValueForIsMe = (value: boolean) => {
        isMe.current = value;
    };

    useEffect(() => {
        const selectedId = studentStatusList.map((studentStatus: StudentStatus) => studentStatus.componentId);

        setValueForIsLocked(false);
        setValueForIsMe(false);


        const index = selectedId.indexOf(component.id);
        let selected = false;
        if (index > -1) {
            selected = true;
        } else {
            selected = false;
        }

        const focusByMeIndex = studentStatusList.findIndex((studentStatus: StudentStatus) =>
            studentStatus.userAction === 'focus' && studentStatus.componentId === component.id && studentStatus.tabID === sessionStorage.tabID);
        let focusComponenetByMe = '0';
        if (focusByMeIndex > -1)
            focusComponenetByMe = studentStatusList[focusByMeIndex].componentId;
        if (selected) {
            // handleClickParent(currentItem);
            console.log('selected:', component.id);
            studentStatusList.map((studentStatus: StudentStatus) => {
                if (studentStatus.componentId == component.id) {
                    if (studentStatus.userAction === 'focus') {
                        // handleClickParent(currentItem);
                        if (focusComponenetByMe !== studentStatus.componentId) {
                            console.log('focus by other');
                            setSelectedId(true);
                            setValueForIsLocked(true);
                            setValueForIsMe(false);
                            //search color
                            const index = colorList.findIndex((item: any) =>
                                item.tabID === studentStatus.tabID
                            );
                            if (index > -1) {
                                const color = colorList[index].color;
                                setComponentColor(color);
                                setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                            } else {
                                const color = '#28AAE1';
                                setComponentColor(color);
                                setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                            }
                            console.log('name', studentStatus.username);
                            console.log('active:', activeStudentData);
                            console.log('component color', componentColor);
                            console.log('isLocked:', isLocked.current);
                            console.log('isMe:', isMe.current);
                        } else if (focusComponenetByMe === studentStatus.componentId && studentStatus.tabID !== sessionStorage.tabID) {
                            console.log('focus by me and other');
                            // place logic here
                            if (studentStatus.tabID > sessionStorage.tabID) {
                                console.log('else release');
                                if (handleReleaseFocus)
                                    handleReleaseFocus();
                                setSelectedId(true);
                                setValueForIsLocked(true);
                                setValueForIsMe(false);
                                //search color
                                const index = colorList.findIndex((item: any) =>
                                    item.tabID === studentStatus.tabID
                                );
                                if (index > -1) {
                                    const color = colorList[index].color;
                                    setComponentColor(color);
                                    setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                                } else {
                                    const color = '#28AAE1';
                                    setComponentColor(color);
                                    setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                                }

                            } else {
                                console.log('else else release');
                                setValueForIsLocked(false);
                                setValueForIsMe(false);
                                setValueForActiveStudentData({ name: '', color: 'release' });
                            }
                        } else if (focusComponenetByMe === studentStatus.componentId && studentStatus.tabID == sessionStorage.tabID) {
                            console.log('focus by me and only');
                            //search color
                            const index = colorList.findIndex((item: any) =>
                                item.tabID === studentStatus.tabID
                            );
                            console.log('index:', index);
                            if (index > -1) {
                                const color = colorList[index].color;
                                setComponentColor(color);
                                console.log('index and color :', color);
                                setValueForActiveStudentData({ name: 'You', color: `2px solid ${color}` });
                                setValueForIsLocked(false);
                                setValueForIsMe(true);
                                setSelectedId(true);
                            } else {
                                const color = '#28AAE1';
                                setComponentColor(color);
                                setValueForActiveStudentData({ name: studentStatus.username, color: `2px solid ${color}` });
                                setValueForIsLocked(false);
                                setValueForIsMe(true);
                                setSelectedId(true);
                            }
                            console.log('student name :', studentStatus.username);
                            console.log('active:', activeStudentData);
                            console.log('isLocked:', isLocked.current);
                            console.log('isMe:', isMe.current);
                        }
                    } else if (studentStatus.userAction === 'submit') {
                        if (studentStatus.tabID !== sessionStorage.tabID) {
                            console.log('submit by other then reload');
                            setValueForIsLocked(false);
                            setValueForIsMe(false);
                            setValueForActiveStudentData({ name: '', color: 'submit' });
                            // if (handleStudentInputUpdate)
                            //     handleStudentInputUpdate(component.id);
                        }
                    }
                }
            });
        } else {
            console.log('not current component');
            setValueForIsLocked(false);
            setValueForIsMe(false);
            setSelectedId(false);
        }



    }, [studentStatusList, triggerStudentInput, focusList]);


    return (
        <div className='chat-element-wrapper' key={component.id} data-component-id={component.id}
            style={{
                border: getBorder()
            }}
            onClick={() => !token && !isWorksheetLocked && !isLocked.current && handleClick(component)}
            onMouseDown={() => !token && !isWorksheetLocked && !isLocked.current && handleClick(component)}
            onTouchStart={() => !token && !isWorksheetLocked && !isLocked.current && handleClick(component)}
        >
            
            <video controls playsInline src={component.data.url ? component.data.url + '#t=0.001' : ''} className="chat-image"  style={{
                padding: mode === 'collab' ? '16px':'8px',
                paddingBottom: mode === 'collab' && currentChatLog && currentChatLog.id === component.id ? '0px':'16px'
            }}></video>
            {((parentType === PARENT_TYPE_CONSTANTS.viewSubmission && mode === 'collab') || (mode === 'collab' && (currentChatLog && currentChatLog.id === component.id))) && 
                <div className="owner ">
                    <img src={Tick} alt="tick" />
                    <span className='text-c50'>Added by&nbsp;</span>
                    <span className='text-c75'>{component.studentName}</span>
                </div>}
            {currentChatLog && currentChatLog.id == component.id && <div className="chat-tool-wrapper">
                <img src={EditIcon} alt="" className='cursor' onClick={handleEdit} style={{
                    margin: '4px 6px 4px 0px'
                }} />
                <img src={DeleteIcon} alt="" className='cursor' style={{
                    margin: '4px 0px 4px 6px'
                }} onClick={handleDelete} />
            </div>}
            {conditionAdded() && <div className="lock-user body-m text-s0"
                style={{
                    background: componentColor
                }}>
                {activeStudentData.name}
            </div>}
        </div>
    );
};

export default Video;