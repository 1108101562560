import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Template } from '../../models/template-item.model';
import { AuthService } from '../../service/auth.service';

const _authService = new AuthService();

const baseUrl = process.env.REACT_APP_WORKSHEET_API_URL + 'api/v1/';

export const getTemplates = createAsyncThunk(
    'templates/getTemplates',
    async () => {
        const userInfo = _authService.getUserInfo();
        console.log('userInfo ', userInfo);
        const apiurl = baseUrl + 'templates?ownerId=' + userInfo.userid;
        // const apiurl = baseUrl + 'worksheets';
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.get(apiurl, { headers });
        console.log('response ', response);
        const res = response.data;
        const tempalteArray: Template[] = [];
        res.map((template: any) => {
            const templateObj: Template = {
                id: template._id,
                name: template.name,
                thumbnailUrl: template.thumbnailUrl,
                labelId: template.labelId,
                lessonId: template.lessonId,
                ownerId: template.ownerId,
            };
            tempalteArray.push(templateObj);
        });
        console.log('worksheetArray', tempalteArray);
        return tempalteArray;
    }
);

export const createTemplate = createAsyncThunk(
    'templates/createTemplate',
    async (body: any) => {
        const apiurl = baseUrl + 'templates';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiurl, body, { headers });
        console.log('createTemplate------', response);
        return response.data;
    }
);

export const deleteTemplate = createAsyncThunk(
    'templates/deleteTemplate',
    async (templateId: string) => {
        const userInfo = _authService.getUserInfo();
        console.log('userInfo ', userInfo);
        const apiurl =
            baseUrl + 'templates/' + templateId + '?ownerId=' + userInfo.userid;
        const headers = _authService.getHeadersInfo();
        const response = await axios.delete(apiurl, { headers });
        console.log('deleteTemplate------', response);
        return response.data;
    }
);

export const updateTemplate = createAsyncThunk(
    'templates/updateTemplate',
    async (templateData: any) => {
        const userInfo = _authService.getUserInfo();
        console.log('userInfo ', userInfo);
        const apiurl = baseUrl + 'templates/' + templateData.id;
        const headers = _authService.getHeadersInfo();
        const updatedData = {
            name: templateData.name,
            thumbnailUrl: templateData.thumbnailUrl,
            lessonId: templateData.lessonId,
            labelId: templateData.labelId,
            ownerId: templateData.ownerId,
        };
        const response = await axios.put(apiurl, updatedData, { headers });
        console.log('updateTemplate------', response);
        return response.data;
    }
);

export const createWorksheetFromTemplate = createAsyncThunk(
    'templates/createWorksheet',
    async (body: any) => {
        const apiurl = baseUrl + 'worksheets/from_template';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiurl, body, { headers });
        console.log('createTemplate------', response);
        return response.data;
    }
);
