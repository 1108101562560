import React, { useEffect, useState } from 'react';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../constants/navBarBtnActionConstants';
import ActivityNavbar from '../../global-components/activity-navbar/ActivityNavbar';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ActivityList from './components/activity-list/activity-list';
import { useGetActivityLogByIdQuery } from '../../api/worksheet/worksheetListApi';
import { getActivityLog } from '../../api/worksheet/worksheetsApi';
import { AppDispatch } from '../../store/store';
import { Activity } from '../../models/activity-log.model';
import ReactPaginate from 'react-paginate';
import { getWorksheetDetail } from '../../api/worksheet/teacherApi';
import ActivityLoading from './components/activity-loading/activity-loading';
import NoActivityIcon from '../../assets/images/no-activity.svg';
import {
    resetActivityList
} from './activityLogSlice';

const ActivityLog = () => {
    const params = useParams<{ id?: string }>();
    const { id } = params;

    //console.log('worksheet id ~~~~~~~~~~ ', id);
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const [loading, setloading] = useState<boolean>(true);
    // const { data, error, isLoading } = useGetActivityLogByIdQuery({});

    useEffect(() => {
        return () => {
            dispatch(resetActivityList());
        };
    }, []);

    const handleNavBtnAction = (type: string) => {
        switch (type) {
            case NAVBAR_BTN_ACTION_CONSTANTS.BACK:
                navigate('/submissions/' + id);
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.BACKEDITOR:
                navigate('/submissions/' + id);
                break;
            default:
                console.log('default');
        }
    };

    const { activityList, worksheetDetail } = useSelector(
        (state: any) => state.activityLog
    );
    const initialPageNumber = 1;

    const getActivityLogApi = async (pageNumber: number) => {
        try {

            console.log('calling activitylog', pageNumber);

            const activityLogResponse = await dispatch(getActivityLog(
                {
                    id: id,
                    page: pageNumber,
                    limit: 10
                }
            )).unwrap();
            setloading(false);
            //console.log(activityLogResponse);

        } catch (err: any) {
            console.log('Activity Log Error', err);
        }
    };

    const getWorksheetDetailCall = async () => {
        try {
            const getWorksheetDetailApi = await dispatch(
                getWorksheetDetail(id)
            ).unwrap();
            console.log('success:', getWorksheetDetailApi);
        } catch (err: any) {
            console.log('error', `Fetch failed: ${err.message}`);
        }
    };

    const onPageChange = (pageNumber: number) => {

        getActivityLogApi(pageNumber);

    };

    useEffect(() => {
        if (id) {
            getActivityLogApi(initialPageNumber);
            getWorksheetDetailCall();
        }
    }, [id]);

    console.log('ActivityListLog', activityList);

    return (
        <>
            {/* {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
            ) : data ? (
                <>
                    <ActivityNavbar handleNavBtnAction={handleNavBtnAction} />
                    <ActivityList activityList={activityList} />
                </>
            ) : null} */}
            <>
                <ActivityNavbar
                    worksheetDetail={worksheetDetail}
                    handleNavBtnAction={handleNavBtnAction} />
                <div style={{ width: '1072px', margin: 'auto' }}>
                    {!loading && activityList.length == 0 ? (
                        <div></div>
                    ) : (
                        <div className="activity-header h2-strong text-c300">
                            Activity Log
                        </div>
                    )}

                </div>
                {loading ? (
                    <ActivityLoading></ActivityLoading>
                ) : !loading && activityList.length > 0 ? (
                    <ActivityList
                        onPageChange={onPageChange}
                        activityList={activityList} />
                ) : (
                    <div className='center-wrapper mt-80'>
                        <img src={NoActivityIcon} alt="" />
                        <div className='text-c75 h3-strong mt-18'>There is no Activity log to show.</div>
                    </div>
                )}
            </>
        </>
    );
};

export default ActivityLog;
