export const ROUTE_CONSTANTS = {
    HOME: '/home',
    WORKSHEET_LIST: '/',
    TEMPLATE_LIBRARY: '/templates',
    NOT_FOUND: '/404',
    EDITOR: '/editor',
    STUDENT: '/student',
    ACTIVITY_LOG: '/activities',
    REDIRECT: '/redirect',
    VIEW_SUBMISSION: '/submissions',
    PUBILC: '/public',
};
