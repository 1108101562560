export const POPUP_ICON_CONSTANTS = {
    INFO: 'info',
    QUESTION_MARK: 'question_mark',
    ALERT: 'alert',
    CHECK: 'check',
    SEARCH: 'search',
    ICON1: 'icon1',
    ICON2: 'icon2',
    ICON3: 'icon3',
    ICON4: 'icon4',
    ICON5: 'icon5',
    ICON6: 'icon6',
    ICON7: 'icon7',
    ICON8: 'icon8',
    ICON9: 'icon9',
};
