import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './create-worksheet.css';
import CreateWorksheetForm from './components/create-worksheet-form';
import { WorksheetFormModel } from '../../models/worksheet-form.model';
import { Worksheet } from '../../models/worksheet-item.model';
import { getLabelsApi } from '../../api/global/globalApi';
import loading from '../../assets/images/loading.gif';
import { AppDispatch } from '../../store/store';
import { LabelModel } from '../../models/label.model';
import CreateLoading from './components/create-loading/create-loading';

export interface Props {
    type: string;
    worksheet: Worksheet;
    clickCancel: () => void;
    clickCreate: (worksheet: WorksheetFormModel, file: File | undefined, isDeleteThumbnail: boolean) => void;
}

const CreateWorksheet: FC<Props> = ({
    type,
    worksheet,
    clickCancel,
    clickCreate,
}) => {
    const dispatch = useDispatch<AppDispatch>(); //use AppDispatch for redux thunk

    const cancelCreateModal = () => {
        console.log('cancel create modal');
        clickCancel();
    };

    const createNewWorksheet = (worksheet: WorksheetFormModel, file: File | undefined, isDeleteThumbnail: boolean) => {
        console.log('create new worksheet', worksheet);
        clickCreate(worksheet, file, isDeleteThumbnail);
    };

    // for redux thunk
    const labels = useSelector((state: any) => state.createWorksheet.labels);
    console.log('labels state', labels);
    const labelList: LabelModel[] = labels.labelList;

    const [labelChange, setlabelChange] = useState(false);

    const getLabels = async () => {
        try {
            const getLabelList = await dispatch(getLabelsApi()).unwrap();
            console.log('success:', getLabelList);
            setlabelChange(true);
            console.log('labelList', labelList);
        } catch (err: any) {
            console.log('error', `Fetch failed: ${err.message}`);
        }
    };

    useEffect(() => {
        if (!labelChange) getLabels();
    }, [dispatch]);

    return (
        <>
            <div className="createform_wrapper">
                {labels.error ? (
                    <>Oh no, there was an error</>
                ) : labels.loading === 'pending' ? (
                    <div className="list-wrapper"><CreateLoading></CreateLoading></div>
                ) : labels.labelList ? (
                    <CreateWorksheetForm
                        type={type}
                        worksheet={worksheet}
                        labels={labels.labelList}
                        clickCancel={cancelCreateModal}
                        clickCreate={createNewWorksheet}
                    />
                ) : null}
            </div>
        </>
    );
};

export default CreateWorksheet;
