import React, { Component, FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SubmissionToolbar from './components/submission-toolbar/submission-toolbar';
import { addFeedback, deleteFeedback, getTeacherDetail, getToken, getWorksheetDetail, getWorksheetSubmissionDetails, postGrading, postWorksheetComponent, saveTeacherActionsApi, updateFeedback } from '../../api/worksheet/teacherApi';
import { AppDispatch } from '../../store/store';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../constants/navBarBtnActionConstants';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
import EditorNavbar from '../../global-components/editor-navbar/EditorNavbar';
import SubmissionSidebar from './components/submission-sidebar/submission-sidebar';
import {
    setCurrentDragItemType
    , setCurrentItem
    , toggleEditLayout
    , setReturningWorksheet
    , updateComponent
    , setModalType
    , setSelectedStudentId
    , setStudentSubmission
    , updateComponentSize
    , setCanvas,
    setWorksheetSubmissions
} from './view-submission-slice';
import './view-submission.css';
import { DraggableElement } from '../../models/draggable-element.model';
import { Submission, WorksheetSubmissionDetail } from '../../models/worksheet-submission-detail.model';
import { WorksheetComponent } from '../../models/worksheet-component.model';
import { AlertModel } from '../../models/alert.model';
import { PointModel } from '../../models/point.model';
import { WORKSHEET_CONSTANTS } from '../../constants/worksheetConstants';
import Modal from '../../global-components/modal/modal';
import { CustomModal } from '../../models/custom-modal.model';
import Button from '../../global-components/button/Button';
import { BtnModel } from '../../models/btn.model';

import LockIcon from '../../assets/images/locked.svg';
import CustomAlert from '../../global-components/custom-alert/CustomAlert';
import { Profile } from '../../models/profile.model';
import { AuthService } from '../../service/auth.service';
import { getClassName } from '../../api/global/globalApi';
import { profile } from 'console';
import GenerateQRcode from '../generate-qrcode/generate-qrcode';
import NosubmissionsIcon from '../../assets/images/no-submissions.svg';
import SubmissionLoading from './components/submission-loading/submission-loading';
import { PARENT_TYPE_CONSTANTS } from '../../constants/parentTypeConstants';
import CommonCanvas from '../common-canvas/CommonCanvas';
import { ChatLogDataModel } from '../../models/chat-log-data.model';
import CanvasLoading from '../../global-components/canvas-loading/CanvasLoading';

const ViewSubmission: FC = () => {
    const _authService = new AuthService();
    const params = useParams<{ worksheetid?: string }>();
    const { worksheetid } = params;
    const userInfo = _authService.getUserInfo();
    console.log('worksheetid------', worksheetid);
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>(); //use AppDispatch for redux thunk
    const sidebar = useRef<HTMLDivElement | null>(null);

    const {
        worksheetDetail
        , worksheetSubmissions
        , studentSubmission
        , selectedStudentId
        , canvasWidth
        , canvasHeight
        , loading
        , error
        , maxScore
        , maxBase
        , currentDragItemType
    } = useSelector((state: any) => state.viewSubmissions);
    // const [studentSubmission, setStudentSubmission] = useState<Submission | null>(null);
    const [submissionsChange, setsubmissionsChange] = useState(false);
    const [isSideBarItemsSelectable, setIsSideBarItemsSelectable] = useState(true);
    const [openShareModal, setOpenShareModal] = useState<boolean>(false);
    const [teacherProfile, setTeacherProfile] = useState<Profile>({
        url: '',
        name: '',
        initials: ''
    });

    const [showWorksheetModal, setShowWorksheetModal] = useState<boolean>(false);
    const [className, setClassName] = useState<string>('');
    const [teacherInfoChange, setTeacherInfoChange] = useState(false);

    const defaultAlertData: AlertModel = {
        title: 'default',
        body: null,
        type: 'success',
        uiClass: 'default',
    };
    const [modalData, setModalData] = useState<CustomModal>({
        title: '',
        showCrossBtn: true,
        width: '100px',
        height: null,
        padding: '15px',
    });
    const [alertData, setalertData] = useState(defaultAlertData);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const isEditLayout = useSelector(
        (state: any) => state.viewSubmissions.isEditLayout
    );

    // const [selectedStudentId, setSelectedStudentId] = useState('');

    console.log('state.viewSubmissions.isEditLayout===', isEditLayout);

    const currentItem = useSelector(
        (state: any) => state.viewSubmissions.currentItem
    );

    const currentFeedback = useSelector(
        (state: any) => state.viewSubmissions.currentItem
    );

    const handleNavBtnAction = (type: string) => {
        console.log('ActionType', type);
        switch (type) {
            case NAVBAR_BTN_ACTION_CONSTANTS.BACK:
                navigate('/');
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.BACKEDITOR:
                navigate('/editor/' + worksheetid);
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.EDIT_LAYOUT:
                console.log('handle edit layout');
                dispatch(toggleEditLayout());
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.PUBLISH:
                console.log('handle publish');
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.RETURN:
                console.log('handle return');
                callSaveTeacherActions();
                break;
            default:
                console.log('default');
        }
    };

    const handleToolbarBtnAction = (type: string | undefined) => {
        switch (type) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.SCORE:
                console.log('score');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.GRADING:
                console.log('grading');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK:
                console.log('feedback');
                break;
            default:
                console.log('default');
        }
    };

    const handleShareBtnAction = async () => {
        console.log('share------');
        setOpenShareModal(true);
        try {
            const payload = {
                studentId: selectedStudentId,
                worksheetId: worksheetid
            };
            const getTokenApi = await dispatch(
                getToken(payload)
            ).unwrap().then((res) => {
                console.log('res:', res);
                setShareLink(currentURLOrigin + '/public/' + res.shareToken);
            }).catch((error) => {
                console.error('err:', error);
                setShareLink(currentURLOrigin + '/public/' + error.message);
            });
            console.log('success:', getTokenApi);
        } catch (err: any) {
            console.log('error', `Fetch failed: ${err.message}`);
        }


    };

    const updateCurrentItem = (component: DraggableElement | null) => {
        dispatch(setCurrentItem(component));
    };

    const handleClickStudent = (submission: any) => {

        if (submission && submission.submissions.length != 0) {
            //selectedStudentId = submission.studentId;
            // setSelectedStudentId(submission.studentId);
            //setStudentSubmission(submission.submissions[0]);

            console.log('OnStudentChoose1', submission);

            dispatch(setSelectedStudentId(submission.studentId));
            dispatch(setStudentSubmission(submission.submissions[0]));

        }

    };

    const handleQRAction = () => {
        console.log('Hey! Do we need to show QR?');
        const qrModalProps: CustomModal = {
            title: 'Login with QR Code',
            showCrossBtn: true,
            width: '480px',
            height: null,
            padding: '32px',
        };
        dispatch(setModalType(NAVBAR_BTN_ACTION_CONSTANTS.QRCODE));
        handleModalData(qrModalProps);
        setShowWorksheetModal(true);
    };

    const handleModalData = (value: CustomModal) => {
        setModalData(value);
    };

    const handleHistory = () => {
        navigate(`/activities/${worksheetid}`);
    };

    const getWorksheetDetailCall = async () => {
        try {
            const getWorksheetDetailApi = await dispatch(
                getWorksheetDetail(worksheetid)
            ).unwrap();
            console.log('success:', getWorksheetDetailApi);
        } catch (err: any) {
            console.log('error', `Fetch failed: ${err.message}`);
        }
    };



    const getSelectedStudentIndex = (worksheetSubmissions: WorksheetSubmissionDetail[] | null) => {

        let index = 0;

        if (selectedStudentId && worksheetSubmissions) {

            for (let i = 0; i < worksheetSubmissions.length; i++) {
                if (selectedStudentId === worksheetSubmissions[i].studentId) {
                    index = i;
                }
            }
        }

        return index;
    };

    const getClassNameCall = async () => {
        try {
            const getClassNameApi = await dispatch(getClassName()).unwrap().then((res) => {
                console.log('student Name', res);
                setClassName(res.name);
            })
                .catch((error) => {
                    console.log('student Name error', error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    const getTeacherInfo = async () => {
        try {
            console.log('userInfo:', userInfo.userid);
            const id = [userInfo.userid];
            const callApi = await dispatch(getTeacherDetail(id));
            console.log('====================================');
            console.log(callApi.payload.userData[0]);
            console.log('====================================');
            const data = callApi.payload.userData[0];

            const profile: Profile = {
                url: data.url ? data.url : '',
                name: data.firstName && data.lastName ? data.firstName + ' ' + data.lastName : data.username,
                initials: data.initials ? data.initials : data.username.slice(0, 2).toUpperCase()
            };
            setTeacherProfile(profile);
            setTeacherInfoChange(true);
        } catch (error) {
            console.log('error', `Fetch failed: ${error}`);
        }
    };
    const [modeForCanvas, setModeForCanvas] = useState('single');
    const getWorksheetSubmissions = async () => {
        try {
            const { mode, worksheetSubmissions } = await dispatch(
                getWorksheetSubmissionDetails(worksheetid ? worksheetid : '')
            ).unwrap();

            setModeForCanvas(mode);

            const selectedStudentIndex = getSelectedStudentIndex(worksheetSubmissions ? worksheetSubmissions : null);

            const initialSubmission = worksheetSubmissions ? (
                worksheetSubmissions.length != 0 ? (
                    worksheetSubmissions[selectedStudentIndex].submissions.length != 0 ? (
                        worksheetSubmissions[selectedStudentIndex].submissions[0]
                    ) : null
                ) : null
            ) : null;

            // setSelectedStudentId(worksheetSubmissions ? (
            //     worksheetSubmissions.length != 0 ? (
            //         worksheetSubmissions[selectedStudentIndex].studentId
            //     ) : ''
            // ) : '');

            console.log('SelectedStudentId', selectedStudentId);

            setIsSideBarItemsSelectable(mode === WORKSHEET_CONSTANTS.MODE_SINGLE);

            // if (initialSubmission) {
            //     setStudentSubmission(initialSubmission);
            // }
            setsubmissionsChange(true);

        } catch (err: any) {
            console.log('WorksheetSubmissionsError', err);
        }
    };

    const callSaveTeacherActions = async () => {

        console.log('callSaveTeacher', studentSubmission, selectedStudentId, (studentSubmission && selectedStudentId != ''), 'Something');

        if (studentSubmission && selectedStudentId != '') {

            console.log('returningworksheet');

            const payload = {
                worksheetId: worksheetid,
                studentId: selectedStudentId,
                submissionKey: studentSubmission.submissionKey
            };
            try {

                dispatch(setReturningWorksheet(true));

                const saveTeacherActions = await dispatch(
                    saveTeacherActionsApi(payload)
                ).unwrap();

                const alertData: AlertModel = {
                    title: 'Worksheet returned successfully.',
                    body: null,
                    type: 'success',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);

                console.log('saveTeacherActions > success', saveTeacherActions);
            } catch (error) {
                if (error) console.error(error);

                const alertData: AlertModel = {
                    title: 'Worksheet return failed.',
                    body: null,
                    type: 'error',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);

            } finally {
                dispatch(setReturningWorksheet(false));
            }

        }
    };

    const handleUpdatePosition = (position: PointModel) => {

        console.log('handleUpdatePosition', position, currentFeedback);

        if (position && currentFeedback) {

            const updatedFeedback: DraggableElement = { ...currentFeedback };
            updatedFeedback.location = position;
            console.log('FeedbackUpdatePosition', position, updatedFeedback);
            onFeedbackChange(updatedFeedback);
        }
        //dispatch(setCurrentItem(null));

    };

    const handleTempSize = (size: any) => {

        if (size) {
            dispatch(updateComponentSize({ size: size, id: currentItem.id }));
        }

    };

    const handleUpdateSize = (size: any) => {

        console.log('handleUpdatesize', size);

        if (size && currentFeedback) {

            const updatedFeedback: DraggableElement = { ...currentFeedback };
            updatedFeedback.size = size;

            onFeedbackChange(updatedFeedback);

            // onFeedbackChange(currentFeedback);

        }

    };

    const handleDeleteFeedback = async () => {

        if (currentFeedback && currentFeedback.type === TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK) {

            try {

                const deleteFeedbackApi = await dispatch(deleteFeedback({ id: currentFeedback.id })).unwrap();

                updateCurrentItem(null);

                getWorksheetSubmissions();

            } catch (err: any) {

                console.log('DeleteFeedbackError', err);

            }

        }

    };

    const handleAddComponent = (
        payload: WorksheetComponent
    ) => {

        callAddFeedbackApi({
            id: '',
            type: payload.type,
            location: payload.location,
            size: payload.size,
            data: payload.data
        });

    };

    const addComponent = (component: DraggableElement) => {

        if (component && studentSubmission) {
            const tmpComponents: DraggableElement[] = [];

            studentSubmission.components.forEach((component: DraggableElement) => {
                tmpComponents.push(Object.assign({}, component));
            });

            tmpComponents.push(component);

            dispatch(setStudentSubmission({
                submittedTime: studentSubmission.submittedTime,
                submissionKey: studentSubmission.submissionKey,
                components: tmpComponents,
                teacherFeedback: []
            }));

        }

    };

    const onFeedbackChange = async (feedback: DraggableElement) => {
        try {

            const payload = {
                id: feedback.id,
                location: feedback.location,
                size: feedback.size,
                data: {
                    text: feedback.data.text
                }
            };

            const updateFeedbackApi = await dispatch(updateFeedback(payload)).unwrap();

            console.log('addFeedbackSuccess', updateFeedbackApi);

            //const updatedFeedback: DraggableElement = { ...currentFeedback };
            const updatedFeedback: DraggableElement = {
                id: currentFeedback.id,
                type: currentFeedback.type,
                location: feedback.location,
                size: feedback.size,
                data: feedback.data
            };
            //updatedFeedback.data.text = feedback.data.text;

            if (currentFeedback && currentFeedback.id === feedback.id) {

                console.log('UpdatingCurrentFeedback', currentFeedback, feedback, updatedFeedback);

                updateCurrentItem(updatedFeedback);
            }

            getWorksheetSubmissions();

            dispatch(updateComponent({ studentId: selectedStudentId, tool: updatedFeedback }));
            //updateComponentState(updatedFeedback);

        } catch (err: any) {
            console.log('addFeedbackError', err);
        }
    };

    const callAddFeedbackApi = async (feedback: DraggableElement) => {
        try {

            const payload = {
                worksheetId: worksheetid,
                studentId: selectedStudentId,
                submissionKey: studentSubmission?.submissionKey,
                location: feedback.location,
                size: feedback.size,
                data: {
                    text: feedback.data.text
                }
            };

            const addFeedbackApi = await dispatch(addFeedback(payload)).unwrap();

            console.log('addFeedbackSuccess', addFeedbackApi);

            if (
                addFeedbackApi.meta &&
                addFeedbackApi.meta._id
            ) {
                const element: DraggableElement = {
                    id: addFeedbackApi.meta._id,
                    type: feedback.type,
                    location: feedback.location,
                    size: feedback.size,
                    data: feedback.data,
                };

                addComponent(element);

                getWorksheetSubmissions();

            }

            dispatch(setCurrentDragItemType(''));

        } catch (err: any) {
            console.log('addFeedbackError', err);
        }
    };

    const updateComponentState = (updatedComponent: DraggableElement) => {

        const tmpComponents: DraggableElement[] = [];
        let updatedScore = 0;

        console.log('UpdatingMarkScore', updatedScore);

        studentSubmission?.components.forEach((component: DraggableElement) => {
            //console.log('UpdatingMarkScore', component);
            if (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
                if (component.id === updatedComponent.id) {
                    updatedScore += updatedComponent.data.score.mark ? updatedComponent.data.score.mark : 0;
                } else {
                    updatedScore += component.data.score.mark ? component.data.score.mark : 0;
                }
            }
        });

        studentSubmission?.components.forEach((component: DraggableElement) => {
            if (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.SCORE) {
                const tmpScoreComponent = {
                    id: component.id,
                    type: component.type,
                    location: component.location,
                    size: component.size,
                    data: {
                        text: component.data.text,
                        score: {
                            base: component.data.score.base,
                            mark: updatedScore
                        },
                        url: component.data.url,
                        chatLog: component.data.chatLog,
                        style: component.data.style,
                        iconType: component.data.iconType,
                        descText: component.data.descText,
                        descStyle: component.data.descStyle,
                        isBack: component.data.isBack

                    }
                };

                tmpComponents.push(Object.assign({}, tmpScoreComponent));
            } else if (component.id === updatedComponent.id) {

                tmpComponents.push(Object.assign({}, updatedComponent));

            } else {
                tmpComponents.push(Object.assign({}, component));
            }
        });

        studentSubmission?.components.forEach((component: DraggableElement) => {
            if (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK) {
                const tmpScoreComponent = {
                    id: component.id,
                    type: component.type,
                    location: component.location,
                    size: component.size,
                    data: {
                        text: component.data.text,
                        score: {
                            base: component.data.score.base,
                            mark: updatedScore
                        },
                        url: component.data.url,
                        chatLog: component.data.chatLog,
                        style: component.data.style,
                        iconType: component.data.iconType,
                        descText: component.data.descText,
                        descStyle: component.data.descStyle,
                        isBack: component.data.isBack

                    }
                };

                tmpComponents.push(Object.assign({}, tmpScoreComponent));
            } else if (component.id === updatedComponent.id) {

                tmpComponents.push(Object.assign({}, updatedComponent));

            } else {
                tmpComponents.push(Object.assign({}, component));
            }
        });

        const updatedStudentSubmission = Object.assign({}, studentSubmission);
        updatedStudentSubmission.components = tmpComponents;

        dispatch(setStudentSubmission(updatedStudentSubmission));
        

    };

    const handleClickOutside = () => {
        console.log('ClickedOutside');
        dispatch(setCurrentItem(null));
    };

    const onGradeChange = async (gradingComponent: DraggableElement, submission: Submission) => {

        console.log('UpdatingStudentGrade', selectedStudentId, worksheetSubmissions[0].studentId);

        try {
            const payload = {
                worksheetId: worksheetid,
                studentId: selectedStudentId,
                componentId: gradingComponent.id,
                submissionKey: submission.submissionKey,
                data: {
                    score: gradingComponent.data.score.mark
                }
            };

            const postGradingApi = await dispatch(
                postGrading(payload)
            ).unwrap();

            dispatch(updateComponent({
                studentId: selectedStudentId,
                tool: gradingComponent
            }));

            updateComponentState(gradingComponent);

            //getWorksheetSubmissions();
        } catch (err: any) {
            console.log('Error', err);
        }
    };

    const handleModalClose = () => {
        console.log('handleModalClose');
        setOpenShareModal(false);
    };
    const modalProps: CustomModal = {
        title: 'Share this Worksheet',
        showCrossBtn: true,
        width: '720px',
        height: null,
        padding: '40px'
    };

    const shareBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Copy Link',
        uiClass: 'copy-btn',
        iconClass: undefined
    };
    const currentURLOrigin = new URL(window.location.href).origin;
    const [shareLink, setShareLink] = useState<string>('Loading . . . ');

    const handleShareClicked = async () => {

        console.log('handleShareClicked');
        navigator.clipboard.writeText(shareLink);
        setOpenShareModal(false);
    };

    const toggleWorksheetModal = () => {
        setShowWorksheetModal(!showWorksheetModal);
    };

    const handleCancel = () => {
        setShowWorksheetModal(false);
    };

    // useEffect(() => {
    //     if (worksheetid && worksheetDetail == null) {
    //         console.log('calling api .......');
    //         getWorksheetDetailCall();
    //     }
    //     //old code
    //     if (worksheetid && worksheetSubmissions == null) {
    //         console.log('calling submission detail api .......');
    //         getWorksheetSubmissions();
    //     }
    //     if (!submissionsChange) {
    //         getWorksheetSubmissions();
    //     }
    // }, [worksheetid, worksheetDetail, worksheetSubmissions, submissionsChange]);

    useEffect(() => {

        console.log('ExecutingUseEffect1');

        if (!teacherInfoChange) {
            getTeacherInfo();
            getClassNameCall();
        }

    }, [teacherInfoChange]);

    useEffect(() => {

        console.log('ExecutingUseEffect2',submissionsChange);

        if (worksheetid && worksheetDetail == null)
            getWorksheetDetailCall();

        if (!submissionsChange)
            getWorksheetSubmissions();

    }, [dispatch, submissionsChange]);


    useEffect(() => {
        return () => {
            dispatch(setWorksheetSubmissions(null));
        };
    }, []);
    
    

    // console.log('worksheetSubmissionLength', worksheetSubmissions.length);

    return (
        <>
            {
                openShareModal && <Modal
                    modalProps={modalProps}
                    isOpen={openShareModal}
                    toggle={handleModalClose} cancelModal={handleModalClose}>
                    <div className='share-modal'>
                        <span className='h4-strong text-c300'>Link to share</span>
                        <div className="link-wrapper">
                            <img src={LockIcon} alt="" />
                            <span className='link'>{shareLink}</span>
                        </div>
                        <Button btnProps={shareBtnProps} handleBtnClicked={handleShareClicked}></Button>
                    </div>
                </Modal>
            }
            {
                // worksheetDetail == null ? (<div></div>) : (
                <>
                    <EditorNavbar
                        handleNavBtnAction={handleNavBtnAction}
                        isTeacher={true}
                        worksheetDetail={worksheetDetail}
                        saveClick={false}
                        isViewSubmissions={true}
                        isEditLayout={isEditLayout}
                    />
                    <SubmissionToolbar
                        handleToolbarBtnAction={handleToolbarBtnAction}
                        handleShareBtnAction={handleShareBtnAction}
                        handleHistory={handleHistory}
                        handleQR={handleQRAction}
                        isEditLayout={isEditLayout}
                        currentItem={currentItem}
                        profile={teacherProfile}
                        className={className}
                    />
                </>
                // )
            }
            {showAlert && (
                <CustomAlert
                    alertData={alertData}
                    alertClose={() => setShowAlert(false)}
                ></CustomAlert>
            )}
            {
                (worksheetSubmissions == null) ? (<SubmissionLoading></SubmissionLoading>) : (worksheetSubmissions && worksheetSubmissions.length > 0) ? (
                    <div className='submission-view-wrapper'>
                        <div
                            ref={sidebar}
                            id='sidebar-container'
                            className='bg-s0 sidebar-container'>
                            <SubmissionSidebar
                                activeStudentId={selectedStudentId}
                                submissionDetails={worksheetSubmissions}
                                onClickStudent={handleClickStudent}
                                isStudentsSelectable={isSideBarItemsSelectable} />
                        </div>
                        <Modal
                            modalProps={modalData}
                            isOpen={showWorksheetModal}
                            toggle={toggleWorksheetModal}
                            cancelModal={handleCancel}
                        >
                            {showWorksheetModal && (
                                <GenerateQRcode
                                    type={NAVBAR_BTN_ACTION_CONSTANTS.QRCODE}
                                    worksheetId={worksheetid ? worksheetid : ''}
                                ></GenerateQRcode>
                            )}
                        </Modal>
                        <div className='canvas-container'>
                            {/* {
                                studentSubmission &&
                                <Canvas
                                    addComponent={handleAddComponent}
                                    submissionDetail={studentSubmission}
                                    updateCurrentItem={updateCurrentItem}
                                    handleUpdatePosition={handleUpdatePosition}
                                    handleUpdateSize={handleUpdateSize}
                                    deleteFeedback={handleDeleteFeedback}
                                    onGradeChange={onGradeChange}
                                    onFeedbackChange={onFeedbackChange}
                                    sidebarWidth={sidebar.current ? sidebar.current?.clientWidth : 0}
                                    canvasWidth={canvasWidth}
                                    canvasHeight={canvasHeight} />
                            } */}
                            {studentSubmission.components.length == 0 ? <CanvasLoading type='viewSubmission'></CanvasLoading>:<CommonCanvas
                                mode={modeForCanvas}
                                parentType={PARENT_TYPE_CONSTANTS.viewSubmission}
                                submissionDetail={studentSubmission}
                                components={studentSubmission.components}
                                currentDragItemType={currentDragItemType}
                                editItem={false}
                                currentItem={currentItem}
                                canvasWidth={canvasWidth}
                                canvasHeight={canvasHeight}
                                totalScore={maxScore}
                                totalBase={maxBase}
                                isText={false}
                                publishedStatus={''}
                                isEditLayout={isEditLayout}
                                studentId={selectedStudentId ? selectedStudentId : ''}

                                toggleModal={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleModalData={function (value: CustomModal): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleTempPosition={function (position: PointModel): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleEdit={() => { throw new Error('Handle edit not implemented.'); }}
                                handleClickOutside={handleClickOutside}
                                addComponent={handleAddComponent}
                                deleteComponent={handleDeleteFeedback}
                                handleBlur={() => dispatch(setCurrentItem(null))}
                                updateCurrentItem={updateCurrentItem}
                                handleUpdatePosition={handleUpdatePosition}
                                handleBgDelete={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleBgEdit={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleTextChange={function (text: string, descText: string): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleResize={handleUpdateSize}
                                handleClone={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleTempSize={handleTempSize}
                                handleFontChange={function (data: string): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleFontSizeChange={function (data: string): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleFontWeightChange={function (data: string): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleItalic={function (data: boolean): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleUnderline={function (data: boolean): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleColorChange={function (data: string): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleBorderChange={function (data: string): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleBackgroundChange={function (data: string): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handlePopupIconClicked={function (data: string): void {
                                    throw new Error('Function not implemented.');
                                }}
                                updateActiveItem={function (isText: boolean): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleInputChange={function (text: string | undefined): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleIsBack={function (value: boolean): void {
                                    throw new Error('Function not implemented.');
                                }}
                                handleCanvas={(value: any) => dispatch(setCanvas(value))}
                                changeCurrentInputType={
                                    () => {
                                        throw new Error('Function not implemented.');

                                    }
                                }
                                handleStudentInputClick={() => console.log()}
                                addText={() => {
                                    throw new Error('Function not implemented.');

                                }}
                                textUploaded={false}
                                updateText={() => {
                                    throw new Error('Function not implemented.');

                                }}
                                handleChatLogClick={(item: ChatLogDataModel) => console.log()}
                                openModal={() => {
                                    throw new Error('Function not implemented.');

                                }}
                                handleDelete={() => {
                                    throw new Error('Function not implemented.');

                                }}
                                handleReleaseFocus={() => {
                                    throw new Error('Function not implemented.');

                                }}
                                handleStudentInputUpdate={() => {
                                    throw new Error('Function not implemented.');

                                }}
                                triggerStudentInput={false}

                                studentStatusList={[]}
                                colorList={() => {
                                    throw new Error('Function not implemented.');

                                }}
                                focusList={() => {
                                    throw new Error('Function not implemented.');

                                }}
                                worksheetDetail={worksheetDetail}
                                currentChatLog={null}
                                onGradeChange={onGradeChange}
                                onFeedbackChange={onFeedbackChange}
                                sidebarWidth={sidebar.current ? sidebar.current?.clientWidth : 0}
                                handleClosePopup={() => dispatch(setCurrentItem(null))}
                            />
                            }
                        </div>
                    </div>
                    // <SubmissionLoading></SubmissionLoading>
                ) : (
                    <div className='center-wrapper mt-80'>
                        <img src={NosubmissionsIcon} alt="" />
                        <div className='text-c75 h3-strong mt-18'>There is no submissions.</div>
                    </div>
                )
            }
        </>
    );
};

export default ViewSubmission;