import React, { FC, useState } from 'react';
import { WorksheetFormModel } from '../../../models/worksheet-form.model';
import './create-worksheet-form.css';
import { LabelModel } from '../../../models/label.model';
import { BtnModel } from '../../../models/btn.model';
import EmergingIcon from '../../../assets/images/btnEmerging.svg';
import ExpectedIcon from '../../../assets/images/btnExpected.svg';
import ExceedingIcon from '../../../assets/images/btnExceeding.svg';
import Button from '../../../global-components/button/Button';
import { OVERLAY_MENU_CONSTANTS } from '../../../constants/overlayMenuConstants';
import { Worksheet } from '../../../models/worksheet-item.model';
import UploadThumbnail from '../../../global-components/upoad-thumbnail/upload-thumbnail';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../../constants/navBarBtnActionConstants';

type Props = {
    type: string;
    worksheet: Worksheet;
    labels: LabelModel[];
    clickCancel: () => void;
    clickCreate: (article: WorksheetFormModel | any, file: File | undefined, isDeleteThumbnail: boolean) => void;
};

const CreateWorksheetForm: FC<Props> = ({
    type,
    worksheet,
    labels,
    clickCancel,
    clickCreate,
}) => {
    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel-l',
        iconClass: undefined,
    };

    const createBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: type == OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET || type == OVERLAY_MENU_CONSTANTS.TEMPLATE ? 'Create' : 'Edit',
        uiClass: 'primary-m',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: type == OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET || type == OVERLAY_MENU_CONSTANTS.TEMPLATE ? 'Create' : 'Edit',
        uiClass: 'disabled-m',
        iconClass: undefined,
        loadingText: type == OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET || type == OVERLAY_MENU_CONSTANTS.TEMPLATE ? 'Creating' : 'Editing'
    };

    const [file, setFile] = useState<File | undefined>(undefined);
    const [isDeleteThumbnail, setIsDeleteThumbnail] = useState<boolean>(false);

    const [isLoadingBtn, setisLoadingBtn] = useState(false);

    const createLabel = (type == OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET || type == NAVBAR_BTN_ACTION_CONSTANTS.EDIT_WORKSHEET) ? 'Worksheet Name' : 'Template Name';

    const getLabelButton = (labelId: string) => {
        let button;
        switch (labelId) {
            case '62e373088599581bb880e26e':
                // Expected group
                // button = 'assets/images/btnExpected.svg';
                button = ExpectedIcon;
                break;
            case '62e79f6cd53e870014fff9ff':
                // Emerging group
                button = EmergingIcon;
                break;
            case '62e79f74d53e870014fffa01':
                // Exceeding group
                button = ExceedingIcon;
                break;
        }
        return button;
    };

    const [worksheetForm, setWorksheetForm] = useState<WorksheetFormModel>({
        id: worksheet.id,
        name: worksheet.name,
        labelId: worksheet.labelId,
    });

    console.log('worksheetForm', worksheetForm);

    const handleWorksheetData = (e: React.FormEvent<HTMLInputElement>) => {
        console.log('handleWorksheet', e.currentTarget.value);
        setWorksheetForm({
            ...worksheetForm,
            [e.currentTarget.id]: e.currentTarget.value,
        });
    };

    const createWorksheet = (e: React.FormEvent) => {
        e.preventDefault();
        setisLoadingBtn(true);
        clickCreate(worksheetForm, file, isDeleteThumbnail);
    };

    const onClickCancel = () => {
        console.log('onClickCancel');
        clickCancel();
    };

    const handleUploadThumbnail = (file: File | undefined) => {
        setFile(file);
        setIsDeleteThumbnail(false);
    };

    const deleteThumbnail = () => {
        setFile(undefined);
        setIsDeleteThumbnail(true);
    };

    return (
        <form onSubmit={createWorksheet} className="Add-worksheetForm">
            <div className="mt-16">
                <div className="h4-strong text-c300 mb-8">{createLabel}*</div>
                <input
                    type="text"
                    id="name"
                    value={worksheetForm.name ? worksheetForm.name : ''}
                    className="light-body-m text-c300 modal-text-input"
                    placeholder="Type Worksheet Name..."
                    onChange={handleWorksheetData}
                />
            </div>
            {/* <div className="mt-30">
                <div>
                    <span className="text-c300 h4-strong">Pick Label</span>
                </div>
                <div className="label-wrapper mt-16">
                    {labels.map((item: LabelModel, index: any) => (
                        <label className="radio-btn" key={index}>
                            <input
                                type="radio"
                                id="labelId"
                                value={item.id}
                                checked={worksheetForm.labelId === item.id}
                                onChange={handleWorksheetData}
                            />
                            <div className="bg-s40 label-item">
                                <span className="icon-wrapper">
                                    <img src={getLabelButton(item.id)} />
                                </span>
                                <span className="text-c300 body-m">
                                    for {item.name}
                                </span>
                            </div>
                        </label>
                    ))}
                </div>
            </div> */}
            <div className='mt-30'>
                {
                    type == OVERLAY_MENU_CONSTANTS.TEMPLATE || type == NAVBAR_BTN_ACTION_CONSTANTS.EDIT_TEMPLATE ? (
                        <div className='upload-thumbnail'>
                            <img src={worksheet.thumbnailUrl} />
                        </div>
                    ) : (
                        <UploadThumbnail worksheet={worksheet} handleUpload={handleUploadThumbnail} handleDelete={deleteThumbnail} />
                    )
                }
            </div>
            <div className="custom-modal-footer mt-123">
                <span className="mr-16">
                    <Button
                        btnProps={cancelBtnProps}
                        handleBtnClicked={onClickCancel}
                    />
                </span>
                {/* <button
                    disabled={worksheetForm.name === '' ? true : false}
                    className="body-m text-s0 primary-m modal-btn-l"
                    onClick={() => createWorksheet}
                >
                    {(type == OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET || type == OVERLAY_MENU_CONSTANTS.TEMPLATE) ? 'Create' : 'Edit'}
                </button> */}
                {!isLoadingBtn ?
                    <Button btnProps={worksheetForm.name === '' ? disabledBtnProps : createBtnProps} handleBtnClicked={() => createWorksheet} /> :
                    <Button btnProps={disabledBtnProps} isLoading={isLoadingBtn} handleBtnClicked={() => createWorksheet} />
                }
            </div>
        </form>
    );
};

export default CreateWorksheetForm;
