import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './worksheet-home.css';
import HeaderNavbar from '../../global-components/header-navbar/header-navbar';
import Modal from '../../global-components/modal/modal';
import Button from '../../global-components/button/Button';
import SearchInput from '../../global-components/search-input/search-input';
import List from './components/list/list';
import ListHeader from './components/list-header/list-header';
import { useGetWorksheetListQuery } from '../../api/worksheet/worksheetListApi';
import CreateWorksheet from '../create-worksheet/create-worksheet';
import { OVERLAY_MENU_CONSTANTS } from '../../constants/overlayMenuConstants';
import DeleteWorksheet from '../delete-worksheet/delete-worksheet';
import ChangeTitle from '../change-title/change-title';
import { setModalType, setCurrentWorksheet } from './worksheetshome_slice';
import UseModal from '../../hooks/UseModal';
import UseWorksheetsHomeModal from '../../hooks/UseWorksheetsHomeModal';
import PublishWorksheet from '../publish-worksheet/publish-worksheet';
import { Worksheet } from '../../models/worksheet-item.model';
import { BtnModel } from '../../models/btn.model';
import { CustomModal } from '../../models/custom-modal.model';
import { WorksheetFormModel } from '../../models/worksheet-form.model';
import { AuthService } from '../../service/auth.service';
import { useCreateWorksheetMutation } from '../../api/worksheet/worksheetListApi';
import { useDeleteWorksheetMutation } from '../../api/worksheet/worksheetListApi';
import {
    createWorksheetApi,
    deleteWorksheetApi,
    publishWorksheetApi,
    useAsTemplateApi,
    uploadThumbnail,
    deleteThumbnail
} from '../../api/worksheet/worksheetsApi';
import { getWorksheets } from '../../api/worksheet/worksheetsApi';
import { AppDispatch } from '../../store/store';
import { DeleteFormModel } from '../../models/delete-form.model';
import { editWorksheetApi } from '../../api/global/globalApi';
import loading from '../../assets/images/loading.gif';
import { AlertModel } from '../../models/alert.model';
import CustomAlert from '../../global-components/custom-alert/CustomAlert';
import { async } from '@firebase/util';
import HomeLoading from '../home-loading/home-loading';
import { error } from 'console';

const _authService = new AuthService();

const WorksheetsHome: FC = () => {
    const navigate = useNavigate();
    const userInfo = _authService.getUserInfo();
    if (userInfo) {
        console.log('userInfo:', userInfo);
        if (userInfo.usertype == 3) {
            navigate('/404');
        }
    } else {
        navigate('/404');
    }
    const queryParams = new URLSearchParams(window.location.search);
    const action = decodeURIComponent(queryParams.get('action') || '');

    const createBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Create New Worksheet',
        uiClass: 'create-btn',
        iconClass: undefined,
    };

    const libraryBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Template Library',
        uiClass: 'secondary-btn',
        iconClass: undefined,
    };

    const createModalProps: CustomModal = {
        title: 'Create new Worksheet',
        showCrossBtn: true,
        width: '947px',
        height: 'max-content',
        padding: '49px 32px',
    };

    const deleteModalProps: CustomModal = {
        title: 'Confirm delete',
        showCrossBtn: true,
        width: '433px',
        height: null,
        padding: '20px',
    };

    const changeTitleModal: CustomModal = {
        title: 'Change title',
        showCrossBtn: true,
        width: '523px',
        height: null,
        padding: '28px',
    };

    const publishModal: CustomModal = {
        title: 'Publish',
        showCrossBtn: true,
        width: '947px',
        height: 'max-content',
        padding: '49px 32px 0px 32px',
    };

    const defaultAlertData: AlertModel = {
        title: 'default',
        body: null,
        type: 'success',
        uiClass: 'top-right-alert',
    };

    // const { data, error, isLoading } = useGetWorksheetListQuery({});

    // const [createWorksheet, response] = useCreateWorksheetMutation();
    // const [createWorksheet, response] = createWorksheetApi();

    // const [deleteWorksheet, responseData] = useDeleteWorksheetMutation();



    const worksheetList = useSelector(
        (state: any) => state.worksheetsHome.worksheetList
    );

    const currentModalType = useSelector(
        (state: any) => state.worksheetsHome.currentModalType
    );

    const currentWorksheet = useSelector(
        (state: any) => state.worksheetsHome.currentWorksheet
    );

    const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
    const [alertData, setalertData] = useState(defaultAlertData);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const createFormValue: Worksheet = {
        id: '',
        name: '',
        thumbnailUrl: '',
        publishedStatus: '',
        isPublished: false,
        isLocked: false,
        labelId: '',
        label: {},
        mode: '',
        students: []
    };

    const dispatch = useDispatch<AppDispatch>(); //use AppDispatch for redux thunk

    const { isOpen, toggle } = UseModal();

    const { modalData, setModalData } = UseWorksheetsHomeModal();

    const handleHeaderNavBtnAction = (type: string) => {
        console.log('handle header nav btn action', type);
    };

    const gotoTemplateLibrary = () => {
        console.log('gotoTemplateLibrary');
        navigate('/templates');
    };

    const goToCreateWorksheet = () => {
        console.log('goToCreateWorksheet');
        dispatch(setModalType('createworksheet'));
        toggle();
        setModalData(createModalProps);
    };

    const renderModal = (type: string) => {
        console.log('render modal', type);
        switch (type) {
            case OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET:
                console.log('create worksheet----');
                return (
                    <CreateWorksheet
                        type={OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET}
                        worksheet={createFormValue}
                        clickCancel={cancelModal}
                        clickCreate={createNewWorksheet}
                    />
                );
            case OVERLAY_MENU_CONSTANTS.CHANGE_TITLE:
                console.log('change title----');
                return (
                    <ChangeTitle
                        type={OVERLAY_MENU_CONSTANTS.WORKSHEET}
                        worksheet={currentWorksheet}
                        clickCancel={cancelModal}
                        clickSave={changeWorksheetTitle}
                    ></ChangeTitle>
                );
            case OVERLAY_MENU_CONSTANTS.DELETE:
                console.log('delete----');
                return (
                    <DeleteWorksheet
                        type={OVERLAY_MENU_CONSTANTS.WORKSHEET}
                        worksheet={currentWorksheet}
                        clickCancel={cancelModal}
                        clickDelete={handleDeleteWorksheet}
                    ></DeleteWorksheet>
                );
            case OVERLAY_MENU_CONSTANTS.PUBLISH:
                console.log('publish----');
                return (
                    <PublishWorksheet
                        worksheet={currentWorksheet}
                        clickCancel={cancelModal}
                        clickAssign={handlePublishWorksheet}
                    ></PublishWorksheet>
                );
        }
    };

    const handleClickMenu = (type: string, item: Worksheet) => {
        console.log('handleClickMenu--------', type, item);
        dispatch(setModalType(type));
        dispatch(setCurrentWorksheet(item));
        switch (type) {
            case OVERLAY_MENU_CONSTANTS.USE_AS_TEMPLATE:
                useAsTemplate(item.id);
                break;
            case OVERLAY_MENU_CONSTANTS.PUBLISH:
                toggle();
                setModalData(publishModal);
                break;
            case OVERLAY_MENU_CONSTANTS.CHANGE_TITLE:
                toggle();
                setModalData(changeTitleModal);
                break;
            case OVERLAY_MENU_CONSTANTS.DELETE:
                toggle();
                setModalData(deleteModalProps);
                break;
            case OVERLAY_MENU_CONSTANTS.EDIT:
                navigate(`/editor/${item.id}`);
                break;
            case OVERLAY_MENU_CONSTANTS.UNPUBLISH:
                console.log('unpublish');
                editWorksheet(type, item, null, null);
                break;
            case OVERLAY_MENU_CONSTANTS.LOCK:
                console.log('lock');
                editWorksheet(type, item, null, null);
                break;
            case OVERLAY_MENU_CONSTANTS.UNLOCK:
                console.log('unlock');
                editWorksheet(type, item, null, null);
                break;
            default:
                console.log('default');
        }
    };

    const cancelModal = () => {
        console.log('cancel modal');
        toggle();
    };

    // const [deleteWorksheet, responseData] = deleteWorksheetApi();

    const handleDeleteWorksheet = async (worksheet: DeleteFormModel) => {
        console.log('deleteWorksheet', worksheet);
        try {
            await dispatch(deleteWorksheetApi(worksheet.id))
                .unwrap()
                .then((res: any) => {
                    toggle();
                    showMessage('Your worksheet has been deleted.', 'success');
                }, error => {
                    toggle();
                    showMessage('Fail to delete worksheet!', 'error');
                });
        } catch (err: any) {
            console.log(
                'handleDeleteWorksheet > error',
                `Fetch failed: ${err.message}`
            );
            toggle();
            showMessage('Fail to delete worksheet!', 'error');
        }
    };

    const changeWorksheetTitle = (worksheetTitle: string) => {
        console.log('changeWorksheetTitle', worksheetTitle);
        const updatedData: Worksheet = {
            id: currentWorksheet.id,
            name: worksheetTitle,
            thumbnailUrl: currentWorksheet.thumbnailUrl,
            publishedStatus: currentWorksheet.publishedStatus,
            isPublished: currentWorksheet.isPublished,
            isLocked: currentWorksheet.isLocked,
            labelId: currentWorksheet.labelId,
            label: currentWorksheet.label != undefined ? currentWorksheet.label : null,
            mode: currentWorksheet.mode,
            students: []
        };
        editWorksheet(OVERLAY_MENU_CONSTANTS.CHANGE_TITLE, updatedData, null, null);
        // toggle();
    };

    const callDeleteThumbnailApi = async (worksheetId: string, publishData: any, type: string) => {
        try {
            const deleteThumbnailApi = await dispatch(deleteThumbnail(worksheetId)).unwrap();
            console.log('deleteThumbnailApi', deleteThumbnailApi);
            publishWorksheet(publishData);
            // if (type == OVERLAY_MENU_CONSTANTS.PUBLISH)
            //     publishWorksheet(publishData);
            // else
            //     callCreateWorksheetApi(publishData, null);
        } catch (error) {
            console.log(error);
        }
    };


    const publishWorksheet = async (data: any) => {
        try {
            await dispatch(publishWorksheetApi(data))
                .unwrap()
                .then((res) => {
                    console.log('Worksheet is succesussfully published.', res);
                    toggle();
                    getWorksheetList();
                    showMessage('Your worksheet has been successfully published.', 'success');
                }, error => {
                    console.log('error', error);
                    toggle();
                    showMessage('Fail to publish worksheet!', 'error');
                });
        } catch (err: any) {
            console.log(
                'publishWorksheet > error',
                `Fetch failed: ${err.message}`
            );
            toggle();
            showMessage('Fail to publish worksheet!', 'error');
        }
    };

    const handlePublishWorksheet = (data: any, file: File | undefined, isDeleteThumbnail: boolean) => {
        console.log('handlePublishWorksheet', data, file, 'isDeleteThumbnail', isDeleteThumbnail);
        if (file == undefined) {
            if (!isDeleteThumbnail)
                publishWorksheet(data);
            else
                callDeleteThumbnailApi(currentWorksheet.id, data, OVERLAY_MENU_CONSTANTS.PUBLISH);
        } else {
            const worksheetForm = {
                id: data.id,
                name: data.name,
                labelId: data.labelId,
            };
            callUploadThumbnailApi(worksheetForm, file, OVERLAY_MENU_CONSTANTS.EDIT_THUMBNAIL, data);
        }
    };

    const handleSearch = (keyword: string | null) => {
        console.log('handleSearch', keyword);
        setSearchKeyword(keyword);
    };

    const callUploadThumbnailApi = async (worksheet: WorksheetFormModel, file: File, type: string, publishData: any) => {
        console.log('file---', file);
        const timestamp = Date.now();
        const formData = new FormData();
        formData.append(
            'file',
            file,
            timestamp.toString().concat('_', file.name)
        );
        console.log('formData:', formData);
        try {
            const uploadThumbnailApi = await dispatch(uploadThumbnail(formData)).unwrap();
            console.log('uploadThumbnailApi', uploadThumbnailApi);
            if (type == OVERLAY_MENU_CONSTANTS.CREATE_THUMBNAIL)
                callCreateWorksheetApi(worksheet, uploadThumbnailApi.url);
            else
                editWorksheet(OVERLAY_MENU_CONSTANTS.EDIT_THUMBNAIL, currentWorksheet, uploadThumbnailApi.url, publishData);
        } catch (error) {
            console.log(error);
        }
    };

    const callCreateWorksheetApi = async (worksheet: WorksheetFormModel, fileUrl: string | null) => {
        try {
            const data = getCreatePayload(worksheet, fileUrl);
            await dispatch(createWorksheetApi(data))
                .unwrap()
                .then((res: any) => {
                    console.log('Worksheet is succesussfully created.', res);
                    const worksheetId = res.meta._id;
                    console.log('worksheetId---', worksheetId);
                    navigate(`/editor/${worksheetId}`);
                    toggle();
                }, error => {
                    console.log('error', error);
                    toggle();
                    showMessage('Fail to create worksheet!', 'error');
                });
        } catch (error) {
            console.log(error);
            toggle();
            showMessage('Fail to create worksheet!', 'error');
        }
    };

    const showMessage = (message: string, messageType: string) => {
        const alertData: AlertModel = {
            title: message,
            body: null,
            type: messageType,
            uiClass: 'top-right-alert',
        };
        setalertData(alertData);
        setShowAlert(true);
    };

    const getCreatePayload = (worksheet: WorksheetFormModel, fileUrl: string | null) => {
        let createPayload = {};
        if (fileUrl == null)
            createPayload = {
                name: worksheet.name,
                lessonId: userInfo.lessonId,
                ownerId: userInfo.userid,
                // labelId: worksheet.labelId,
            };
        else
            createPayload = {
                name: worksheet.name,
                lessonId: userInfo.lessonId,
                ownerId: userInfo.userid,
                // labelId: worksheet.labelId,
                thumbnailUrl: fileUrl
            };
        return createPayload;
    };

    const createNewWorksheet = (worksheet: WorksheetFormModel, file: File | undefined, isDeleteThumbnail: boolean) => {
        console.log('createNewWorksheet------', worksheet, file, 'isDeleteThumbnail', isDeleteThumbnail);
        if (file == undefined) {
            callCreateWorksheetApi(worksheet, null);
        } else {
            callUploadThumbnailApi(worksheet, file, OVERLAY_MENU_CONSTANTS.CREATE_THUMBNAIL, null);
        }
    };

    const useAsTemplate = async (worksheetId: string) => {
        console.log('useAsTemplate---', worksheetId);
        // dispatch(useAsTemplateApi(worksheetId));
        try {
            await dispatch(useAsTemplateApi(worksheetId))
                .unwrap()
                .then((res: any) => {
                    console.log('Your worksheet has been used as template.');
                    showMessage('Your worksheet has been used as template.', 'success');
                });
        } catch (err: any) {
            console.log(
                'useAsTemplate > error',
                `Fetch failed: ${err.message}`
            );
        }
    };

    // for redux thunk
    const worksheets = useSelector(
        (state: any) => state.worksheetsHome.worksheets
    );

    const getWorksheetList = async () => {
        try {
            const worksheetData = await dispatch(getWorksheets()).unwrap();
            console.log(worksheetData);
        } catch (err: any) {
            console.log(
                'getWorksheetList > error',
                `Fetch failed: ${err.message}`
            );
        }
    };

    const editWorksheet = async (type: string, worksheet: Worksheet, url: string | null, publishData: any) => {
        console.log('editWorksheet', type, worksheet);
        const payload = getEditWorksheetPayload(type, worksheet, url);
        try {
            // const worksheetEditApi = await dispatch(
            //     editWorksheetApi({
            //         id: worksheet.id,
            //         payload: payload,
            //     })
            // ).unwrap();
            await dispatch(
                editWorksheetApi({
                    id: worksheet.id,
                    payload: payload,
                })
            ).unwrap()
                .then((res: any) => {
                    console.log('worksheetEditApi > success', res);
                    if (type == OVERLAY_MENU_CONSTANTS.CHANGE_TITLE) {
                        toggle();
                        showMessage('Worksheet title successfully changed.', 'success');
                    }
                    if (type != OVERLAY_MENU_CONSTANTS.EDIT_THUMBNAIL)
                        getWorksheetList();
                    else
                        publishWorksheet(publishData);
                }, error => {
                    if (type == OVERLAY_MENU_CONSTANTS.CHANGE_TITLE) {
                        toggle();
                        showMessage('Fail to change title worksheet!', 'error');
                    }
                    else showMessage('Fail to ' + type + 'worksheet!', 'error');
                });
        } catch (error) {
            if (error) console.error(error);
        }
    };

    const getEditWorksheetPayload = (type: string, worksheet: Worksheet, url: string | null) => {
        let editPayload = {};
        switch (type) {
            case OVERLAY_MENU_CONSTANTS.CHANGE_TITLE:
                console.log('CHANGE_TITLE');
                editPayload = {
                    name: worksheet.name
                };
                break;
            case OVERLAY_MENU_CONSTANTS.UNPUBLISH:
                console.log('UNPUBLISH');
                editPayload = {
                    publishedStatus: 'draft',
                };
                break;
            case OVERLAY_MENU_CONSTANTS.LOCK:
                console.log('LOCK');
                editPayload = {
                    isLocked: true
                };
                break;
            case OVERLAY_MENU_CONSTANTS.UNLOCK:
                console.log('UNLOCK');
                editPayload = {
                    isLocked: false
                };
                break;
            case OVERLAY_MENU_CONSTANTS.EDIT_THUMBNAIL:
                console.log('EDIT_THUMBNAIL');
                editPayload = {
                    thumbnailUrl: url
                };
                break;
            default:
                console.log('default');
        }
        return editPayload;
    };

    // for redux thunk
    useEffect(() => {
        getWorksheetList();
        if (action) {
            goToCreateWorksheet();
        }
    }, [dispatch]);

    return (
        <>
            {showAlert && (
                <CustomAlert
                    alertData={alertData}
                    alertClose={() => setShowAlert(false)}
                ></CustomAlert>
            )}

            <Modal
                modalProps={modalData}
                isOpen={isOpen}
                toggle={toggle}
                cancelModal={cancelModal}
                currentModalType={currentModalType}
            >
                {isOpen && renderModal(currentModalType)}
            </Modal>
            <div>
                <HeaderNavbar handleNavBtnAction={handleHeaderNavBtnAction} />
                <div className="list-wrapper">
                    <ListHeader />
                    <div className="mt-40">
                        {/* <CreateWorksheet handelCreate={getWorksheets} /> */}
                        <Button
                            btnProps={createBtnProps}
                            handleBtnClicked={goToCreateWorksheet}
                        />
                        <Button
                            btnProps={libraryBtnProps}
                            handleBtnClicked={gotoTemplateLibrary}
                        />
                    </div>
                    <div className="mt-106">
                        <div className="h3-strong text-c300 mb-32">Recent</div>
                    </div>
                    <SearchInput
                        hint={'Search worksheet'}
                        handleSearch={handleSearch}
                    />
                </div>
                {worksheets.error ? (
                    <>Oh no, there was an error</>
                ) : worksheets.loading === 'pending' ? (
                    <div className='list-wrapper'><HomeLoading></HomeLoading></div>
                ) : worksheets.data ? (
                    <>
                        <List
                            worksheetList={worksheets.data}
                            keyword={searchKeyword}
                            clickMenu={handleClickMenu}
                        />
                    </>
                ) : null}
            </div>
        </>
    );
};

export default WorksheetsHome;
