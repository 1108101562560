import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import CustomLoading from '../../global-components/loading/loading';
import './home-loading.css';

// export interface IProps {
// }
const HomeLoading = () => {
    const listArr = [1, 2, 3, 4, 5];

    return (
        <>
            <div className="loading-list">
                {listArr.map((index: any) => (
                    <div key={index} className='home-loading-wrapper'>
                        <div>
                            <div className='cover-loading-wrapper'>
                                <CustomLoading loadingData='listPosterLoading'></CustomLoading>
                                <CustomLoading loadingData='pencilLoading'></CustomLoading>
                            </div>
                            <CustomLoading loadingData='listNameLoading1'></CustomLoading>
                            <CustomLoading loadingData='listNameLoading2'></CustomLoading>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default HomeLoading;