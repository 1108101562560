export class Group {
    constructor(
        public id: string,
        public name: string,
        public icon: any,
        public label: {
            id: string;
            name: string;
        },
        public students: Array<any>,
        public checked: boolean = false
    ) {}
}
