import { WorksheetModel } from '../../../models/worksheet.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DraggableElement } from '../../../models/draggable-element.model';
import { PointModel } from '../../../models/point.model';
import { FONT_CONSTANTS } from '../../../constants/fontConstants';
import { DropdownModel } from '../../../models/dropdown.model';
import { FONT_WEIGHT_CONSTANTS } from '../../../constants/fontWeightConstants';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../constants/toolbarBtnActionConstants';
import { worksheetListApi } from '../../../api/worksheet/worksheetListApi';
import { StudentListModel } from '../../../models/student-list.model';
import {
    getTemplateDetail,
    getWorksheetDetail,
} from '../../../api/worksheet/teacherApi';
interface WorksheetState {
    worksheet: WorksheetModel;
    currentDragItemType: string | null;
    mobileDragPosition: PointModel;
    mobileDragStart: boolean;
    deviceType: string;
    fontList: DropdownModel[];
    fontWeightList: DropdownModel[];
    fontSizeList: DropdownModel[];
    currentItem: DraggableElement | null;
    editItem: boolean;
    deleteItem: boolean;
    editItemType: string | null;
    lastId: number;
    isEditLayout: boolean;
    existBackground: DraggableElement | null;
    studentList: StudentListModel[] | [];
    isText: boolean;
    canvasWidth: number;
    canvasHeight: number;

    loading: string;
    error: string | null;

    totalBase: number;
    totalScore: number;

    currentModalType: string | null;
    isQRcode: boolean;
}

const generatedFontList = () => {
    const fontList: DropdownModel[] = [];
    FONT_CONSTANTS.fonts.map((font: string, index: number) => {
        const fontObj: DropdownModel = {
            id: index.toString(),
            name: font,
        };
        fontList.push(fontObj);
    });
    return fontList;
};

const generatedFontWeightList = () => {
    const fontWeightList: DropdownModel[] = [];
    FONT_WEIGHT_CONSTANTS.fonts.map((fontWeight: string, index: number) => {
        const fontWeightObj: DropdownModel = {
            id: index.toString(),
            name: fontWeight,
        };
        fontWeightList.push(fontWeightObj);
    });
    return fontWeightList;
};

const generateFontSizeList = () => {
    const fontSizeList: DropdownModel[] = [];
    const numArray = Array.from(Array(100).keys());
    numArray.map((num, index) => {
        const fontSizeObj: DropdownModel = {
            id: index.toString(),
            name: num.toString(),
        };
        fontSizeList.push(fontSizeObj);
    });
    return fontSizeList;
};

const initialState = {
    worksheet: {
        id: '',
        name: '',
        studentGroup: '',
        publishedStatus: '',
        components: [],
        componentGroup: [],
        createdAt: '',
        updatedAt: '',
        submissionCount: 0,
        isLocked: false,
    },
    currentDragItemType: null,
    deviceType: '',
    mobileDragPosition: { x: 0, y: 0 },
    mobileDragStart: false,
    fontList: generatedFontList(),
    fontWeightList: generatedFontWeightList(),
    fontSizeList: generateFontSizeList(),
    currentItem: null,
    editItem: false,
    deleteItem: false,
    editItemType: null,
    lastId: 0,
    isEditLayout: true,
    isQRcode: false,
    existBackground: null,
    studentList: [],
    isText: true,
    canvasWidth: 720,
    canvasHeight: 720,

    error: null,
    loading: 'idle',

    totalBase: 0,
    totalScore: 0,

    currentModalType: null,
} as WorksheetState;

export const worksheetSlice: any = createSlice({
    name: 'worksheet',
    initialState,
    reducers: {
        addComponent: (state, action: PayloadAction<DraggableElement>) => {
            const { id, type, location, size, data } = action.payload;

            const element: DraggableElement = {
                id: id,
                type: type,
                location: location,
                size: size,
                data: data,
            };
            if (element.type === TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND) {
                state.existBackground = element;
            }
            state.worksheet.components.push(element);
            if (element.location.x + element.size.width > state.canvasWidth)
                state.canvasWidth =
                    element.location.x + element.size.width + 500;
            if (element.location.y + element.size.height > state.canvasHeight)
                state.canvasHeight =
                    element.location.y + element.size.height + 500;
            // const typeList = [
            //     TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY,
            //     TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO,
            //     TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND,
            //     TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO,
            //     TOOLBAR_BTN_ACTION_CONSTANTS.GRADING,
            // ];
            // if (!typeList.includes(element.type)) {
            //     state.currentItem = element;
            // }
            state.currentItem = element;

            state.editItem = true;

            console.log('calculateScore');
            let maxWidth = state.canvasWidth;
            let maxHeight = state.canvasHeight;
            let maxBase = 0;
            let maxScore = 0;
            state.worksheet.components.map((element: DraggableElement) => {
                if (
                    element.location.x + element.size.width + 100 >
                    state.canvasWidth
                )
                    maxWidth = element.location.x + element.size.width + 500;
                if (
                    element.location.y + element.size.height + 100 >
                    state.canvasHeight
                )
                    maxHeight = element.location.y + element.size.height + 500;

                //calculate score
                if (element.type == TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
                    maxBase += element.data.score.base
                        ? element.data.score.base
                        : 0;
                    maxScore += element.data.score.mark
                        ? element.data.score.mark
                        : 0;
                }
            });
            state.canvasWidth = maxWidth;
            state.canvasHeight = maxHeight;
            state.totalBase = maxBase;
            state.totalScore = maxScore;
            console.log('ful fill ', state.canvasHeight);
        },
        setCurrentDragItemType: (state, action: PayloadAction<string>) => {
            state.currentDragItemType = action.payload;
        },
        updateComponentPosition: (state, action: PayloadAction<any>) => {
            const { position, id } = action.payload;
            state.worksheet.components.map((component: DraggableElement) => {
                if (component.id === id && !state.editItem) {
                    component.location = position;
                    console.log(
                        'update position',
                        component.location,
                        position
                    );
                    state.currentItem = component;
                }
            });
        },
        updateComponentSize: (state, action: PayloadAction<any>) => {
            const { size, id } = action.payload;
            state.worksheet.components.map((component: DraggableElement) => {
                if (component.id === id && !state.editItem) {
                    component.size = size;
                    console.log('update size', component.size);
                    state.currentItem = component;
                }
            });
        },
        updateComponentData: (state, action: PayloadAction<any>) => {
            const { data, id } = action.payload;
            state.worksheet.components.map((component: DraggableElement) => {
                if (component.id === id) {
                    component.data = data;
                    console.log('update data', component.data);
                    state.currentItem = component;
                    if (
                        state.currentDragItemType !==
                        TOOLBAR_BTN_ACTION_CONSTANTS.TEXT
                    ) {
                        state.editItem = false;
                    }
                }
            });
        },
        resetWorksheet: (state, action: PayloadAction) => {
            state.worksheet = {
                id: '',
                name: '',
                studentGroup: '',
                publishedStatus: '',
                components: [],
                componentGroup: [],
                createdAt: '',
                updatedAt: '',
                isLocked: false,
            };
        },
        setMobileDragPosition: (state, action: PayloadAction<PointModel>) => {
            state.mobileDragPosition = action.payload;
        },
        setDeviceType: (state, action: PayloadAction<string>) => {
            state.deviceType = action.payload;
        },
        toggleMobileDragStart: () => {
            console.log('drag start');
        },
        setCurrentItem: (
            state,
            action: PayloadAction<DraggableElement | null>
        ) => {
            console.log('set id');
            state.currentItem = action.payload;
            if (action.payload === null) {
                state.editItemType = null;
                state.editItem = false;
            }
            console.log(state.currentItem);
        },
        toggleEditItem: (state, action: PayloadAction<string | null>) => {
            console.log('toggleEditItem ', action.payload);
            const origin = state.editItem;
            console.log('origin', origin);
            state.editItem = action.payload ? true : false;
            if (state.editItem) {
                state.editItemType = action.payload;
            }
            console.log('new', state.editItem);
        },
        deleteBackground: (state) => {
            state.worksheet.components.splice(
                state.worksheet.components.findIndex(
                    (arrow) => arrow.id === state.existBackground?.id
                ),
                1
            );

            setCurrentItem(null);
            state.editItemType = null;
            state.existBackground = null;
        },
        toggleDeleteItem: (state) => {
            state.worksheet.components.splice(
                state.worksheet.components.findIndex(
                    (arrow) => arrow.id === state.currentItem!.id
                ),
                1
            );

            setCurrentItem(null);
            state.editItemType = null;

            console.log('calculateScore');
            let maxWidth = state.canvasWidth;
            let maxHeight = state.canvasHeight;
            let maxBase = 0;
            let maxScore = 0;
            state.worksheet.components.map((element: DraggableElement) => {
                if (
                    element.location.x + element.size.width + 100 >
                    state.canvasWidth
                )
                    maxWidth = element.location.x + element.size.width + 500;
                if (
                    element.location.y + element.size.height + 100 >
                    state.canvasHeight
                )
                    maxHeight = element.location.y + element.size.height + 500;

                //calculate score
                if (element.type == TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
                    maxBase += element.data.score.base
                        ? element.data.score.base
                        : 0;
                    maxScore += element.data.score.mark
                        ? element.data.score.mark
                        : 0;
                }
            });
            state.canvasWidth = maxWidth;
            state.canvasHeight = maxHeight;
            state.totalBase = maxBase;
            state.totalScore = maxScore;
            console.log('ful fill ', state.canvasHeight);
        },
        setFont: (state: any, action: PayloadAction<string>) => {
            if (state.isText) {
                state.currentItem.data.style.fontFamily = action.payload;
            } else {
                state.currentItem.data.descStyle.fontFamily = action.payload;
            }
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setFontWeight: (state: any, action: PayloadAction<string>) => {
            if (state.isText) {
                state.currentItem.data.style.fontWeight = action.payload;
            } else {
                state.currentItem.data.descStyle.fontWeight = action.payload;
            }

            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setFontSize: (state: any, action: PayloadAction<string>) => {
            if (state.isText) {
                state.currentItem.data.style.fontSize = action.payload + 'px';
            } else {
                state.currentItem.data.descStyle.fontSize =
                    action.payload + 'px';
            }
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setFontStyle: (state: any, action: PayloadAction<boolean>) => {
            if (state.isText) {
                state.currentItem.data.style.fontStyle = action.payload
                    ? 'italic'
                    : '';
            } else {
                state.currentItem.data.descStyle.fontStyle = action.payload
                    ? 'italic'
                    : '';
            }
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setFontColor: (state: any, action: PayloadAction<string>) => {
            console.log('update color');
            if (state.isText) {
                state.currentItem.data.style.fontColor = action.payload;
            } else {
                state.currentItem.data.descStyle.fontColor = action.payload;
            }
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setBorder: (state: any, action: PayloadAction<string>) => {
            console.log('update border');
            state.currentItem.data.style.border = action.payload;
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setBackground: (state: any, action: PayloadAction<string>) => {
            console.log('update background');
            state.currentItem.data.style.background = action.payload;
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setIsBack: (state: any, action: PayloadAction<boolean>) => {
            console.log('update background');
            state.currentItem.data.isBack = action.payload;
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setTextDecoration: (state: any, action: PayloadAction<boolean>) => {
            console.log('update decoration');
            if (state.isText) {
                state.currentItem.data.style.textDecoration = action.payload
                    ? 'underline'
                    : '';
            } else {
                state.currentItem.data.descStyle.textDecoration = action.payload
                    ? 'underline'
                    : '';
            }
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setText: (state, action: PayloadAction<string>) => {
            console.log('update text');
            if (state.currentItem) {
                if (state.isText) {
                    state.currentItem.data.text = action.payload;
                } else {
                    state.currentItem.data.descText = action.payload;
                }
                const index = state.worksheet.components.findIndex(
                    (component: DraggableElement) =>
                        state.currentItem &&
                        component.id === state.currentItem.id
                );
                state.worksheet.components[index] = state.currentItem;
            }
        },
        setPopupText: (state, action: PayloadAction<any>) => {
            const { text, descText } = action.payload;
            console.log('update text', state.isText);
            if (state.currentItem) {
                state.currentItem.data.text = text;
                state.currentItem.data.descText = descText;
                // const index = state.worksheet.components.findIndex(
                //     (component: DraggableElement) =>
                //         state.currentItem &&
                //         component.id === state.currentItem.id
                // );
                // state.worksheet.components[index] = state.currentItem;
            }
        },
        toggleEditLayout: (state: any) => {
            state.isEditLayout = !state.isEditLayout;
        },
        toggleQRcode: (state: any) => {
            state.isQRcode = !state.isQRcode;
        },
        setPopupIconType: (state: any, action: PayloadAction<string>) => {
            state.currentItem.data.iconType = action.payload;
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === state.currentItem.id
            );
            state.worksheet.components[index] = state.currentItem;
        },
        setExistBackground: (
            state: any,
            action: PayloadAction<DraggableElement | null>
        ) => {
            state.existBackground = action.payload;
        },
        checkedStudent: (
            state: any,
            action: PayloadAction<StudentListModel[]>
        ) => {
            state.studentList = action.payload;
        },
        setIsText: (state: any, action: PayloadAction<boolean>) => {
            console.log('set is Text :', action.payload);
            state.isText = action.payload;
        },
        setCanvas: (state: any, action: PayloadAction<any>) => {
            const { width, height } = action.payload;
            if (width > 0) state.canvasWidth = width;
            if (height > 0) state.canvasHeight = height;
        },
        setLastId: (state: any, action: PayloadAction<string>) => {
            state.lastId = action.payload;
            console.log('set last id . . . . . . : ', state.lastId);
        },
        updateComponentById: (
            state: any,
            action: PayloadAction<DraggableElement>
        ) => {
            const index = state.worksheet.components.findIndex(
                (component: DraggableElement) =>
                    component.id === action.payload.id
            );
            state.worksheet.components[index] = action.payload;

            console.log('calculateScore');
            let maxWidth = state.canvasWidth;
            let maxHeight = state.canvasHeight;
            let maxBase = 0;
            let maxScore = 0;
            state.worksheet.components.map((element: DraggableElement) => {
                if (
                    element.location.x + element.size.width + 100 >
                    state.canvasWidth
                )
                    maxWidth = element.location.x + element.size.width + 500;
                if (
                    element.location.y + element.size.height + 100 >
                    state.canvasHeight
                )
                    maxHeight = element.location.y + element.size.height + 500;

                //calculate score
                if (element.type == TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
                    maxBase += element.data.score.base
                        ? element.data.score.base
                        : 0;
                    maxScore += element.data.score.mark
                        ? element.data.score.mark
                        : 0;
                }
            });
            state.canvasWidth = maxWidth;
            state.canvasHeight = maxHeight;
            state.totalBase = maxBase;
            state.totalScore = maxScore;
            console.log('ful fill ', state.canvasHeight);
        },
        toggleLockStatus: (state) => {
            state.worksheet.isLocked = !state.worksheet.isLocked;
        },
        setModalType: (state, action: PayloadAction<string | null>) => {
            state.currentModalType = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWorksheetDetail.pending, (state, action) => {
            if (state.loading === 'idle') {
                console.log('state.worksheets.loading === idle');
                state.loading = 'pending';
            }
        });
        builder.addCase(getWorksheetDetail.fulfilled, (state, action) => {
            state.worksheet = action.payload;
            let maxWidth = state.canvasWidth;
            let maxHeight = state.canvasHeight;
            let maxBase = 0;
            let maxScore = 0;
            state.worksheet.components.map((element: DraggableElement) => {
                //surplus 100
                if (
                    element.location.x + element.size.width + 100 >
                    state.canvasWidth
                )
                    maxWidth = element.location.x + element.size.width + 500;
                if (
                    element.location.y + element.size.height + 100 >
                    state.canvasHeight
                )
                    maxHeight = element.location.y + element.size.height + 500;

                if (element.type === TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND) {
                    state.existBackground = element;
                }

                //calculate score
                if (element.type == TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
                    maxBase += element.data.score.base
                        ? element.data.score.base
                        : 0;
                    maxScore += element.data.score.mark
                        ? element.data.score.mark
                        : 0;
                }
            });
            state.canvasWidth = maxWidth;
            state.canvasHeight = maxHeight;
            state.totalBase = maxBase;
            state.totalScore = maxScore;
            console.log('ful fill ', state.canvasHeight);
        });
        builder.addCase(getWorksheetDetail.rejected, (state, action) => {
            if (state.loading === 'pending') {
                console.log('state.worksheets.loading === pending');
                state.loading = 'idle';
                state.error = 'Error occured';
            }
        });
        //template
        builder.addCase(getTemplateDetail.pending, (state, action) => {
            if (state.loading === 'idle') {
                console.log('state.worksheets.loading === idle');
                state.loading = 'pending';
            }
        });
        builder.addCase(getTemplateDetail.fulfilled, (state, action) => {
            state.worksheet = action.payload;
            let maxWidth = state.canvasWidth;
            let maxHeight = state.canvasHeight;
            let maxBase = 0;
            let maxScore = 0;
            state.worksheet.components.map((element: DraggableElement) => {
                if (
                    element.location.x + element.size.width + 100 >
                    state.canvasWidth
                )
                    maxWidth = element.location.x + element.size.width + 500;
                if (
                    element.location.y + element.size.height + 100 >
                    state.canvasHeight
                )
                    maxHeight = element.location.y + element.size.height + 500;

                if (element.type === TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND) {
                    state.existBackground = element;
                }

                //calculate score
                if (element.type == TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
                    maxBase += element.data.score.base
                        ? element.data.score.base
                        : 0;
                    maxScore += element.data.score.mark
                        ? element.data.score.mark
                        : 0;
                }
            });
            state.canvasWidth = maxWidth;
            state.canvasHeight = maxHeight;
            state.totalBase = maxBase;
            state.totalScore = maxScore;
            console.log('ful fill ', state.canvasHeight);
        });
        builder.addCase(getTemplateDetail.rejected, (state, action) => {
            if (state.loading === 'pending') {
                console.log('state.worksheets.loading === pending');
                state.loading = 'idle';
                state.error = 'Error occured';
            }
        });
        //template
        builder.addMatcher(
            worksheetListApi.endpoints.getStudentListById.matchFulfilled,
            (state, action) => {
                state.studentList = action.payload;
            }
        );
    },
});

export const {
    addComponent,
    setCurrentDragItemType,
    updateComponentPosition,
    updateComponentSize,
    updateComponentData,
    setMobileDragPosition,
    setDeviceType,
    setCurrentItem,
    toggleEditItem,
    toggleDeleteItem,
    setFont,
    setFontSize,
    setFontWeight,
    setFontColor,
    setFontStyle,
    setTextDecoration,
    toggleEditLayout,
    setPopupIconType,
    setExistBackground,
    checkedStudent,
    setIsText,
    setCanvas,
    setLastId,
    setText,
    updateComponentById,
    deleteBackground,
    setPopupText,
    toggleLockStatus,
    setModalType,
    toggleQRcode,
    setBorder,
    setBackground,
    setIsBack,
    resetWorksheet
} = worksheetSlice.actions;

export default worksheetSlice.reducer;
