import React, { FC, useEffect, useRef } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';
export interface IProps {
    component: DraggableElement;
    handleItemClicked: () => void;
    editItem: boolean;
    isEditLayout: boolean;
}
const AudioTool: FC<IProps> = ({
    component,
    handleItemClicked,
    editItem,
    isEditLayout,
}) => {
    const ref = useRef<HTMLAudioElement>(null);

    const clickedItem = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        handleItemClicked();
    };

    useEffect(() => {
        console.log('change component', component.data.url);
        if (ref.current) {
            ref.current.load();
        }
    }, [component.data.url]);

    return (
        <>
            {component.data.url && (
                <div
                    onClick={clickedItem}
                    style={{
                        width: 'fit-content',
                        height: 'fit-content',
                    }}
                >
                    <audio
                        controls
                        ref={ref}
                        key={component.id}
                        style={{
                            pointerEvents: isEditLayout ? 'none' : 'all',
                        }}
                    >
                        <source src={component.data.url} type="audio/mp3" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            )}
        </>
    );
};

export default AudioTool;
