import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { Font } from '../../../../models/font.model';
import FeedbackIcon from '../../../../assets/images/feedback_icon_red.svg';
import './feedback-tool.css';
import CancelStrokeIcon from '../../../../assets/images/cancel_stroke.svg';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import IconButton from '../../../../global-components/icon-button/IconButton';
import { AppDispatch } from '../../../../store/store';
import { setCurrentItem } from '../../../view-submission/view-submission-slice';

export interface IProps {
    component: DraggableElement;
    handleItemClicked: () => void;
    editItem: boolean;
    readonly: boolean;
    updateActiveItem: (isText: boolean) => void;
    handleFeedbackChange: (feedback: DraggableElement) => void;
}

const FeedbackTool: FC<IProps> = ({
    component,
    handleItemClicked,
    editItem,
    readonly,
    updateActiveItem,
    handleFeedbackChange,
}) => {
    const titleRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    // const [editText, setEditText] = useState<boolean>(false);
    // const [editHintText, setEditHintText] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();

    const [feedback, setFeedback] = useState<string | null>(null);
    console.log('RenderingFeedbackTool', component);

    const cancelBtnProps: IconBtnModel = {
        icon: CancelStrokeIcon,
        alt: 'cancelStroke',
        uiClass: 'cancel-popup',
        disable: false,
        draggable: false,
    };

    const handleTextChange = (e: any) => {

        setFeedback(e.target.value);

        if (e.target.value) {
            //handleFeedbackTextChange(gatherComponentData(e.target.value));
            dispatch(setCurrentItem(gatherComponentData(e.target.value)));

        }

    };

    const handleFocusOut = (e: any) => {

        if (feedback && handleFeedbackChange) {
            handleFeedbackChange(gatherComponentData(feedback));
        }

    };

    const gatherComponentData = (feedbackText: string) => {
        const comp: DraggableElement = {
            id: component.id,
            type: component.type,
            location: component.location,
            size: component.size,
            data: {
                text: feedbackText ? feedbackText : '',
                score: {
                    base: 0,
                    mark: 0,
                },
                url: component.data.url,
                chatLog: component.data.chatLog,
                style: component.data.style,
                iconType: component.data.iconType,
                descText: component.data.descText,
                descStyle: component.data.descStyle,
                isBack: component.data.isBack,
            }
        };

        return comp;
    };

    const clickedItem = () => {
        handleItemClicked();
    };

    const handleBtnClicked = () => {
        //
    };

    const iconBtnProps: IconBtnModel = {
        icon: FeedbackIcon,
        alt: 'Feedback',
        uiClass: 'feedback-component-icon',
        disable: false,
        draggable: false,
    };

    const idForTextTool = 'component-' + component.id;
    const fontStyle: Font | null = component.data.style;
    const descFontStyle: Font | null = component.data.descStyle;

    const mapFontWeight = () => {
        if (component.data.style) {
            switch (component.data.style.fontWeight) {
                case 'Light':
                    return '300';
                case 'Regular':
                    return '400';
                case 'Medium':
                    return '500';
            }
        }
        return '400';
    };

    const mapDescFontWeight = () => {
        if (component.data.style) {
            switch (component.data.style.fontWeight) {
                case 'Light':
                    return '300';
                case 'Regular':
                    return '400';
                case 'Medium':
                    return '500';
            }
        }
        return '400';
    };

    const handleTextClicked = (type: string) => {
        if (type === 'text') {
            updateActiveItem(true);
        } else {
            updateActiveItem(false);
        }
    };

    useEffect(() => {
        document.body.style.cursor = 'auto';
        titleRef.current && titleRef.current.focus();
        updateActiveItem(true);
    }, []);

    useEffect(() => {

        setFeedback(component.data.text ? component.data.text : null);

    }, [component]);

    // return (
    //     <div>
    //         {component.data.text}
    //     </div>
    // );

    return (
        <>
            <div
                id={idForTextTool}
                className="feedback-tool"
                onInput={handleTextChange}
                suppressContentEditableWarning={true}
                onClick={clickedItem}
            >
                <IconButton
                    btnProps={iconBtnProps}
                    handleBtnClicked={handleBtnClicked}
                />
                <div
                // className="popup"

                // style={{
                //     visibility:
                //         currentItem && currentItem.id === component.id
                //             ? 'visible'
                //             : 'hidden',
                // }}
                >
                    <textarea
                        id='feedack-input'
                        className='feedback-content'
                        placeholder='Add feedback'
                        onChange={handleTextChange}
                        onBlur={handleFocusOut}
                        value={feedback ? feedback : ''}
                        readOnly={readonly}
                        style={{
                            width: component.size.width - 86,
                            height: component.size.height - 26
                        }}>
                    </textarea>
                </div>
            </div>
        </>
    );
};

export default FeedbackTool;
