import { upload } from '@testing-library/user-event/dist/upload';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { ElementDataModel } from '../../../../models/element-data.model';
import { PointModel } from '../../../../models/point.model';
import {
    addComponent,
    setCurrentDragItemType,
    toggleEditItem,
} from '../../../teacher/slices/worksheetSlice';
import './audio-upload.css';
export interface IProps {
    closeModal: () => void;
    modalLoading: boolean;
    uploadComponent: (file: File | undefined) => void;
}
const AudioUpload: FC<IProps> = ({
    closeModal,
    modalLoading,
    uploadComponent
}) => {
    const dispatch = useDispatch();

    const galleryBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Upload',
        disable: false,
        btnText: 'Upload video',
        uiClass: 'gallery-upload',
        iconClass: undefined,
    };

    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Cancel',
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel',
        iconClass: undefined,
    };

    const insertBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Insert',
        disable: false,
        btnText: 'Insert',
        uiClass: 'insert',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Insert',
        uiClass: 'disabled',
        iconClass: undefined,
        loadingText: 'Updating'
    };

    const [uploadItem, setUploadItem] = useState<File | undefined>(undefined);
    const [url, setUrl] = useState<string | null>(null);
    const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    const [uploadError, setUploadError] = useState<boolean>(false);
    const [recordAudio, setRecordAudio] = useState<boolean>(false);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [blobUrl, setBlobUrl] = useState<string>('');
    const audioRef = useRef<HTMLVideoElement>(null);
    const recordBtnRef = useRef<HTMLButtonElement>(null);
    const stopBtnRef = useRef<HTMLButtonElement>(null);
    const [streamData, setStreamData] = useState<MediaStream | null>(null);
    const { isMobile } = useSelector((state: any) => state.student);

    const handleInputChange = (e: any) => {
        console.log(e.target.files);
        const element = e.target.files[0];
        const url = URL.createObjectURL(e.target.files[0]);
        console.log('url', url, element);
        if (element.type.includes('audio')) {
            setUploadItem(element);
            setUrl(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
        // const audio: HTMLAudioElement = document.createElement('audio');

        // audio.onload = function () {
        //     // setElementSize({ width: audio.width, height: audio.height });
        //     // console.log({ width: audio.width, height: audio.height });
        // };
        // audio.src = url;
    };

    const updateElement = () => {
        // console.log('update');
        // const data: ElementDataModel = {
        //     text: null,
        //     score: {
        //         mark: null,
        //         base: null,
        //     },
        //     url: uploadItem,
        //     chatLog: null,
        //     style: null,
        //     iconType: null,
        //     descText: null,
        //     descStyle: null,
        // };
        // handleDataUpdate(data);
        // closeModal();
    };

    const renderElement = () => {
        console.log('render');
        uploadComponent(uploadItem);
    };

    const handleBtnClicked = (type: string | undefined) => {
        console.log('handleBtnClicked', type);
        switch (type) {
            case 'Cancel':
                closeModal();
                if (streamData) {
                    streamData.getTracks() // get all tracks from the MediaStream
                        .forEach(track => track.stop()); // stop each of them
                }
                break;
            case 'Upload':
                console.log('hello');
                break;
            case 'Insert':
                renderElement();
                // if (editItem) {
                //     updateElement();
                // } else {
                //     renderElement();
                // }

                break;
            default:
                closeModal();
        }
    };

    const handleOnLoad = (e: any) => {
        console.log(e.width, e.height);
    };

    const handleOnDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const url = URL.createObjectURL(e.dataTransfer.files[0]);
        const element = e.dataTransfer.files[0];
        if (element.type.includes('audio')) {
            setUploadItem(element);
            setUrl(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const handleOnDragEnter = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragLeave = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleRecordAudio = () => {
        setRecordAudio(true);
        const getStreamUrl = () => {
            navigator.mediaDevices
                .getUserMedia({ audio: true, video: false }) //for record
                .then((stream) => {
                    const mediaRecorder = new MediaRecorder(stream);
                    let parts: any[] = [];
                    setStreamData(stream);
                    try {
                        console.info('record start');
                        if (mediaRecorder) {
                            mediaRecorder.start(1000);
                            mediaRecorder.ondataavailable = (e: any) => {
                                parts.push(e.data);
                            };
                            console.log('Recording . . . .', mediaRecorder);
                            setIsRecording(true);
                        }
                    } catch (e) {
                        console.log(e);
                    }

                    if (stopBtnRef && stopBtnRef.current) {
                        const stopBtn = stopBtnRef.current;
                        stopBtn.addEventListener('click', (e: any) => {
                            if (mediaRecorder) {
                                console.info('record stop');
                                mediaRecorder.stop();
                                stream.getTracks() // get all tracks from the MediaStream
                                    .forEach(track => track.stop()); // stop each of them
                                const blob = new Blob(parts, {
                                    type: 'audio/webm',
                                });
                                const myBlob = new Blob(parts, {
                                    type: 'audio/mp3',
                                });
                                const url = URL.createObjectURL(myBlob);
                                // const a = document.createElement('a');
                                // a.style.display = 'none';
                                // a.href = url;
                                // a.download = 'aungpo.mp4';
                                // a.click();
                                // console.log('Recording stop . . . .');
                                setIsRecording(false);
                                parts = [];
                                setBlobUrl(url);
                                const file = new File([blob], 'recording.webm', { type: blob.type, lastModified: Date.now() });
                                setUploadItem(file);
                                console.log('====================================');
                                console.log('file', file);
                                console.log('====================================');
                                // calculateDimension(url);
                            }
                        });
                    }
                });
        };

        getStreamUrl();
    };

    useEffect(() => {


        return () => {
            if (streamData) {
                streamData.getTracks() // get all tracks from the MediaStream
                    .forEach(track => track.stop()); // stop each of them
            }
        };
    }, []);


    return (
        <>
            {uploadItem && !recordAudio && (
                <div className="upload-item bg-s10">
                    <audio controls>
                        <source src={url ? url : ''} type="audio/mp3" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            )}
            {
                recordAudio && (
                    <div className="live-item bg-s10">
                        {blobUrl.length == 0 && <div className="live-audio-frame"></div>}
                        {blobUrl.length > 0 && (
                            <audio className="live-audio" controls src={blobUrl}></audio>
                        )}
                        {blobUrl.length == 0 && <div className="live-item-btn">
                            <button ref={stopBtnRef} className="stop-btn text-s0 body-m">Stop</button>
                        </div>}

                        {isRecording && (<span className="recording-text body-m">Recording...</span>)}

                    </div>
                )
            }
            {!uploadItem && !recordAudio && (
                <div
                    className="drag-drop bg-s10"
                    onDrop={handleOnDrop}
                    onDragEnter={handleOnDragEnter}
                    onDragLeave={handleOnDragLeave}
                    onDragOver={handleOnDragOver}
                >
                    <div className="btn-gp-for-modal">
                        {!isMobile && <label className=" body-m text-s0">
                            <input type="file" accept=".mp3" onChange={handleInputChange} />
                            Upload audio
                        </label>}
                        <button className=" text-s0 body-m" onClick={handleRecordAudio}>
                            Record audio
                        </button>

                    </div>

                    <div className="text-s70 body-xl placeholder">
                        {uploadError
                            ? 'File type is wrong or corrupted. Please upload another.'
                            : 'Drag & drop audios here'}
                    </div>
                </div>
            )}

            <div className="modal-btn-group">
                <Button
                    btnProps={cancelBtnProps}
                    handleBtnClicked={() =>
                        handleBtnClicked(cancelBtnProps.alt)
                    }
                ></Button>
                {uploadItem && (
                    <Button
                        btnProps={
                            modalLoading ? disabledBtnProps : insertBtnProps
                        }
                        isLoading={modalLoading}
                        handleBtnClicked={() =>
                            handleBtnClicked(insertBtnProps.alt)
                        }
                    ></Button>
                )}

                {!uploadItem && (
                    <Button
                        btnProps={disabledBtnProps}
                        handleBtnClicked={() =>
                            handleBtnClicked(disabledBtnProps.alt)
                        }
                    ></Button>
                )}
            </div>
        </>
    );
};

export default AudioUpload;
