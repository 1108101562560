import * as CryptoJS from 'crypto-js';

export class AuthService {
    public secretKey = 'bc776';

    encrypt(message: string, secretkey: string) {
        const encrypted = CryptoJS.AES.encrypt(
            message,
            secretkey ? secretkey : this.secretKey
        ).toString();
        return encrypted;
    }

    decrypt(encrypted: string, secretkey: string) {
        const decrypted = CryptoJS.AES.decrypt(
            encrypted,
            secretkey ? secretkey : this.secretKey
        ).toString(CryptoJS.enc.Utf8);
        return decrypted;
    }

    getUserInfo() {
        const encodedData: any = localStorage.getItem('userInfo');
        if (encodedData) {
            const userInfo = JSON.parse(this.decrypt(encodedData, ''));
            console.log('userInfo', userInfo);
            return userInfo;
        } else {
            return null;
        }
    }

    getHeadersInfo() {
        const userinfo = this.getUserInfo();
        const headersInfo = {
            // 'content-Type': 'application/json',
            username: userinfo.username,
            password: userinfo.password,
            apikey: userinfo.apikey,
        };
        return headersInfo;
    }

    getHeadersWithLessonId() {
        const userinfo = this.getUserInfo();
        const headersInfo = {
            // 'content-Type': 'application/json',
            username: userinfo.username,
            password: userinfo.password,
            apikey: userinfo.apikey,
            lessonId: userinfo.lessonId,
        };
        return headersInfo;
    }

    saveData(keyword: string, value: string) {
        localStorage.setItem(keyword, value);
    }
}
