import './checkbox.css';
import React, { FC, ReactNode, useEffect } from 'react';

export interface IProps {
    item: any;
    uiClass: string;
    children: ReactNode;
    checked: boolean;
    handleOnCheck: (id: string, value: boolean) => void;
}

const Checkbox: FC<IProps> = ({
    children,
    uiClass,
    checked,
    handleOnCheck,
    item,
}) => {
    const onCheck = (id: string, value: boolean) => {
        console.log('checked ', value);
        handleOnCheck(id, value);
    };
    // useEffect(() => {
    //     // console.log('item ', item);
    // }, [item]);

    return (
        <label className={uiClass}>
            <input
                type="checkbox"
                id={item.id}
                checked={checked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onCheck(item.id, e.target.checked)
                }
            />
            <span className='cb-wrapper'>
                <span className="cb-icon"></span>
            </span>
            {children}
        </label>
    );
};

export default Checkbox;
