import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import CustomLoading from '../../../../global-components/loading/loading';
import './activity-loading.css';

// export interface IProps {
// }
const ActivityLoading = () => {
    const listArr = [1, 2, 3, 4, 5, 6, 7];

    return (
        <>
            <div className="activity-list">
                {/* <div className="activity-header h2-strong text-c300">
                                Activity Log
                            </div> */}
                <div className="activities">
                    <div className="list-header">
                        <div className="students-col text-c100 caption-u">
                            Users
                        </div>
                        <div className="actions-col text-c100 caption-u">
                            Actions
                        </div>
                        <div className="date-time-col text-c100 caption-u">
                            Date & time
                        </div>
                    </div>
                    <div className="">
                        {listArr.map((index: any) => (
                            <div key={index} className='loading-wrapper'>
                                <div>
                                    <CustomLoading loadingData='profileLoading'></CustomLoading>
                                </div>
                                <div>
                                    <CustomLoading loadingData='nameLoading'></CustomLoading>
                                </div>
                                <div>
                                    <CustomLoading loadingData='action1Loading'></CustomLoading>
                                    <CustomLoading loadingData='action2Loading'></CustomLoading>
                                </div>
                                <div>
                                    <CustomLoading loadingData='dateLoading'></CustomLoading>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='loading-wrapper pg-loading'>
                        <div className='text-s70 body-m pg-text'>Displaying </div>
                        <div>
                            <CustomLoading loadingData='pg1Loading'></CustomLoading>
                        </div>
                        <div>
                            <CustomLoading loadingData='pg2Loading'></CustomLoading>
                        </div>
                        <div>
                            <CustomLoading loadingData='pg3Loading'></CustomLoading>
                        </div>
                        <div>
                            <CustomLoading loadingData='pg2Loading'></CustomLoading>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActivityLoading;