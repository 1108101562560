import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { worksheetListApi } from '../../api';
import { getLabelsApi } from '../../api/global/globalApi';
import { LabelModel } from '../../models/label.model';

interface CreateWorksheetState {
    labelList: LabelModel[];
    labels: {
        labelList: LabelModel[];
        loading: string | null;
        error: string | null;
    };
}

const initialState = {
    labelList: [],
    labels: {
        labelList: [],
        loading: 'idle',
        error: null
    }
} as CreateWorksheetState;  

export const CreateWorksheetSlice = createSlice({
    name: 'createWorksheet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLabelsApi.pending, (state, action) => {
            if (state.labels.loading === 'idle') {
                console.log('state.labels.loading === idle');
                state.labels.loading = 'pending';
            }
        });

        builder.addCase(getLabelsApi.fulfilled, (state, action) => {
            if (state.labels.loading === 'pending') {
                console.log('state.labels.loading === pending', action.payload);
                state.labels.labelList = action.payload;
                state.labels.loading = 'idle';
            }
        });

        builder.addCase(getLabelsApi.rejected, (state, action) => {
            if (state.labels.loading === 'pending') {
                console.log('state.labels.loading === pending');
                state.labels.loading = 'idle';
                state.labels.error = 'Error occured';
            }
        });
    },
});

export default CreateWorksheetSlice.reducer;
