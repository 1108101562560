import React, { FC, useEffect, useRef, useState } from 'react';
import IconButton from '../../../../global-components/icon-button/IconButton';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import MoveIcon from '../../../../assets/images/move.svg';
import EditIcon from '../../../../assets/images/pencil_l.svg';
import DeleteIcon from '../../../../assets/images/delete_l.svg';
import ResizeIcon from '../../../../assets/images/resize.svg';
import SendbackIcon from '../../../../assets/images/sendback.svg';
import CloneIcon from '../../../../assets/images/clone.svg';
import './draggable.css';
import { ELEMENT_CONTROL_CONSTANTS } from '../../../../constants/elementControlConstants';
import UseOutsideClick from '../../../../hooks/UseOutsideClick';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import Resizable from '../resizable/Resizable';
import { PointModel } from '../../../../models/point.model';
import { setCanvas } from '../../../teacher/slices/worksheetSlice';
import { PARENT_TYPE_CONSTANTS } from '../../../../constants/parentTypeConstants';
export interface IProps {
    parentType: string;
    children?: JSX.Element;
    component: DraggableElement;
    currentItem: DraggableElement | null;
    handleEdit: () => void;
    isEditLayout: boolean;
    deleteComponent: () => void;
    handleBlur: (item: DraggableElement) => void;
    handleUpdatePosition: (position: PointModel) => void;
    draggableClicked: (item: DraggableElement) => void;
    handleResize: (size: any) => void;
    handleTempSize: (size: any) => void;
    publishedStatus: string;
    handleClone: () => void;
    handleIsBack: (value: boolean) => void;
    editItem: boolean;
    canvasWidth: number;
    canvasHeight: number;
    handleCanvas: (data: any) => void;
    sidebarWidth?: number | null;
}
const Draggable: FC<IProps> = ({
    parentType,
    component,
    currentItem,
    children,
    handleEdit,
    isEditLayout,
    deleteComponent,
    handleBlur,
    handleUpdatePosition,
    draggableClicked,
    handleResize,
    handleTempSize,
    publishedStatus,
    handleClone,
    handleIsBack,
    editItem,
    canvasWidth,
    canvasHeight,
    handleCanvas,
    sidebarWidth = null,
}) => {
    const draggable = useRef<HTMLDivElement | null>(null);

    const isDisabled =
        // component.type === TOOLBAR_BTN_ACTION_CONSTANTS.TEXT ||
        component.type === TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO ||
        component.type === TOOLBAR_BTN_ACTION_CONSTANTS.SCORE ||
        component.type === TOOLBAR_BTN_ACTION_CONSTANTS.GROUP ||
        component.type === TOOLBAR_BTN_ACTION_CONSTANTS.GRADING;

    const [isMove, setIsMove] = useState<boolean>(false);

    const [isBlur, setIsBlur] = useState<boolean>(false);

    const boxSizingList = [
        TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX,
        TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY,
        TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO,
    ];

    const isTextBox = boxSizingList.includes(component.type);

    const moveBtnProps: IconBtnModel = {
        icon: MoveIcon,
        alt: ELEMENT_CONTROL_CONSTANTS.MOVE,
        uiClass: 'move-icon',
        disable: publishedStatus === 'published' ? true : false,
        draggable: false,
    };

    const editBtnProps: IconBtnModel = {
        icon: EditIcon,
        alt: ELEMENT_CONTROL_CONSTANTS.EDIT,
        uiClass: 'edit-icon',
        disable: publishedStatus === 'published' ? true : false,
        draggable: false,
    };

    const sendBackBtnProps: IconBtnModel = {
        icon: SendbackIcon,
        alt: ELEMENT_CONTROL_CONSTANTS.SENDBACK,
        uiClass: 'edit-icon',
        disable: publishedStatus === 'published' ? true : false,
        draggable: false,
    };

    const cloneBtnProps: IconBtnModel = {
        icon: CloneIcon,
        alt: ELEMENT_CONTROL_CONSTANTS.CLONE,
        uiClass: 'edit-icon',
        disable: publishedStatus === 'published' ? true : false,
        draggable: false,
    };

    const deleteBtnProps: IconBtnModel = {
        icon: DeleteIcon,
        alt: ELEMENT_CONTROL_CONSTANTS.DELETE,
        uiClass: 'delete-icon',
        disable: publishedStatus === 'published' ? true : false,
        draggable: false,
    };

    const resizeBtnProps: IconBtnModel = {
        icon: ResizeIcon,
        alt: ELEMENT_CONTROL_CONSTANTS.RESIZE,
        uiClass: 'resize-icon',
        disable: false,
        draggable: false,
    };

    const handleDown = (event: any) => {
        event.preventDefault();
        if (publishedStatus !== 'published') {
            document.addEventListener('mousemove', handleMove);
            document.addEventListener('mouseup', handleUp);
        }
    };

    const handleMove = (event: any) => {
        if (!editItem) {
            event.preventDefault();

            console.log('move', event.clientX, event.clientY);
            const element = document.getElementById(component.id);
            const canvas = document.getElementById('canvas-wrapper');
            let scrollTop = 0;
            let scrollLeft = 0;

            const isOverTop = event.clientY < 145;
            const isOverLeft = event.clientX < 30;

            if (element && !isOverTop && !isOverLeft) {
                if (canvas) {
                    scrollLeft = canvas.scrollLeft;
                    scrollTop = canvas.scrollTop;
                }
                console.log(scrollLeft, scrollTop);
                const left = event.clientX + scrollLeft - 28 - (sidebarWidth ? sidebarWidth : 0);
                const top = event.clientY + scrollTop - 76;
                if (left + (component.size.width + 30 + 50 + 200) >= canvasWidth) {

                    handleCanvas({
                        width: left + component.size.width + 500,
                        height: 0,
                    });

                }
                // 30 is margin
                // 50 is for tool
                // 200 is surplus bottom
                if (top + (component.size.height + 30 + 50 + 200) >= canvasHeight) {

                    handleCanvas({
                        width: 0,
                        height: top + component.size.height + 500,
                    });

                }

                element.style.left = left + 'px';
                element.style.top = top + 'px';
            } else {
                console.log('out of top');
            }

        }
    };

    const handleUp = (event: any) => {
        console.log('mouse up');
        const canvas = document.getElementById('canvas-wrapper');
        const element = document.getElementById(component.id);
        let scrollTop = 0;
        let scrollLeft = 0;
        if (canvas) {
            scrollLeft = canvas.scrollLeft;
            scrollTop = canvas.scrollTop;
        }
        const scrollY = event.clientY + scrollTop - 76;

        // const x = Number.parseInt((currentItem && currentItem.type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP ? event.clientX + scrollLeft - 28 + 16 + 289 : event.clientX + scrollLeft - 28 + 16 - (sidebarWidth ? sidebarWidth : 0)).toString());
        const x = Number.parseInt((currentItem && currentItem.type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP ? event.clientX + scrollLeft + component.size.width - 46 : event.clientX + scrollLeft - 28 + 16 - (sidebarWidth ? sidebarWidth : 0)).toString());
        const y = Number.parseInt((scrollY < 80 ? 80 : scrollY + 13).toString()); 
        
        console.log('moveHandleUpNewPosition', event.clientX, event.clientY, x, y);

        // if(element) {
        //     element.style.left = x + 'px';
        //     element.style.top = y + 'px';
        // }
        
        handleUpdatePosition({
            x: x,
            y: y,
        });
        // dispatch(
        //     updateComponentPosition({
        //         position: {
        //             x: event.clientX + scrollLeft - 28 + 16,
        //             y: scrollY < 80 ? 80 : scrollY + 13,
        //         },
        //         id: component.id,
        //     })
        // );

        document.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleUp);
    };

    const handleTouchStart = (event: any) => {
        console.log('down');
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchCancel);
    };

    const handleTouchMove = (event: any) => {
        if (!editItem) {
            event.cancelable && event.preventDefault();
            event.cancelable && event.stopPropagation();
            console.log(
                'move',
                event.changedTouches[0].clientX,
                event.changedTouches[0].clientY
            );
            const element = document.getElementById(component.id);
            const canvas = document.getElementById('canvas-wrapper');
            let scrollTop = 0;
            let scrollLeft = 0;

            if (element) {
                if (canvas) {
                    scrollLeft = canvas.scrollLeft;
                    scrollTop = canvas.scrollTop;
                }
                console.log(scrollLeft, scrollTop);

                const left = event.changedTouches[0].clientX + scrollLeft - 28;
                const top = event.changedTouches[0].clientY + scrollTop - 76;
                if (left + component.size.width >= canvasWidth) {

                    handleCanvas({
                        width: left + component.size.width + 500,
                        height: canvasHeight,
                    });

                }
                if (top + component.size.height >= canvasHeight) {

                    handleCanvas({
                        width: canvasWidth,
                        height: top + component.size.height + 500,
                    });

                }

                element.style.left = left + 'px';
                element.style.top = top + 'px';
            }
        }
    };

    const handleTouchCancel = (event: any) => {
        console.log('mouse up');
        const canvas = document.getElementById('canvas-wrapper');
        let scrollTop = 0;
        let scrollLeft = 0;
        if (canvas) {
            scrollLeft = canvas.scrollLeft;
            scrollTop = canvas.scrollTop;
        }
        // dispatch(
        //     updateComponentPosition({
        //         position: {
        //             x: event.changedTouches[0].clientX + scrollLeft - 28,
        //             y: event.changedTouches[0].clientY + scrollTop - 76,
        //         },
        //         id: component.id,
        //     })
        // );
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchCancel);
    };

    const handleClick = (
        event: React.MouseEvent<MouseEvent | HTMLDivElement>,
        type: string
    ) => {
        console.log('user click draggable', type);
        event.stopPropagation();
        event.preventDefault();
        if (publishedStatus !== 'published') {
            switch (type) {
                case ELEMENT_CONTROL_CONSTANTS.EDIT:
                    {
                        handleEdit();
                    }
                    break;
                case ELEMENT_CONTROL_CONSTANTS.DELETE:
                    {
                        deleteComponent();
                    }
                    break;
                case ELEMENT_CONTROL_CONSTANTS.RESIZE:
                    {
                        console.log('resizing');
                    }
                    break;
                case ELEMENT_CONTROL_CONSTANTS.SENDBACK:
                    {
                        console.log('send back');
                        if (currentItem) {
                            const element = document.getElementById(currentItem.id);
                            console.log('element', element);
                            if (element) {
                                element.style.zIndex = '0';
                            }
                            handleIsBack(true);
                        }

                    }
                    break;
                case ELEMENT_CONTROL_CONSTANTS.CLONE: {
                    console.log('clone');
                    handleClone();
                } break;
            }
        }

    };

    const handleDragStartAction = (e: any, type: string) => {
        console.log('start ', e);
    };

    const handleDragEndAction = () => {
        console.log('end ');
    };

    const handleClickOutside = () => {
        setIsBlur(true);
        // dispatch(setCurrentItem(null));
    };

    // const handleResize = (size: any) => {


    // };

    const handleKeyDown = (event: any) => {
        if (currentItem && currentItem.id == component.id) {
            if (event.key === 'ArrowLeft') {

                console.log('left');
            } else if (event.key === 'ArrowRight') {
                console.log('right');
            } else if (event.key === 'ArrowDown') {
                console.log('down');
            } else if (event.key === 'ArrowUp') {
                console.log('up');
            }
        }

    };

    UseOutsideClick(draggable, handleClickOutside);

    useEffect(() => {
        if (isBlur && currentItem && currentItem.id === component.id) {
            console.log('====================================');
            console.log('currentItem:', currentItem);
            console.log('====================================');
            handleBlur(currentItem);
            setIsBlur(false);
        } else {
            setIsBlur(false);
        }

        document.addEventListener('keydown', handleKeyDown);


        return () => {
            document.removeEventListener('keydown', handleKeyDown);

        };

    }, [isBlur, component, currentItem, editItem]);

    // console.log('FeedbackDragging', currentItem);

    const handleSingleDouble = (event: any) => {
        if (event.detail == 1) {
            draggableClicked(component);
        } else if (event.detail == 2) {
            if (parentType === PARENT_TYPE_CONSTANTS.teacher || parentType ===PARENT_TYPE_CONSTANTS.template) {
                draggableClicked(component);
                handleEdit();
            }
        }
    };

    const getLeft = () => {
        if (currentItem && currentItem.type !== TOOLBAR_BTN_ACTION_CONSTANTS.POPUP)
            return currentItem && component.id === currentItem.id ? component.location.x - 16 : component.location.x;
        else if (currentItem && currentItem.type == TOOLBAR_BTN_ACTION_CONSTANTS.POPUP)
            return currentItem && component.id === currentItem.id ? component.location.x - 16 - component.size.width + 34 : component.location.x;
        else
            return component.location.x;
    };

    const getBorderColor = () => {
        switch (parentType) {
            case PARENT_TYPE_CONSTANTS.viewSubmission: {
                if (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK && currentItem && currentItem.id == component.id && isEditLayout)
                    return '#8CC4FF';
                else
                    return 'transparent';
            }
            case PARENT_TYPE_CONSTANTS.teacher: {
                if (currentItem && currentItem.id == component.id)
                    return '#8CC4FF';
                else
                    return 'transparent';
            }
            case PARENT_TYPE_CONSTANTS.template: {
                if (currentItem && currentItem.id == component.id)
                    return '#8CC4FF';
                else
                    return 'transparent';
            }
            default: return 'transparent';
        }
    };

    const getToolWrapperDisplay = ()=>{
        if(currentItem && currentItem.id == component.id){
            if(isEditLayout && parentType === PARENT_TYPE_CONSTANTS.viewSubmission && component.type === TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK)
                return 'flex';
            else if(isEditLayout &&  publishedStatus !=='published' &&parentType === PARENT_TYPE_CONSTANTS.teacher)
                return 'flex';
            else if (parentType === PARENT_TYPE_CONSTANTS.template)
                return 'flex';
            else
                return 'none';
        }
        else
            return 'none';
    };

    return (
        <div
            data-component-id={component.id}
            ref={draggable}
            id={component.id}
            className="draggable"
            onClick={handleSingleDouble}
            style={{
                top: currentItem && component.id === currentItem.id ? component.location.y - 13 : component.location.y,
                left: getLeft(),
                padding: (currentItem && currentItem.id == component.id) ? '11px 14px' : '0px 0px',
                // padding: '11px 14px',
                borderWidth:
                    currentItem && currentItem.id == component.id
                        ? '2px'
                        : 'none',
                borderStyle: currentItem && currentItem.id == component.id ? 'solid' : 'none',
                borderRadius:
                    currentItem && currentItem.id == component.id
                        ? '1px'
                        : 'none',
                boxSizing: isTextBox ? 'border-box' : 'border-box',
                // borderColor: parentType !== PARENT_TYPE_CONSTANTS.student && currentItem && currentItem.id == component.id ? '#8CC4FF' : 'transparent',
                // borderColor: parentType === PARENT_TYPE_CONSTANTS.viewSubmission ? (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK && currentItem && currentItem.id == component.id && isEditLayout
                //     ? '#8CC4FF'
                //     : 'transparent') : (currentItem && currentItem.id == component.id ? '#8CC4FF' : 'transparent'),
                borderColor: getBorderColor(),
                zIndex: component.data.isBack ? '0' : (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK || component.type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP) ? '3' : '2'
            }}
        >
            <div
                className="tool-wrapper"
                style={{
                    display: getToolWrapperDisplay(),
                    cursor: publishedStatus === 'published' ? 'not-allowed' : 'pointer'
                }}
            >
                <div onMouseDown={handleDown} onTouchStart={handleTouchStart}>
                    <IconButton
                        btnProps={moveBtnProps}
                        handleBtnClicked={(event) =>
                            handleClick(event, moveBtnProps.alt)
                        }
                        handleDragStartAction={handleDragStartAction}
                        handleDragEndAction={handleDragEndAction}
                    />
                </div>

                {component.type !== TOOLBAR_BTN_ACTION_CONSTANTS.SCORE && component.type !== TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK &&(
                    <IconButton
                        btnProps={editBtnProps}
                        handleBtnClicked={(e) =>
                            handleClick(e, editBtnProps.alt)
                        }
                    />
                )}

                {/* {component.type === TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY && (
                    <IconButton
                        btnProps={sendBackBtnProps}
                        handleBtnClicked={(e) =>
                            handleClick(e, sendBackBtnProps.alt)
                        }
                    />
                )} */}
                <IconButton
                    btnProps={sendBackBtnProps}
                    handleBtnClicked={(e) =>
                        handleClick(e, sendBackBtnProps.alt)
                    }
                />

                <IconButton
                    btnProps={cloneBtnProps}
                    handleBtnClicked={(e) =>
                        handleClick(e, cloneBtnProps.alt)
                    }
                />

                <IconButton
                    btnProps={deleteBtnProps}
                    handleBtnClicked={(e) => handleClick(e, deleteBtnProps.alt)}
                />
            </div>
            {currentItem && component.id === currentItem.id && !isDisabled && (parentType == PARENT_TYPE_CONSTANTS.template ||parentType == PARENT_TYPE_CONSTANTS.teacher || (parentType == PARENT_TYPE_CONSTANTS.viewSubmission && currentItem.type == TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK) ) && (
                <Resizable
                    draggable={draggable}
                    component={component}
                    editItem={editItem}
                    resizeBtnProps={resizeBtnProps}
                    publishedStatus={publishedStatus}
                    handleResize={handleResize}
                    handleTempSize={handleTempSize}
                />
            )}
            {children}
        </div>
    );
};

export default Draggable;
