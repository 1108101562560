import React, { FC, useEffect, useRef } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';
export interface IProps {
    component: DraggableElement;
    handleItemClicked: () => void;
    editItem: boolean;
}
const GalleryTool: FC<IProps> = ({
    component,
    handleItemClicked,
    editItem,
}) => {
    const ref = useRef<HTMLImageElement>(null);

    const clickedItem = () => {
        handleItemClicked();
    };

    console.log('GalleryToolInViewSubmission', component.data?.url);

    useEffect(() => {
        //
    }, [component]);

    return (
        <>
            {component.data?.url && (
                <img
                    ref={ref}
                    onClick={clickedItem}
                    style={{
                        width: component.size.width + 'px',
                        height: component.size.height + 'px',
                    }}
                    src={component.data?.url}
                    alt=""
                />
            )}
        </>
    );
};

export default GalleryTool;
