import React, { FC, useEffect, useState } from 'react';
import './toolbar.css';
import '../../../../assets/css/main.css';
import TextIcon from '../../../../assets/images/text.svg';
import TextboxIcon from '../../../../assets/images/textbox.svg';
import GalleryIcon from '../../../../assets/images/gallery_s.svg';
import BackgroundIcon from '../../../../assets/images/background.svg';
import VideoIcon from '../../../../assets/images/video_camera.svg';
import AudioIcon from '../../../../assets/images/audio.svg';
import PopupIcon from '../../../../assets/images/popup.svg';
import ScoreIcon from '../../../../assets/images/task.svg';
import GradingIcon from '../../../../assets/images/score.svg';
import GroupIcon from '../../../../assets/images/group.svg';
import JournalIcon from '../../../../assets/images/journal.svg';
import DefaultUserIcon from '../../../../assets/images/user.svg';

import HistoryIcon from '../../../../assets/images/history_l.svg';

import TextActiveIcon from '../../../../assets/images/toolbar-active/textActive.svg';
import TextboxActiveIcon from '../../../../assets/images/toolbar-active/textboxActive.svg';
import GalleryActiveIcon from '../../../../assets/images/toolbar-active/galleryActive.svg';
import BackgroundActiveIcon from '../../../../assets/images/toolbar-active/backgroundActive.svg';
import VideoActiveIcon from '../../../../assets/images/toolbar-active/videoActive.svg';
import AudioActiveIcon from '../../../../assets/images/toolbar-active/audioActive.svg';
import PopupActiveIcon from '../../../../assets/images/toolbar-active/popupActive.svg';
import GradingActiveIcon from '../../../../assets/images/toolbar-active/scoreActive.svg';
import ScoreActiveIcon from '../../../../assets/images/toolbar-active/gradingActive.svg';
import GroupActiveIcon from '../../../../assets/images/toolbar-active/groupActive.svg';

import PreviewIcon from '../../../../assets/images/eye_open_l.svg';
import PreviewHoverIcon from '../../../../assets/images/eye_open_l_hover.svg';
import JournalHoverIcon from '../../../../assets/images/journal_hover.svg';
import DefaultUseHoverIcon from '../../../../assets/images/user_stroke_hover.svg';
import { TOOLTIP_CONSTANTS } from '../../../../constants/toolTipConstants';
import IconButton from '../../../../global-components/icon-button/IconButton';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
import { PointModel } from '../../../../models/point.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { useSelector, useDispatch } from 'react-redux';
import {
    setCurrentDragItemType,
    setCurrentItem,
} from '../../slices/worksheetSlice';
import { Link, useNavigate } from 'react-router-dom';
import { WorksheetDetailModel } from '../../../../models/worksheet-detail.model';
import { Worksheet } from '../../../../models/worksheet-item.model';
import { AuthService } from '../../../../service/auth.service';
import UserProfile from '../../../../global-components/user-profile/UserProfile';
import { Profile } from '../../../../models/profile.model';
export interface IProps {
    handleToolbarBtnAction: (type: string | undefined) => void;
    handleEditLayoutBtnAction: () => void;
    handleQR: () => void;
    isEditLayout: boolean;
    currentItem: DraggableElement;
    submissionCount: number;
    worksheet: WorksheetDetailModel | Worksheet;
    profile?: Profile;
    className?: string | null;
}
const _authService = new AuthService();
const Toolbar: FC<IProps> = ({
    handleToolbarBtnAction,
    handleEditLayoutBtnAction,
    handleQR,
    isEditLayout,
    currentItem,
    submissionCount,
    worksheet,
    profile,
    className
}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type') ? queryParams.get('type') : null;
    const [currentRefId, setCurrentRefId] = useState('');
    const dispatch = useDispatch();

    const deviceType = useSelector((state: any) => state.worksheet.deviceType);
    const worksheetDetail = useSelector((state: any) => state.worksheet.worksheet);

    const userInfo = _authService.getUserInfo();
    const currentURLOrigin = new URL(window.location.href).origin;
    const previewLink = currentURLOrigin + '/student/' + userInfo.userid + '/sheet/' + worksheetDetail?.id;
    const [showUserProfile, setShowUserProfile] = useState(false);

    const toolBarIconList: IconBtnModel[] = [
        {
            icon: TextIcon,
            activeIcon: TextActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.TEXT,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },
        {
            icon: TextboxIcon,
            activeIcon: TextboxActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },

        {
            icon: GalleryIcon,
            activeIcon: GalleryActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },
        {
            icon: BackgroundIcon,
            activeIcon: BackgroundActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },
        {
            icon: VideoIcon,
            activeIcon: VideoActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },
        {
            icon: AudioIcon,
            activeIcon: AudioActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },
        {
            icon: PopupIcon,
            activeIcon: PopupActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.POPUP,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },
        {
            icon: ScoreIcon,
            activeIcon: ScoreActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.SCORE,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },
        {
            icon: GradingIcon,
            activeIcon: GradingActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.GRADING,
            uiClass: 'toolbar-icon',
            disable: worksheet.publishedStatus === 'draft' || type === 'template' ? false : true,
            draggable: true,
        },
        // {
        //     icon: GroupIcon,
        //activeIcon: GroupActiveIcon,
        //     alt: TOOLBAR_BTN_ACTION_CONSTANTS.GROUP,
        //     uiClass: 'toolbar-icon',
        //     disable: false,
        //     draggable: true,
        // },
    ];

    const editLayoutBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: isEditLayout ? 'Edit Layout' : 'View Layout',
        uiClass: worksheet.publishedStatus === 'draft' ? 'edit-layout-btn' : 'disabled-m',
        iconClass: undefined,
    };

    const journalBtnProps: IconBtnModel = {
        icon: JournalIcon,
        activeIcon: JournalHoverIcon,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.JOURNAL,
        uiClass: 'journal-icon ',
        disable: false,
        draggable: false,
    };

    const userBtnProps: IconBtnModel = {
        icon: DefaultUserIcon,
        activeIcon: DefaultUseHoverIcon,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.USER,
        uiClass: 'user-icon',
        disable: false,
        draggable: false,
    };

    const previewBtnProps: IconBtnModel = {
        icon: PreviewIcon,
        activeIcon: PreviewHoverIcon,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.PREVIEW,
        uiClass: 'preview-icon',
        disable: false,
        draggable: false,
    };


    const handleIconAction = (type: string | undefined) => {
        console.log('action type ', type);
        console.log('TOOLBAR_BTN_ACTION_CONSTANTS.USER ', TOOLBAR_BTN_ACTION_CONSTANTS.USER);
        handleToolbarBtnAction(type);
        if (type === TOOLBAR_BTN_ACTION_CONSTANTS.USER)
            setShowUserProfile(!showUserProfile);
        else
            setShowUserProfile(false);
    };

    const handleEditLayoutBtn = () => {
        if (worksheet.publishedStatus === 'draft')
            handleEditLayoutBtnAction();
    };

    const handleDragStartAction = (e: any, type: string) => {
        createGhostText(e, type);
        dispatch(setCurrentDragItemType(type));
        dispatch(setCurrentItem(null));
    };

    const handleDragEndAction = () => {
        const elem = document.getElementById(currentRefId);
        const parent = document.getElementById('canvas');
        if (elem && parent) {
            parent.removeChild(elem);
            console.log('text is back to normal');
        }
        setCurrentRefId('');
    };

    const navigate = useNavigate();

    const handleHistory = () => {
        navigate(`/activities/${21}`);
    };

    const handleProfileAction = () => {
        handleQR();
        setShowUserProfile(false);
    };
    const getIcon = (type: string) => {
        switch (type) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.TEXT:
                return TextIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX:
                return TextboxIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                return GalleryIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND:
                return BackgroundIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                return VideoIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                return AudioIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.POPUP:
                return PopupIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.SCORE:
                return ScoreIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.GRADING:
                return GradingIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.GROUP:
                return GroupIcon;
            default:
                return TextIcon;
        }
    };

    const createGhostText = (e: any, type: string) => {
        const ghostEle = document.createElement('img');
        ghostEle.src = getIcon(type);
        ghostEle.style.width = '40px';
        ghostEle.style.height = '40px';
        ghostEle.style.position = 'absolute';
        ghostEle.style.zIndex = '10';
        ghostEle.id = 'refText';
        ghostEle.style.bottom = '-150px';

        // console.log('event ', e);
        if (deviceType === 'Mobile') {
            // e.target = ghostEle;
            console.log('cannot add ghost image');
        } else if (deviceType === 'Desktop') {
            const parent = document.getElementById('canvas');
            if (parent) {
                parent.appendChild(ghostEle);
            }
            e.dataTransfer.setDragImage(ghostEle, 0, 0);
        }

        setCurrentRefId('refText');
        // setCurrentDragItemType(ToolType.text);
    };

    const checkPopup = (alt: string) => {
        return alt ===
            TOOLBAR_BTN_ACTION_CONSTANTS.POPUP;
    };

    return (
        <>
            <div className="tool-bar bg-b100">
                <div
                    className="icon-group"
                    style={{ pointerEvents: isEditLayout || type === 'template' ? 'all' : 'none', cursor: worksheet.publishedStatus == 'published' && type !== 'template' ? 'not-allowed' : '' }}
                >
                    {toolBarIconList.map((toolbarIcon: IconBtnModel) => {
                        return (
                            <React.Fragment key={toolbarIcon.alt}>
                                <IconButton
                                    currentSelected={
                                        currentItem &&
                                        currentItem.type === toolbarIcon.alt
                                    }
                                    btnProps={toolbarIcon}
                                    handleBtnClicked={() =>
                                        handleIconAction(toolbarIcon.alt)
                                    }
                                    handleDragStartAction={
                                        handleDragStartAction
                                    }
                                    handleDragEndAction={handleDragEndAction}
                                />
                                {checkPopup(toolbarIcon.alt) && (
                                    <div className="line"></div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
                <div className="btn-group">
                    {type !== 'template' && <div style={{ marginRight: worksheet.publishedStatus === 'published' ? '24px' : '' }}>
                        <Button
                            btnProps={editLayoutBtnProps}
                            handleBtnClicked={handleEditLayoutBtn}
                        />
                    </div>}
                    {type !== 'template' && <a href={previewLink} target="_blank" rel="noreferrer noopener">
                        <IconButton
                            btnProps={previewBtnProps}
                            handleBtnClicked={() =>
                                handleIconAction(previewBtnProps.alt)
                            }
                        />
                    </a>
                    }
                    {type !== 'template' && <div className="journal-wrapper">
                        <div className="notification">
                            <span className="small text-r300">
                                {submissionCount}
                            </span>
                        </div>
                        <IconButton
                            btnProps={journalBtnProps}
                            handleBtnClicked={() =>
                                handleIconAction(journalBtnProps.alt)
                            }
                        />
                    </div>}


                    <div style={{ position: 'relative' }}>
                        <IconButton
                            btnProps={userBtnProps}
                            currentSelected={showUserProfile}
                            handleBtnClicked={() =>
                                handleIconAction(userBtnProps.alt)
                            }
                        />
                        {showUserProfile && profile && <UserProfile className={className} profile={profile} showQR={() =>
                            handleProfileAction()
                        }></UserProfile>}
                    </div>


                </div>
            </div>
        </>
    );
};

export default Toolbar;
