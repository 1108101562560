import './popup-icon-list.css';
import React, { FC } from 'react';
import InfoIcon from '../../../../assets/images/popup/info_circle_stroke.svg';
import QuestionMarkIcon from '../../../../assets/images/popup/question_circle_stroke.svg';
import AlertIcon from '../../../../assets/images/popup/alert_circle_stroke.svg';
import CheckIcon from '../../../../assets/images/popup/check.svg';
import SearchIcon from '../../../../assets/images/popup/search_s.svg';
import Icon1 from '../../../../assets/images/popup/1.svg';
import Icon2 from '../../../../assets/images/popup/2.svg';
import Icon3 from '../../../../assets/images/popup/3.svg';
import Icon4 from '../../../../assets/images/popup/4.svg';
import Icon5 from '../../../../assets/images/popup/5.svg';
import Icon6 from '../../../../assets/images/popup/6.svg';
import Icon7 from '../../../../assets/images/popup/7.svg';
import Icon8 from '../../../../assets/images/popup/8.svg';
import Icon9 from '../../../../assets/images/popup/9.svg';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import IconButton from '../../../../global-components/icon-button/IconButton';
import { POPUP_ICON_CONSTANTS } from '../../../../constants/popupIconConstants';
export interface IProps {
    handlePopupIconClicked: (type: string) => void;
}
const PopupIconList: FC<IProps> = ({ handlePopupIconClicked }) => {
    const popupIconList: IconBtnModel[] = [
        {
            icon: InfoIcon,
            alt: POPUP_ICON_CONSTANTS.INFO,
            uiClass: 'popup-list-icon',
            disable: false,
            draggable: false,
        },
        {
            icon: QuestionMarkIcon,
            alt: POPUP_ICON_CONSTANTS.QUESTION_MARK,
            uiClass: 'popup-list-icon',
            disable: false,
            draggable: false,
        },
        {
            icon: AlertIcon,
            alt: POPUP_ICON_CONSTANTS.ALERT,
            uiClass: 'popup-list-icon',
            disable: false,
            draggable: false,
        },
        {
            icon: CheckIcon,
            alt: POPUP_ICON_CONSTANTS.CHECK,
            uiClass: 'popup-list-icon',
            disable: false,
            draggable: false,
        },
        {
            icon: SearchIcon,
            alt: POPUP_ICON_CONSTANTS.SEARCH,
            uiClass: 'popup-list-icon',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon1,
            alt: POPUP_ICON_CONSTANTS.ICON1,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon2,
            alt: POPUP_ICON_CONSTANTS.ICON2,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon3,
            alt: POPUP_ICON_CONSTANTS.ICON3,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon4,
            alt: POPUP_ICON_CONSTANTS.ICON4,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon5,
            alt: POPUP_ICON_CONSTANTS.ICON5,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon6,
            alt: POPUP_ICON_CONSTANTS.ICON6,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon7,
            alt: POPUP_ICON_CONSTANTS.ICON7,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon8,
            alt: POPUP_ICON_CONSTANTS.ICON8,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
        {
            icon: Icon9,
            alt: POPUP_ICON_CONSTANTS.ICON9,
            uiClass: 'popup-list-number',
            disable: false,
            draggable: false,
        },
    ];

    const handleBtnClicked = (type: string) => {
        handlePopupIconClicked(type);
    };
    return (
        <div className="popup-list-wrapper">
            {popupIconList.map((icon: IconBtnModel) => {
                return (
                    <IconButton
                        key={icon.alt}
                        btnProps={icon}
                        handleBtnClicked={() => handleBtnClicked(icon.alt)}
                    />
                );
            })}
        </div>
    );
};

export default PopupIconList;
