import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { worksheetListApi } from '../../api';
import { getWorksheetDetail } from '../../api/worksheet/teacherApi';
import { getActivityLog } from '../../api/worksheet/worksheetsApi';
import { Activity } from '../../models/activity-log.model';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';

interface ActivityLogState {
    activityList: Activity[];
    totalLogs: number;
    worksheetDetail: WorksheetDetailModel | null;
}

const initialState = {
    activityList: [],
    totalLogs: 0,
    worksheetDetail: null
} as ActivityLogState;

export const ActivityLogSlice = createSlice({
    name: 'activityLog',
    initialState,
    reducers: {
        test: (state: any) => {
            console.log('');
        },
        resetActivityList: (state: any) => {

            state.activityList = [];

        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getActivityLog.fulfilled,
            (state, action) => {
                console.log('ActivityLogResponse', action.payload);
                state.activityList = action.payload.activityLog;
                state.totalLogs = action.payload.totalLogs;
            }
        );

        builder.addCase(getWorksheetDetail.fulfilled, (state, action) => {

            state.worksheetDetail = action.payload;
        });

        builder.addMatcher(
            worksheetListApi.endpoints.getActivityLogById.matchFulfilled,
            (state, action) => {
                // console.log('action ', action);
                state.activityList = action.payload;
            }
        );

    },
});
export const {
    test,
    resetActivityList
} = ActivityLogSlice.actions;
export default ActivityLogSlice.reducer;
