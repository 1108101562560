import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { ElementDataModel } from '../../../../models/element-data.model';
import { PointModel } from '../../../../models/point.model';
import upArrow from '../../../../assets/images/up_filled.svg';
import downArrow from '../../../../assets/images/down_filled.svg';
import {
    addComponent,
    setCurrentDragItemType,
} from '../../../teacher/slices/worksheetSlice';
import './grading-base.css';
import { current } from '@reduxjs/toolkit';
import { WorksheetComponent } from '../../../../models/worksheet-component.model';
export interface IProps {
    closeModal: () => void;
    editItem: boolean;
    currentId: number;
    currentDragItemType: string;
    position: PointModel;
    handleDataUpdate: (value: ElementDataModel) => void;
    currentItem: DraggableElement;
    addComponent: (element: WorksheetComponent) => void;
}
const GradingBase: FC<IProps> = ({
    closeModal,
    editItem,
    currentId,
    currentDragItemType,
    position,
    handleDataUpdate,
    currentItem,
    addComponent,
}) => {
    const dispatch = useDispatch();
    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Cancel',
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel-l',
        iconClass: undefined,
    };

    const addBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Add',
        disable: false,
        btnText: 'Add',
        uiClass: 'add-l',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Add',
        uiClass: 'disabled-l',
        iconClass: undefined,
    };

    const [base, setBase] = useState<string>('10');

    const updateElement = () => {
        console.log('update');
        const data: ElementDataModel = {
            text: null,
            score: {
                mark: null,
                base: Number(base),
            },
            url: null,
            chatLog: null,
            style: null,
            iconType: null,
            descText: null,
            descStyle: null,
            isBack: false,
        };
        handleDataUpdate(data);
        closeModal();
    };

    const renderElement = () => {
        console.log('render');
        const element: WorksheetComponent = {
            type: currentDragItemType,
            location: { x: position.x, y: position.y },
            size: { width: 0, height: 0 },
            data: {
                text: null,
                score: {
                    mark: null,
                    base: Number(base),
                },
                url: null,
                chatLog: null,
                style: null,
                iconType: null,
                descText: null,
                descStyle: null,
                isBack: false
            },
        };

        if (base) {
            addComponent(element);
            // dispatch(addComponent(element));
            // dispatch(setCurrentDragItemType(''));
            // closeModal();
        }
    };

    const handleBtnClicked = (type: string | undefined) => {
        console.log('handleBtnClicked', type);
        switch (type) {
            case 'Cancel':
                closeModal();
                break;
            case 'Add':
                renderElement();
                // if (editItem) {
                //     updateElement();
                // } else {
                //     renderElement();
                // }
                break;
            // default:
            //     closeModal();
        }
    };

    const handleUpArrow = () => {
        let tempBase = Number(base);
        tempBase++;
        setBase(tempBase.toString());
    };

    const handleDownArrow = () => {
        let tempBase = Number(base);
        if (tempBase != 1) {
            tempBase--;
        }
        setBase(tempBase.toString());
    };

    const handleBaseChange = (e: any) => {
        setBase(e.target.value);
    };

    useEffect(() => {
        // console.log('grading useEffect:', currentItem.data.score.base);
        if (currentItem && currentItem.data.score.base !== null) {
            setBase(currentItem.data.score.base.toString());
        } else {
            setBase('10');
            // console.log('else', currentItem.data.score.base);
        }
    }, [currentItem]);

    return (
        <>
            <div className="grading">
                <span className="body-m text-c300">Grade points</span>
                <div className="point-wrapper">
                    <div className="arrow-group">
                        <img
                            src={upArrow}
                            alt=""
                            className="up-arrow"
                            onClick={handleUpArrow}
                        />
                        <img
                            src={downArrow}
                            alt=""
                            className="down-arrow"
                            onClick={handleDownArrow}
                        />
                    </div>
                    <input
                        type="text"
                        value={base}
                        className="base-input"
                        onChange={handleBaseChange}
                        min={1}
                    />
                </div>
            </div>
            <div className="grading-btn-group">
                <Button
                    btnProps={cancelBtnProps}
                    handleBtnClicked={() =>
                        handleBtnClicked(cancelBtnProps.alt)
                    }
                ></Button>
                {(base && base !== '0') && (
                    <Button
                        btnProps={addBtnProps}
                        handleBtnClicked={() =>
                            handleBtnClicked(addBtnProps.alt)
                        }
                    ></Button>
                )}

                {(!base || base == '0') && (
                    <Button
                        btnProps={disabledBtnProps}
                        handleBtnClicked={() =>
                            handleBtnClicked(disabledBtnProps.alt)
                        }
                    ></Button>
                )}
            </div>
        </>
    );
};

export default GradingBase;
