export const NAVBAR_BTN_ACTION_CONSTANTS = {
    BACK: 'back',
    EDIT: 'edit',
    LOCK: 'lock',
    PUBLISH: 'publish',
    UNPUBLISH: 'unpublish',
    CREATEWORKSHEET: 'createworksheet',
    BACKEDITOR: 'back_editor',
    SAVE: 'save',
    RETURN: 'return',
    EDIT_LAYOUT: 'edit_layout',
    EDIT_THUMBNAIL: 'edit_thumbnail',
    QRCODE: 'qrcode',
    EDIT_WORKSHEET: 'edit_worksheet',
    EDIT_TEMPLATE: 'edit_template',
};
