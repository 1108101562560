import React, { useEffect, useRef } from 'react';
import { PARENT_TYPE_CONSTANTS } from '../../constants/parentTypeConstants';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
import { ChatLogDataModel } from '../../models/chat-log-data.model';
import { CustomModal } from '../../models/custom-modal.model';
import { DraggableElement } from '../../models/draggable-element.model';
import { Font } from '../../models/font.model';
import { PointModel } from '../../models/point.model';
import { WorksheetComponent } from '../../models/worksheet-component.model';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';
import { Submission } from '../../models/worksheet-submission-detail.model';
import AudioTool from './components/audio-tool/AudioTool';
import BackgroundTool from './components/background-tool/BackgroundTool';
import Draggable from './components/draggable/Draggable';
import FeedbackTool from './components/feedback-tool/FeedbackTool';
import GalleryTool from './components/gallery-tool/GalleryTool';
import GradingTool from './components/grading-tool/GradingTool';
import PopupTool from './components/popup-tool/PopupTool';
import ScoreTool from './components/score-tool/ScoreTool';
import StudentInput from './components/student-input/StudentInput';
import StylingToolBar from './components/styling-tool-bar/StylingToolBar';
import TextTool from './components/text-tool/TextTool';
import TextboxTool from './components/textbox-tool/TextboxTool';
import VideoTool from './components/video-tool/VideoTool';

export interface IProps {
    fileUploaded?: boolean;
    mode:string;
    worksheetDetail?: WorksheetDetailModel;
    submissionDetail?: Submission;
    parentType: string;
    components: DraggableElement[];
    currentDragItemType: string;
    editItem: boolean;
    currentItem: DraggableElement | null;
    canvasWidth: number;
    canvasHeight: number;
    totalScore: number;
    totalBase: number;
    isText: boolean;
    publishedStatus: string;
    isEditLayout: boolean;
    studentId: string;
    textUploaded: boolean;
    triggerStudentInput: boolean;
    currentChatLog?: ChatLogDataModel | null;
    studentStatusList?: any;
    colorList?: any;
    focusList?: any;
    sidebarWidth?: number | null;

    toggleModal: () => void;
    handleModalData: (value: CustomModal) => void;
    handleTempPosition: (position: PointModel) => void;
    handleEdit: () => void;
    handleClickOutside: () => void;
    addComponent: (element: WorksheetComponent) => void;
    deleteComponent: () => void;
    handleBlur: (item: DraggableElement) => void;
    updateCurrentItem: (component: DraggableElement) => void;
    handleUpdatePosition: (position: PointModel) => void;
    handleBgDelete: () => void;
    handleBgEdit: () => void;
    handleTextChange: (text: string, descText: string) => void;
    handleResize: (size: any) => void;
    handleClone: () => void;
    handleTempSize: (size: any) => void;
    handleFontChange: (data: string) => void;
    handleFontSizeChange: (data: string) => void;
    handleFontWeightChange: (data: string) => void;
    handleItalic: (data: boolean) => void;
    handleUnderline: (data: boolean) => void;
    handleColorChange: (data: string) => void;
    handleBorderChange: (data: string) => void;
    handleBackgroundChange: (data: string) => void;
    handlePopupIconClicked: (data: string) => void;
    updateActiveItem: (isText: boolean) => void;
    handleInputChange: (text: string | undefined) => void;
    handleIsBack: (value: boolean) => void;
    handleCanvas: (value: any) => void;

    changeCurrentInputType: (type: string) => void;
    handleStudentInputClick: (item: DraggableElement) => void;
    addText: (text: string, id: string) => void;
    updateText: (text: string, id: string) => void;
    handleChatLogClick: (item: ChatLogDataModel) => void;
    openModal: (id: string) => void;
    handleDelete: () => void;
    handleReleaseFocus: () => void;
    handleStudentInputUpdate: (id: string) => void;

    onGradeChange: (gradingComponent: DraggableElement, submission: Submission) => void;
    onFeedbackChange: (feedbackComponent: DraggableElement) => void;
    handleClosePopup: () => void;

}

const CommonCanvas: React.FC<IProps> = ({
    fileUploaded,
    mode,
    worksheetDetail,
    submissionDetail,
    components,
    parentType,
    totalScore,
    totalBase,
    isText,
    publishedStatus,
    isEditLayout,
    currentDragItemType,
    editItem,
    currentItem,
    canvasWidth,
    canvasHeight,
    studentId,
    textUploaded,
    currentChatLog,
    studentStatusList,
    colorList,
    focusList,
    sidebarWidth = null,

    toggleModal,
    handleModalData,
    handleTempPosition,
    handleEdit,
    handleClickOutside,
    addComponent,
    deleteComponent,
    handleBlur,
    updateCurrentItem,
    handleUpdatePosition,
    handleBgDelete,
    handleBgEdit,
    handleTextChange,
    handleResize,
    handleClone,
    handleTempSize,
    handleFontChange,
    handleFontSizeChange,
    handleFontWeightChange,
    handleItalic,
    handleUnderline,
    handleColorChange,
    handleBorderChange,
    handleBackgroundChange,
    handlePopupIconClicked,
    updateActiveItem,
    handleInputChange,
    handleIsBack,
    handleCanvas,

    changeCurrentInputType,
    handleStudentInputClick,
    addText,
    updateText,
    handleChatLogClick,
    openModal,
    handleDelete,
    handleReleaseFocus,
    handleStudentInputUpdate,
    triggerStudentInput,

    onGradeChange,
    onFeedbackChange,
    handleClosePopup

}) => {

    const node = useRef<HTMLDivElement | null>(null);
    const parent = useRef<HTMLDivElement | null>(null);

    const typeList = [
        TOOLBAR_BTN_ACTION_CONSTANTS.TEXT,
        TOOLBAR_BTN_ACTION_CONSTANTS.POPUP,
        TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX,
    ];

    const handleGradeChange = (component: DraggableElement) => {
        if (submissionDetail) {
            onGradeChange(component, submissionDetail);
        }
    };

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('handleDragEnter');
    };

    const handleDragLeave = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('handleDragLeave');
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('handleDrop', currentDragItemType);

        if (parent.current) {
            const isPopup = currentDragItemType === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP ? true : false;
            let calculatedX = 0;
            let calculatedY = 0;
            if(isPopup){
                calculatedX = e.pageX > 310 ? Math.round(e.pageX + parent.current.scrollLeft - (sidebarWidth ? sidebarWidth : 0)) : 310;
                calculatedY = e.pageY - 70 - 107 > 5 ? Math.round(e.pageY + parent.current.scrollTop - 107) : 105;
            }else{
                calculatedX = e.pageX > 10 ? Math.round(e.pageX + parent.current.scrollLeft - (sidebarWidth ? sidebarWidth : 0)) : 105;
                calculatedY = e.pageY - 70 - 107 > 5 ? Math.round(e.pageY + parent.current.scrollTop - 107) : 75;
            }
                
            
            
            
            
            createElement(currentDragItemType, {
                x:calculatedX,
                y:calculatedY,
            });
        }
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const createElement = (type: string, position: PointModel) => {
        console.log('I am here', type, position);
        let tempSize;
        let data;
        let chatLog = null;
        const font: Font = {
            fontFamily: 'Inter',
            fontWeight: 'Regular',
            fontSize: '24px',
            fontColor: '#000000',
            fontStyle: null,
            textDecoration: null,
            border: null,
            background: null,
        };
        if (type === TOOLBAR_BTN_ACTION_CONSTANTS.GROUP) {
            toggleModal();
            const modalData: CustomModal = {
                title: 'Add Group Members',
                showCrossBtn: true,
                width: '500px',
                height: null,
                padding: '32px 36px',
            };
            handleModalData(modalData);
            handleTempPosition(position);
            return;
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
            toggleModal();
            const modalData: CustomModal = {
                title: 'Grading',
                showCrossBtn: true,
                width: '376px',
                height: null,
                padding: '24px 32px',
            };
            handleModalData(modalData);
            handleTempPosition(position);
            return;
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.SCORE) {
            tempSize = { width: 0, height: 0 };
            data = 'Sample Text';
            chatLog = null;
            // toggleEditItem(type);
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP) {
            tempSize = { width: 323, height: 112 };
            data = 'Sample Text';
            chatLog = null;
            // toggleEditItem(type);
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO) {
            toggleModal();
            const modalData: CustomModal = {
                title: 'Upload Audio',
                showCrossBtn: true,
                width: '944px',
                height: null,
                padding: '32px',
            };
            handleModalData(modalData);
            handleTempPosition(position);
            return;
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO) {
            toggleModal();
            const modalData: CustomModal = {
                title: 'Upload Video',
                showCrossBtn: true,
                width: '944px',
                height: null,
                padding: '32px',
            };
            handleModalData(modalData);
            handleTempPosition(position);
            return;
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND) {
            toggleModal();
            const modalData: CustomModal = {
                title: 'Upload Image',
                showCrossBtn: true,
                width: '944px',
                height: null,
                padding: '32px',
            };
            handleModalData(modalData);
            handleTempPosition(position);
            return;
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY) {
            toggleModal();
            const modalData: CustomModal = {
                title: 'Upload Image',
                showCrossBtn: true,
                width: '944px',
                height: null,
                padding: '32px',
            };
            handleModalData(modalData);
            handleTempPosition(position);
            return;
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.TEXT) {
            tempSize = { width: 200, height: 30 };
            data = 'Sample Text';
            chatLog = null;
            // toggleEditItem(type);
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX) {
            tempSize = { width: 272, height: 100 };
            data = '';
            chatLog = null;
        } else if (type === TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK) {
            tempSize = { width: 405, height: 89 };
            data = null;
            chatLog = null;
        } else {
            tempSize = { width: 350, height: 150 };
            data = null;
            chatLog = null;
        }
        const element: WorksheetComponent = {
            type: type,
            location: {
                x: position.x, y: type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP
                    ? position.y - 22 : position.y
            },
            size: tempSize,
            data: {
                text:
                    type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP
                        ? 'Sample Title'
                        : data,
                score: {
                    mark: null,
                    base: null,
                },
                url: data,
                chatLog: chatLog,
                style: {
                    fontFamily: 'Inter',
                    fontWeight: type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP
                        ? 'Bold' : '',
                    fontSize: '24px',
                    fontColor: '#000000',
                    fontStyle: null,
                    textDecoration: null,
                    border: null,
                    background: null,
                },
                iconType: null,
                descText:
                    type === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP
                        ? 'Sample Description'
                        : data,
                descStyle: {
                    fontFamily: 'Inter',
                    fontWeight: 'Regular',
                    fontSize: '18px',
                    fontColor: '#000000',
                    fontStyle: null,
                    textDecoration: null,
                    border: null,
                    background: null,
                },
                isBack: false,
            },
        };

        if (type.length > 0) {
            addComponent(element);
        }
    };

    const renderElement = (component: DraggableElement) => {
        switch (component.type) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.TEXT:
                return (
                    <TextTool
                        parentType={parentType}
                        component={component}
                        currentItem={currentItem}
                        editItem={editItem}
                        handleItemClicked={() => updateCurrentItem(component)}
                        handleInputChange={handleInputChange}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX:
                {
                    if (parentType === PARENT_TYPE_CONSTANTS.teacher || parentType === PARENT_TYPE_CONSTANTS.template) {
                        return (
                            <TextboxTool
                                component={component}
                                editItem={editItem}
                                currentItem={currentItem}
                                handleItemClicked={() => updateCurrentItem(component)}
                            />
                        );
                        // if (parentType === PARENT_TYPE_CONSTANTS.student || parentType === PARENT_TYPE_CONSTANTS.viewSubmission)
                    } else {
                        return (
                            <StudentInput
                                fileUploaded={fileUploaded}
                                mode={mode}
                                parentType={parentType}
                                worksheetDetail={worksheetDetail ? worksheetDetail : null}
                                component={component}
                                openModal={openModal}
                                changeCurrentInputType={changeCurrentInputType}
                                addText={addText}
                                textUploaded={textUploaded}
                                changeCurrentItem={updateCurrentItem}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handleClick={handleChatLogClick}
                                handleStudentInputClick={handleStudentInputClick}
                                updateText={updateText}
                                editable={parentType === PARENT_TYPE_CONSTANTS.student}
                                handleReleaseFocus={handleReleaseFocus}
                                handleStudentInputUpdate={handleStudentInputUpdate}
                                triggerStudentInput={triggerStudentInput}
                                currentItem={currentItem}
                                studentStatusList={studentStatusList}
                                colorList={colorList}
                                focusList={focusList}
                                currentChatLog={currentChatLog ? currentChatLog : null}
                            />
                        );
                    }
                    // else {
                    //     return (
                    //         <TextboxTool
                    //             component={component}
                    //             editItem={editItem}
                    //             currentItem={currentItem}
                    //             handleItemClicked={() => updateCurrentItem(component)}
                    //         />
                    //     );
                    // }
                } break;

            case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                return (
                    <GalleryTool
                        component={component}
                        editItem={editItem}
                        handleItemClicked={() => updateCurrentItem(component)}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND:
                return (
                    <BackgroundTool
                        handleClickOutside={handleClickOutside}
                        component={component}
                        editItem={editItem}
                        handleItemClicked={() => updateCurrentItem(component)}
                        handleEdit={handleBgEdit}
                        handleDelete={handleBgDelete}
                        readonly={parentType === PARENT_TYPE_CONSTANTS.student ? true : false}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                return (
                    <VideoTool
                        component={component}
                        editItem={editItem}
                        isEditLayout={isEditLayout}
                        handleItemClicked={() => updateCurrentItem(component)}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                return (
                    <AudioTool
                        component={component}
                        editItem={editItem}
                        isEditLayout={isEditLayout}
                        handleItemClicked={() => updateCurrentItem(component)}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.POPUP:
                return (
                    <PopupTool
                        parentType={parentType}
                        component={component}
                        currentItem={currentItem}
                        editItem={editItem}
                        handleItemClicked={() => updateCurrentItem(component)}
                        updateActiveItem={updateActiveItem}
                        handleTextChange={handleTextChange}
                        handleClosePopup={handleClosePopup}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.SCORE:
                return (
                    <ScoreTool
                        component={component}
                        totalScore={parentType === PARENT_TYPE_CONSTANTS.viewSubmission ? (component.data.score.mark ? component.data.score.mark : 0) : totalScore}
                        totalBase={parentType === PARENT_TYPE_CONSTANTS.viewSubmission ? (component.data.score.base ? component.data.score.base : totalBase) : totalBase}
                        editItem={editItem}
                        handleItemClicked={() => updateCurrentItem(component)}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.GRADING:
                return (
                    <GradingTool
                        component={component}
                        editItem={editItem}
                        handleItemClicked={() => updateCurrentItem(component)}
                        readOnly={parentType !== PARENT_TYPE_CONSTANTS.viewSubmission}
                        handleEnterHit={handleGradeChange}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK:
                return (

                    <FeedbackTool
                        component={component}
                        editItem={false}
                        handleFeedbackChange={onFeedbackChange}
                        readonly={!(parentType == PARENT_TYPE_CONSTANTS.viewSubmission)}
                        handleItemClicked={() => updateCurrentItem(component)}
                        updateActiveItem={(mock: boolean) => console.log(mock)}
                    />
                );
            case TOOLBAR_BTN_ACTION_CONSTANTS.GROUP:
                return (
                    // <GroupTool
                    //     component={component}
                    //     editItem={editItem}
                    //     handleItemClicked={() => updateCurrentItem(component)}
                    //     studentList={studentList.filter(
                    //         (student: StudentListModel) =>
                    //             student.checked === true
                    //     )}
                    // />
                    <></>
                );
            default:
                console.log('default');
        }
    };

    const getHeight = () => {
        if (parentType === PARENT_TYPE_CONSTANTS.teacher) {
            if (
                editItem &&
                (currentDragItemType === TOOLBAR_BTN_ACTION_CONSTANTS.TEXT ||
                    currentDragItemType === TOOLBAR_BTN_ACTION_CONSTANTS.POPUP)
            ) {
                return 'calc(100vh - 172px)';
            }
            return 'calc(100vh - 120px)';
        } else if (parentType === PARENT_TYPE_CONSTANTS.student) {
            if (worksheetDetail?.mode === 'collab') {
                return 'calc(100vh - 120px)';
            } else {
                return 'calc(100vh - 60px)';
            }

        }else{
            return 'calc(100vh - 120px)';
        }
    };

    const getMarginTop = () => {
        if (parentType === PARENT_TYPE_CONSTANTS.student) {
            if (worksheetDetail?.mode === 'collab') {
                return '120px';
            } else {
                return '60px';
            }
        } else {
            return '';
        }
    };

    useEffect(() => {
        console.log('canvas', canvasWidth, canvasHeight);
        return () => {
            //
        };
    }, [components, editItem, canvasWidth, canvasHeight, currentChatLog]);

    return (
        <>
            {currentItem && typeList.includes(currentItem.type) && editItem && <StylingToolBar
                isText={isText}
                type={currentItem ? currentItem.type : ''}
                currentItem={currentItem}
                handlePopupIconClicked={(data: string) => handlePopupIconClicked(data)}
                handleColorChange={(data: string) => handleColorChange(data)}
                handleBorderChange={(data: string) => handleBorderChange(data)}
                handleBackgroundChange={(data: string) => handleBackgroundChange(data)}
                handleBold={(data: string) => handleFontWeightChange(data)}
                handleItalic={(data: boolean) => handleItalic(data)}
                handleUnderline={(data: boolean) => handleUnderline(data)}
                handleFontChange={(data: string) => handleFontChange(data)}
                handleFontSizeChange={(data: string) => handleFontSizeChange(data)}
            />}

            <div
                ref={parent}
                className="canvas-wrapper"
                id="canvas-wrapper"
                style={{
                    height: parentType == PARENT_TYPE_CONSTANTS.viewSubmission ? 'calc(100vh - 120px)' : getHeight(),
                    position: 'relative',
                    overflow: 'overlay',
                    marginTop: getMarginTop()
                }}
            >
                <div
                    id="canvas"
                    ref={node}
                    onDragEnter={(e) => handleDragEnter(e)}
                    onDragLeave={(e) => handleDragLeave(e)}
                    onDrop={(e) => handleDrop(e)}
                    onDragOver={(e) => handleDragOver(e)}
                    style={{
                        width: canvasWidth == 720 ? '100%' : canvasWidth + 'px',
                        height:
                            canvasHeight == 720 ? '100%' : canvasHeight + 'px',
                        position: 'absolute',
                    }}
                >
                    {components.map((component: DraggableElement) => {
                        if (
                            component.type ===
                            TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND
                        ) {
                            return (
                                <React.Fragment key={component.id}>
                                    {renderElement(component)}
                                </React.Fragment>
                            );
                        } else {
                            return (
                                <React.Fragment key={component.id}>
                                    <Draggable
                                        parentType={parentType}
                                        component={component}
                                        currentItem={currentItem}
                                        handleEdit={handleEdit}
                                        isEditLayout={isEditLayout}
                                        deleteComponent={deleteComponent}
                                        handleBlur={handleBlur}
                                        handleUpdatePosition={
                                            handleUpdatePosition
                                        }
                                        draggableClicked={updateCurrentItem}
                                        handleResize={handleResize}
                                        publishedStatus={publishedStatus}
                                        handleClone={handleClone}
                                        handleTempSize={handleTempSize}
                                        handleIsBack={handleIsBack}
                                        editItem={editItem}
                                        canvasHeight={canvasHeight}
                                        canvasWidth={canvasWidth}
                                        handleCanvas={handleCanvas}
                                        sidebarWidth={sidebarWidth}
                                    >
                                        {renderElement(component)}
                                    </Draggable>
                                </React.Fragment>
                            );
                        }
                    })}
                </div>
            </div>
        </>
    );
};

export default CommonCanvas;
