import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderNavbar from '../../global-components/header-navbar/header-navbar';
import { useNavigate } from 'react-router-dom';
import TemplateList from './template-list/template-list';
import SearchInput from '../../global-components/search-input/search-input';
import './template-library.css';
import { Template } from '../../models/template-item.model';
import { setModalType, setCurrentTemplate } from './templateLibrary_slice';
import UseModal from '../../hooks/UseModal';
import UseWorksheetsHomeModal from '../../hooks/UseWorksheetsHomeModal';
import Modal from '../../global-components/modal/modal';
import { OVERLAY_MENU_CONSTANTS } from '../../constants/overlayMenuConstants';
import { CustomModal } from '../../models/custom-modal.model';
import DeleteWorksheet from '../delete-worksheet/delete-worksheet';
import ChangeTitle from '../change-title/change-title';
import CreateWorksheet from '../create-worksheet/create-worksheet';
import { WorksheetFormModel } from '../../models/worksheet-form.model';
import { AuthService } from '../../service/auth.service';
import {
    getTemplates,
    deleteTemplate,
    updateTemplate,
    createWorksheetFromTemplate,
} from '../../api/worksheet/templatesApi';
import { AppDispatch } from '../../store/store';
import { DeleteFormModel } from '../../models/delete-form.model';
import { createWorksheetApi } from '../../api/worksheet/worksheetsApi';
import loading from '../../assets/images/loading.gif';
import { AlertModel } from '../../models/alert.model';
import CustomAlert from '../../global-components/custom-alert/CustomAlert';
import HomeLoading from '../home-loading/home-loading';

const _authService = new AuthService();

const TemplateLibrary = () => {
    console.log('init template library');
    const navigate = useNavigate();

    const userInfo = _authService.getUserInfo();
    if (userInfo) {
        console.log('userInfo:', userInfo);
        if (userInfo.usertype == 3) {
            navigate('/404');
        }
    } else {
        navigate('/404');
    }

    const handleHeaderNavBtnAction = (type: string) => {
        console.log('handle header nav btn action', type);
        navigate('/');
    };

    const defaultAlertData: AlertModel = {
        title: 'default',
        body: null,
        type: 'success',
        uiClass: 'top-right-alert',
    };

    const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
    const [alertData, setalertData] = useState(defaultAlertData);
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const handleSearch = (keyword: string | null) => {
        console.log('handleSearch', keyword);
        setSearchKeyword(keyword);
    };

    const cancelModal = () => {
        console.log('cancel modal');
        toggle();
    };

    const deleteTemplateFun = (worksheet: DeleteFormModel) => {
        console.log('deleteWorksheet', worksheet);
        dispatch(deleteTemplate(worksheet.id)).then(() => {
            console.log('successfully deleted');
            dispatch(getTemplates());
            toggle();
        });
    };

    const changeTemplateTitle = (worksheetTitle: string) => {
        console.log('changeWorksheetTitle', worksheetTitle);
        console.log('current template ', currentTemplate);
        const updatedData = {
            id: currentTemplate.id,
            name: worksheetTitle,
            thumbnailUrl: currentTemplate.thumbnailUrl,
            lessonId: currentTemplate.lessonId,
            labelId: currentTemplate.labelId,
            ownerId: currentTemplate.ownerId,
        };
        dispatch(updateTemplate(updatedData)).then(() => {
            console.log('successfully updated');
            dispatch(getTemplates());
            toggle();
        });
    };

    const currentModalType = useSelector(
        (state: any) => state.templateLibrary.currentModalType
    );

    const currentTemplate = useSelector(
        (state: any) => state.templateLibrary.currentTemplate
    );

    const createModalProps: CustomModal = {
        title: 'Create new Worksheet',
        showCrossBtn: true,
        width: '947px',
        height: 'max-content',
        padding: '49px 32px',
    };

    const deleteModalProps: CustomModal = {
        title: 'Confirm delete',
        showCrossBtn: true,
        width: '433px',
        height: null,
        padding: '20px',
    };

    const changeTitleModal: CustomModal = {
        title: 'Change title',
        showCrossBtn: true,
        width: '523px',
        height: null,
        padding: '28px',
    };

    const dispatch = useDispatch<AppDispatch>(); //use AppDispatch for redux thunk

    const { isOpen, toggle } = UseModal();

    const { modalData, setModalData } = UseWorksheetsHomeModal();

    const renderModal = (type: string) => {
        console.log('render modal', type);
        switch (type) {
            case OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET:
                console.log('create worksheet----');
                return (
                    <CreateWorksheet
                        type={OVERLAY_MENU_CONSTANTS.TEMPLATE}
                        worksheet={currentTemplate}
                        clickCancel={cancelModal}
                        clickCreate={createNewWorksheet}
                    />
                );
            case OVERLAY_MENU_CONSTANTS.CHANGE_TITLE:
                console.log('change title----');
                return (
                    <ChangeTitle
                        type={OVERLAY_MENU_CONSTANTS.TEMPLATE}
                        worksheet={currentTemplate}
                        clickCancel={cancelModal}
                        clickSave={changeTemplateTitle}
                    ></ChangeTitle>
                );
            case OVERLAY_MENU_CONSTANTS.DELETE:
                console.log('delete----');
                return (
                    <DeleteWorksheet
                        type={OVERLAY_MENU_CONSTANTS.TEMPLATE}
                        worksheet={currentTemplate}
                        clickCancel={cancelModal}
                        clickDelete={deleteTemplateFun}
                    ></DeleteWorksheet>
                );
        }
    };

    const handleClickMenu = (type: string, item: Template) => {
        console.log('handleClickMenu--------', type, item);
        dispatch(setModalType(type));
        dispatch(setCurrentTemplate(item));
        switch (type) {
            case OVERLAY_MENU_CONSTANTS.CREATE_WORKSHEET:
                toggle();
                setModalData(createModalProps);
                break;
            case OVERLAY_MENU_CONSTANTS.CHANGE_TITLE:
                toggle();
                setModalData(changeTitleModal);
                break;
            case OVERLAY_MENU_CONSTANTS.DELETE:
                toggle();
                setModalData(deleteModalProps);
                break;
            case OVERLAY_MENU_CONSTANTS.EDIT:
                navigate(`/editor/${item.id}?type=${encodeURIComponent('template')}`);
                break;
            default:
                console.log('default');
        }
    };

    // const { data, error, isLoading } = useGetTemplateListQuery({});

    const createNewWorksheet = async (worksheet: WorksheetFormModel) => {
        console.log('createNewWorksheet------', worksheet);
        console.log('current template ', currentTemplate);
        const payload = {
            templateId: currentTemplate.id
        };
        try {
            const data = {
                templateId: currentTemplate.id,
                name: worksheet.name,
                labelId: worksheet.labelId
            };
            await dispatch(createWorksheetFromTemplate(data))
                .unwrap()
                .then((res: any) => {
                    console.log('Worksheet is succesussfully created.', res);
                    const worksheetId = res.meta.worksheet._id;
                    console.log('worksheetId---', worksheetId);
                    navigate(`/editor/${worksheetId}`);
                    toggle();
                }, error => {
                    toggle();
                    showErrorMessage('Fail to create worksheet!');
                });
        } catch (error) {
            console.log(error);
            toggle();
            showErrorMessage('Fail to create worksheet!');
        }
    };

    const showErrorMessage = (message: string) => {
        const alertData: AlertModel = {
            title: message,
            body: null,
            type: 'error',
            uiClass: 'top-right-alert',
        };
        setalertData(alertData);
        setShowAlert(true);
    };

    // for redux thunk
    const templates = useSelector(
        (state: any) => state.templateLibrary.templates
    );

    // for redux thunk
    useEffect(() => {
        dispatch(getTemplates());
    }, [dispatch]);

    return (
        <>
            {showAlert && (
                <CustomAlert
                    alertData={alertData}
                    alertClose={() => setShowAlert(false)}
                ></CustomAlert>
            )}
            <HeaderNavbar handleNavBtnAction={handleHeaderNavBtnAction} />
            <div className="list-wrapper">
                <div>
                    <div className="h3-strong text-c300">Library</div>
                </div>
                <div className="search-padding">
                    <SearchInput
                        hint={'Search template'}
                        handleSearch={handleSearch}
                    />
                </div>
            </div>
            {templates.error ? (
                <>Oh no, there was an error</>
            ) : templates.loading === 'pending' ? (
                <div className='list-wrapper'><HomeLoading></HomeLoading></div>
            ) : templates.data ? (
                <>
                    <TemplateList
                        templateList={templates.data}
                        clickMenu={handleClickMenu}
                        keyword={searchKeyword}
                    />
                    <Modal
                        modalProps={modalData}
                        isOpen={isOpen}
                        toggle={toggle}
                        cancelModal={cancelModal}
                        currentModalType={currentModalType}
                    >
                        {isOpen && renderModal(currentModalType)}
                    </Modal>
                </>
            ) : null}
        </>
    );
};

export default TemplateLibrary;
