import React, { FC, useState } from 'react';
import { OVERLAY_MENU_CONSTANTS } from '../../constants/overlayMenuConstants';
import Button from '../../global-components/button/Button';
import { BtnModel } from '../../models/btn.model';
import { Worksheet } from '../../models/worksheet-item.model';
import './change-title.css';

export interface IProps {
    type: string;
    worksheet: Worksheet;
    clickCancel: () => void;
    clickSave: (worksheetTitle: string) => void;
}

const ChangeTitle: FC<IProps> = ({
    type,
    worksheet,
    clickCancel,
    clickSave,
}) => {
    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel',
        iconClass: undefined,
    };

    const saveBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Save Changes',
        uiClass: 'primary-m',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Save Changes',
        uiClass: 'disabled-m',
        iconClass: undefined,
        loadingText: 'Saving...'
    };

    const labelName =
        type == OVERLAY_MENU_CONSTANTS.WORKSHEET ? 'Worksheet' : 'Template';

    const onClickCancel = () => {
        clickCancel();
    };

    const onClickSave = () => {
        setisLoadingBtn(true);
        clickSave(worksheetTitle);
    };

    const [worksheetTitle, setWorksheetTitle] = useState(worksheet.name);
    const [isLoadingBtn, setisLoadingBtn] = useState(false);

    const handleWorksheetTitle = (e: React.FormEvent<HTMLInputElement>) => {
        setWorksheetTitle(e.currentTarget.value);
    };

    return (
        <>
            <div className="mt-16">
                <div className="h4-strong text-c300 mb-8">
                    {labelName} Name*
                </div>
                <input
                    type="text"
                    id="name"
                    value={worksheetTitle}
                    className="light-body-m text-c300 modal-text-input"
                    placeholder="Type Worksheet Name..."
                    onChange={handleWorksheetTitle}
                />
            </div>
            <div className="modal-footer mt-33">
                <span className="mr-16">
                    <Button
                        btnProps={cancelBtnProps}
                        handleBtnClicked={onClickCancel}
                    />
                </span>
                {/* <button
                    disabled={
                        worksheetTitle === null || worksheetTitle === ''
                            ? true
                            : false
                    }
                    className="body-m text-s0 primary-m"
                    onClick={onClickSave}
                >
                    Save Changes
                </button> */}
                {!isLoadingBtn ?
                    <Button btnProps={saveBtnProps} handleBtnClicked={onClickSave} /> :
                    <Button btnProps={disabledBtnProps} isLoading={isLoadingBtn} handleBtnClicked={onClickCancel} />
                }
            </div>
        </>
    );
};

export default ChangeTitle;
