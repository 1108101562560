export const OVERLAY_MENU_CONSTANTS = {
    USE_AS_TEMPLATE: 'use_as_template',
    PUBLISH: 'publish',
    CHANGE_TITLE: 'changetitle',
    DELETE: 'delete',
    EDIT: 'edit',
    UNPUBLISH: 'unpublish',
    LOCK: 'lock',
    UNLOCK: 'unlock',
    CREATE_WORKSHEET: 'createworksheet',
    WORKSHEET: 'worksheet',
    TEMPLATE: 'template',
    CREATE_THUMBNAIL: 'create_thumbnail',
    EDIT_THUMBNAIL: 'edit_thumbnail',
};
