import React, { FC } from 'react';
import './list-header.css';

const ListHeader: FC = () => {
    return (
        <>
            <div>
                <div className="h1-strong text-c300 mb-8">
                    Creating new Worksheet
                </div>
                <div className="body-l text-c300">
                    Import worksheets from Library or create new worksheet to
                    create new worksheet
                </div>
            </div>
        </>
    );
};

export default ListHeader;
