import { async } from '@firebase/util';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { idText } from 'typescript';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
import { WORKSHEET_CONSTANTS } from '../../constants/worksheetConstants';
import { DraggableElement } from '../../models/draggable-element.model';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';
import {
    Submission,
    WorksheetSubmissionDetail,
} from '../../models/worksheet-submission-detail.model';
import { AuthService } from '../../service/auth.service';

const _authService = new AuthService();

const baseUrl = process.env.REACT_APP_WORKSHEET_API_URL + 'api/v1/';

const smartSchoolUrl = process.env.REACT_APP_APLS_API_URL + 'api/v1';

export const getWorksheetDetail = createAsyncThunk(
    'worksheet/getWorksheetDetail',
    async (id: any) => {
        const apiurl = baseUrl + 'worksheets/' + id;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.get(apiurl, { headers });
        const worksheetDetail: WorksheetDetailModel[] = [];
        const mapWorksheetComponents = (components: any) => {
            const componentArray: DraggableElement[] = [];
            components.map((component: any) => {
                const componentObj: DraggableElement = {
                    id: component._id,
                    type: component.type,
                    location: component.location,
                    size: component.size,
                    data: component.data,
                };
                componentArray.push(componentObj);
            });
            return componentArray;
        };
        [response.data].map((worksheet: any) => {
            const worksheetObj: WorksheetDetailModel = {
                id: worksheet._id,
                name: worksheet.name,
                studentGroup: worksheet.studentGroup
                    ? worksheet.studentGroup
                    : null,
                publishedStatus: worksheet.publishedStatus,
                components: mapWorksheetComponents(
                    worksheet.worksheetComponents
                ),
                componentGroup: worksheet.componentGroup
                    ? worksheet.componentGroup
                    : [],
                createdAt: worksheet.createdAt,
                updatedAt: worksheet.updatedAt,
                mode: worksheet.mode,
                submissionCount: worksheet.submissionCount,
                isLocked: worksheet.isLocked,
                thumbnailUrl: worksheet.thumbnailUrl,
                labelId: worksheet.labelId,
                label: worksheet.label != undefined ? worksheet.label : null,
                students:
                    worksheet.studentId != undefined &&
                    worksheet.studentId != null
                        ? worksheet.studentId
                        : [],
            };
            worksheetDetail.push(worksheetObj);
        });

        console.log('worksheet', worksheetDetail[0]);
        return worksheetDetail[0];
    }
);

export const getTemplateDetail = createAsyncThunk(
    'worksheet/getTemplateDetail',
    async (id: any) => {
        const apiurl = baseUrl + 'templates/' + id;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.get(apiurl, { headers });
        const worksheetDetail: WorksheetDetailModel[] = [];
        const mapWorksheetComponents = (components: any) => {
            const componentArray: DraggableElement[] = [];
            components.map((component: any) => {
                const componentObj: DraggableElement = {
                    id: component._id,
                    type: component.type,
                    location: component.location,
                    size: component.size,
                    data: component.data,
                };
                componentArray.push(componentObj);
            });
            return componentArray;
        };
        [response.data].map((worksheet: any) => {
            const worksheetObj: WorksheetDetailModel = {
                id: worksheet._id,
                name: worksheet.name,
                studentGroup: worksheet.studentGroup
                    ? worksheet.studentGroup
                    : null,
                publishedStatus: worksheet.publishedStatus,
                components: mapWorksheetComponents(
                    worksheet.templateComponents
                ),
                componentGroup: worksheet.componentGroup
                    ? worksheet.componentGroup
                    : [],
                createdAt: worksheet.createdAt,
                updatedAt: worksheet.updatedAt,
                mode: worksheet.mode,
                submissionCount: worksheet.submissionCount,
                isLocked: worksheet.isLocked,
                thumbnailUrl: worksheet.thumbnailUrl,
                labelId: worksheet.labelId,
                label: worksheet.label != undefined ? worksheet.label : null,
                students:
                    worksheet.studentId != undefined &&
                    worksheet.studentId != null
                        ? worksheet.studentId
                        : [],
            };
            worksheetDetail.push(worksheetObj);
        });

        console.log('worksheet', worksheetDetail[0]);
        return worksheetDetail[0];
    }
);

const mapWorksheetSubmissions = (submissions: any) => {
    const submissionArray: Submission[] = [];
    submissions.map((submission: any) => {
        const components: DraggableElement[] = [];

        components.push(
            ...mapWorksheetComponents(submission.worksheetComponents)
        );
        components.push(...mapFeedbacks(submission.teacherFeedback));

        const submissionObj: Submission = {
            submittedTime: submission.submittedTime,
            submissionKey: submission.submissionKey,
            components: components,
            teacherFeedback: [],
        };
        submissionArray.push(submissionObj);
    });

    return submissionArray;
};

const mapFeedbacks = (feedbacks: any) => {
    const feedbackArray: DraggableElement[] = [];

    feedbacks.map((feedback: any) => {
        const feedbackObj: DraggableElement = {
            id: feedback._id,
            type: TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK,
            location: feedback.location,
            size: feedback.size,
            data: feedback.data,
        };

        feedbackArray.push(feedbackObj);
    });

    return feedbackArray;
};

const calculateScores = (components: any) => {
    const score: { base: number; mark: number } = { base: 0, mark: 0 };

    components.map((component: DraggableElement) => {
        if (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
            score.base += component.data.score.base
                ? component.data.score.base
                : 0;
            score.mark += component.data.score.mark
                ? component.data.score.mark
                : 0;
        }
    });

    return score;
};

const mapWorksheetComponents = (components: any) => {
    const componentArray: DraggableElement[] = [];
    const totalScore = calculateScores(components);

    components.map((component: any) => {
        const componentObj: DraggableElement = {
            id: component._id,
            type: component.type,
            location: component.location,
            size: component.size,
            data: component.data,
        };
        if (componentObj.type === TOOLBAR_BTN_ACTION_CONSTANTS.SCORE) {
            componentObj.data.score = totalScore;
        }
        componentArray.push(componentObj);
    });
    return componentArray;
};

const getUsernameInitials = (username: string) => {
    const initials = username.charAt(0).toUpperCase();
    return initials;
};

const defaultProfileImage =
    'https://pagewerkz-main.s3.amazonaws.com/user-profile-svg-icons/grey_deco_11.svg';

const getDefaultProfileImage = (username: string) => {
    const profileImage = {
        initials: getUsernameInitials(username),
        profileImage: defaultProfileImage,
    };
    return profileImage;
};

const getDefaultStudentData = (studentId: string) => {
    const data = {
        id: studentId,
        username: studentId,
        usertype: '',
        generatedProfileImage: getDefaultProfileImage(studentId),
    };
    return data;
};

const isEmptyObj = (obj: any) => {
    return JSON.stringify(obj) == '{}';
};

export const getWorksheetSubmissionDetails = createAsyncThunk(
    'worksheet/getWorksheetSubmissionDetails',
    async (worksheetId: string) => {
        const apiUrl = baseUrl + 'worksheets/' + worksheetId + '/submission';
        const headers = _authService.getHeadersWithLessonId();
        const worksheetSubmissions: WorksheetSubmissionDetail[] = [];
        let submissionMode = WORKSHEET_CONSTANTS.MODE_SINGLE;

        const response = await axios.get(apiUrl, { headers });

        console.log('submissionsResponse', response.data.submissions);

        if (response.data.mode) {
            submissionMode = response.data.mode;
        }

        response.data.submissions.map((submission: any) => {
            const validCondition =
                submission.studentData != undefined &&
                submission.studentData != null &&
                !isEmptyObj(submission.studentData);
            const validImage =
                submission.studentData.generatedProfileImage != undefined &&
                submission.studentData.generatedProfileImage != null &&
                submission.studentData.generatedProfileImage != '' &&
                !isEmptyObj(submission.studentData.generatedProfileImage);
            const validStructure = {
                id: submission.studentData._id,
                username: submission.studentData.username,
                usertype: submission.studentData.usertype,
                generatedProfileImage: validImage
                    ? submission.studentData.generatedProfileImage
                    : getDefaultProfileImage(submission.studentData.username),
            };
            const worksheetSubmissionDetail: WorksheetSubmissionDetail = {
                studentId: submission.studentId,
                studentData: validCondition
                    ? validStructure
                    : getDefaultStudentData(submission.studentId),
                submissions: mapWorksheetSubmissions(submission.submissions),
            };
            console.log('worksheetSubmissions', worksheetSubmissions);

            worksheetSubmissions.push(worksheetSubmissionDetail);
        });

        return {
            mode: submissionMode,
            worksheetSubmissions: worksheetSubmissions,
        };
    }
);

export const addFeedback = createAsyncThunk(
    'worksheet/addFeedback',
    async (payload: any) => {
        const teacherInfo = _authService.getUserInfo();
        payload.teacherId = teacherInfo.userid;
        const apiUrl = baseUrl + 'feedbacks';
        const headers = _authService.getHeadersWithLessonId();

        const response = await axios.post(apiUrl, payload, { headers });

        return response.data;
    }
);

export const updateFeedback = createAsyncThunk(
    'worksheet/updateFeedback',
    async (payload: any) => {
        const teacherInfo = _authService.getUserInfo();
        payload.teacherId = teacherInfo.userid;
        const apiUrl = baseUrl + 'feedbacks/' + payload.id;
        const headers = _authService.getHeadersWithLessonId();

        const response = await axios.put(apiUrl, payload, { headers });

        return response.data;
    }
);

export const deleteFeedback = createAsyncThunk(
    'worksheet/deleteFeedback',
    async (payload: any) => {
        const apiUrl = baseUrl + 'feedbacks/' + payload.id;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.delete(apiUrl, { headers });

        return response.data;
    }
);

export const postWorksheetComponent = createAsyncThunk(
    'worksheet/postWorksheetComponent',
    async (payload: any) => {
        const { id, body } = payload;
        const apiurl = baseUrl + `worksheets/${id}/components`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.post(apiurl, body, { headers });
        return response.data;
    }
);

export const postTemplateComponent = createAsyncThunk(
    'worksheet/postTemplateComponent',
    async (payload: any) => {
        const { id, body } = payload;
        const apiurl = baseUrl + `templates/${id}/components`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.post(apiurl, body, { headers });
        return response.data;
    }
);

export const deleteWorksheetComponent = createAsyncThunk(
    'worksheet/deleteWorksheetComponent',
    async (payload: any) => {
        const { id, componentId } = payload;
        const apiurl = baseUrl + `worksheets/${id}/components/${componentId}`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.delete(apiurl, { headers });
        return response.data;
    }
);

export const deleteTemplateComponent = createAsyncThunk(
    'worksheet/deleteTemplateComponent',
    async (payload: any) => {
        const { id, componentId } = payload;
        const apiurl = baseUrl + `templates/${id}/components/${componentId}`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.delete(apiurl, { headers });
        return response.data;
    }
);

export const updateWorksheetComponent = createAsyncThunk(
    'worksheet/updateWorksheetComponent',
    async (payload: any) => {
        const { id, componentId, body } = payload;
        const apiurl = baseUrl + `worksheets/${id}/components/${componentId}`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.put(apiurl, body, { headers });
        return response.data;
    }
);

export const updateTemplateComponent = createAsyncThunk(
    'worksheet/updateTemplateComponent',
    async (payload: any) => {
        const { id, componentId, body } = payload;
        const apiurl = baseUrl + `templates/${id}/components/${componentId}`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.put(apiurl, body, { headers });
        return response.data;
    }
);

export const uploadMedia = createAsyncThunk(
    'worksheet/updateWorksheetComponent',
    async (payload: any) => {
        const { id, body } = payload;
        console.log('uploadMedia:', body);
        const apiurl = baseUrl + `worksheets/${id}/media`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.post(apiurl, body, { headers });
        return response.data;
    }
);

export const uploadTemplateMedia = createAsyncThunk(
    'worksheet/uploadTemplateMedia',
    async (payload: any) => {
        const { id, body } = payload;
        console.log('uploadMedia:', body);
        const apiurl = baseUrl + `templates/${id}/media`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.post(apiurl, body, { headers });
        return response.data;
    }
);

export const postGrading = createAsyncThunk(
    'worksheet/postGrading',
    async (body: any) => {
        const teacherInfo = _authService.getUserInfo();
        body.teacherId = teacherInfo.userid;

        console.log('GradingBody', body);
        console.log('TeacherInfo', teacherInfo);

        const apiUrl = baseUrl + 'gradings';
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.post(apiUrl, body, { headers });

        return response.data;
    }
);

export const saveTeacherActionsApi = createAsyncThunk(
    'worksheet/saveActions',
    async (payload: any) => {
        console.log('saveTeacherActionsApi:', payload);
        const apiurl = baseUrl + 'teacher-action/save';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiurl, payload, { headers });
        return response.data;
    }
);

export const getTeacherDetail = createAsyncThunk(
    'worksheet/getTeacherDetail',
    async (id: string[]) => {
        const apiUrl = smartSchoolUrl + '/users';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiUrl, { userId: id }, { headers });
        return response.data;
    }
);

// worksheets/:worksheetId/students/:studentId/share
export const getToken = createAsyncThunk(
    'worksheet/getToken',
    async (payload: any) => {
        const { worksheetId, studentId } = payload;
        const apiUrl =
            baseUrl + `worksheets/${worksheetId}/students/${studentId}/share`;
        const headers = _authService.getHeadersInfo();
        const response = await axios.get(apiUrl, { headers });
        return response.data;
    }
);

export const cloneElementApi = createAsyncThunk(
    'worksheet/cloneElement',
    async (payload: any) => {
        console.log('cloneElementApi:', payload);
        const { id, sheetId, position } = payload;
        const apiurl = baseUrl + `worksheets/${sheetId}/clone_component`;
        const headers = _authService.getHeadersInfo();
        const body = {
            componentId: id,
            newLocation: {
                x: position.x,
                y: position.y,
            },
        };

        const response = await axios.post(apiurl, body, { headers });
        return response.data;
    }
);

export const cloneTemplateElementApi = createAsyncThunk(
    'worksheet/cloneTemplateElement',
    async (payload: any) => {
        console.log('cloneTemplateElementApi:', payload);
        const { id, sheetId, position } = payload;
        const apiurl = baseUrl + `templates/${sheetId}/clone_component`;
        const headers = _authService.getHeadersInfo();
        const body = {
            componentId: id,
            newLocation: {
                x: position.x,
                y: position.y,
            },
        };

        const response = await axios.post(apiurl, body, { headers });
        return response.data;
    }
);
