import React, { FC, useEffect } from 'react';
import { AlertModel } from '../../models/alert.model';
import './custom-alert.css';
import Tick from '../../assets/images/alert/tick.svg';
import Error from '../../assets/images/alert/cross.svg';
export interface IProps {
    alertData: AlertModel;
    alertClose: () => void;
}
const CustomAlert: FC<IProps> = ({ alertData, alertClose }) => {
    useEffect(() => {
        setTimeout(() => {
            alertClose();
        }, 3000);
    }, []);

    return (
        <div className={alertData.uiClass}>
            <img src={alertData.type == 'success' ? Tick : Error} alt="" />{' '}
            {alertData.title}
        </div>
    );
};

export default CustomAlert;
