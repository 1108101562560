import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
import { ChatLogDataModel } from '../../models/chat-log-data.model';
import { DraggableElement } from '../../models/draggable-element.model';
import { WorksheetDetailModel } from '../../models/worksheet-detail.model';
import { AuthService } from '../../service/auth.service';

const _authService = new AuthService();
const baseUrl = process.env.REACT_APP_WORKSHEET_API_URL + 'api/v1/';
const baseUrlWithoutV1 = process.env.REACT_APP_WORKSHEET_API_URL;

const smartSchoolUrl = process.env.REACT_APP_APLS_API_URL + 'api/v1';

export const submitWorksheet = createAsyncThunk(
    'worksheet/submitWorksheet',
    async (payload: any) => {
        console.log('Worksheet submit payload', payload, payload.worksheetId);

        const apiUrl =
            baseUrl + 'worksheets/' + payload.worksheetId + '/submit';
        const headers = _authService.getHeadersInfo();
        const body = {
            studentId: payload.studentId,
        };
        const response = await axios.post(apiUrl, body, { headers });

        return response.data;
    }
);

export const uploadMedia = createAsyncThunk(
    'worksheet/uploadMedia',
    async (payload: any) => {
        const { id, body } = payload;
        console.log(
            'upload Media payload',
            // payload.file.get('file'),
            payload.worksheetId
        );

        const apiUrl = baseUrl + `student-action/${id}/media`;
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiUrl, body, { headers });

        return response.data;
    }
);

export const studentAction = createAsyncThunk(
    'worksheet/studentAction',
    async (payload: any) => {
        const { id, body } = payload;
        console.log('upload Media payload', payload);

        const apiUrl = baseUrl + 'student-action';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiUrl, payload, { headers });

        return response.data;
    }
);

export const getWorksheetDetail = createAsyncThunk(
    'worksheet/getWorksheetDetail',
    async (payload: any) => {
        const { studentId, worksheetId } = payload;
        const apiurl =
            baseUrl + `student/${studentId}/worksheets/${worksheetId}`;
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.get(apiurl, { headers });
        const worksheetDetail: WorksheetDetailModel[] = [];
        const mapComponentData = (data: any) => {
            // console.log('mapComponentData');
            data.map((item: any) => {
                // console.log(item.chatLog);
                if (item.chatLog) {
                    // console.log('data.chatLog exist');
                    const chatLogArray: ChatLogDataModel[] = [];
                    item.chatLog.map((log: any) => {
                        const chatLog: ChatLogDataModel = {
                            id: log._id,
                            type: log.type,
                            data: {
                                url: log.data.url ? log.data.url : null,
                                text: log.data.text ? log.data.text : null,
                                name: log.data.name ? log.data.name : null,
                            },
                            studentName: log.studentName,
                            studentSubmissionId: log.studentSubmissionId,
                        };
                        chatLogArray.push(chatLog);
                    });
                    item.chatLog = chatLogArray;
                    return item;
                } else {
                    // console.log('else');
                    return item;
                }
            });
            return data[0];
        };
        const mapTeacherFeedbacks = (feedbacks: any) => {
            const feedbackArray: DraggableElement[] = [];

            feedbacks.map((feedback: any) => {
                const feedbackObj: DraggableElement = {
                    id: feedback._id,
                    type: TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK,
                    location: feedback.location,
                    size: feedback.size,
                    data: feedback.data,
                };

                feedbackArray.push(feedbackObj);
            });

            console.log('FeedbackArray', feedbackArray);

            return feedbackArray;
        };
        const mapWorksheetComponents = (components: any) => {
            const componentArray: DraggableElement[] = [];
            components.map((component: any) => {
                if (component.type) {
                    const componentObj: DraggableElement = {
                        id: component._id,
                        type: component.type,
                        location: component.location,
                        size: component.size,
                        data: mapComponentData([component.data]),
                        // chatLog: mapComponentData([component.chatLog]),
                    };
                    componentArray.push(componentObj);
                }
            });
            return componentArray;
        };
        [response.data].map((worksheet: any) => {
            const mergedComponents: DraggableElement[] = [];

            mergedComponents.push(
                ...mapTeacherFeedbacks(worksheet.teacherFeedback)
            );

            console.log('Merged Components', mergedComponents);

            mergedComponents.push(
                ...mapWorksheetComponents(worksheet.worksheetComponents)
            );

            const worksheetObj: WorksheetDetailModel = {
                id: worksheet._id,
                name: worksheet.name,
                studentGroup: worksheet.studentGroup
                    ? worksheet.studentGroup
                    : null,
                publishedStatus: worksheet.publishedStatus,
                components: mergedComponents,
                componentGroup: worksheet.componentGroup
                    ? worksheet.componentGroup
                    : [],
                createdAt: worksheet.createdAt,
                updatedAt: worksheet.updatedAt,
                mode: worksheet.mode,
                submissionCount: worksheet.submissionCount,
                isLocked: worksheet.isLocked,
                thumbnailUrl: worksheet.thumbnailUrl,
                labelId: worksheet.labelId,
                label: worksheet.label != undefined ? worksheet.label : null,
                students:
                    worksheet.studentId != undefined &&
                    worksheet.studentId != null
                        ? worksheet.studentId
                        : [],
            };
            worksheetDetail.push(worksheetObj);
        });

        console.log('worksheet', worksheetDetail[0]);
        return worksheetDetail[0];
    }
);

export const deleteStudentInput = createAsyncThunk(
    'worksheet/deleteStudentInput',
    async (payload: any) => {
        const { userInputId, studentSubmissionId } = payload;
        console.log('delete Media payload', payload);

        const apiUrl =
            baseUrl +
            `student-action/${studentSubmissionId}/userInput/${userInputId}`;
        const headers = _authService.getHeadersInfo();
        const response = await axios.delete(apiUrl, { headers });
        return response.data;
    }
);

export const editStudentInput = createAsyncThunk(
    'worksheet/editStudentInput',
    async (payload: any) => {
        const { body, studentSubmissionId } = payload;
        console.log('edit Media payload', payload);

        const apiUrl = baseUrl + `student-action/${studentSubmissionId}`;
        const headers = _authService.getHeadersInfo();
        const response = await axios.put(apiUrl, body, { headers });
        return response.data;
    }
);

export const getStudentDetail = createAsyncThunk(
    'worksheet/getStudentDetail',
    async (id: string[]) => {
        const apiUrl = smartSchoolUrl + '/users';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiUrl, { userId: id }, { headers });
        return response.data;
    }
);

// student/:studentId/worksheets/:worksheetId/components/:componentId
export const getStudentInputDetail = createAsyncThunk(
    'worksheet/getStudentInputDetail',
    async (payload: any) => {
        const { studentId, worksheetId, componentId } = payload;
        const apiUrl =
            baseUrl +
            `student/${studentId}/worksheets/${worksheetId}/components/${componentId}`;
        const headers = { ..._authService.getHeadersInfo(), mode: 'collab' };
        const response = await axios.get(apiUrl, { headers });
        console.log(
            'in api call , ',
            response,
            response.data.worksheetComponent
        );
        const map: any = response.data;
        const mapComponentData = (data: any) => {
            // console.log('mapComponentData');
            data.map((item: any) => {
                // console.log(item.chatLog);
                if (item.chatLog) {
                    // console.log('data.chatLog exist');
                    const chatLogArray: ChatLogDataModel[] = [];
                    item.chatLog.map((log: any) => {
                        const chatLog: ChatLogDataModel = {
                            id: log._id,
                            type: log.type,
                            data: {
                                url: log.data.url ? log.data.url : null,
                                text: log.data.text ? log.data.text : null,
                                name: log.data.name ? log.data.name : null,
                            },
                            studentName:log.studentName,
                            studentSubmissionId: log.studentSubmissionId,
                        };
                        chatLogArray.push(chatLog);
                    });
                    item.chatLog = chatLogArray;
                    return item;
                } else {
                    // console.log('else');
                    return item;
                }
            });
            return data[0];
        };
        const component: DraggableElement = {
            id: map.worksheetComponent._id,
            type: map.worksheetComponent.type,
            location: map.worksheetComponent.location,
            size: map.worksheetComponent.size,
            data: mapComponentData([map.worksheetComponent.data]),
        };
        console.log('ha ha `````` :', component);
        return component;
    }
);
export const getWorksheetDetailWithToken = createAsyncThunk(
    'worksheet/getWorksheetDetailWithToken',
    async (id: string) => {
        const apiUrl = baseUrlWithoutV1 + 'share/' + id;
        const response = await axios.get(apiUrl);
        const worksheetDetail: WorksheetDetailModel[] = [];
        const mapComponentData = (data: any) => {
            // console.log('mapComponentData');
            data.map((item: any) => {
                // console.log(item.chatLog);
                if (item.chatLog) {
                    // console.log('data.chatLog exist');
                    const chatLogArray: ChatLogDataModel[] = [];
                    item.chatLog.map((log: any) => {
                        const chatLog: ChatLogDataModel = {
                            id: log._id,
                            type: log.type,
                            data: {
                                url: log.data.url ? log.data.url : null,
                                text: log.data.text ? log.data.text : null,
                                name: log.data.name ? log.data.name : null,
                            },
                            studentName:log.studentName,
                            studentSubmissionId: log.studentSubmissionId,
                        };
                        chatLogArray.push(chatLog);
                    });
                    item.chatLog = chatLogArray;
                    return item;
                } else {
                    // console.log('else');
                    return item;
                }
            });
            return data[0];
        };
        const mapTeacherFeedbacks = (feedbacks: any) => {
            const feedbackArray: DraggableElement[] = [];

            feedbacks.map((feedback: any) => {
                const feedbackObj: DraggableElement = {
                    id: feedback._id,
                    type: TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK,
                    location: feedback.location,
                    size: feedback.size,
                    data: feedback.data,
                };

                feedbackArray.push(feedbackObj);
            });

            console.log('FeedbackArray', feedbackArray);

            return feedbackArray;
        };
        const mapWorksheetComponents = (components: any) => {
            const componentArray: DraggableElement[] = [];
            components.map((component: any) => {
                if (component.type) {
                    const componentObj: DraggableElement = {
                        id: component._id,
                        type: component.type,
                        location: component.location,
                        size: component.size,
                        data: mapComponentData([component.data]),
                        // chatLog: mapComponentData([component.chatLog]),
                    };
                    componentArray.push(componentObj);
                }
            });
            return componentArray;
        };
        [response.data].map((worksheet: any) => {
            const mergedComponents: DraggableElement[] = [];

            mergedComponents.push(
                ...mapTeacherFeedbacks(worksheet.teacherFeedback)
            );

            console.log('Merged Components', mergedComponents);

            mergedComponents.push(
                ...mapWorksheetComponents(worksheet.worksheetComponents)
            );

            const worksheetObj: WorksheetDetailModel = {
                id: worksheet._id,
                name: worksheet.name,
                studentGroup: worksheet.studentGroup
                    ? worksheet.studentGroup
                    : null,
                publishedStatus: worksheet.publishedStatus,
                components: mergedComponents,
                componentGroup: worksheet.componentGroup
                    ? worksheet.componentGroup
                    : [],
                createdAt: worksheet.createdAt,
                updatedAt: worksheet.updatedAt,
                mode: worksheet.mode,
                submissionCount: worksheet.submissionCount,
                isLocked: worksheet.isLocked,
                thumbnailUrl: worksheet.thumbnailUrl,
                labelId: worksheet.labelId,
                label: worksheet.label != undefined ? worksheet.label : null,
                students:
                    worksheet.studentId != undefined &&
                    worksheet.studentId != null
                        ? worksheet.studentId
                        : [],
            };
            worksheetDetail.push(worksheetObj);
        });

        console.log('worksheet', worksheetDetail[0]);
        return worksheetDetail[0];
    }
);
