import React, { FC, useEffect, useState } from 'react';
import { DropdownModel } from '../../models/dropdown.model';
import './drop-down-with-search.css';

import ChevronDownIcon from '../../assets/images/chevron_down_s.svg';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { STYLING_TOOL_BAR_CONSTANTS } from '../../constants/stylingToolBarConstants';
import {
    setFont,
    setFontSize,
    setFontWeight,
} from '../../features/teacher/slices/worksheetSlice';
import { current } from '@reduxjs/toolkit';
import { DraggableElement } from '../../models/draggable-element.model';
export interface IProps {
    type?: string;
    currentItem: DraggableElement | null;
    handleFontChange: (name: string) => void;
    handleFontSizeChange: (name: string) => void;
}

const DropdownWithSearch: FC<IProps> = ({
    type,
    currentItem,
    handleFontChange,
    handleFontSizeChange,
}) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [searchData, setSearchData] = useState<string | null>(null);
    const [currentFont, setCurrentFont] = useState('Inter');
    const [currentFontSize, setCurrentFontSize] = useState('24');
    const [currentFontWeight, setCurrentFontWeight] = useState('bold');

    const fontList = useSelector((state: any) => state.worksheet.fontList);
    const isText = useSelector((state: any) => state.worksheet.isText);
    const fontWeightList = useSelector(
        (state: any) => state.worksheet.fontWeightList
    );
    const fontSizeList = useSelector(
        (state: any) => state.worksheet.fontSizeList
    );

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSearch = (e: any) => {
        console.log('', e.target.value);
        setSearchData(e.target.value);
    };

    const handleItemChoose = (name: string) => {
        switch (type) {
            case STYLING_TOOL_BAR_CONSTANTS.FONT:
                {
                    // dispatch(setFont(name));
                    setCurrentFont(name);
                    handleFontChange(name);
                }
                break;
            case STYLING_TOOL_BAR_CONSTANTS.FONT_WEIGHT:
                {
                    // dispatch(setFontWeight(name));
                    // setCurrentFontWeight(name);
                    // handleFontWeightChange(name);
                }
                break;
            case STYLING_TOOL_BAR_CONSTANTS.FONT_SIZE:
                {
                    // dispatch(setFontSize(name));
                    setCurrentFontSize(name);
                    handleFontSizeChange(name);
                }
                break;
        }
        toggleDropdown();
        setSearchData('');
    };

    const getCurrentFont = () => {
        fontList.map((item: DropdownModel) => {
            if (
                isText && currentItem &&
                currentItem.data.style &&
                item.name === currentItem!.data!.style!.fontFamily
            ) {
                setCurrentFont(item.name);
            } else if (
                !isText && currentItem &&
                currentItem.data.descStyle &&
                item.name === currentItem!.data!.descStyle.fontFamily
            ) {
                setCurrentFont(item.name);
            }
        });
    };

    const getCurrentFontWeight = () => {
        fontWeightList.map((item: DropdownModel) => {
            if (
                isText && currentItem &&
                currentItem.data.style &&
                item.name === currentItem!.data!.style!.fontWeight
            ) {
                setCurrentFontWeight(item.name);
            } else if (
                !isText && currentItem &&
                currentItem.data.descStyle &&
                item.name === currentItem!.data!.descStyle.fontWeight
            ) {
                setCurrentFontWeight(item.name);
            }
        });

    };
    const getCurrentFontSize = () => {
        fontSizeList.map((item: DropdownModel) => {
            if (
                isText && currentItem &&
                currentItem.data.style &&
                item.name.concat('px') === currentItem!.data!.style!.fontSize
            ) {
                setCurrentFontSize(item.name);
            } else if (
                !isText && currentItem &&
                currentItem.data.descStyle &&
                item.name.concat('px') === currentItem!.data!.descStyle.fontSize
            ) {
                setCurrentFontSize(item.name);
            }
        });
    };

    const searchFontList = fontList.map((item: DropdownModel) => {
        if (searchData && item.name.includes(searchData)) {
            return (
                <div
                    key={item.id}
                    className="data-list-item small"
                    onClick={() => handleItemChoose(item.name)}
                >
                    {item.name}
                </div>
            );
        } else if (!searchData) {
            return (
                <div
                    key={item.id}
                    className="data-list-item small"
                    onClick={() => handleItemChoose(item.name)}
                >
                    {item.name}
                </div>
            );
        }
    });

    const searchFontWeightList = fontWeightList.map((item: DropdownModel) => {
        if (searchData && item.name.includes(searchData)) {
            return (
                <div
                    key={item.id}
                    className="data-list-item small"
                    onClick={() => handleItemChoose(item.name)}
                >
                    {item.name}
                </div>
            );
        } else if (!searchData) {
            return (
                <div
                    key={item.id}
                    className="data-list-item small"
                    onClick={() => handleItemChoose(item.name)}
                >
                    {item.name}
                </div>
            );
        }
    });

    const searchFontSizeList = fontSizeList.map((item: DropdownModel) => {
        if (searchData && item.name.includes(searchData)) {
            return (
                <div
                    key={item.id}
                    className="data-list-item small"
                    onClick={() => handleItemChoose(item.name)}
                >
                    {item.name}
                </div>
            );
        } else if (!searchData) {
            return (
                <div
                    key={item.id}
                    className="data-list-item small"
                    onClick={() => handleItemChoose(item.name)}
                >
                    {item.name}
                </div>
            );
        }
    });

    const getDataTitleWidth = () => {
        switch (type) {
            case STYLING_TOOL_BAR_CONSTANTS.FONT:
                return '200px';
            case STYLING_TOOL_BAR_CONSTANTS.FONT_WEIGHT:
                return '107px';
            case STYLING_TOOL_BAR_CONSTANTS.FONT_SIZE:
                return '74px';
            default:
                return '200px';
        }
    };

    const getInputWidth = () => {
        switch (type) {
            case STYLING_TOOL_BAR_CONSTANTS.FONT:
                return '180px';
            case STYLING_TOOL_BAR_CONSTANTS.FONT_WEIGHT:
                return '87px';
            case STYLING_TOOL_BAR_CONSTANTS.FONT_SIZE:
                return '54px';
            default:
                return '180px';
        }
    };

    const getMarginLeftData = () => {
        switch (type) {
            case STYLING_TOOL_BAR_CONSTANTS.FONT:
                return '36px';
            case STYLING_TOOL_BAR_CONSTANTS.FONT_WEIGHT:
                return '18px';
            case STYLING_TOOL_BAR_CONSTANTS.FONT_SIZE:
                return '16px';
            default:
                return '0px';
        }
    };



    useEffect(() => {
        getCurrentFont();
        getCurrentFontSize();
        getCurrentFontWeight();
        console.log('currentItem:', currentItem);
        return () => {
            //
        };
    }, []);


    return (
        <>
            <div
                className="dropdown-wrapper"
                style={{ marginLeft: getMarginLeftData() }}
            >
                <div
                    className="data-title"
                    onClick={toggleDropdown}
                    style={{
                        width: getDataTitleWidth(),
                    }}
                >
                    <span className="small">
                        {type === STYLING_TOOL_BAR_CONSTANTS.FONT &&
                            currentFont}
                        {type === STYLING_TOOL_BAR_CONSTANTS.FONT_WEIGHT &&
                            currentFontWeight}
                        {type === STYLING_TOOL_BAR_CONSTANTS.FONT_SIZE &&
                            currentFontSize}
                    </span>
                    <img
                        src={ChevronDownIcon}
                        className="chevron-down"
                        alt="chevron-down-icon"
                    />
                </div>
                {showDropdown && (
                    <div
                        className="data-list-wrapper"
                        style={{
                            width: getDataTitleWidth(),
                        }}
                    >
                        <div className="data-list-inner-wrapper">
                            <input
                                type="text"
                                name="search"
                                id="search"
                                className="data-list-search"
                                onChange={handleSearch}
                                style={{
                                    width: getInputWidth(),
                                }}
                            />
                            <div className="data-list">
                                {type === STYLING_TOOL_BAR_CONSTANTS.FONT &&
                                    searchFontList}
                                {type ===
                                    STYLING_TOOL_BAR_CONSTANTS.FONT_WEIGHT &&
                                    searchFontWeightList}
                                {type ===
                                    STYLING_TOOL_BAR_CONSTANTS.FONT_SIZE &&
                                    searchFontSizeList}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DropdownWithSearch;
