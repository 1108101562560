import React, { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '../constants/routeConstants';
import Editor from '../features/editor/Editor';
import Home from '../features/home/Home';
import WorksheetsHome from '../features/worksheets-home/worksheets-home';
import TemplateLibrary from '../features/template-library/template-library';
import NotFound from '../features/not-found/NotFound';
import Student from '../features/student/Student';
import ActivityLog from '../features/activity-log/activity-log';
import RedirectComponent from '../features/redirect/redirect';
import ViewSubmission from '../features/view-submission/view-submission';

const Router: FC = () => {
    const routes = useRoutes([
        { path: '*', element: <NotFound /> },
        { path: ROUTE_CONSTANTS.NOT_FOUND, element: <NotFound /> },
        {
            path: ROUTE_CONSTANTS.HOME,
            children: [{ index: true, element: <Home /> }],
        },
        {
            path: ROUTE_CONSTANTS.WORKSHEET_LIST,
            children: [{ index: true, element: <WorksheetsHome /> }],
        },
        {
            path: ROUTE_CONSTANTS.TEMPLATE_LIBRARY,
            children: [{ index: true, element: <TemplateLibrary /> }],
        },
        {
            path: ROUTE_CONSTANTS.EDITOR,
            children: [{ path: ':id', element: <Editor /> }],
        },
        {
            path: ROUTE_CONSTANTS.STUDENT,
            children: [{ path: ':id/sheet/:sheetId', element: <Student /> }],
        },
        {
            path: ROUTE_CONSTANTS.ACTIVITY_LOG,
            children: [{ path: ':id', element: <ActivityLog /> }],
        },
        {
            path: ROUTE_CONSTANTS.REDIRECT,
            children: [{ index: true, element: <RedirectComponent /> }]
        },
        {
            path: ROUTE_CONSTANTS.VIEW_SUBMISSION,
            children: [{ path: ':worksheetid', element: <ViewSubmission /> }]
        },
        {
            path: ROUTE_CONSTANTS.PUBILC,
            children: [{
                path: ':token', element: <Student />
            }]
        },
        { path: ROUTE_CONSTANTS.NOT_FOUND, element: <NotFound /> },
    ]);
    return routes;
};

export default Router;
