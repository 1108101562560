import React, { FC } from 'react';
import { OverlayMenu } from '../../models/overlay-menu';
import './overlay-navigation.css';

export interface IProps {
    menuItem: OverlayMenu[];
    clickMenu: (type: string) => void;
}

const OverlayNavigation: FC<IProps> = ({ menuItem, clickMenu }) => {
    const onClickMenuItem = (type: string) => {
        clickMenu(type);
    };

    return (
        <>
            <div className="overlay-menu">
                {menuItem.map((item: any, index: any) => (
                    <div
                        className="body-m text-s0 menu-item"
                        key={index}
                        onClick={() => onClickMenuItem(item.type)}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </>
    );
};

export default OverlayNavigation;
