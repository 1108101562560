import './checklist.css';
import React, { FC, useEffect, useState } from 'react';
import Checkbox from '../../../../../../global-components/checkbox/Checkbox';
export interface IProps {
    studentList: any;
    handleCheckbox: (id: string, value: boolean) => void;
    handleAllChecked: (value: boolean) => void;
}
const CheckList: FC<IProps> = ({
    studentList,
    handleCheckbox,
    handleAllChecked,
}) => {
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const handleSelectAll = (id: string, value: boolean) => {
        handleAllChecked(value);
        setSelectAll(value);
    };

    const handleItemCheck = (id: string, value: boolean) => {
        console.log('handleItemCheck ', id);
        handleCheckbox(id, value);
    };

    useEffect(() => {
        console.log('list ', studentList);
    }, []);

    return (
        <div className="checklist-wrapper">
            <div className="select-all">
                <Checkbox
                    item={{ id: 'select-all' }}
                    uiClass="student-list"
                    checked={selectAll}
                    handleOnCheck={handleSelectAll}
                >
                    <span className="select-all-item">Select all</span>
                </Checkbox>
            </div>

            {studentList.map((student: any) => {
                return (
                    <Checkbox
                        key={student.id}
                        uiClass={'student-list'}
                        checked={student.checked}
                        handleOnCheck={handleItemCheck}
                        item={student}
                    >
                        <div className="student-checkbox-item">
                            <span className="student-profile"></span>
                            <span>{student.name}</span>
                        </div>
                    </Checkbox>
                );
            })}
        </div>
    );
};

export default CheckList;
