import React from 'react';
import CustomLoading from '../loading/loading';
import './canvas-loading.css';

export interface IProps{
    type: string;
}

const CanvasLoading:React.FC<IProps> = ({type}) => {
    const groupItem = [1,2,3,4,5,6,7,8,9];
    return (
        <div className="canvas-loading-wrapper" style={{
            height : type == 'teacher' || type === 'viewSubmission' ? 'calc(100vh - 120px)' : 'calc(100vh - 120px)'
        }}>
            <div className="canvas-loading">
                <div className="title-wrapper">
                    <CustomLoading loadingData='canvasTitleLoading'></CustomLoading>
                </div>
                <div className="body-wrapper">
                    <CustomLoading loadingData='canvasDescriptionLoading'></CustomLoading>
                    <div className="group-wrapper">
                        {groupItem.map((item, index) => {return (
                            <div key={item} className="group-content">
                                <CustomLoading loadingData='canvasItem1Loading'></CustomLoading>
                                <CustomLoading loadingData='canvasItem2Loading'></CustomLoading>
                                <CustomLoading loadingData='canvasItem3Loading'></CustomLoading>
                            </div>);}
                        )};
                    </div>
                    
                </div>
            </div>
            
            
            
        </div>
        
    );
};

export default CanvasLoading;