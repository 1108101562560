import { upload } from '@testing-library/user-event/dist/upload';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { ElementDataModel } from '../../../../models/element-data.model';
import { PointModel } from '../../../../models/point.model';
import { WorksheetComponent } from '../../../../models/worksheet-component.model';
import {
    addComponent,
    setCurrentDragItemType,
    toggleEditItem,
} from '../../../teacher/slices/worksheetSlice';
import './audio-upload.css';
export interface IProps {
    closeModal: () => void;
    modalLoading: boolean;
    editItem: boolean;
    currentId: number;
    currentDragItemType: string;
    position: PointModel;
    handleDataUpdate: (value: ElementDataModel) => void;
    addComponent: (
        element: WorksheetComponent,
        file?: File | undefined
    ) => void;
}
const AudioUpload: FC<IProps> = ({
    closeModal,
    modalLoading,
    editItem,
    currentId,
    currentDragItemType,
    position,
    handleDataUpdate,
    addComponent,
}) => {
    const dispatch = useDispatch();

    const galleryBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Upload',
        disable: false,
        btnText: 'Upload video',
        uiClass: 'gallery-upload',
        iconClass: undefined,
    };

    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Cancel',
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel',
        iconClass: undefined,
    };

    const insertBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Insert',
        disable: false,
        btnText: 'Insert',
        uiClass: 'insert',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Insert',
        uiClass: 'disabled',
        iconClass: undefined,
        loadingText: 'Uploading',
    };

    const [uploadItem, setUploadItem] = useState<null | string>(null);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    const [uploadError, setUploadError] = useState<boolean>(false);

    const handleInputChange = (e: any) => {
        console.log(e.target.files);
        const element = e.target.files[0];
        const url = URL.createObjectURL(e.target.files[0]);
        console.log('url', url, element);
        if (element.type.includes('audio')) {
            setUploadItem(url);
            setUploadError(false);
            setFile(element);
        } else {
            setUploadError(true);
        }
        // const audio: HTMLAudioElement = document.createElement('audio');

        // audio.onload = function () {
        //     // setElementSize({ width: audio.width, height: audio.height });
        //     // console.log({ width: audio.width, height: audio.height });
        // };
        // audio.src = url;
    };

    const updateElement = () => {
        console.log('update');
        const data: ElementDataModel = {
            text: null,
            score: {
                mark: null,
                base: null,
            },
            url: uploadItem,
            chatLog: null,
            style: null,
            iconType: null,
            descText: null,
            descStyle: null,
            isBack: false,
        };
        handleDataUpdate(data);
        closeModal();
    };

    const renderElement = () => {
        console.log('render');
        const element: DraggableElement = {
            id: currentId.toString(),
            type: currentDragItemType,
            location: { x: position.x, y: position.y },
            size: elementSize,
            data: {
                text: null,
                score: {
                    mark: null,
                    base: null,
                },
                url: uploadItem,
                chatLog: null,
                style: null,
                iconType: null,
                descText: null,
                descStyle: null,
                isBack: false
            },
        };

        if (uploadItem!.length > 0) {
            // dispatch(addComponent(element));
            // dispatch(setCurrentDragItemType(''));
            // dispatch(toggleEditItem(''));
            addComponent(element, file);
        }
        // closeModal();
    };

    const handleBtnClicked = (type: string | undefined) => {
        console.log('handleBtnClicked', type);
        switch (type) {
            case 'Cancel':
                if (!modalLoading) closeModal();
                break;
            case 'Upload':
                console.log('hello');
                break;
            case 'Insert':
                // if (editItem) {
                //     updateElement();
                // } else {
                //     renderElement();
                // }
                renderElement();

                break;
            default:
                closeModal();
        }
    };

    const handleOnLoad = (e: any) => {
        console.log(e.width, e.height);
    };

    const handleOnDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const url = URL.createObjectURL(e.dataTransfer.files[0]);
        const element = e.dataTransfer.files[0];
        if (element.type.includes('audio')) {
            setUploadItem(url);
            setUploadError(false);
            setFile(element);
        } else {
            setUploadError(true);
        }
    };

    const handleOnDragEnter = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragLeave = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            {uploadItem && (
                <div className="upload-item bg-s10">
                    <audio controls>
                        <source src={uploadItem} type="audio/mp3" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            )}
            {!uploadItem && (
                <div
                    className="drag-drop bg-s10"
                    onDrop={handleOnDrop}
                    onDragEnter={handleOnDragEnter}
                    onDragLeave={handleOnDragLeave}
                    onDragOver={handleOnDragOver}
                >
                    <label className="custom-file-upload body-m text-s0">
                        <input type="file" accept=".mp3" onChange={handleInputChange} />
                        Upload audio
                    </label>
                    <div className="text-s70 body-xl placeholder">
                        {uploadError
                            ? 'File type is wrong or corrupted. Please upload another.'
                            : 'Drag & drop audios here'}
                    </div>
                </div>
            )}

            <div className="modal-btn-group">
                <Button
                    btnProps={cancelBtnProps}
                    handleBtnClicked={() =>
                        handleBtnClicked(cancelBtnProps.alt)
                    }
                ></Button>
                {uploadItem && (
                    <Button
                        btnProps={
                            modalLoading ? disabledBtnProps : insertBtnProps
                        }
                        isLoading={modalLoading}
                        handleBtnClicked={() =>
                            handleBtnClicked(insertBtnProps.alt)
                        }
                    ></Button>
                )}

                {!uploadItem && (
                    <Button
                        btnProps={disabledBtnProps}
                        handleBtnClicked={() =>
                            handleBtnClicked(disabledBtnProps.alt)
                        }
                    ></Button>
                )}
            </div>
        </>
    );
};

export default AudioUpload;
