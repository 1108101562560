import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './router/Router';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                </Helmet>
            </HelmetProvider>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </>
    );
}

export default App;
