import { render } from '@testing-library/react';
import { upload } from '@testing-library/user-event/dist/upload';
import { exit } from 'process';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { ElementDataModel } from '../../../../models/element-data.model';
import { PointModel } from '../../../../models/point.model';
import { WorksheetComponent } from '../../../../models/worksheet-component.model';
import {
    addComponent,
    setCurrentDragItemType,
    toggleEditItem,
} from '../../../teacher/slices/worksheetSlice';
import './gallery-upload.css';
export interface IProps {
    closeModal: () => void;
    modalLoading: boolean;
    editItem: boolean;
    currentId: number;
    currentDragItemType: string;
    position: PointModel;
    handleDataUpdate: (value: ElementDataModel) => void;
    handleSizeUpdate: (value: any) => void;
    existBackground: DraggableElement | null;
    addComponent: (
        element: WorksheetComponent,
        file?: File | undefined
    ) => void;
}
const GalleryUpload: FC<IProps> = ({
    closeModal,
    modalLoading,
    editItem,
    currentId,
    currentDragItemType,
    position,
    existBackground,
    handleDataUpdate,
    handleSizeUpdate,
    addComponent,
}) => {
    const dispatch = useDispatch();

    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Cancel',
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel',
        iconClass: undefined,
    };

    const insertBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Insert',
        disable: false,
        btnText: 'Insert',
        uiClass: 'insert',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Insert',
        uiClass: 'disabled',
        iconClass: undefined,
        loadingText: 'Uploading',
    };

    const [uploadItem, setUploadItem] = useState<null | string>(null);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    const [uploadError, setUploadError] = useState<boolean>(false);

    const handleInputChange = (e: any) => {
        const element = e.target.files[0];
        const url = URL.createObjectURL(e.target.files[0]);
        console.log('url', url, element, element.type.includes('image'));
        if (element.type.includes('image')) {
            setUploadItem(url);
            setFile(element);
            calculateImageDimension(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const updateElement = () => {
        console.log('update', 'editItem ', editItem);
        const data: ElementDataModel = {
            text: null,
            score: {
                mark: null,
                base: null,
            },
            url: uploadItem,
            chatLog: null,
            style: null,
            iconType: null,
            descText: null,
            descStyle: null,
            isBack: false,
        };
        console.log('handleDataUpdate . . . .');
        handleDataUpdate(data);
        if (currentDragItemType !== TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND) {
            handleSizeUpdate(elementSize);
        }
        // closeModal();
    };

    const renderElement = () => {
        console.log('render', 'editItem ', editItem);
        const element: WorksheetComponent = {
            type: currentDragItemType
                ? currentDragItemType
                : TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND,
            location: { x: position.x, y: position.y },
            size: elementSize,
            data: {
                text: null,
                score: {
                    mark: null,
                    base: null,
                },
                url: uploadItem,
                chatLog: null,
                style: null,
                iconType: null,
                descText: null,
                descStyle: null,
                isBack: false,
            },
        };

        if (uploadItem!.length > 0) {
            console.log('file ::::: ', file);
            addComponent(element, file);
        }
    };

    const handleBtnClicked = (type: string | undefined) => {
        console.log('handleBtnClicked', type);
        console.log(
            'existBackground',
            existBackground,
            currentDragItemType,
            'currentDragItemType'
        );
        switch (type) {
            case 'Cancel':
                if (!modalLoading) closeModal();
                break;
            case 'Upload':
                console.log('hello');
                break;
            case 'Insert':
                renderElement();
                // if (
                //     TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND ===
                //         currentDragItemType &&
                //     existBackground
                // ) {
                //     updateElement();
                // } else if (
                //     TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND !=
                //         currentDragItemType &&
                //     editItem
                // ) {
                //     updateElement();
                // } else {
                //     renderElement();
                // }

                break;
            default:
                closeModal();
        }
    };

    const handleOnDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const url = URL.createObjectURL(e.dataTransfer.files[0]);
        const element = e.dataTransfer.files[0];
        const blob = new Blob([element], { type: element.type });
        console.log('blob . . . . . : ', blob);
        if (element.type.includes('image')) {
            setUploadItem(url);
            setFile(element);
            calculateImageDimension(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const calculateImageDimension = (url: string) => {
        const img: HTMLImageElement = document.createElement('img');

        img.onload = function () {
            console.log('onLoad', img.width, currentDragItemType);
            const isBackground =
                currentDragItemType == null ||
                currentDragItemType == TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND;
            const imgWidth = img.width > 450 && !isBackground ? 450 : img.width;
            const imgHeight =
                img.height > 450 && !isBackground
                    ? calculateImageHeight(img.width, img.height)
                    : img.height;
            setElementSize({
                width: Math.round(imgWidth),
                height: Math.round(imgHeight),
            });
        };
        img.src = url;
    };

    const calculateImageHeight = (width: number, height: number) => {
        console.log((height * 450) / width);
        return (height * 450) / width;
    };

    const handleOnDragEnter = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragLeave = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            {uploadItem && (
                <div className="upload-item bg-s10">
                    <img src={uploadItem} alt="" />
                </div>
            )}
            {!uploadItem && (
                <div
                    className="drag-drop bg-s10"
                    onDrop={handleOnDrop}
                    onDragEnter={handleOnDragEnter}
                    onDragLeave={handleOnDragLeave}
                    onDragOver={handleOnDragOver}
                >
                    <label className="custom-file-upload body-m text-s0">
                        <input type="file" accept="image/*" onChange={handleInputChange} />
                        Upload image
                    </label>
                    <div className="text-s70 body-xl placeholder">
                        {uploadError
                            ? 'File type is wrong or corrupted. Please upload another.'
                            : 'Drag & drop images here'}
                    </div>
                </div>
            )}

            <div className="modal-btn-group">
                <Button
                    btnProps={cancelBtnProps}
                    handleBtnClicked={() =>
                        handleBtnClicked(cancelBtnProps.alt)
                    }
                ></Button>
                {uploadItem ? (
                    <Button
                        btnProps={
                            modalLoading ? disabledBtnProps : insertBtnProps
                        }
                        isLoading={modalLoading}
                        handleBtnClicked={() =>
                            handleBtnClicked(insertBtnProps.alt)
                        }
                    ></Button>
                ) : (
                    <Button
                        btnProps={disabledBtnProps}
                        handleBtnClicked={() =>
                            handleBtnClicked(disabledBtnProps.alt)
                        }
                    ></Button>
                )}
            </div>
        </>
    );
};

export default GalleryUpload;
