import React, { FC } from 'react';
import IconButton from '../icon-button/IconButton';
import { IconBtnModel } from '../../models/icon-btn.model';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../constants/navBarBtnActionConstants';
import ArrowIcon from '../../assets/images/arrow.svg';
import './header-navbar.css';

export interface IProps {
    handleNavBtnAction: (type: string) => void;
}

const HeaderNavbar: FC<IProps> = ({ handleNavBtnAction }) => {
    const arrowBtnProps: IconBtnModel = {
        icon: ArrowIcon,
        alt: 'ArrowIcon',
        uiClass: 'arrow-icon',
        disable: false,
        draggable: false,
    };

    const handleBack = () => {
        handleNavBtnAction(NAVBAR_BTN_ACTION_CONSTANTS.BACK);
    };

    return (
        <>
            <div className="header-navbar">
                <div className="navbar-left">
                    <IconButton
                        btnProps={arrowBtnProps}
                        handleBtnClicked={handleBack}
                    />
                    <span className="text-c100 body-m">Activity worksheet</span>
                </div>
            </div>
        </>
    );
};

export default HeaderNavbar;
