// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import * as firebase from 'firebase/app';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDaYWDXRx6IrJlJTCIr0W9bNFUGpv53GJA',
    authDomain: 'activity-worksheet-dev.firebaseapp.com',
    databaseURL:
        'https://activity-worksheet-dev-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'activity-worksheet-dev',
    storageBucket: 'activity-worksheet-dev.appspot.com',
    messagingSenderId: '271301320043',
    appId: '1:271301320043:web:1a2d90fed6e19ef163c02e',
    measurementId: 'G-3490EV80TW',
};

firebase.initializeApp(firebaseConfig);
const database = getDatabase();
export default database;
