import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Activity } from '../../../../models/activity-log.model';
import StudentProfileDeco from '../../../../assets/images/student_profile_deco.svg';
import './activity-card.css';
import moment from 'moment';

export interface IProps {
    item: Activity;
}

const ActivityCard: FC<IProps> = ({ item }) => {
    return (
        <div className="card-wrap">
            <div className="profile">
                <span className="initials text-c300">{item.initials.toUpperCase()}</span>
                <img src={item.profile !== '' ? item.profile : StudentProfileDeco } alt="" width={'40px'} />
            </div>
            <div
                className="list-info h5-strong text-c100"
                style={{ width: '18%' }}
            >
                {item.studentname}
            </div>
            <div
                className="list-info body-m text-c300"
                style={{ width: '53%' }}
            >
                {item.action}
            </div>
            <div
                className="list-info body-m text-c300"
                style={{ width: '10%' }}
            >
                {item.date}
            </div>
            <div className="list-info light-body-m text-c300 text-c100">
                {item.time}
            </div>
        </div>
    );
};
export default ActivityCard;
