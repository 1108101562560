import React, { useEffect, useLayoutEffect, useState } from 'react';
import { NAVBAR_BTN_ACTION_CONSTANTS } from '../../constants/navBarBtnActionConstants';
import EditorNavbar from '../../global-components/editor-navbar/EditorNavbar';
import { useNavigate, useParams } from 'react-router-dom';
import Toolbar from './components/toolbar/Toolbar';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
// import Canvas from './components/canvas/Canvas';
import CommonCanvas from '../common-canvas/CommonCanvas';
import { useSelector } from 'react-redux';
import Modal from '../../global-components/modal/modal';
import TeacherModal from '../teacher-modal/TeacherModal';
import { CustomModal } from '../../models/custom-modal.model';
import {
    addComponent,
    checkedStudent,
    deleteBackground,
    setCurrentDragItemType,
    setCurrentItem,
    setFont,
    setFontColor,
    setFontSize,
    setFontStyle,
    setFontWeight,
    setLastId,
    setPopupIconType,
    setPopupText,
    setText,
    setTextDecoration,
    toggleDeleteItem,
    toggleEditItem,
    toggleEditLayout,
    toggleLockStatus,
    updateComponentById,
    updateComponentData,
    updateComponentSize,
    setModalType,
    setBorder,
    setBackground,
    updateComponentPosition,
    setIsText,
    setIsBack,
    setCanvas,
    resetWorksheet
} from './slices/worksheetSlice';
import { PointModel } from '../../models/point.model';
import { useDispatch } from 'react-redux';
import { ElementDataModel } from '../../models/element-data.model';
import { DraggableElement } from '../../models/draggable-element.model';
import { StudentListModel } from '../../models/student-list.model';
import PublishWorksheet from '../publish-worksheet/publish-worksheet';
import { Worksheet } from '../../models/worksheet-item.model';
import { WorksheetComponent } from '../../models/worksheet-component.model';
import { AppDispatch } from '../../store/store';
import {
    cloneElementApi,
    cloneTemplateElementApi,
    deleteTemplateComponent,
    deleteWorksheetComponent,
    getTeacherDetail,
    getTemplateDetail,
    getWorksheetDetail,
    postTemplateComponent,
    postWorksheetComponent,
    updateTemplateComponent,
    updateWorksheetComponent,
    uploadMedia,
    uploadTemplateMedia,
} from '../../api/worksheet/teacherApi';
import { editWorksheetApi, getClassName } from '../../api/global/globalApi';
import {
    updateTemplate,
    createWorksheetFromTemplate,
} from '../../api/worksheet/templatesApi';
import CustomAlert from '../../global-components/custom-alert/CustomAlert';
import { AlertModel } from '../../models/alert.model';
import {
    publishWorksheetApi,
    uploadThumbnail,
    deleteThumbnail,
} from '../../api/worksheet/worksheetsApi';
import CreateWorksheet from '../create-worksheet/create-worksheet';
import { WorksheetFormModel } from '../../models/worksheet-form.model';
import { AuthService } from '../../service/auth.service';
import { async } from '@firebase/util';
import { Profile } from '../../models/profile.model';
import { OVERLAY_MENU_CONSTANTS } from '../../constants/overlayMenuConstants';
import GenerateQRcode from '../generate-qrcode/generate-qrcode';
import { template } from '@babel/core';
import UseKeyboardHandler from '../../hooks/UseKeyboardHandler';
import { PARENT_TYPE_CONSTANTS } from '../../constants/parentTypeConstants';
import { ChatLogDataModel } from '../../models/chat-log-data.model';
import CanvasLoading from '../../global-components/canvas-loading/CanvasLoading';

const _authService = new AuthService();
const Teacher = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type') ? queryParams.get('type') : null;
    const params = useParams<{ id?: string }>();
    const { id } = params;
    const navigate = useNavigate();
    const userInfo = _authService.getUserInfo();

    // const worksheetById = useGetWorksheetByIdQuery({ id });
    // const { data, error, isLoading } = useGetStudentListByIdQuery('id');

    const worksheetDetail = useSelector(
        (state: any) => state.worksheet.worksheet
    );

    const components = useSelector(
        (state: any) => state.worksheet.worksheet.components
    );

    const { canvasWidth,
        canvasHeight,
        currentModalType,
        studentList,
        existBackground,
        currentItem,
        currentDragItemType,
        isEditLayout,
        editItem,
        editItemType,
        totalScore,
        totalBase,
        isText

    } = useSelector(
        (state: any) => state.worksheet
    );

    const [keyboardDelete, setKeyboardDelete] = useState<boolean>(false);
    const [isClone, setIsClone] = useState<boolean>(false);
    const [isBlur, setIsBlur] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<CustomModal>({
        title: '',
        showCrossBtn: true,
        width: '100px',
        height: null,
        padding: '15px',
    });
    const defaultAlertData: AlertModel = {
        title: 'default',
        body: null,
        type: 'success',
        uiClass: 'default',
    };
    const [showWorksheetModal, setShowWorksheetModal] =
        useState<boolean>(false);
    const [position, setPosition] = useState<PointModel>({ x: 0, y: 0 });
    const [changeItem, setChangeItem] = useState<boolean>(false); // user click one component from other
    const [changeItemData, setChangeItemData] =
        useState<DraggableElement | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [alertData, setalertData] = useState(defaultAlertData);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [saveClick, setSaveClick] = useState<boolean>(false);

    const [teacherProfile, setTeacherProfile] = useState<Profile>({
        url: '',
        name: '',
        initials: '',
    });

    const [className, setClassName] = useState<string>('');

    const [teacherInfoChange, setTeacherInfoChange] = useState(false);

    const dispatch = useDispatch<AppDispatch>(); //use AppDispatch for redux thunk

    const handleNavBtnAction = (type: string) => {
        switch (type) {
            case NAVBAR_BTN_ACTION_CONSTANTS.BACK:
                if (saveClick) {
                    console.log('hey do smth');
                    const alertData: AlertModel = {
                        title: 'You have to wait worksheet update finish.',
                        body: null,
                        type: 'error',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                } else navigate('/');
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.EDIT_WORKSHEET:
                {
                    const editModalProps: CustomModal = {
                        title: 'Edit Worksheet',
                        showCrossBtn: true,
                        width: '947px',
                        height: 'max-content',
                        padding: '49px 32px',
                    };
                    dispatch(
                        setModalType(NAVBAR_BTN_ACTION_CONSTANTS.EDIT_WORKSHEET)
                    );
                    handleModalData(editModalProps);
                    setShowWorksheetModal(true);
                }
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.EDIT_TEMPLATE:
                {
                    const editModalProps: CustomModal = {
                        title: 'Edit Template',
                        showCrossBtn: true,
                        width: '947px',
                        height: 'max-content',
                        padding: '49px 32px',
                    };
                    dispatch(
                        setModalType(NAVBAR_BTN_ACTION_CONSTANTS.EDIT_TEMPLATE)
                    );
                    handleModalData(editModalProps);
                    setShowWorksheetModal(true);
                }
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.LOCK:
                console.log('handle lock');
                callLockApi();
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.PUBLISH:
                {
                    const modalData: CustomModal = {
                        title: 'Publish',
                        showCrossBtn: true,
                        width: '947px',
                        height: 'max-content',
                        padding: '49px 32px 0px 32px',
                    };
                    dispatch(setModalType(NAVBAR_BTN_ACTION_CONSTANTS.PUBLISH));
                    handleModalData(modalData);
                    setShowWorksheetModal(true);
                }
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.UNPUBLISH:
                editWorksheet(type, worksheetDetail, null, null);
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.SAVE:
                setSaveClick(true);
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.CREATEWORKSHEET:
                {
                    const editModalProps: CustomModal = {
                        title: 'Create Worksheet',
                        showCrossBtn: true,
                        width: '947px',
                        height: 'max-content',
                        padding: '49px 32px',
                    };
                    dispatch(
                        setModalType(
                            NAVBAR_BTN_ACTION_CONSTANTS.CREATEWORKSHEET
                        )
                    );
                    handleModalData(editModalProps);
                    setShowWorksheetModal(true);
                }
                break;
            default:
                console.log('default');
        }
    };

    const handleToolbarBtnAction = (type: string | undefined) => {
        switch (type) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.TEXT:
                console.log('text');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX:
                console.log('textbox');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                console.log('gallery');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND:
                console.log('background');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                console.log('video');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                console.log('audio');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.POPUP:
                console.log('popup');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.SCORE:
                console.log('score');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.GRADING:
                console.log('grading');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.GROUP:
                console.log('group');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.JOURNAL:
                console.log('view submission');
                navigate(`/submissions/${id}`);
                // if(worksheetDetail.submissionCount > 0) {
                //     navigate(`/submissions/${id}`);
                // }

                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.USER:
                console.log('user');
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.PREVIEW:
                console.log('preview');
                break;
            default:
                console.log('default');
        }
    };

    const handleEditLayoutBtnAction = () => {
        dispatch(toggleEditLayout());
    };

    const typeList = [
        TOOLBAR_BTN_ACTION_CONSTANTS.TEXT,
        TOOLBAR_BTN_ACTION_CONSTANTS.POPUP,
        TOOLBAR_BTN_ACTION_CONSTANTS.TEXTBOX,
    ];

    const toggleModal = () => {
        // if (showModal) {
        //     dispatch(toggleEditItem(null));
        // }
        console.log('toggelModal');
        setShowModal(!showModal);
    };

    const handleModalData = (value: CustomModal) => {
        setModalData(value);
    };

    const handleTempPosition = (position: PointModel) => {
        setPosition(position);
    };

    const handleDataUpdate = (value: ElementDataModel) => {
        if (currentDragItemType === TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND) {
            dispatch(
                updateComponentData({ id: existBackground.id, data: value })
            );
        } else {
            dispatch(updateComponentData({ id: currentItem.id, data: value }));
        }
    };

    const handleSizeUpdate = (value: any) => {
        if (currentDragItemType === TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND) {
            dispatch(
                updateComponentSize({ id: existBackground.id, size: value })
            );
        } else {
            dispatch(updateComponentSize({ id: currentItem.id, size: value }));
        }
    };

    const handleEdit = () => {
        const modelList = [
            TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY,
            TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND,
            TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO,
            TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO,
            TOOLBAR_BTN_ACTION_CONSTANTS.GRADING,
            TOOLBAR_BTN_ACTION_CONSTANTS.GROUP,
        ];

        dispatch(toggleEditItem(currentItem.type));

        if (currentItem.type === TOOLBAR_BTN_ACTION_CONSTANTS.GRADING) {
            const modalData: CustomModal = {
                title: 'Grading',
                showCrossBtn: true,
                width: '376px',
                height: null,
                padding: '24px 32px',
            };
            setModalData(modalData);
            setShowModal(true);
        } else if (modelList.includes(currentItem.type)) {
            const modalData: CustomModal = {
                title: 'Upload Audio',
                showCrossBtn: true,
                width: '944px',
                height: null,
                padding: '32px',
            };

            switch (currentItem.type) {
                case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                    modalData.title = 'Upload Image';
                    break;
                case TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND:
                    modalData.title = 'Upload Image';
                    break;
                case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                    modalData.title = 'Upload Audio';
                    break;
                case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                    modalData.title = 'Upload Video';
                    break;
                case TOOLBAR_BTN_ACTION_CONSTANTS.GRADING:
                    modalData.title = 'Upload Image';
                    break;
            }
            setModalData(modalData);
            setShowModal(true);
        }
        dispatch(setCurrentDragItemType(currentItem.type));
        console.log('currentItem:', currentItem);
    };

    const handleClickOutside = () => {
        dispatch(setCurrentItem(null));
    };

    const handlePopupIconClicked = (type: string) => {
        dispatch(setPopupIconType(type));
    };

    const updateStudentListById = (list: StudentListModel[]) => {
        dispatch(checkedStudent(list));
    };

    const addGroupTool = () => {
        const id = components ? components.length.toString() : '0';
        const element: DraggableElement = {
            id: id,
            type: currentDragItemType,
            location: { x: position.x, y: position.y },
            size: { width: 0, height: 0 },
            data: {
                text: null,
                score: {
                    mark: null,
                    base: null,
                },
                url: null,
                chatLog: null,
                style: {
                    fontFamily: 'Inter',
                    fontWeight: 'Regular',
                    fontSize: '24px',
                    fontColor: '#000000',
                    fontStyle: null,
                    textDecoration: null,
                    border: null,
                    background: null,
                },
                iconType: null,
                descText: null,
                descStyle: null,
                isBack: false,
            },
        };
        dispatch(addComponent(element));
        dispatch(toggleEditItem(null));
    };

    const handleColorChange = (color: string) => {
        dispatch(setFontColor(color));
    };

    const handleBorderChange = (color: string) => {
        dispatch(setBorder(color));
    };

    const handleBackgroundChange = (color: string) => {
        dispatch(setBackground(color));
    };

    const handleBold = (value: string) => {
        dispatch(setFontWeight(value));
    };

    const handleItalic = (value: boolean) => {
        dispatch(setFontStyle(value));
    };

    const handleUnderline = (value: boolean) => {
        dispatch(setTextDecoration(value));
    };

    const [worksheet, setworksheet] = useState<Worksheet>({
        id: '',
        name: '',
        thumbnailUrl: '',
        publishedStatus: '',
        isPublished: false,
        isLocked: false,
        labelId: '',
        label: {},
        mode: '',
        students: [],
    });

    const handleCancel = () => {
        setShowWorksheetModal(false);
    };

    const handleAssign = (
        data: any,
        file: File | undefined,
        isDeleteThumbnail: boolean
    ) => {
        console.log('handleAssign', data, file);
        if (file == undefined) {
            if (!isDeleteThumbnail) publishWorksheet(data);
            else
                callDeleteThumbnailApi(
                    worksheetDetail.id,
                    data,
                    OVERLAY_MENU_CONSTANTS.PUBLISH
                );
        } else {
            callUploadThumbnailApi(worksheet, file, data);
        }
    };

    const callDeleteThumbnailApi = async (
        worksheetId: string,
        data: any,
        type: string
    ) => {
        try {
            const deleteThumbnailApi = await dispatch(
                deleteThumbnail(worksheetId)
            ).unwrap();
            console.log('deleteThumbnailApi', deleteThumbnailApi);
            if (type == OVERLAY_MENU_CONSTANTS.PUBLISH) publishWorksheet(data);
            else editWorksheetForm(data, null);
        } catch (error) {
            console.log(error);
        }
    };

    const publishWorksheet = async (data: any) => {
        try {
            await dispatch(publishWorksheetApi(data))
                .unwrap()
                .then((res: any) => {
                    setShowWorksheetModal(false);
                    setWorksheetData(res.worksheet);
                    const alertData: AlertModel = {
                        title: 'Your worksheet has been successfully published.',
                        body: null,
                        type: 'success',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                }, error => {
                    console.log('error', error);
                    setShowWorksheetModal(false);
                    showPublishError();
                });
        } catch (err: any) {
            console.log(
                'publishWorksheet > error',
                `Fetch failed: ${err.message}`
            );
            setShowWorksheetModal(false);
            showPublishError();
        }
    };

    const showPublishError = () => {
        const alertData: AlertModel = {
            title: 'Fail to publish worksheet!',
            body: null,
            type: 'error',
            uiClass: 'top-right-alert',
        };
        setalertData(alertData);
        setShowAlert(true);
    };

    const getFileFormat = (file: File) => {
        console.log('file---', file);
        const timestamp = Date.now();
        const formData = new FormData();
        formData.append(
            'file',
            file,
            timestamp.toString().concat('_', file.name)
        );
        console.log('formData:', formData);
        return formData;
    };

    const callUploadThumbnailApi = async (
        worksheet: Worksheet,
        file: File,
        publishData: any
    ) => {
        const formData = getFileFormat(file);
        try {
            const uploadThumbnailApi = await dispatch(
                uploadThumbnail(formData)
            ).unwrap();
            console.log('uploadThumbnailApi', uploadThumbnailApi);
            editWorksheet(
                NAVBAR_BTN_ACTION_CONSTANTS.EDIT_THUMBNAIL,
                worksheet,
                uploadThumbnailApi.url,
                publishData
            );
        } catch (error) {
            console.log(error);
        }
    };

    const toggleWorksheetModal = () => {
        setShowWorksheetModal(!showWorksheetModal);
    };

    const handleAddComponentForGrading = async (item: WorksheetComponent) => {
        console.log('handleAddComponentForGrading', isEdit);
        try {
            if (isEdit) {
                const element: DraggableElement = {
                    ...item,
                    id: currentItem.id,
                    // type: currentItem.type,
                    location: currentItem.location,
                    // size: item.size,
                    // data: payload.data,
                };
                if (type === 'template') updateTemplateApiCall(element);
                else updateApiCall(element);
            } else {
                console.log('callAddComponentApi------');
                if (type == 'template') callAddTemplateComponentApi(item);
                else callAddComponentApi(item);
            }
            setShowModal(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddComponentFromModal = async (
        payload: WorksheetComponent,
        file?: File | undefined
    ) => {
        const timestamp = Date.now();
        console.log('file is here : ', file, timestamp);
        console.log('payload:', payload);
        const formData = new FormData();
        if (file)
            formData.append(
                'file',
                file,
                timestamp.toString().concat('_', file.name)
            );
        console.log('formData:', formData);
        try {
            setModalLoading(true);
            if (type === 'template') {
                const uploadMediaApi = await dispatch(
                    uploadTemplateMedia({ id, body: formData })
                ).unwrap();
                console.log(uploadMediaApi);
                payload.data.url = uploadMediaApi.url;
            } else {
                const uploadMediaApi = await dispatch(
                    uploadMedia({ id, body: formData })
                ).unwrap();
                console.log(uploadMediaApi);
                payload.data.url = uploadMediaApi.url;
            }
            console.log(existBackground, isEdit);
            //you have to know render or upate
            if (isEdit) {
                const element: DraggableElement = {
                    ...payload,
                    id: currentItem.id,
                    type: currentItem.type,
                    location: currentItem.location,
                    size: payload.size,
                    data: payload.data,
                };
                if (type === 'template') updateTemplateApiCall(element);
                else updateApiCall(element);
            } else if (
                existBackground &&
                payload.type === TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND
            ) {
                const element: DraggableElement = {
                    ...payload,
                    id: existBackground.id,
                    type: TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND,
                    location: existBackground.location,
                    size: payload.size,
                    data: payload.data,
                };
                console.log('existBackground is here ~~~~~~~~~~~~~~~');
                if (type === 'template') updateTemplateApiCall(element);
                else updateApiCall(element);
            } else {
                if (type === 'template') callAddTemplateComponentApi(payload);
                else callAddComponentApi(payload);
            }
            setShowModal(false);
            setModalLoading(true);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddComponent = async (
        payload: WorksheetComponent,
        file?: File | undefined
    ) => {
        if (type === 'template') callAddTemplateComponentApi(payload);
        else callAddComponentApi(payload);
    };

    const callAddTemplateComponentApi = async (payload: WorksheetComponent) => {
        setSaveClick(true);
        try {
            const postWorksheetComponentApi = await dispatch(
                postTemplateComponent({ body: payload, id: id })
            ).unwrap();
            console.log('success post:', postWorksheetComponentApi);
            if (
                postWorksheetComponentApi.meta &&
                postWorksheetComponentApi.meta._id
            ) {
                const element: DraggableElement = {
                    id: postWorksheetComponentApi.meta._id,
                    type: payload.type,
                    location: payload.location,
                    size: payload.size,
                    data: payload.data,
                };

                const alertData: AlertModel = {
                    title: 'Your template saved successfully.',
                    body: null,
                    type: 'success',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);

                dispatch(addComponent(element));
                dispatch(setCurrentDragItemType(''));
                dispatch(toggleEditItem(''));
                setShowModal(false);
                setModalLoading(false);
                setSaveClick(false);
            }
        } catch (err: any) {
            if (err) {
                console.log('error', `Fetch failed: ${err.message}`);
                setModalLoading(false);
                setShowModal(false);
                const alertData: AlertModel = {
                    title: 'Your template cannot saved successfully.',
                    body: null,
                    type: 'error',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setSaveClick(false);
            }
        }
    };

    const callAddComponentApi = async (payload: WorksheetComponent) => {
        setSaveClick(true);
        try {
            const postWorksheetComponentApi = await dispatch(
                postWorksheetComponent({ body: payload, id: id })
            ).unwrap();
            console.log('success post:', postWorksheetComponentApi);
            if (
                postWorksheetComponentApi.meta &&
                postWorksheetComponentApi.meta._id
            ) {
                const element: DraggableElement = {
                    id: postWorksheetComponentApi.meta._id,
                    type: payload.type,
                    location: payload.location,
                    size: payload.size,
                    data: payload.data,
                };

                const alertData: AlertModel = {
                    title: 'Your worksheet saved successfully.',
                    body: null,
                    type: 'success',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);

                dispatch(addComponent(element));
                dispatch(setCurrentDragItemType(''));
                dispatch(toggleEditItem(''));
                setShowModal(false);
                setModalLoading(false);
                setSaveClick(false);
            }
        } catch (err: any) {
            if (err) {
                console.log('error', `Fetch failed: ${err.message}`);
                setModalLoading(false);
                setShowModal(false);
                const alertData: AlertModel = {
                    title: 'Your worksheet cannot saved successfully.',
                    body: null,
                    type: 'error',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setSaveClick(false);
            }
        }
    };

    const getTemplateDetailCall = async () => {
        try {
            const getWorksheetDetailApi = await dispatch(
                getTemplateDetail(id)
            ).unwrap();
            console.log('success:', getWorksheetDetailApi);
            dispatch(setCurrentItem(null));
            setWorksheetData(getWorksheetDetailApi);
        } catch (err: any) {
            console.log('error', `Fetch failed: ${err.message}`);
        }
    };

    const getWorksheetDetailCall = async () => {
        try {
            const getWorksheetDetailApi = await dispatch(
                getWorksheetDetail(id)
            ).unwrap();
            console.log('success:', getWorksheetDetailApi);
            dispatch(setCurrentItem(null));
            setWorksheetData(getWorksheetDetailApi);
        } catch (err: any) {
            console.log('error', `Fetch failed: ${err.message}`);
        }
    };

    const getTeacherInfo = async () => {
        try {
            console.log('userInfo:', userInfo.userid);
            const id = [userInfo.userid];
            const callApi = await dispatch(getTeacherDetail(id));
            console.log('====================================');
            console.log(callApi.payload.userData[0]);
            console.log('====================================');
            const data = callApi.payload.userData[0];

            const profile: Profile = {
                url: data.url ? data.url : '',
                name:
                    data.firstName && data.lastName
                        ? data.firstName + ' ' + data.lastName
                        : data.username,
                initials: data.initials
                    ? data.initials
                    : data.username.slice(0, 2).toUpperCase(),
            };
            setTeacherProfile(profile);
            setTeacherInfoChange(true);
        } catch (error) {
            console.log('error', `Fetch failed: ${error}`);
        }
    };

    const setWorksheetData = (data: any) => {
        const worksheetObj: Worksheet = {
            id: data.id,
            name: data.name,
            thumbnailUrl: data.thumbnailUrl,
            publishedStatus: data.publishedStatus,
            isPublished: data.publishedStatus == 'published' ? true : false,
            isLocked: data.isLocked,
            labelId: data.labelId,
            label: data.label != undefined ? data.label : null,
            mode: data.mode,
            students:
                data.students != undefined && data.students != null
                    ? data.students
                    : [],
        };
        console.log('worksheetObj', worksheetObj);
        setworksheet(worksheetObj);
    };

    const handleDeleteTemplateComponent = async () => {
        if (worksheet.publishedStatus !== 'published') {
            try {
                const deleteComponent = await dispatch(
                    deleteTemplateComponent({ id, componentId: currentItem.id })
                )
                    .unwrap()
                    .then((res) => {
                        console.log('success: update', res);
                        const alertData: AlertModel = {
                            title: 'Your worksheet component deleted successfully.',
                            body: null,
                            type: 'success',
                            uiClass: 'default',
                        };
                        setalertData(alertData);
                        setShowAlert(true);
                        dispatch(toggleDeleteItem());
                        dispatch(setCurrentItem(null));
                    })
                    .catch((error) => {
                        console.log('fail: update', error.message);
                        const alertData: AlertModel = {
                            title: 'Your worksheet component did not deleted successfully.',
                            body: null,
                            type: 'fail',
                            uiClass: 'default',
                        };
                        setalertData(alertData);
                        setShowAlert(true);
                        dispatch(toggleDeleteItem());
                        dispatch(setCurrentItem(null));
                    });
            } catch (err: any) {
                console.log('error', `Fetch failed: ${err.message}`);
            }
        }

        // dispatch(toggleDeleteItem());
    };

    const handleDeleteComponent = async () => {
        if (worksheet.publishedStatus !== 'published') {
            try {
                const deleteComponent = await dispatch(
                    deleteWorksheetComponent({
                        id,
                        componentId: currentItem.id,
                    })
                )
                    .unwrap()
                    .then((res) => {
                        console.log('success: update', res);
                        const alertData: AlertModel = {
                            title: 'Your worksheet component deleted successfully.',
                            body: null,
                            type: 'success',
                            uiClass: 'default',
                        };
                        setalertData(alertData);
                        setShowAlert(true);
                        dispatch(toggleDeleteItem());
                        dispatch(setCurrentItem(null));
                    })
                    .catch((error) => {
                        console.log('fail: update', error.message);
                        const alertData: AlertModel = {
                            title: 'Your worksheet component did not deleted successfully.',
                            body: null,
                            type: 'fail',
                            uiClass: 'default',
                        };
                        setalertData(alertData);
                        setShowAlert(true);
                        dispatch(toggleDeleteItem());
                        dispatch(setCurrentItem(null));
                    });
            } catch (err: any) {
                console.log('error', `Fetch failed: ${err.message}`);
            }
        }

        // dispatch(toggleDeleteItem());
    };

    const handleFontChange = (name: string) => {
        dispatch(setFont(name));
    };
    const handleFontSizeChange = (name: string) => {
        dispatch(setFontSize(name));
    };
    const handleFontWeightChange = (name: string) => {
        dispatch(setFontWeight(name));
    };

    const updateCurrentItem = (component: DraggableElement) => {
        // console.log('updateCurrentItem:');
        // setChangeItemData(component);
        // setChangeItem(true);
        // smart update currently postpone
        if (component.type === TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND) {
            console.log('background clicked');
        } else {
            dispatch(setCurrentItem(component));
        }
    };

    const updateApiCall = async (item: DraggableElement) => {
        try {
            const updateComponent = await dispatch(
                updateWorksheetComponent({
                    id,
                    componentId: item.id,
                    body: item,
                })
            )
                .unwrap()
                .then((res) => {
                    console.log('success: update', res);
                    const alertData: AlertModel = {
                        title: 'Your worksheet saved successfully.',
                        body: null,
                        type: 'success',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                })
                .catch((error) => {
                    console.log('fail: update', error.message);
                    const alertData: AlertModel = {
                        title: 'Your worksheet did not saved successfully.',
                        body: null,
                        type: 'fail',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                });

            // if (changeItem) {
            //     setChangeItem(false);
            //     dispatch(setCurrentItem(changeItemData));
            //     dispatch(toggleEditItem(null));
            //     console.log('if');
            // } else {
            //     dispatch(setCurrentItem(null));
            //     dispatch(toggleEditItem(null));
            //     console.log('else');
            //     setChangeItem(false);
            // }
            console.log('item: ', item);
            dispatch(setCurrentItem(null));
            dispatch(toggleEditItem(null));
            dispatch(updateComponentById(item));
            setShowModal(false);
            setModalLoading(false);
            setSaveClick(false);
        } catch (err: any) {
            if (err) {
                console.log('error', `Fetch failed: ${err.message}`);
                setSaveClick(false);
                const alertData: AlertModel = {
                    title: 'Your worksheet saved successfully.',
                    body: null,
                    type: 'success',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
            }
        }
    };

    const updateTemplateApiCall = async (item: DraggableElement) => {
        try {
            const updateComponent = await dispatch(
                updateTemplateComponent({
                    id,
                    componentId: item.id,
                    body: item,
                })
            )
                .unwrap()
                .then((res) => {
                    console.log('success: update', res);
                    const alertData: AlertModel = {
                        title: 'Your worksheet saved successfully.',
                        body: null,
                        type: 'success',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                })
                .catch((error) => {
                    console.log('fail: update', error.message);
                    const alertData: AlertModel = {
                        title: 'Your worksheet did not saved successfully.',
                        body: null,
                        type: 'fail',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                });

            // if (changeItem) {
            //     setChangeItem(false);
            //     dispatch(setCurrentItem(changeItemData));
            //     dispatch(toggleEditItem(null));
            //     console.log('if');
            // } else {
            //     dispatch(setCurrentItem(null));
            //     dispatch(toggleEditItem(null));
            //     console.log('else');
            //     setChangeItem(false);
            // }
            console.log('item: ', item);
            dispatch(setCurrentItem(null));
            dispatch(toggleEditItem(null));
            dispatch(updateComponentById(item));
            setShowModal(false);
            setModalLoading(false);
            setSaveClick(false);
        } catch (err: any) {
            if (err) {
                console.log('error', `Fetch failed: ${err.message}`);
                setSaveClick(false);
                const alertData: AlertModel = {
                    title: 'Your worksheet saved successfully.',
                    body: null,
                    type: 'success',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
            }
        }
    };

    const handleBlur = (item: DraggableElement) => {
        console.log('blur . . . . . . . . . ');
        console.log('currentItem', item);
        if (worksheet.publishedStatus != 'published') {
            setSaveClick(true);
            if (type === 'template') updateTemplateApiCall(item);
            else updateApiCall(item);
        }
        setIsBlur(false);
    };

    const [isMoveDone, setIsMoveDone] = useState<boolean>(false);
    const [isResizeDone, setIsResizeDone] = useState<boolean>(false);
    const [movePosition, setMovePosition] = useState<PointModel>({
        x: 0,
        y: 0,
    });
    const handleUpdatePosition = (position: PointModel) => {
        setIsMoveDone(true);
        setMovePosition(position);
        dispatch(updateComponentPosition({ position: position, id: currentItem.id }));
    };

    const handleResize = (size: any) => {
        setIsResizeDone(true);
    };

    const handleTempSize = (size: any) => {
        console.log('handleTempSize:', size);
        dispatch(updateComponentSize({ size: size, id: currentItem.id }));
    };

    const handleClone = async () => {
        console.log('current Item:', currentItem);
        const cloneApi = await dispatch(
            cloneElementApi({
                id: currentItem.id,
                sheetId: id,
                position: {
                    x: currentItem.location.x + 20,
                    y: currentItem.location.y + 20,
                },
            })
        )
            .unwrap()
            .then((result: any) => {
                console.log(result, result.meta);

                const element: DraggableElement = JSON.parse(
                    JSON.stringify(currentItem)
                );
                if (result.meta._id) {
                    element.id = result.meta._id;
                    element.location.x += 20;
                    element.location.y += 20;

                    dispatch(addComponent(element));
                    dispatch(setCurrentDragItemType(''));
                    dispatch(toggleEditItem(''));
                    setShowModal(false);
                    setModalLoading(false);
                    setSaveClick(false);
                    const alertData: AlertModel = {
                        title: 'You have cloned worksheet component successfully',
                        body: null,
                        type: 'success',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                } else {
                    const alertData: AlertModel = {
                        title: 'Cloning worksheet component failed.',
                        body: null,
                        type: 'fail',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                    setShowModal(false);
                    setModalLoading(false);
                    setSaveClick(false);
                }
                setIsClone(false);
            })
            .catch((error: any) => {
                console.log(error);
                setIsClone(false);
                const alertData: AlertModel = {
                    title: 'Cloning worksheet component failed.',
                    body: null,
                    type: 'fail',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setShowModal(false);
                setModalLoading(false);
                setSaveClick(false);
            });
    };

    const handleTemplateClone = async () => {
        console.log('current Item:', currentItem);
        const cloneApi = await dispatch(
            cloneTemplateElementApi({
                id: currentItem.id,
                sheetId: id,
                position: {
                    x: currentItem.location.x + 20,
                    y: currentItem.location.y + 20,
                },
            })
        )
            .unwrap()
            .then((result: any) => {
                console.log(result, result.meta);

                const element: DraggableElement = JSON.parse(
                    JSON.stringify(currentItem)
                );
                if (result.meta._id) {
                    element.id = result.meta._id;
                    element.location.x += 20;
                    element.location.y += 20;

                    dispatch(addComponent(element));
                    dispatch(setCurrentDragItemType(''));
                    dispatch(toggleEditItem(''));
                    setShowModal(false);
                    setModalLoading(false);
                    setSaveClick(false);
                    const alertData: AlertModel = {
                        title: 'You have cloned worksheet component successfully',
                        body: null,
                        type: 'success',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                } else {
                    const alertData: AlertModel = {
                        title: 'Cloning worksheet component failed.',
                        body: null,
                        type: 'fail',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                    setShowModal(false);
                    setModalLoading(false);
                    setSaveClick(false);
                }
                setIsClone(false);
            })
            .catch((error: any) => {
                console.log(error);
                setIsClone(false);
                const alertData: AlertModel = {
                    title: 'Cloning worksheet component failed.',
                    body: null,
                    type: 'fail',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
                setShowModal(false);
                setModalLoading(false);
                setSaveClick(false);
            });
    };

    const checkTemplateOrWorksheetBgDelete = () => {
        if(type === 'template') handleTemplateBgDelete();
        else handleBgDelete();
    };

    const handleBgEdit = () => {
        console.log('bg edit');
        const modalData: CustomModal = {
            title: 'Upload Image',
            showCrossBtn: true,
            width: '944px',
            height: null,
            padding: '32px',
        };
        setModalData(modalData);
        setShowModal(true);
    };

    const handleBgDelete = async () => {
        try {
            const deleteComponent = await dispatch(
                deleteWorksheetComponent({
                    id,
                    componentId: existBackground.id,
                })
            ).unwrap();
            console.log('success:', deleteComponent);
            dispatch(deleteBackground());
            dispatch(setCurrentItem(null));
        } catch (err: any) {
            if (err) console.log('error', `Fetch failed: ${err.message}`);
        }
    };

    const handleTemplateBgDelete = async () => {
        try {
            const deleteComponent = await dispatch(
                deleteTemplateComponent({
                    id,
                    componentId: existBackground.id,
                })
            ).unwrap();
            console.log('success:', deleteComponent);
            dispatch(deleteBackground());
            dispatch(setCurrentItem(null));
        } catch (err: any) {
            if (err) console.log('error', `Fetch failed: ${err.message}`);
        }
    };

    const callLockApi = async () => {
        //
        console.log('worksheet', worksheet);
        const payload = {
            name: worksheet.name,
            publishedStatus: worksheet.publishedStatus,
            isLocked: !worksheet.isLocked,
            mode: worksheet.mode,
        };
        try {
            const worksheetLockApi = await dispatch(
                editWorksheetApi({
                    id: id,
                    payload: payload,
                })
            ).unwrap();
            dispatch(toggleLockStatus());
        } catch (error) {
            if (error) console.error(error);
        }
    };

    const editWorksheet = async (
        type: string,
        worksheet: Worksheet,
        url: string | null,
        publishData: any
    ) => {
        console.log('editWorksheet', type, worksheet);
        const payload = getEditWorksheetPayload(type, worksheet, url);
        try {
            const worksheetEditApi = await dispatch(
                editWorksheetApi({
                    id: worksheet.id,
                    payload: payload,
                })
            ).unwrap();
            console.log('worksheetEditApi > success', worksheetEditApi);
            if (type != NAVBAR_BTN_ACTION_CONSTANTS.EDIT_THUMBNAIL) {
                const worksheetObj: Worksheet = {
                    id: worksheetEditApi.worksheet._id,
                    name: worksheetEditApi.worksheet.name,
                    thumbnailUrl: worksheetEditApi.worksheet.thumbnailUrl,
                    publishedStatus: worksheetEditApi.worksheet.publishedStatus,
                    isPublished:
                        worksheetEditApi.worksheet.publishedStatus ==
                            'published'
                            ? true
                            : false,
                    isLocked: worksheetEditApi.worksheet.isLocked,
                    labelId: worksheetEditApi.worksheet.labelId,
                    label:
                        worksheetEditApi.label != undefined
                            ? worksheetEditApi.label
                            : null,
                    mode: worksheetEditApi.worksheet.mode,
                    students: worksheet.students,
                };
                console.log('worksheetObj', worksheetObj);
                setworksheet(worksheetObj);
                const alertData: AlertModel = {
                    title: 'Your worksheet has been unpublished.',
                    body: null,
                    type: 'success',
                    uiClass: 'default',
                };
                setalertData(alertData);
                setShowAlert(true);
            } else {
                publishWorksheet(publishData);
            }
        } catch (error) {
            if (error) console.error(error);
        }
    };

    const getEditWorksheetPayload = (
        type: string,
        worksheet: Worksheet,
        url: string | null
    ) => {
        let editPayload = {};
        switch (type) {
            case NAVBAR_BTN_ACTION_CONSTANTS.UNPUBLISH:
                console.log('UNPUBLISH');
                editPayload = {
                    publishedStatus: 'draft',
                };
                break;
            case NAVBAR_BTN_ACTION_CONSTANTS.EDIT_THUMBNAIL:
                console.log('EDIT_THUMBNAIL');
                editPayload = {
                    thumbnailUrl: url,
                };
                break;
            default:
                console.log('default');
        }
        return editPayload;
    };

    const handleTextChange = (text: string, descText: string) => {
        console.log('handleTextChange . . . . ', text);
        dispatch(setPopupText({ text: text, descText: descText }));
    };

    const renderModal = (modalType: string) => {
        console.log('render modal', modalType);
        switch (modalType) {
            case NAVBAR_BTN_ACTION_CONSTANTS.EDIT_WORKSHEET:
                console.log('edit worksheet----');
                return (
                    <CreateWorksheet
                        type={NAVBAR_BTN_ACTION_CONSTANTS.EDIT_WORKSHEET}
                        worksheet={worksheet}
                        clickCancel={handleCancel}
                        clickCreate={handleEditWorksheet}
                    />
                );
            case NAVBAR_BTN_ACTION_CONSTANTS.EDIT_TEMPLATE:
                console.log('edit template----');
                return (
                    <CreateWorksheet
                        type={NAVBAR_BTN_ACTION_CONSTANTS.EDIT_TEMPLATE}
                        worksheet={worksheet}
                        clickCancel={handleCancel}
                        clickCreate={handleEditTemplate}
                    />
                );
            case NAVBAR_BTN_ACTION_CONSTANTS.CREATEWORKSHEET:
                console.log('create worksheet----');
                return (
                    <CreateWorksheet
                        type={NAVBAR_BTN_ACTION_CONSTANTS.CREATEWORKSHEET}
                        worksheet={worksheet}
                        clickCancel={handleCancel}
                        clickCreate={createNewWorksheet}
                    />
                );
            case NAVBAR_BTN_ACTION_CONSTANTS.PUBLISH:
                console.log('publish----');
                return (
                    <PublishWorksheet
                        worksheet={worksheet}
                        clickCancel={handleCancel}
                        clickAssign={handleAssign}
                    />
                );
            case NAVBAR_BTN_ACTION_CONSTANTS.QRCODE:
                console.log('show QR----');
                return (
                    <GenerateQRcode
                        type={NAVBAR_BTN_ACTION_CONSTANTS.QRCODE}
                        worksheetId={worksheet.id}
                    ></GenerateQRcode>
                );
        }
    };

    const handleEditWorksheet = async (
        worksheet: WorksheetFormModel,
        file: File | undefined,
        isDeleteThumbnail: boolean
    ) => {
        console.log(
            'handleEditWorksheet',
            worksheet,
            file,
            'isDeleteThumbnail',
            isDeleteThumbnail
        );
        setShowWorksheetModal(false);
        if (file == undefined) {
            if (!isDeleteThumbnail) editWorksheetForm(worksheet, null);
            else
                callDeleteThumbnailApi(
                    worksheetDetail.id,
                    worksheet,
                    OVERLAY_MENU_CONSTANTS.EDIT
                );
        } else {
            const formData = getFileFormat(file);
            try {
                const uploadThumbnailApi = await dispatch(
                    uploadThumbnail(formData)
                ).unwrap();
                console.log('uploadThumbnailApi', uploadThumbnailApi);
                editWorksheetForm(worksheet, uploadThumbnailApi.url);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleEditTemplate = async (
        worksheet: WorksheetFormModel,
        file: File | undefined,
        isDeleteThumbnail: boolean
    ) => {
        console.log(
            'handleEditTemplate',
            worksheet,
            file,
            'isDeleteThumbnail',
            isDeleteThumbnail
        );
        setShowWorksheetModal(false);
        editTemplateForm(worksheet, null);
    };

    const handleQRAction = () => {
        console.log('Hey! Do we need to show QR?');
        const qrModalProps: CustomModal = {
            title: 'Login with QR Code',
            showCrossBtn: true,
            width: '480px',
            height: null,
            padding: '32px',
        };
        dispatch(setModalType(NAVBAR_BTN_ACTION_CONSTANTS.QRCODE));
        handleModalData(qrModalProps);
        setShowWorksheetModal(true);
    };

    const editWorksheetForm = async (
        worksheet: WorksheetFormModel,
        url: string | null
    ) => {
        const payload =
            url == null
                ? {
                    name: worksheet.name,
                    // labelId: worksheet.labelId,
                }
                : {
                    name: worksheet.name,
                    // labelId: worksheet.labelId,
                    thumbnailUrl: url,
                };
        try {
            await dispatch(
                editWorksheetApi({
                    id: worksheet.id,
                    payload: payload,
                })
            ).unwrap()
                .then((res: any) => {
                    console.log('worksheetEditApi > success', res);
                    setShowWorksheetModal(false);
                    if (type)
                        getTemplateDetailCall();
                    else
                        getWorksheetDetailCall();
                    const alertData: AlertModel = {
                        title: 'Your worksheet has been successfully updated.',
                        body: null,
                        type: 'success',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                }, error => {
                    setShowWorksheetModal(false);
                    showErrorMessage('Fail to update worksheet!');
                });
        } catch (error) {
            if (error) {
                console.error(error);
                setShowWorksheetModal(false);
                showErrorMessage('Fail to update worksheet!');
            }
        }
    };

    const showErrorMessage = (message: string) => {
        const alertData: AlertModel = {
            title: message,
            body: null,
            type: 'error',
            uiClass: 'top-right-alert',
        };
        setalertData(alertData);
        setShowAlert(true);
    };

    const editTemplateForm = async (worksheetForm: WorksheetFormModel, url: string | null) => {
        const payload = {
            id: worksheet.id,
            name: worksheetForm.name,
            thumbnailUrl: worksheet.thumbnailUrl,
            lessonId: userInfo.lessonId,
            // labelId: worksheetForm.labelId,
            ownerId: userInfo.userid,
        };
        console.log('editTemplateForm', payload);
        try {
            await dispatch(
                updateTemplate(payload)
            ).unwrap()
                .then((res: any) => {
                    setShowWorksheetModal(false);
                    console.log('updateTemplateApiCall > success', updateTemplateApiCall);
                    if (type)
                        getTemplateDetailCall();
                    else
                        getWorksheetDetailCall();
                    const alertData: AlertModel = {
                        title: 'Your template has been successfully updated.',
                        body: null,
                        type: 'success',
                        uiClass: 'default',
                    };
                    setalertData(alertData);
                    setShowAlert(true);
                }, error => {
                    setShowWorksheetModal(false);
                    showErrorMessage('Fail to update template!');
                });
        } catch (error) {
            if (error) {
                console.error(error);
                setShowWorksheetModal(false);
                showErrorMessage('Fail to update template!');
            }
        }
    };

    const getClassNameCall = async () => {
        try {
            const getClassNameApi = await dispatch(getClassName())
                .unwrap()
                .then((res) => {
                    console.log('student Name', res);
                    setClassName(res.name);
                })
                .catch((error) => {
                    console.log('student Name error', error);
                });
        } catch (error) {
            console.error(error);
        }
    };

    const createNewWorksheet = async (worksheet: WorksheetFormModel) => {
        console.log('createNewWorksheet------', worksheet);
        const payload = {
            templateId: id,
        };
        try {
            const data = {
                templateId: id,
                name: worksheet.name,
                // labelId: worksheet.labelId
            };
            await dispatch(createWorksheetFromTemplate(data))
                .unwrap()
                .then((res: any) => {
                    console.log('Worksheet is succesussfully created.', res);
                    setShowWorksheetModal(false);
                    const worksheetId = res.meta.worksheet._id;
                    console.log('worksheetId---', worksheetId);
                    navigate(`/editor/${worksheetId}`);
                }, error => {
                    setShowWorksheetModal(false);
                    showErrorMessage('Fail to create worksheet!');
                });
        } catch (error) {
            console.log(error);
            setShowWorksheetModal(false);
            showErrorMessage('Fail to create worksheet!');
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            setKeyboardDelete(true);
        }
        console.log(event.key);
    };

    const handleInputChange = (data: string | undefined) => {
        const text = data ? data : '';
        console.log('handleInputChange ......',text,data);
        dispatch(setText(text));
    };

    useEffect(() => {
        return () => {
            dispatch(resetWorksheet());
        };
    }, []);

    useEffect(() => {
        console.log('---------', id, 'worksheet > id', worksheet.id);
        if (!teacherInfoChange) {
            getTeacherInfo();
            getClassNameCall();
        }

        if (id != worksheet.id) {
            console.log('id != worksheet.id', id, worksheet.id);
            if (type) getTemplateDetailCall();
            else getWorksheetDetailCall();
        }

        // if (changeItem && currentItem) {
        //     console.log('in change item useEffect');
        //     handleBlur(currentItem);
        //     setChangeItem(false);
        // } else {
        //     dispatch(setCurrentItem(changeItemData));
        //     setChangeItem(false);
        //     console.log('set here', changeItem);
        // }

        if (editItem) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }

        if (isMoveDone) {
            console.log('isMoveDone :', movePosition);
            console.log('currentItem:', currentItem.location);
            if (type === 'template') updateTemplateApiCall(currentItem);
            else updateApiCall(currentItem);
            setIsMoveDone(false);
        }

        if (isResizeDone) {
            if (type === 'template') updateTemplateApiCall(currentItem);
            else updateApiCall(currentItem);
            setIsResizeDone(false);
        }

        if (saveClick && currentItem) {
            if (type === 'template') updateTemplateApiCall(currentItem);
            else updateApiCall(currentItem);
        } else if (saveClick && !currentItem) {
            setSaveClick(false);
        }

        if (keyboardDelete && !editItem) {
            type !== 'template'
                ? handleDeleteComponent()
                : handleDeleteTemplateComponent();
            setKeyboardDelete(false);
        }

        if (isClone && !editItem && currentItem) {
            if(type === 'template') handleTemplateClone();
            else handleClone();
        } else {
            setIsClone(false);
        }

        if (isBlur) {
            console.log('blur 88888888888. . . . . . . . . . . . . . :', currentItem);
            handleBlur(currentItem);
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [
        id,
        changeItem,
        editItem,
        isMoveDone,
        saveClick,
        worksheet,
        isResizeDone,
        keyboardDelete,
        isClone,
        isBlur
    ]);

    return (
        <>
            {showAlert && (
                <CustomAlert
                    alertData={alertData}
                    alertClose={() => setShowAlert(false)}
                ></CustomAlert>
            )}

            <Modal
                modalProps={modalData}
                isOpen={showWorksheetModal}
                toggle={toggleWorksheetModal}
                cancelModal={handleCancel}
                currentModalType={currentModalType}
            >
                {showWorksheetModal && renderModal(currentModalType)}
            </Modal>
            <TeacherModal
                showModal={showModal}
                modalLoading={modalLoading}
                modalData={modalData}
                editItem={editItem}
                position={position}
                components={components} // this is for before api
                currentItem={currentItem}
                existBackground={existBackground}
                handleCloseModal={toggleModal}
                handleDataUpdate={handleDataUpdate}
                handleSizeUpdate={handleSizeUpdate}
                studentList={studentList}
                updateStudentListById={updateStudentListById}
                addGroupTool={addGroupTool}
                handleAddComponent={handleAddComponentFromModal}
                handleAddComponentForGrading={handleAddComponentForGrading}
            />

            <EditorNavbar
                handleNavBtnAction={handleNavBtnAction}
                isTeacher={true}
                worksheetDetail={worksheet}
                saveClick={saveClick}
                isViewSubmissions={false}
                isEditLayout={false}
                isLocked={worksheetDetail.isLocked}
            />
            <Toolbar
                handleToolbarBtnAction={handleToolbarBtnAction}
                handleEditLayoutBtnAction={handleEditLayoutBtnAction}
                handleQR={handleQRAction}
                isEditLayout={isEditLayout}
                currentItem={currentItem}
                submissionCount={worksheetDetail.submissionCount}
                worksheet={worksheet}
                profile={teacherProfile}
                className={className}
            />

            {worksheet.mode == '' ? (<CanvasLoading type='teacher'></CanvasLoading>):(<CommonCanvas
                mode={worksheet.mode}
                parentType={type==='template'?PARENT_TYPE_CONSTANTS.template:PARENT_TYPE_CONSTANTS.teacher}
                toggleModal={toggleModal}
                handleModalData={handleModalData}
                handleTempPosition={handleTempPosition}
                handleEdit={handleEdit}
                handleClickOutside={handleClickOutside}
                addComponent={handleAddComponent}
                deleteComponent={type !== 'template'
                    ? handleDeleteComponent
                    : handleDeleteTemplateComponent}
                handleBlur={() => setIsBlur(true)}
                updateCurrentItem={updateCurrentItem}
                handleUpdatePosition={handleUpdatePosition}
                handleBgDelete={checkTemplateOrWorksheetBgDelete}
                handleBgEdit={handleBgEdit}
                handleTextChange={handleTextChange}
                handleResize={handleResize}
                publishedStatus={worksheet.publishedStatus}
                handleClone={() => setIsClone(true)}
                components={components}
                isEditLayout={isEditLayout}
                currentDragItemType={currentDragItemType}
                editItem={editItem}
                currentItem={currentItem}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                handleTempSize={handleTempSize}
                totalScore={totalScore}
                totalBase={totalBase}
                isText={isText}
                studentId={''}

                //ui change
                handleFontChange={(data: string) => dispatch(setFont(data))}
                handleFontSizeChange={(data: string) => dispatch(setFontSize(data))}
                handleFontWeightChange={(data: string) => dispatch(setFontWeight(data))}
                handleItalic={(data: boolean) => dispatch(setFontStyle(data))}
                handleUnderline={(data: boolean) => dispatch(setTextDecoration(data))}
                handleColorChange={(data: string) => dispatch(setFontColor(data))}
                handleBorderChange={(data: string) => dispatch(setBorder(data))}
                handleBackgroundChange={(data: string) => dispatch(setBackground(data))}
                handlePopupIconClicked={(data: string) => dispatch(setPopupIconType(data))}
                updateActiveItem={(isText: boolean) => dispatch(setIsText(isText))}
                handleInputChange={handleInputChange}
                handleIsBack={(value: boolean) => dispatch(setIsBack(value))}
                handleCanvas={(value: any) => dispatch(setCanvas(value))}
                changeCurrentInputType={function (type: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleStudentInputClick={function (item: DraggableElement): void {
                    throw new Error('Function not implemented.');
                }}
                addText={function (text: string, id: string): void {
                    throw new Error('Function not implemented.');
                }}
                textUploaded={false}
                updateText={function (text: string, id: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleChatLogClick={function (item: ChatLogDataModel): void {
                    throw new Error('Function not implemented.');
                }}
                openModal={function (id: string): void {
                    throw new Error('Function not implemented.');
                }}
                handleDelete={function (): void {
                    throw new Error('Function not implemented.');
                }}
                handleReleaseFocus={function (): void {
                    throw new Error('Function not implemented.');
                }}
                handleStudentInputUpdate={function (id: string): void {
                    throw new Error('Function not implemented.');
                }}
                triggerStudentInput={false}
                onGradeChange={() => {
                    throw new Error('Function not implemented');

                }}
                onFeedbackChange={() => {
                    throw new Error('Function not implemented');

                }}
                handleClosePopup={() => dispatch(setCurrentItem(null))} />)}

            
        </>
    );
};

export default Teacher;
