import React, { FC } from 'react';
import ListItem from '../list-item/list-item';
import './list.css';
import { Worksheet } from '../../../../models/worksheet-item.model';

export interface IProps {
    worksheetList: Worksheet[];
    clickMenu: (type: string, item: Worksheet) => void;
    keyword?: string | null;
}

const List: FC<IProps> = ({ worksheetList, clickMenu, keyword }) => {
    const handleClickMenu = (type: string, item: Worksheet) => {
        clickMenu(type, item);
    };

    return (
        <>
            <div className="list-wrapper">
                <div className="list">
                    {!keyword && worksheetList.map((item: Worksheet, index: any) => (
                        <ListItem
                            key={index}
                            item={item}
                            handleClickMenu={handleClickMenu}
                        />
                    ))}
                    {keyword && worksheetList.map((item: Worksheet, index: any) => {
                        if (item.name.toLowerCase().includes(keyword.toLowerCase()))
                            return (<ListItem
                                key={index}
                                item={item}
                                handleClickMenu={handleClickMenu}
                            />);
                    })}
                </div>
            </div>
        </>
    );
};

export default List;
