import React, { FC, useState } from 'react';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
import './pdf-upload.css';
export interface IProps {
    closeModal: () => void;
    modalLoading: boolean;
    uploadComponent: (file: File | undefined) => void;
}
const PdfUpload: FC<IProps> = ({ closeModal, uploadComponent, modalLoading }) => {
    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Cancel',
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel',
        iconClass: undefined,
    };

    const insertBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Insert',
        disable: false,
        btnText: 'Insert',
        uiClass: 'insert',
        iconClass: undefined,

    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Insert',
        uiClass: 'disabled',
        iconClass: undefined,
        loadingText: 'Uploading'
    };

    const [uploadItem, setUploadItem] = useState<File | undefined>(undefined);
    const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    const [uploadError, setUploadError] = useState<boolean>(false);
    const [url, setUrl] = useState<string | null>(null);

    const handleInputChange = (e: any) => {
        const element = e.target.files[0];
        const url = URL.createObjectURL(e.target.files[0]);
        console.log('url', url, element, element.type.includes('pdf'));
        if (element.type.includes('pdf')) {
            setUploadItem(element);
            setUrl(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const updateElement = () => {
        console.log('update', 'editItem ');
        closeModal();
    };

    const renderElement = () => {
        console.log('render', 'editItem ', uploadItem);
        uploadComponent(uploadItem);
        // closeModal();
    };

    const handleBtnClicked = (type: string | undefined) => {
        switch (type) {
            case 'Cancel':
                if (!modalLoading)
                    closeModal();
                break;
            case 'Upload':
                console.log('hello');
                break;
            case 'Insert':
                renderElement();
                // if (
                //     TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND ===
                //         currentDragItemType &&
                //     existBackground
                // ) {
                //     updateElement();
                // } else if (
                //     TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND !=
                //         currentDragItemType &&
                //     editItem
                // ) {
                //     updateElement();
                // } else {
                //     renderElement();
                // }

                break;
            default:
                closeModal();
        }
    };

    const handleOnDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const url = URL.createObjectURL(e.dataTransfer.files[0]);
        const element = e.dataTransfer.files[0];
        console.log('pdf type : ', element.type);
        if (element.type.includes('pdf')) {
            setUploadItem(element);
            setUrl(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const handleOnDragEnter = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragLeave = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            {uploadItem && (
                <div className="upload-item bg-s10">
                    <div className='file-info'>File name : {uploadItem.name}</div>
                </div>
            )}
            {!uploadItem && (
                <div
                    className="drag-drop bg-s10"
                    onDrop={handleOnDrop}
                    onDragEnter={handleOnDragEnter}
                    onDragLeave={handleOnDragLeave}
                    onDragOver={handleOnDragOver}
                >
                    <label className="custom-file-upload body-m text-s0">
                        <input type="file" accept="application/pdf" onChange={handleInputChange} />
                        Upload Pdf
                    </label>
                    <div className="text-s70 body-xl placeholder">
                        {uploadError
                            ? 'File type is wrong or corrupted. Please upload another.'
                            : 'Drag & drop pdfs here'}
                    </div>
                </div>
            )}

            <div className="modal-btn-group">
                <Button
                    btnProps={cancelBtnProps}
                    handleBtnClicked={() =>
                        handleBtnClicked(cancelBtnProps.alt)
                    }
                ></Button>
                {uploadItem ? (
                    <Button
                        btnProps={
                            modalLoading ? disabledBtnProps : insertBtnProps
                        }
                        isLoading={modalLoading}
                        handleBtnClicked={() =>
                            handleBtnClicked(insertBtnProps.alt)
                        }
                    ></Button>
                ) : (
                    <Button
                        btnProps={disabledBtnProps}
                        handleBtnClicked={() =>
                            handleBtnClicked(disabledBtnProps.alt)
                        }
                    ></Button>
                )}
            </div>
        </>
    );
};

export default PdfUpload;