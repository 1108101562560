import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import CustomLoading from '../../../../global-components/loading/loading';
import './create-loading.css';

// export interface IProps {
// }
const CreateLoading = () => {

    return (
        <>
            <div className='create-loading-wrapper'>
                <CustomLoading loadingData='createLoading1'></CustomLoading>
                <CustomLoading loadingData='createLoading2'></CustomLoading>
                <CustomLoading loadingData='createLoading3'></CustomLoading>
                <CustomLoading loadingData='createLoading4'></CustomLoading>
                <CustomLoading loadingData='createLoading5'></CustomLoading>
                <CustomLoading loadingData='createLoading6'></CustomLoading>
                <div className="custom-modal-footer mt-123">
                    <CustomLoading loadingData='createLoading7'></CustomLoading>
                    <CustomLoading loadingData='createLoading7'></CustomLoading>
                </div>
            </div>
        </>
    );
};

export default CreateLoading;