
import { timeStamp } from 'console';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
// import { useSelector } from 'react-redux';
// import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
// import { DraggableElement } from '../../../../models/draggable-element.model';
import { ElementDataModel } from '../../../../models/element-data.model';
// import { PointModel } from '../../../../models/point.model';
// import {
//     addComponent,
//     setCurrentDragItemType,
//     toggleEditItem,
// } from '../../../teacher/slices/worksheetSlice';
import './video-upload.css';
import LCover from '../../../../assets/images/landscape.png';
import PCover from '../../../../assets/images/portrait.png';
export interface IProps {
    closeModal: () => void;
    modalLoading: boolean;
    uploadComponent: (file: File | undefined) => void;
}
const VideoUpload: FC<IProps> = ({
    closeModal,
    modalLoading,
    uploadComponent
}) => {
    // const dispatch = useDispatch();

    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Cancel',
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel',
        iconClass: undefined,
    };

    const insertBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Insert',
        disable: false,
        btnText: 'Insert',
        uiClass: 'insert',
        iconClass: undefined,
    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Insert',
        uiClass: 'disabled',
        iconClass: undefined,
        loadingText: 'Uploading'
    };

    const { isMobile } = useSelector((state: any) => state.student);

    const [uploadItem, setUploadItem] = useState<File | undefined>(undefined);
    const [url, setUrl] = useState<string | null>(null);
    const [preview, setPreview] = useState<string | null>(null);
    // const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    const [uploadError, setUploadError] = useState<boolean>(false);
    const [recordVideo, setRecordVideo] = useState<boolean>(false);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [blobUrl, setBlobUrl] = useState<string>('');
    const [streamData, setStreamData] = useState<MediaStream | null>(null);
    const [isSupport, setIsSupport] = useState(true);
    const videoRef = useRef<HTMLVideoElement>(null);
    const recordBtnRef = useRef<HTMLButtonElement>(null);
    const stopBtnRef = useRef<HTMLButtonElement>(null);
    const calculateVideoDimension = (url: string) => {
        const video: HTMLVideoElement = document.createElement('video');

        video.onloadedmetadata = function () {
            console.log('onLoad', video.videoWidth);
            const videoWidth = video.videoWidth > 450 ? 450 : video.videoWidth;
            const videoHeight =
                video.videoHeight > 450
                    ? calculateVideoHeight(video.videoWidth, video.videoHeight)
                    : video.videoHeight;
            console.log('after calc ', videoWidth, videoHeight);
            // setElementSize({ width: videoWidth, height: videoHeight });
        };
        video.src = url;
    };

    const calculateVideoHeight = (width: number, height: number) => {
        console.log((height * 450) / width);
        return (height * 450) / width;
    };
    const handleInputChange = (e: any) => {
        const element = e.target.files[0];
        const url = URL.createObjectURL(e.target.files[0]);
        console.log('url', url, element);
        if (element.type.includes('video')) {
            setUploadItem(element);
            setUrl(url);
            calculateVideoDimension(url);
            console.log('video::::::', element);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const updateElement = () => {
        console.log('update');
        const data: ElementDataModel = {
            text: null,
            score: {
                mark: null,
                base: null,
            },
            url: url,
            chatLog: null,
            style: null,
            iconType: null,
            descText: null,
            descStyle: null,
            isBack: false
        };
        closeModal();
    };

    const renderElement = () => {
        console.log('render');
        uploadComponent(uploadItem);
    };

    const handleBtnClicked = (type: string | undefined) => {
        console.log('handleBtnClicked', type);
        switch (type) {
            case 'Cancel':
                if (!modalLoading)
                    closeModal();
                if (streamData) {
                    streamData.getTracks() // get all tracks from the MediaStream
                        .forEach(track => track.stop()); // stop each of them
                }
                break;
            case 'Upload':
                console.log('hello');
                break;
            case 'Insert':
                renderElement();
                // if (editItem) {
                //     updateElement();
                // } else {
                //     renderElement();
                // }

                break;
            default:
                closeModal();
        }
    };

    const handleOnDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const url = URL.createObjectURL(e.dataTransfer.files[0]);
        const element = e.dataTransfer.files[0];
        if (element.type.includes('video')) {
            setUploadItem(element);
            setUrl(url);
            calculateVideoDimension(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const handleOnDragEnter = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragLeave = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleRecordVideo = () => {
        setRecordVideo(true);
        const getStreamUrl = () => {
            navigator.mediaDevices
                .getUserMedia({ audio: true, video: true }) //for record
                // .getDisplayMedia({ //for screen record
                //     audio: true,
                //     video: true,
                // })
                .then((stream) => {

                    let parts: any[] = [];
                    setStreamData(stream);
                    try {
                        const mediaRecorder = new MediaRecorder(stream);
                        const video = videoRef.current;
                        video!.srcObject = stream;
                        const isPlaying =
                            video!.currentTime > 0 &&
                            !video!.paused &&
                            !video!.ended &&
                            video!.readyState > video!.HAVE_CURRENT_DATA;
                        if (!isPlaying) {
                            video!.play();
                        }
                        console.info('record start');
                        if (mediaRecorder) {
                            mediaRecorder.start(1000);
                            mediaRecorder.ondataavailable = (e: any) => {
                                parts.push(e.data);
                            };
                            console.log('Recording . . . .', mediaRecorder);
                            setIsRecording(true);
                            if (stopBtnRef && stopBtnRef.current) {
                                const stopBtn = stopBtnRef.current;
                                stopBtn.addEventListener('click', (e: any) => {
                                    if (mediaRecorder) {
                                        console.info('record stop');
                                        stream.getTracks() // get all tracks from the MediaStream
                                            .forEach(track => track.stop()); // stop each of them
                                        mediaRecorder.stop();
                                        const blob = new Blob(parts, {
                                            type: 'video/webm',
                                        });
                                        const myBlob = new Blob(parts, {
                                            type: 'video/mp4',
                                        });
                                        const url = webkitURL.createObjectURL(myBlob);
                                        // const a = document.createElement('a');
                                        // a.style.display = 'none';
                                        // a.href = url;
                                        // a.download = 'aungpo.mp4';
                                        // a.click();
                                        // console.log('Recording stop . . . .');
                                        setIsRecording(false);
                                        parts = [];
                                        setBlobUrl(url);
                                        const file = new File([blob], 'recording.webm', { type: blob.type, lastModified: Date.now() });
                                        setUploadItem(file);
                                        console.log('====================================');
                                        console.log('file', file);
                                        console.log('====================================');
                                        calculateVideoDimension(url);
                                    }
                                });
                            }
                        }

                    } catch (e) {
                        console.log(e);
                        setIsRecording(false);
                        setIsSupport(false);
                        // media recorder don't support
                    }

                }).catch((error) => {
                    console.log('error . . . . . :', error);
                    setIsRecording(false);
                    setIsSupport(false);
                });
        };

        getStreamUrl();
    };

    useEffect(() => {
        console.log('====================================');
        console.log(navigator.userAgent);
        console.log('====================================');

        const mac = /(iPhone|iPod|iPad)/i.test(navigator.platform);
        const safari = /(Safari)/i.test(navigator.userAgent);
        const chrome = /(Chrome)/i.test(navigator.userAgent);
        const firefox = /(Firefox)/i.test(navigator.userAgent);
        if (mac) {
            setPreview(isMobile ? PCover : LCover);
        } else if (safari && !chrome && !firefox) {
            setPreview(isMobile ? PCover : LCover);
        }
    }, []);


    return (
        <>
            {uploadItem && !recordVideo && (
                <div className="upload-item bg-s10">
                    {preview && <video poster={preview} controls playsInline src={url ? url : ''} />}
                    {!preview && <video controls playsInline src={url ? url : ''} />}

                </div>
            )}
            {
                recordVideo && (
                    <div className="live-item bg-s10">
                        {blobUrl.length == 0 && <video className="live-video" ref={videoRef} muted></video>}
                        {blobUrl.length > 0 && preview && (
                            <video poster={preview} className="live-video" controls src={blobUrl} playsInline></video>
                        )}
                        {blobUrl.length > 0 && !preview && (
                            <video className="live-video" controls src={blobUrl} playsInline></video>
                        )}
                        {blobUrl.length == 0 && isSupport && <div className="live-item-btn">
                            <button ref={stopBtnRef} className="stop-btn text-s0 body-m">Stop</button>
                        </div>}
                        {blobUrl.length == 0 && !isSupport && <div className="live-item-btn">
                            <button className="stop-btn text-s0 body-m">Start</button>
                        </div>}

                        {isRecording && (<span className="recording-text body-m">Recording...</span>)}
                        {!isSupport && (<span className="recording-text body-m">Your browser does not support recording.</span>)}

                    </div>
                )
            }
            {!uploadItem && !recordVideo && (
                <div
                    className="drag-drop bg-s10"
                    onDrop={handleOnDrop}
                    onDragEnter={handleOnDragEnter}
                    onDragLeave={handleOnDragLeave}
                    onDragOver={handleOnDragOver}
                >
                    <div className="btn-gp-for-modal">
                        <label className="body-m text-s0">
                            <input type="file" accept="video/*" onChange={handleInputChange} />
                            Upload video
                        </label>
                        {!isMobile && <button className="text-s0 body-m" onClick={handleRecordVideo}>
                            Record Video
                        </button>}

                    </div>

                    <div className="text-s70 body-xl placeholder">
                        {uploadError
                            ? 'File type is wrong or corrupted. Please upload another.'
                            : 'Drag & drop videos here'}
                    </div>
                </div>
            )}

            <div className="modal-btn-group">
                <Button
                    btnProps={cancelBtnProps}
                    handleBtnClicked={() =>
                        handleBtnClicked(cancelBtnProps.alt)
                    }
                ></Button>
                {uploadItem && (
                    <Button
                        btnProps={modalLoading ? disabledBtnProps : insertBtnProps}
                        isLoading={modalLoading}
                        handleBtnClicked={() =>
                            handleBtnClicked(insertBtnProps.alt)
                        }
                    ></Button>
                )}

                {!uploadItem && (
                    <Button
                        btnProps={disabledBtnProps}
                        handleBtnClicked={() =>
                            handleBtnClicked(disabledBtnProps.alt)
                        }
                    ></Button>
                )}
            </div>
        </>
    );
};

export default VideoUpload;
