import { render } from '@testing-library/react';
import React, { FC, useState } from 'react';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
import ModalWithFooter from '../../global-components/modal-with-footer/ModalWithFooter';
import Modal from '../../global-components/modal/modal';
import { BtnModel } from '../../models/btn.model';
import { CustomModal } from '../../models/custom-modal.model';
import AudioUpload from './components/audio-upload/AudioUpload';
import GalleryUpload from './components/gallery-upload/GalleryUpload';
import PdfUpload from './components/pdf-upload/PdfUpload';
import VideoUpload from './components/video-upload/VideoUpload';
export interface IProps {
    showModal: boolean;
    modalData: CustomModal;
    handleCloseModal: () => void;
    currentInputType: string;
    uploadComponent: (file: File | undefined) => void;
    modalLoading: boolean;
}

const StudentModal: FC<IProps> = ({
    showModal,
    modalData,
    handleCloseModal,
    currentInputType,
    uploadComponent,
    modalLoading
}) => {
    const [isGroupTool, setIsGroupTool] = useState<boolean>(false);

    const handleStudentCloseModal = () => {
        if (modalLoading) {
            console.log('modalLoading', modalLoading);
        } else {
            handleCloseModal();
        }
    };

    const renderChildren = () => {
        switch (currentInputType) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.GALLERY:
                return <GalleryUpload modalLoading={modalLoading} closeModal={handleStudentCloseModal} uploadComponent={uploadComponent} />;
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.VIDEO:
                console.log(currentInputType);
                return <VideoUpload modalLoading={modalLoading} closeModal={handleStudentCloseModal} uploadComponent={uploadComponent} />;
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.AUDIO:
                console.log(currentInputType);
                return <AudioUpload modalLoading={modalLoading} closeModal={handleStudentCloseModal} uploadComponent={uploadComponent} />;
                break;
            case TOOLBAR_BTN_ACTION_CONSTANTS.PDF:
                console.log(currentInputType);
                return <PdfUpload modalLoading={modalLoading} closeModal={handleStudentCloseModal} uploadComponent={uploadComponent} />;
                break;
            default:
                console.log(currentInputType);
        }
        return <div>student {currentInputType}</div>;
    };

    const btnList: BtnModel[] = [];

    const getBtnList = () => {
        if (isGroupTool) {
            const cancelBtnProps: BtnModel = {
                icon: undefined,
                alt: 'Cancel',
                disable: false,
                btnText: 'Cancel',
                uiClass: 'cancel',
                iconClass: undefined,
            };

            const addBtnProps: BtnModel = {
                icon: undefined,
                alt: 'Add',
                disable: false,
                btnText: 'Add',
                uiClass: 'insert',
                iconClass: undefined,
            };

            btnList.push(cancelBtnProps);
            btnList.push(addBtnProps);
        }
        return btnList;
    };

    const handleModalBtnClicked = (type: string) => {
        console.log('footer btn clicked ', type);
        switch (type) {
            case 'Cancel':
                console.log('Cancel clicked');
                break;
            case 'Add':
                handleStudentCloseModal();
                // if (editItem) {
                //     updateStudentListById(uiStudentList);
                // } else {
                //     renderGroupTool();
                // }
                break;
            default:
                console.log('type ', type);
        }
    };

    return (
        <>
            {showModal && !isGroupTool ? (
                <Modal
                    modalProps={modalData}
                    isOpen={showModal}
                    toggle={handleStudentCloseModal}
                    cancelModal={handleStudentCloseModal}
                >
                    {renderChildren()}
                </Modal>
            ) : showModal && isGroupTool ? (
                <ModalWithFooter
                    modalProps={modalData}
                    isOpen={showModal}
                    toggle={handleStudentCloseModal}
                    cancelModal={handleStudentCloseModal}
                    btnList={getBtnList()}
                    btnClicked={handleModalBtnClicked}
                >
                    {renderChildren()}
                </ModalWithFooter>
            ) : (
                <></>
            )}
        </>
    );
};

export default StudentModal;
