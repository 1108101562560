import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import Button from '../../../../global-components/button/Button';
import SearchInput from '../../../../global-components/search-input/search-input';
import { BtnModel } from '../../../../models/btn.model';
import CheckList from './components/checklist/CheckList';
import './group.css';
export interface IProps {
    studentList: any;
    closeModal: () => void;
    handleCheckboxClicked: (id: string, value: boolean) => void;
    handleAllChecked: (value: boolean) => void;
}
const Group: FC<IProps> = ({
    studentList,
    handleCheckboxClicked,
    handleAllChecked,
}) => {
    const [tempStudentList, setTempStudentList] = useState<any>([]);

    const typedInput = (keyword: string | null) => {
        if (keyword) {
            const list: any = [];
            studentList.map((student: any) => {
                // console.log('student name ', student.name);
                console.log(student.name.includes(keyword), keyword);
                if (
                    student.name.toLowerCase().includes(keyword.toLowerCase())
                ) {
                    list.push(student);
                }
            });
            setTempStudentList(list);
        } else {
            setTempStudentList(studentList);
        }
    };

    const clickedCheckbox = (id: string, value: boolean) => {
        console.log('clickedCheckbox');
        handleCheckboxClicked(id, value);
    };

    const checkedAll = (value: boolean) => {
        handleAllChecked(value);
    };

    useEffect(() => {
        console.log('useEffect in Group .....');
        setTempStudentList(studentList);
        //   first

        //   return () => {
        //     second
        //   };
    }, [studentList]);

    return (
        <div className="group-wrapper">
            <SearchInput
                hint={'Search student'}
                handleSearch={typedInput}
                width={'430px'}
            />
            <div className="group-list">
                <CheckList
                    studentList={tempStudentList}
                    handleCheckbox={clickedCheckbox}
                    handleAllChecked={checkedAll}
                />
            </div>
        </div>
    );
};

export default Group;
