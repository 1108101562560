import { render } from '@testing-library/react';
import { upload } from '@testing-library/user-event/dist/upload';
import { exit } from 'process';
import React, { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { ElementDataModel } from '../../../../models/element-data.model';
import { PointModel } from '../../../../models/point.model';
import {
    addComponent,
    setCurrentDragItemType,
    toggleEditItem,
} from '../../../teacher/slices/worksheetSlice';
import './gallery-upload.css';
export interface IProps {
    closeModal: () => void;
    modalLoading: boolean;
    uploadComponent: (file: File | undefined) => void;
}
const GalleryUpload: FC<IProps> = ({ closeModal, uploadComponent, modalLoading }) => {
    const dispatch = useDispatch();

    const cancelBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Cancel',
        disable: false,
        btnText: 'Cancel',
        uiClass: 'cancel',
        iconClass: undefined,
    };

    const insertBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Insert',
        disable: false,
        btnText: 'Insert',
        uiClass: 'insert',
        iconClass: undefined,

    };

    const disabledBtnProps: BtnModel = {
        icon: undefined,
        alt: 'Disabled',
        disable: false,
        btnText: 'Insert',
        uiClass: 'disabled',
        iconClass: undefined,
        loadingText: 'Uploading'
    };

    const { isMobile } = useSelector((state: any) => state.student);
    const [recordImage, setRecordImage] = useState<boolean>(false);
    const [isSupport, setIsSupport] = useState<boolean>(false);
    const [streamData, setStreamData] = useState<MediaStream | null>(null);

    const [uploadItem, setUploadItem] = useState<File | undefined>(undefined);
    const [elementSize, setElementSize] = useState({ width: 0, height: 0 });
    const [uploadError, setUploadError] = useState<boolean>(false);
    const [url, setUrl] = useState<string | null>(null);
    const [blobUrl, setBlobUrl] = useState<string | null>(null);

    const videoRef = useRef<HTMLVideoElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const handleInputChange = (e: any) => {
        const element = e.target.files[0];
        const url = URL.createObjectURL(e.target.files[0]);
        console.log('url', url, element, element.type.includes('image'));
        if (element.type.includes('image')) {
            setUploadItem(element);
            setUrl(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const updateElement = () => {
        console.log('update', 'editItem ');
        closeModal();
    };

    const renderElement = () => {
        console.log('render', 'editItem ', uploadItem);
        uploadComponent(uploadItem);
        // closeModal();
    };

    const handleBtnClicked = (type: string | undefined) => {
        switch (type) {
            case 'Cancel':
                if (!modalLoading)
                    closeModal();
                if (streamData) {
                    streamData.getTracks() // get all tracks from the MediaStream
                        .forEach(track => track.stop()); // stop each of them
                }
                break;
            case 'Upload':
                console.log('hello');
                break;
            case 'Insert':
                renderElement();
                // if (
                //     TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND ===
                //         currentDragItemType &&
                //     existBackground
                // ) {
                //     updateElement();
                // } else if (
                //     TOOLBAR_BTN_ACTION_CONSTANTS.BACKGROUND !=
                //         currentDragItemType &&
                //     editItem
                // ) {
                //     updateElement();
                // } else {
                //     renderElement();
                // }

                break;
            default:
                closeModal();
        }
    };

    const handleOnDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const url = URL.createObjectURL(e.dataTransfer.files[0]);
        const element = e.dataTransfer.files[0];
        if (element.type.includes('image')) {
            setUploadItem(element);
            setUrl(url);
            setUploadError(false);
        } else {
            setUploadError(true);
        }
    };

    const handleOnDragEnter = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragLeave = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOnDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleCaptureImage = () => {
        setRecordImage(true);
        setIsSupport(true);
        navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((stream)=>{
            try{
                setStreamData(stream);
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.start(1000);
                if(videoRef.current)
                    videoRef.current.srcObject = stream;
                if(btnRef.current){
                    btnRef.current.addEventListener('click', function() {
                        console.log('hello');
                        if(canvasRef.current && canvasRef.current.getContext('2d') && videoRef.current){
                            canvasRef.current.getContext('2d')?.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                            canvasRef.current.toBlob((blob) => {
                                if(blob){
                                    const url = webkitURL.createObjectURL(blob);
                                    setBlobUrl(url);
                                    const file = new File([blob], 'capture.jpeg', { type: 'image/jpeg' });
                                    setUploadItem(file);
                                    console.log(file);
                                }
                                    
                            }, 'image/jpeg');
                        }
                        if(mediaRecorder){
                            console.info('record stop');
                            stream.getTracks() // get all tracks from the MediaStream
                                .forEach(track => track.stop()); // stop each of them
                            mediaRecorder.stop();
                        }
                        console.log(uploadItem);
                    });
                }
                
            }catch(error){
                console.log(error);
                setRecordImage(false);
                setIsSupport(false);
            }
            
        }).catch((error)=>{
            console.log('error . . . . . :', error);
            setRecordImage(false);
            setIsSupport(false);
        });
    };

    return (
        <>
            {uploadItem && url && !blobUrl && (
                <div className="upload-item bg-s10">
                    <img src={url ? url : ''} alt="" />
                </div>
            )}
            {uploadItem && !url && blobUrl && (
                <div className="upload-item bg-s10">
                    <img src={blobUrl ? blobUrl : ''} alt="" />
                </div>
            )}
            {recordImage && !uploadItem && (
                <div className="live-item bg-s10">
                    {isSupport && (
                        <>
                            <video className='live-video' ref={videoRef} autoPlay></video>
                            <div className="live-item-btn">
                                <button className='stop-btn text-s0 body-m' ref={btnRef}>Capture</button>
                            </div>
                        </>
                    )}
                    
                    
                    <canvas style={{display:'none'}}ref={canvasRef} width="320" height="240"></canvas>
                    {!isSupport && <span className="recording-text body-m">Your browser does not support recording.</span>}
                </div>
            )}

            {!uploadItem && !recordImage && (
                <div
                    className="drag-drop bg-s10"
                    onDrop={handleOnDrop}
                    onDragEnter={handleOnDragEnter}
                    onDragLeave={handleOnDragLeave}
                    onDragOver={handleOnDragOver}
                >
                    <div className="btn-gp-for-modal">
                        <label className="body-m text-s0">
                            <input type="file" accept="image/*" onChange={handleInputChange} />
                            Upload image
                        </label>
                        {!isMobile && <button className="text-s0 body-m" onClick={handleCaptureImage}>
                            Capture Image
                        </button>}

                    </div>
                    <div className="text-s70 body-xl placeholder">
                        {uploadError
                            ? 'File type is wrong or corrupted. Please upload another.'
                            : 'Drag & drop images here'}
                    </div>
                </div>
            )}

            <div className="modal-btn-group">
                <Button
                    btnProps={cancelBtnProps}
                    handleBtnClicked={() =>
                        handleBtnClicked(cancelBtnProps.alt)
                    }
                ></Button>
                {uploadItem ? (
                    <Button
                        btnProps={
                            modalLoading ? disabledBtnProps : insertBtnProps
                        }
                        isLoading={modalLoading}
                        handleBtnClicked={() =>
                            handleBtnClicked(insertBtnProps.alt)
                        }
                    ></Button>
                ) : (
                    <Button
                        btnProps={disabledBtnProps}
                        handleBtnClicked={() =>
                            handleBtnClicked(disabledBtnProps.alt)
                        }
                    ></Button>
                )}
            </div>
        </>
    );
};

export default GalleryUpload;
