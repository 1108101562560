import React, { FC, useEffect, useRef, useState } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';
import './background-tool.css';

import EditIcon from '../../../../assets/images/pencil_l.svg';
import DeleteIcon from '../../../../assets/images/delete_l.svg';

export interface IProps {
    component: DraggableElement;
    handleItemClicked: () => void;
    editItem: boolean;
    readonly?: boolean;
    handleClickOutside: () => void;
    handleDelete: () => void;
    handleEdit: () => void;
}
const BackgroundTool: FC<IProps> = ({
    component,
    handleItemClicked,
    editItem,
    readonly = false,
    handleClickOutside,
    handleDelete,
    handleEdit,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const handleTextChange = (e: any) => {
        console.log(e.target.value);
    };


    const [putSide, setPutSide] = useState<boolean>(false);

    const clickedItem = () => {
        handleItemClicked();
    };

    const getImageUrl = () => {
        if (component.data && component.data.url) {
            return component.data.url;
        } else {
            return '';
        }
    };

    useEffect(() => {
        if (component.size.width > window.screen.width) setPutSide(false);
        else setPutSide(true);
    }, [component]);

    return (
        <>
            {
                !readonly &&
                <div
                    className={putSide ? 'put-side' : 'put-float'}
                    style={{
                        left: putSide
                            ? component.size.width - 112 + 'px'
                            : '',
                    }}
                >
                    <img
                        src={EditIcon}
                        alt=""
                        className="cursor"
                        onClick={handleEdit}
                    />
                    <img
                        src={DeleteIcon}
                        alt=""
                        className="cursor"
                        onClick={handleDelete}
                    />
                </div>
            }
            <div
                className="canvas-bg"
                id="canvas-bg"
                ref={ref}
                onClick={clickedItem}
                style={{
                    // border:
                    //     currentItem && currentItem.id == component.id
                    //         ? '2px solid #8CC4FF'
                    //         : 'none',
                    // borderRadius:
                    //     currentItem && currentItem.id == component.id
                    //         ? '1px'
                    //         : 'none',
                    width: component.size.width + 'px',
                    height: component.size.height + 'px',
                    backgroundImage: `url(${getImageUrl()})`,
                }}
            ></div>
        </>

    );
};

export default BackgroundTool;
