import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import { Activity } from '../../models/activity-log.model';
import { Worksheet } from '../../models/worksheet-item.model';
import { AuthService } from '../../service/auth.service';

const _authService = new AuthService();

const baseUrl = process.env.REACT_APP_WORKSHEET_API_URL + 'api/v1/';

export const getWorksheets = createAsyncThunk(
    'worksheet/getWorksheets',
    async () => {
        const apiurl = baseUrl + 'worksheets';
        const headers = _authService.getHeadersWithLessonId();
        const response = await axios.get(apiurl, { headers });
        const res = response.data.worksheets;
        const worksheetArray: Worksheet[] = [];
        res.map((worksheet: any) => {
            const worksheetObj: Worksheet = {
                id: worksheet._id,
                name: worksheet.name,
                thumbnailUrl: worksheet.thumbnailUrl,
                publishedStatus: worksheet.publishedStatus,
                isPublished:
                    worksheet.publishedStatus === 'published' ? true : false,
                isLocked: worksheet.isLocked,
                labelId: worksheet.labelId,
                label: worksheet.label != undefined ? worksheet.label : null,
                mode: worksheet.mode,
                students:
                    worksheet.studentId != undefined &&
                    worksheet.studentId != null
                        ? worksheet.studentId
                        : [],
            };
            worksheetArray.push(worksheetObj);
        });
        console.log('worksheetArray', worksheetArray);
        return worksheetArray;
    }
);

export const createWorksheetApi = createAsyncThunk(
    'worksheet/createWorksheet',
    async (body: any) => {
        const apiurl = baseUrl + 'worksheets';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiurl, body, { headers });
        console.log('createWorksheetApi------', response);
        return response.data;
    }
);

export const deleteWorksheetApi = createAsyncThunk(
    'worksheet/deleteWorksheet',
    async (worksheetId: string) => {
        const apiurl = baseUrl + 'worksheets/' + worksheetId;
        const headers = _authService.getHeadersInfo();
        const response = await axios.delete(apiurl, { headers });
        console.log('deleteWorksheetApi------', response);
        return response.data;
    }
);

export const useAsTemplateApi = createAsyncThunk(
    'worksheet/useAsTemplate',
    async (worksheetId: string) => {
        const apiurl =
            baseUrl + 'worksheets/' + worksheetId + '/use_as_template';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiurl, null, { headers });
        console.log('useAsTemplate', response);
        return response.data;
    }
);

export const publishWorksheetApi = createAsyncThunk(
    'worksheet/publishWorksheet',
    async (data: any) => {
        const apiurl =
            baseUrl + 'worksheets/' + data.worksheetId + '/publish_assign';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiurl, data.body, { headers });
        console.log('publishWorksheetApi', response);
        return response.data;
    }
);

export const uploadThumbnail = createAsyncThunk(
    'worksheet/uploadThumbnail',
    async (body: FormData) => {
        // const { id, body } = payload;
        // console.log('upload thumbnail', body);
        const apiurl = baseUrl + 'worksheets/thumbnail';
        const headers = _authService.getHeadersInfo();
        const response = await axios.post(apiurl, body, { headers });
        return response.data;
    }
);

export const deleteThumbnail = createAsyncThunk(
    'worksheet/deleteThumbnail',
    async (worksheetId: string) => {
        const apiurl = baseUrl + 'worksheets/' + worksheetId + '/thumbnail';
        const headers = _authService.getHeadersInfo();
        const response = await axios.delete(apiurl, { headers });
        console.log('deleteThumbnail------', response);
        return response.data;
    }
);
const formatDateTime = (dateTime: string) => {
    if (!dateTime || dateTime === '') return '';

    const dt = new Date(dateTime);

    return {
        date: moment(dt).format('DD MMM YYYY'),
        time: moment(dt).format('hh:mm A'),
    };
};

const mapActivityLog = (activityLogResponse: any) => {
    const activityLog: Activity[] = [];

    console.log('logResponse', activityLogResponse);

    activityLogResponse.map((activity: any) => {
        const formatedDateTime = formatDateTime(activity.updatedAt);
        //console.log('dateTime', formatedDateTime, activity.updatedAt);

        const activityObj: Activity = {
            id: activity._id,
            studentname: activity.actorUserInfo.username,
            initials: '',
            profile: '',
            action: activity.action.text,
            date: formatedDateTime != '' ? formatedDateTime.date : '',
            time: formatedDateTime != '' ? formatedDateTime.time : '',
        };

        if (activity.actorUserInfo.generatedProfileImage) {
            
            activityObj.initials = activity.actorUserInfo.generatedProfileImage.initials;
            activityObj.profile = activity.actorUserInfo.generatedProfileImage.profileImage;
            
        } else {
            activityObj.initials = activityObj.studentname.length >= 2 ? activityObj.studentname.slice(0, 2) : activityObj.studentname.charAt(0);
            activityObj.profile = '';
        }

        activityLog.push(activityObj);
    });

    return activityLog;
};

export const getActivityLog = createAsyncThunk(
    'worksheet/activityLogs',
    async (payload: any) => {
        const { id, page, limit } = payload;
        const apiUrl =
            baseUrl +
            'activity-logs?worksheetId=' +
            id +
            '&page=' +
            page +
            '&limit=' +
            limit;
        const headers = _authService.getHeadersInfo();
        const response = await axios.get(apiUrl, { headers });
        const activityLog: Activity[] = [];

        if (response.data) {
            activityLog.push(...mapActivityLog(response.data.activityLogs));

            return { activityLog: activityLog, totalLogs: response.data.total };
        }

        return { activityLog: [], totalLogs: 0 };
    }
);
