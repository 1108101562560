import './color-picker.css';
import React, { FC, useEffect, useRef, useState } from 'react';
import ChevronDownIcon from '../../assets/images/chevron_down_s.svg';
import { useSelector } from 'react-redux';
import { current } from '@reduxjs/toolkit';
import { setCurrentItem } from '../../features/teacher/slices/worksheetSlice';
import { SketchPicker } from 'react-color';
export interface IProps {
    type: string;
    handleColorChange: (color: string) => void;
}
const ColorPicker: FC<IProps> = ({ handleColorChange, type }) => {
    const colorRef = useRef<HTMLInputElement>(null);
    const [color, setColor] = useState<string>('');
    const [showBox, setShowBox] = useState<boolean>(false);
    const isText = useSelector((state: any) => state.worksheet.isText);
    const currentItem = useSelector(
        (state: any) => state.worksheet.currentItem
    );

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setColor(e.target.value);
    //     handleColorChange(color);
    // };

    const handleChange = (value: any) => {
        setColor(value.hex);
        handleColorChange(value.hex);
    };


    const handleBlur = () => {
        console.log('handle blur');
        // handleColorChange(color);
    };

    const handleClick = () => {
        setShowBox(!showBox);
    };

    const handleClose = () => {
        setShowBox(false);
    };

    const getColor = () => {
        console.log(
            currentItem.data.style.fontColor,
            currentItem.data.descStyle.fontColor,
            currentItem.data.style.border,
            currentItem.data.style.background
        );
        if (type == 'color') {
            if (isText) {
                return currentItem.data.style.fontColor;
            }
            return currentItem.data.descStyle.fontColor;
        } else if (type === 'border') {
            return currentItem.data.style.border ? currentItem.data.style.border : '#000000';
        }
        else if (type === 'background') {
            return currentItem.data.style.background ? currentItem.data.style.background : '#ffffff';
        }

    };

    useEffect(() => {
        setColor(getColor);
        setShowBox(false);
        console.log('color:', color);
    }, [isText]);

    return (<>
        {/* <div className="color-picker-wrapper">
            <img
                src={ChevronDownIcon}
                alt="down"
                onClick={() => colorRef.current?.click()}
            />
            <input
                ref={colorRef}
                type="color"
                id="color-picker"
                onChange={handleChange}
                onBlur={handleBlur}
                value={color}
            />
           
        </div> */}

        <div style={{
            marginLeft: '16px'
        }}>
            <div className='swatch' onClick={handleClick}>
                <div className='color' style={{
                    background: color
                }} />
                <img
                    src={ChevronDownIcon}
                    alt="down"
                />
            </div>
            {showBox ? <div className='pop-over'>
                <div className='cover' onClick={handleClose} />
                <SketchPicker color={color} onChange={handleChange} />
            </div> : null}

        </div>



    </>



    );
};

export default ColorPicker;
