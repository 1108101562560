import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../constants/toolbarBtnActionConstants';
import { setMobileDragPosition } from '../../features/teacher/slices/worksheetSlice';
import { DraggableElement } from '../../models/draggable-element.model';
import { IconBtnModel } from '../../models/icon-btn.model';
import { PointModel } from '../../models/point.model';
import './icon-button.css';
export interface IProps {
    btnProps: IconBtnModel;
    handleBtnClicked: (
        e: React.MouseEvent<MouseEvent | HTMLDivElement>
    ) => void;
    handleDragStartAction?: (e: any, type: string) => void;
    handleDragEndAction?: () => void;
    currentSelected?: boolean;
}
const IconButton: FC<IProps> = ({
    btnProps,
    handleBtnClicked,
    handleDragStartAction,
    handleDragEndAction,
    currentSelected,
}) => {
    const clickedIcon = (e: React.MouseEvent<MouseEvent | HTMLDivElement>) => {
        handleBtnClicked(e);
    };

    const dispatch = useDispatch();

    const [isHover, setIsHover] = useState<boolean>(false);

    const handleDragStart = (e: any, type: string) => {
        if (handleDragStartAction) handleDragStartAction(e, type);
    };

    const handleTouchStart = (e: any, type: string) => {
        console.log('touch start:', type);
        if (handleDragStartAction) handleDragStartAction(e.touches[0], type);
    };

    const handleDragEnd = () => {
        if (handleDragEndAction) handleDragEndAction();
    };

    const handleMove = (e: any, type: string) => {
        const touch = e.touches[0] || e.changedTouches[0];
        const x = touch.pageX;
        const y = touch.pageY;

        // if (handleDragStartAction) handleDragStartAction(e.touches[0], type);
    };

    const handleTouchEnd = (e: any, type: string) => {
        if (type !== 'delete' && type !== 'edit' && type !== 'move') {
            if (handleDragEndAction) handleDragEndAction();
            const touch = e.touches[0] || e.changedTouches[0];
            const position: PointModel = {
                x: touch.pageX,
                y: touch.pageY,
            };
            console.log('touch end', touch.pageX, touch.pageY);
            console.log('update mobile drag');
            dispatch(setMobileDragPosition(position));
        }
    };
    const currentItem = useSelector(
        (state: any) => state.worksheet.currentItem
    );

    return (
        <>
            {
                btnProps.draggable && (<div
                    className={btnProps.uiClass}
                    onClick={(e) => clickedIcon(e)}
                    draggable={btnProps.draggable}
                    onDragStart={(e) => handleDragStart(e, btnProps.alt)}
                    onDragEnd={handleDragEnd}
                    onTouchStart={(e) => handleTouchStart(e, btnProps.alt)}
                    onTouchMove={(e) => handleMove(e, btnProps.alt)}
                    onTouchEnd={(e) => handleTouchEnd(e, btnProps.alt)}
                    style={{
                        pointerEvents: btnProps.disable == true ? 'none' : 'auto',
                        opacity: btnProps.disable == true ? '0.5' : ''
                    }}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                >
                    <img draggable={btnProps.draggable} src={btnProps.activeIcon && (currentSelected || isHover) ? btnProps.activeIcon : btnProps.icon} alt={btnProps.alt} />
                    {currentSelected && btnProps.uiClass == 'toolbar-icon' && <div className="active-bar">

                    </div>}

                </div>)
            }

            {
                !btnProps.draggable && (<div
                    className={btnProps.uiClass}
                    onClick={(e) => clickedIcon(e)}
                    draggable={false}
                    onDragStart={(e) => { e.preventDefault(); }}
                    // onDragEnd={handleDragEnd}
                    // onTouchStart={(e) => handleTouchStart(e, btnProps.alt)}
                    // onTouchMove={(e) => handleMove(e, btnProps.alt)}
                    // onTouchEnd={(e) => handleTouchEnd(e, btnProps.alt)}
                    style={{
                        pointerEvents: btnProps.disable == true ? 'none' : 'auto',
                        opacity: btnProps.disable == true ? '0.5' : ''
                    }}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                >
                    <img src={btnProps.activeIcon && (currentSelected || isHover) ? btnProps.activeIcon : btnProps.icon} alt={btnProps.alt} />
                    {currentSelected && btnProps.uiClass == 'toolbar-icon' && <div className="active-bar">

                    </div>}
                </div>)
            }

        </>
    );
};

export default IconButton;
