import React, { FC, useEffect, useState } from 'react';
import './submission-toolbar.css';
import '../../../../assets/css/main.css';
import TextIcon from '../../../../assets/images/text.svg';
import TextActiveIcon from '../../../../assets/images/toolbar-active/textActive.svg';
import ScoreIcon from '../../../../assets/images/task.svg';
import ScoreActiveIcon from '../../../../assets/images/toolbar-active/gradingActive.svg';
import GradingIcon from '../../../../assets/images/score.svg';
import GradingActiveIcon from '../../../../assets/images/toolbar-active/scoreActive.svg';
import FeedbackIcon from '../../../../assets/images/feedback_icon.svg';
import FeedbackActiveIcon from '../../../../assets/images/toolbar-active/feedbackActive.svg';
import DefaultUserIcon from '../../../../assets/images/user.svg';
import DefaultUseHoverIcon from '../../../../assets/images/user_stroke_hover.svg';
import HistoryIcon from '../../../../assets/images/history_l.svg';
import HistoryActiveIcon from '../../../../assets/images/toolbar-active/history_l_active.svg';

import { TOOLTIP_CONSTANTS } from '../../../../constants/toolTipConstants';
import IconButton from '../../../../global-components/icon-button/IconButton';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import { TOOLBAR_BTN_ACTION_CONSTANTS } from '../../../../constants/toolbarBtnActionConstants';
import Button from '../../../../global-components/button/Button';
import { BtnModel } from '../../../../models/btn.model';
import { PointModel } from '../../../../models/point.model';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentDragItemType, setCurrentItem } from '../../view-submission-slice';
import UserProfile from '../../../../global-components/user-profile/UserProfile';
import { Profile } from '../../../../models/profile.model';
export interface IProps {
    handleToolbarBtnAction: (type: string | undefined) => void;
    handleShareBtnAction: () => void;
    handleHistory: () => void;
    handleQR: () => void;
    isEditLayout: boolean;
    currentItem: DraggableElement;
    profile?: Profile;
    className?: string | null;
}
const Toolbar: FC<IProps> = ({
    handleToolbarBtnAction,
    handleShareBtnAction,
    handleHistory,
    handleQR,
    isEditLayout,
    currentItem,
    profile,
    className
}) => {
    const [currentRefId, setCurrentRefId] = useState('');
    const [showUserProfile, setShowUserProfile] = useState(false);

    const dispatch = useDispatch();

    const deviceType = useSelector((state: any) => state.worksheet.deviceType);

    const toolBarIconList: IconBtnModel[] = [
        // {
        //     icon: ScoreIcon,
        //     activeIcon: ScoreActiveIcon,
        //     alt: TOOLBAR_BTN_ACTION_CONSTANTS.SCORE,
        //     uiClass: 'toolbar-icon',
        //     disable: false,
        //     draggable: true,
        // },
        // {
        //     icon: GradingIcon,
        //     activeIcon: GradingActiveIcon,
        //     alt: TOOLBAR_BTN_ACTION_CONSTANTS.GRADING,
        //     uiClass: 'toolbar-icon',
        //     disable: false,
        //     draggable: true,
        // },
        {
            icon: FeedbackIcon,
            activeIcon: FeedbackActiveIcon,
            alt: TOOLBAR_BTN_ACTION_CONSTANTS.FEEDBACK,
            uiClass: 'toolbar-icon',
            disable: false,
            draggable: true,
        }
    ];

    const shareBtnProps: BtnModel = {
        icon: undefined,
        alt: undefined,
        disable: false,
        btnText: 'Share',
        uiClass: 'share-btn',
        iconClass: undefined,
    };

    const historyBtnProps: IconBtnModel = {
        icon: HistoryIcon,
        activeIcon: HistoryActiveIcon,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.JOURNAL,
        uiClass: 'history-icon',
        disable: false,
        draggable: false,
    };

    const userBtnProps: IconBtnModel = {
        icon: DefaultUserIcon,
        activeIcon: DefaultUseHoverIcon,
        alt: TOOLBAR_BTN_ACTION_CONSTANTS.USER,
        uiClass: 'user-icon',
        disable: false,
        draggable: false,
    };

    const handleIconAction = (type: string | undefined) => {
        handleToolbarBtnAction(type);

        if (type === TOOLBAR_BTN_ACTION_CONSTANTS.USER)
            setShowUserProfile(!showUserProfile);
        else
            setShowUserProfile(false);
    };

    const handleProfileAction = () => {
        handleQR();
        setShowUserProfile(false);
    };

    const handleShareBtn = () => {
        handleShareBtnAction();
    };

    const handleDragStartAction = (e: any, type: string) => {
        console.log('handleDragStartAction', isEditLayout, type);
        if (isEditLayout) {
            createGhostText(e, type);
            dispatch(setCurrentDragItemType(type));
            dispatch(setCurrentItem(null));
        }

        // createGhostText(e, type);
        // dispatch(setCurrentDragItemType(type));
        // dispatch(setCurrentItem(null));

    };

    const handleDragEndAction = () => {

        console.log('handleDragEndAction', isEditLayout);

        if (isEditLayout) {
            const elem = document.getElementById(currentRefId);
            const parent = document.getElementById('student-canvas');
            if (elem && parent) {
                parent.removeChild(elem);
                console.log('text is back to normal');
            }
            setCurrentRefId('');
        }

        // const elem = document.getElementById(currentRefId);
        // const parent = document.getElementById('student-canvas');
        // if (elem && parent) {
        //     parent.removeChild(elem);
        //     console.log('text is back to normal');
        // }
        // setCurrentRefId('');

    };

    // const navigate = useNavigate();

    // const handleHistory = () => {
    //     navigate(`/activities/${21}`);
    // };

    const getIcon = (type: string) => {
        switch (type) {
            case TOOLBAR_BTN_ACTION_CONSTANTS.SCORE:
                return ScoreIcon;
            case TOOLBAR_BTN_ACTION_CONSTANTS.GRADING:
                return GradingIcon;
            default:
                return TextIcon;
        }
    };

    console.log('CurrentItem > submission-toolbar', currentItem);
    console.log('isEditLayout > submission-toolbar', isEditLayout);

    const createGhostText = (e: any, type: string) => {
        const ghostEle = document.createElement('img');
        ghostEle.src = getIcon(type);
        ghostEle.style.width = '40px';
        ghostEle.style.height = '40px';
        ghostEle.style.position = 'absolute';
        ghostEle.style.zIndex = '10';
        ghostEle.id = 'refText';
        ghostEle.style.bottom = '-150px';

        // console.log('event ', e);
        if (deviceType === 'Mobile') {
            // e.target = ghostEle;
            console.log('cannot add ghost image');
        } else if (deviceType === 'Desktop') {
            const parent = document.getElementById('student-canvas');
            if (parent) {
                parent.appendChild(ghostEle);
            }
            e.dataTransfer.setDragImage(ghostEle, 0, 0);
        }

        setCurrentRefId('refText');
        // setCurrentDragItemType(ToolType.text);
    };

    return (
        <>
            <div className="tool-bar bg-b100">
                <div
                    className="icon-group"
                    style={{ pointerEvents: isEditLayout ? 'all' : 'none' }}
                >
                    {toolBarIconList.map((toolbarIcon: IconBtnModel) => {
                        return (
                            <React.Fragment key={toolbarIcon.alt}>
                                <IconButton
                                    currentSelected={
                                        currentItem &&
                                        currentItem.type === toolbarIcon.alt
                                    }
                                    btnProps={toolbarIcon}
                                    handleBtnClicked={() =>
                                        handleIconAction(toolbarIcon.alt)
                                    }
                                    handleDragStartAction={
                                        handleDragStartAction
                                    }
                                    handleDragEndAction={handleDragEndAction}
                                />
                                {/* {toolbarIcon.alt ===
                                    TOOLBAR_BTN_ACTION_CONSTANTS.POPUP && (
                                        <div className="line"></div>
                                    )} */}
                            </React.Fragment>
                        );
                    })}
                </div>
                <div className="btn-group">
                    <div className='mr-30'>
                        <Button
                            btnProps={shareBtnProps}
                            handleBtnClicked={handleShareBtn}
                        />
                    </div>
                    {/* <div className="journal-wrapper">
                        <div className="notification">
                            <span className="small text-r300">
                                {submissionCount}
                            </span>
                        </div>
                        <IconButton
                            btnProps={journalBtnProps}
                            handleBtnClicked={() =>
                                handleIconAction(journalBtnProps.alt)
                            }
                        />
                    </div> */}

                    <IconButton
                        btnProps={historyBtnProps}
                        handleBtnClicked={() =>
                            handleHistory()
                        }
                    />

                    {/* <div className="history" onClick={handleHistory}>
                        <img src={HistoryIcon} alt="" />
                    </div> */}

                    <div style={{ position: 'relative' }}>
                        <IconButton
                            btnProps={userBtnProps}
                            currentSelected={showUserProfile}
                            handleBtnClicked={() =>
                                handleIconAction(userBtnProps.alt)
                            }
                        />
                        {showUserProfile && profile && <UserProfile className={className} profile={profile} showQR={() =>
                            handleProfileAction()
                        }></UserProfile>}
                    </div>

                    {/* <IconButton
                        btnProps={userBtnProps}
                        handleBtnClicked={() =>
                            handleIconAction(userBtnProps.alt)
                        }
                    /> */}
                </div>
            </div>
        </>
    );
};

export default Toolbar;
