import React, { FC, useEffect, useState } from 'react';
export interface IProps {
    studentId: string;
    sheetId: string;
    componentId: string;
    changeActiveStatus: boolean;
    resetActiveStatus: () => void;
    changeActiveStudents: (array: any) => void;
    submitStatus: any;
    studentName: any;
    releaseStatus: any;
    callFirebaseFirst: boolean;
    setColorList: (colorList: any[]) => void;
    setFocusList: (focusList: any[]) => void;
}

import firebase from '../../../../firebase-config';
import {
    ref,
    set,
    onDisconnect,
    onValue,
    push,
    serverTimestamp,
    remove,
} from 'firebase/database';
import { useSelector } from 'react-redux';
import { current } from '@reduxjs/toolkit';
import { ChatLogDataModel } from '../../../../models/chat-log-data.model';



const FirebaseStudent: FC<IProps> = ({ studentId, sheetId, componentId,
    changeActiveStatus, resetActiveStatus, changeActiveStudents,
    studentName,
    submitStatus,
    releaseStatus, callFirebaseFirst,
    setColorList,
    setFocusList
}) => {
    const db = firebase;
    const tabID = sessionStorage.tabID;

    const { studentStatusList } = useSelector((state: any) => state.worksheet);
    const { currentItem } = useSelector((state: any) => state.student);


    const connectedRef = ref(db, '.info/connected');
    const colorArray = [
        '#52CC85',
        '#FFDB26',
        '#FF9E99',
        '#5D5FEF',
        '#EF5DA8',
        '#CC8800',
        '#BF2926',
        '#52DCD4',
        '#C780FF',
        '#409E9E',
        '#BFB926',
        '#28AAE1'
    ];

    useEffect(() => {
        if (callFirebaseFirst) firstConnected();
        onValue(connectedRef, (snap) => {
            if (snap.val() === true) {
                // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
                // const con = push(myConnectionsRef);
                // When I disconnect, remove this device
                // onDisconnect(con).remove();

                // Add this device to my connections list
                // this value could contain info about the device or a timestamp too
                // set(con, true);

                const worksheetRef = ref(db, `worksheet/${sheetId}/${tabID}`);
                const colorRef = ref(db, `colors/${sheetId}/${tabID}`);
                // When I disconnect, update the last time I was seen online
                onDisconnect(worksheetRef).remove();
                onDisconnect(colorRef).remove();
            }
        });
        onValue(ref(db, `worksheet/${sheetId}`), (snapshot) => {
            console.log('change . . . . . .', snapshot.val());
            //if data changes it will update
            changeActiveStudents(snapshot.val());
        }, {
            onlyOnce: false
        });
        onValue(ref(db, `colors/${sheetId}`), (snapshot) => {
            console.log('color changes . . . . . .', snapshot.val());
            if (snapshot.val()) {
                const covert = Object.keys(snapshot.val()).map(function (key) {
                    return snapshot.val()[key];
                });
                console.log('color change', covert);
                setColorList(covert);
            }
        }, {
            onlyOnce: false
        });
        // onValue(ref(db, `focus/${sheetId}`), (snapshot) => {
        //     console.log('focus changes . . . . . .', snapshot.val());
        //     if (snapshot.val()) {
        //         const covert = Object.keys(snapshot.val()).map(function (key) {
        //             return snapshot.val()[key];
        //         });
        //         console.log('focus change', covert);
        //         setFocusList(covert);
        //     }
        // }, {
        //     onlyOnce: false
        // });

        console.log('firebase');

        if (changeActiveStatus) {
            if (componentId.length > 0) {
                postStudentAction(studentName, studentId, sheetId, componentId, 'focus');
            }

            else {
                console.log('====================================');
                console.log('componentId: ', componentId);
                console.log('====================================');
            }
        }

        if (submitStatus.componentId.length > 0) {
            console.log('submitStatus change');
            postStudentAction(studentName, submitStatus.studentId, submitStatus.sheetId, submitStatus.componentId, 'submit');
            setTimeout(() => {
                postStudentAction(studentName, submitStatus.studentId, submitStatus.sheetId, submitStatus.componentId, 'browsing');
            }, 500);
        }

        if (releaseStatus.studentId && releaseStatus.sheetId) {
            postStudentAction(studentName, releaseStatus.studentId, releaseStatus.sheetId, releaseStatus.componentId, 'browsing');
        }



    }, [changeActiveStatus, submitStatus, releaseStatus, studentName, callFirebaseFirst]);

    const firstConnected = () => {
        onValue(ref(db, `colors/${sheetId}`), (snapshot) => {
            console.log('Colors set . . . . . .', snapshot.val());
            if (snapshot.val()) {
                const covert = Object.keys(snapshot.val()).map(function (key) {
                    return snapshot.val()[key];
                });
                console.log('color', covert);
                const index = covert.findIndex((item: any) => item.tabID === tabID);
                if (index == -1 && covert.length < colorArray.length) {
                    // reduce colors 
                    const covertColor = covert.map((item: any) => item.color);
                    console.log('covertColor:', covertColor);
                    const mutatedArray = colorArray;
                    covertColor.map((color: string) => {
                        const indexInMap = colorArray.findIndex((item: string) => item === color);
                        colorArray.splice(indexInMap, 1);
                    });
                    console.log('mutatedArray:', mutatedArray);
                    // alert('mutatedArray' + covert.length);

                    set(ref(db, `colors/${sheetId}/${tabID}`), {
                        id: studentId,
                        color: mutatedArray[0],
                        name: studentName.name,
                        tabID: tabID
                    });
                } else if (index == -1 && covert.length >= colorArray.length) {

                    const indexForColor = covert.length % colorArray.length;
                    alert(indexForColor);
                    set(ref(db, `colors/${sheetId}/${tabID}`), {
                        id: studentId,
                        color: colorArray[indexForColor],
                        name: studentName.name,
                        tabID: tabID
                    });
                }
            } else {
                set(ref(db, `colors/${sheetId}/${tabID}`), {
                    id: studentId,
                    color: colorArray[0],
                    name: studentName.name,
                    tabID: tabID
                });
            }
        }, {
            onlyOnce: true
        });
        postStudentAction(studentName, studentId, sheetId, componentId, 'browsing');
    };

    const postStudentAction = (
        studentName: any, studentId: string, sheetId: string, componentId: string, action: string
    ) => {
        const timestamp = new Date().toISOString();
        console.log('componentId:', componentId, studentName, action);
        set(ref(db, `worksheet/${sheetId}/${tabID}`), {
            username: studentName.name,
            url: studentName.url,
            initials: studentName.initials,
            id: studentId,
            componentId: componentId,
            parent: currentItem ? currentItem.id : '',
            userAction: action,
            createdAt: timestamp,
            updatedAt: timestamp,
            tabID: tabID
        });
        // if (action === 'focus' && componentId) {
        //     set(ref(db, `focus/${sheetId}/${componentId}`), {
        //         username: studentName.name,
        //         componentId: componentId,
        //         tabID: tabID,
        //     });
        // } else if (action !== 'focus' && componentId) {
        //     set(ref(db, `focus/${sheetId}/${componentId}`), {
        //         username: studentName.name,
        //         componentId: componentId,
        //         tabID: '',
        //     });
        // }
        resetActiveStatus();
    };

    return (
        <></>
    );
};

export default FirebaseStudent;