import React, { FC, useEffect, useRef, useState } from 'react';
import { DraggableElement } from '../../../../models/draggable-element.model';
import { Font } from '../../../../models/font.model';
import './popup-tool.css';
import CancelStrokeIcon from '../../../../assets/images/cancel_stroke.svg';
import { IconBtnModel } from '../../../../models/icon-btn.model';
import IconButton from '../../../../global-components/icon-button/IconButton';

import InfoIcon from '../../../../assets/images/popup/info_circle_stroke.svg';
import QuestionMarkIcon from '../../../../assets/images/popup/question_circle_stroke.svg';
import AlertIcon from '../../../../assets/images/popup/alert_circle_stroke.svg';
import CheckIcon from '../../../../assets/images/popup/check.svg';
import SearchIcon from '../../../../assets/images/popup/search_s.svg';
import Icon1 from '../../../../assets/images/popup/1.svg';
import Icon2 from '../../../../assets/images/popup/2.svg';
import Icon3 from '../../../../assets/images/popup/3.svg';
import Icon4 from '../../../../assets/images/popup/4.svg';
import Icon5 from '../../../../assets/images/popup/5.svg';
import Icon6 from '../../../../assets/images/popup/6.svg';
import Icon7 from '../../../../assets/images/popup/7.svg';
import Icon8 from '../../../../assets/images/popup/8.svg';
import Icon9 from '../../../../assets/images/popup/9.svg';
import { POPUP_ICON_CONSTANTS } from '../../../../constants/popupIconConstants';
import { PARENT_TYPE_CONSTANTS } from '../../../../constants/parentTypeConstants';

export interface IProps {
    parentType: string;
    component: DraggableElement;
    currentItem: DraggableElement | null;
    handleItemClicked: () => void;
    editItem: boolean;
    updateActiveItem: (isText: boolean) => void;
    handleTextChange?: (text: string, descText: string) => void;
    handleClosePopup: () => void;
}

const PopupTool: FC<IProps> = ({
    parentType,
    component,
    currentItem,
    handleItemClicked,
    editItem,
    updateActiveItem,
    handleTextChange,
    handleClosePopup
}) => {
    const titleRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    const popRef = useRef<HTMLDivElement>(null);
    // const [editText, setEditText] = useState<boolean>(false);
    // const [editHintText, setEditHintText] = useState<boolean>(false);
    const cancelBtnProps: IconBtnModel = {
        icon: CancelStrokeIcon,
        alt: 'cancelStroke',
        uiClass: 'cancel-popup',
        disable: false,
        draggable: false,
    };

    const handleTitleChange = (e: any) => {
        console.log(titleRef.current?.textContent, currentItem);
        // if (titleRef.current?.textContent && handleTextChange && bodyRef.current?.textContent)
        if (handleTextChange)
            handleTextChange(titleRef.current?.textContent ? titleRef.current?.textContent : '', bodyRef.current?.textContent ? bodyRef.current?.textContent : '');
    };

    const handleBodyChange = (e: any) => {
        console.log(bodyRef.current?.textContent, currentItem);
        // if (bodyRef.current?.textContent && handleTextChange && titleRef.current?.textContent)
        if (handleTextChange)
            handleTextChange(titleRef.current?.textContent ? titleRef.current?.textContent : '', bodyRef.current?.textContent ? bodyRef.current?.textContent : '');
    };

    const clickedItem = () => {
        handleItemClicked();
    };

    const handleBtnClicked = (e: any) => {
        // console.log(e);
    };

    const prepareClosePopup = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        handleClosePopup();
    };

    const getIcon = (type: string | null) => {
        switch (type) {
            case POPUP_ICON_CONSTANTS.INFO:
                return InfoIcon;

            case POPUP_ICON_CONSTANTS.QUESTION_MARK:
                return QuestionMarkIcon;

            case POPUP_ICON_CONSTANTS.ALERT:
                return AlertIcon;

            case POPUP_ICON_CONSTANTS.CHECK:
                return CheckIcon;

            case POPUP_ICON_CONSTANTS.SEARCH:
                return SearchIcon;

            case POPUP_ICON_CONSTANTS.ICON1:
                return Icon1;

            case POPUP_ICON_CONSTANTS.ICON2:
                return Icon2;

            case POPUP_ICON_CONSTANTS.ICON3:
                return Icon3;

            case POPUP_ICON_CONSTANTS.ICON4:
                return Icon4;

            case POPUP_ICON_CONSTANTS.ICON5:
                return Icon5;

            case POPUP_ICON_CONSTANTS.ICON6:
                return Icon6;

            case POPUP_ICON_CONSTANTS.ICON7:
                return Icon7;

            case POPUP_ICON_CONSTANTS.ICON8:
                return Icon8;

            case POPUP_ICON_CONSTANTS.ICON9:
                return Icon9;

            default:
                return InfoIcon;
        }
    };

    const getIconAlt = (type: string | null) => {
        switch (type) {
            case POPUP_ICON_CONSTANTS.INFO:
                return POPUP_ICON_CONSTANTS.INFO;

            case POPUP_ICON_CONSTANTS.QUESTION_MARK:
                return POPUP_ICON_CONSTANTS.QUESTION_MARK;

            case POPUP_ICON_CONSTANTS.ALERT:
                return POPUP_ICON_CONSTANTS.ALERT;

            case POPUP_ICON_CONSTANTS.CHECK:
                return POPUP_ICON_CONSTANTS.CHECK;

            case POPUP_ICON_CONSTANTS.SEARCH:
                return POPUP_ICON_CONSTANTS.SEARCH;

            case POPUP_ICON_CONSTANTS.ICON1:
                return POPUP_ICON_CONSTANTS.ICON1;

            case POPUP_ICON_CONSTANTS.ICON2:
                return POPUP_ICON_CONSTANTS.ICON2;

            case POPUP_ICON_CONSTANTS.ICON3:
                return POPUP_ICON_CONSTANTS.ICON3;

            case POPUP_ICON_CONSTANTS.ICON4:
                return POPUP_ICON_CONSTANTS.ICON4;

            case POPUP_ICON_CONSTANTS.ICON5:
                return POPUP_ICON_CONSTANTS.ICON5;

            case POPUP_ICON_CONSTANTS.ICON6:
                return POPUP_ICON_CONSTANTS.ICON6;

            case POPUP_ICON_CONSTANTS.ICON7:
                return POPUP_ICON_CONSTANTS.ICON7;

            case POPUP_ICON_CONSTANTS.ICON8:
                return POPUP_ICON_CONSTANTS.ICON8;

            case POPUP_ICON_CONSTANTS.ICON9:
                return POPUP_ICON_CONSTANTS.ICON9;

            default:
                return POPUP_ICON_CONSTANTS.INFO;
        }
    };

    const iconBtnProps: IconBtnModel = {
        icon: getIcon(component.data.iconType),
        alt: getIconAlt(component.data.iconType),
        uiClass: 'popup-component-icon',
        disable: false,
        draggable: false,
    };

    const idForTextTool = 'component-' + component.id;
    const fontStyle: Font | null = component.data.style;
    const descFontStyle: Font | null = component.data.descStyle;

    const handleTextClicked = (type: string) => {
        if (type === 'text') {
            updateActiveItem(true);
        } else {
            updateActiveItem(false);
        }
    };

    useEffect(() => {
        document.body.style.cursor = 'auto';
        titleRef.current && titleRef.current.focus();
        parentType === PARENT_TYPE_CONSTANTS.teacher && updateActiveItem(true);
        console.log('popup useEffect', currentItem);
    }, []);

    return (
        <>
            <div
                id={idForTextTool}
                className="popup-tool"
                onClick={clickedItem}
                ref={popRef}
                style={{
                    width: component.size.width && currentItem && currentItem.id === component.id
                        ? component.size.width + 'px'
                        : 'fit-content',
                    height: component.size.height && currentItem && currentItem.id === component.id
                        ? component.size.height + 54 + 'px'
                        : 'fit-content',
                }}
            >
                <IconButton
                    btnProps={iconBtnProps}
                    handleBtnClicked={handleBtnClicked}
                />
                <div
                    className="popup"
                    style={{
                        display:
                            currentItem && currentItem.id === component.id
                                ? 'block'
                                : 'none',
                    }}
                >
                    <IconButton
                        btnProps={cancelBtnProps}
                        handleBtnClicked={prepareClosePopup}
                    />
                    <div
                        className="popup-content"
                        style={{
                            width: component.size.width
                                ? component.size.width + 'px'
                                : '323px',
                            height:
                                component.size.height + 'px'
                                    ? component.size.height
                                    : '112px',
                        }}
                    >
                        <div
                            onClick={() => handleTextClicked('text')}
                            className="popup-title"
                            ref={titleRef}
                            onInput={handleTitleChange}
                            contentEditable={editItem}
                            suppressContentEditableWarning={true}
                            tabIndex={-1}
                            style={{
                                fontFamily: fontStyle!.fontFamily
                                    ? fontStyle!.fontFamily
                                    : 'Inter',
                                fontSize: fontStyle!.fontSize
                                    ? fontStyle!.fontSize
                                    : '12px',
                                fontWeight: fontStyle!.fontWeight,
                                fontStyle: fontStyle!.fontStyle
                                    ? fontStyle!.fontStyle
                                    : 'normal',
                                textDecoration: fontStyle!.textDecoration
                                    ? fontStyle!.textDecoration
                                    : 'none',
                                color: fontStyle!.fontColor
                                    ? fontStyle!.fontColor
                                    : '',
                                transition: 'font-family 1s ease 0s',
                            }}
                        >
                            {component.data.text}
                        </div>
                        <div
                            onClick={() => handleTextClicked('hint')}
                            className="popup-body"
                            ref={bodyRef}
                            onInput={handleBodyChange}
                            contentEditable={editItem}
                            suppressContentEditableWarning={true}
                            tabIndex={-1}
                            style={{
                                fontFamily: descFontStyle!.fontFamily
                                    ? descFontStyle!.fontFamily
                                    : 'Inter',
                                fontSize: descFontStyle!.fontSize
                                    ? descFontStyle!.fontSize
                                    : '12px',
                                fontWeight: descFontStyle!.fontWeight,
                                fontStyle: descFontStyle!.fontStyle
                                    ? descFontStyle!.fontStyle
                                    : 'normal',
                                textDecoration: descFontStyle!.textDecoration
                                    ? descFontStyle!.textDecoration
                                    : 'none',
                                color: descFontStyle!.fontColor
                                    ? descFontStyle!.fontColor
                                    : '',
                                transition: 'font-family 1s ease 0s',
                            }}
                        >
                            {component.data.descText}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PopupTool;
